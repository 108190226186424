import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const TelecommandPicker = ({
  defaultsRecord,
  expanded,
  toggleExpanded,
  components,
  openState,
  openDialog,
  jsonPreview,
  setTcFrame,
}) => {
  const classes = useStyles();
  const { dsClient } = useContext(DeepstreamContext);
  const [tctmSettingsRecord, setTctmSettingsRecord] = useState({ SCID: 0, Rev: 0, VC: 0 });
  const { currentResGroup, tctmCreateFunction } = useContext(RgContext);

  useEffect(() => {
    const getTctmSettings = async () => {
      setTctmSettingsRecord(
        await dsClient.record.snapshot(`rg/${currentResGroup}/@c3s/tctm-transcoder-provider/settings`),
      );
    };
    getTctmSettings();
  }, [currentResGroup, dsClient]);

  const loadDefaults = useCallback((dialogId) => defaultsRecord.get(dialogId) ?? {}, [defaultsRecord]);
  const saveDefaults = useCallback(
    ({ dialogId, data }) => {
      defaultsRecord.set(dialogId, data);
    },
    [defaultsRecord],
  );

  const { SCID, Rev, VC } = tctmSettingsRecord;

  return (
    <Accordion square expanded={expanded} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleExpanded}>
        <Typography variant="h6">Send Telecommand</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {components.map((C, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={i} item xs={12} md={4} lg={3}>
              <C
                open={openState[i]}
                setOpen={(v) => openDialog({ index: i, value: v })}
                jsonPreview={jsonPreview}
                updateValues={setTcFrame}
                header={{ SCID, Rev, VC }}
                loadDefaults={loadDefaults}
                saveDefaults={saveDefaults}
                onCreate={() => {
                  tctmCreateFunction(jsonPreview, 'TC Command Sent.');
                }}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

TelecommandPicker.propTypes = {
  defaultsRecord: PropTypes.shape({
    set: PropTypes.func,
    get: PropTypes.func,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  components: PropTypes.arrayOf(PropTypes.func).isRequired,
  openState: PropTypes.arrayOf(PropTypes.bool).isRequired,
  openDialog: PropTypes.func.isRequired,
  jsonPreview: PropTypes.shape({}).isRequired,
  setTcFrame: PropTypes.func.isRequired,
};

export default memo(TelecommandPicker);
