import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import { baudRateFromEnum, baudRateOptions } from '../../SdrCtrlTab/BaudRate/helpers';
import useSDRStatusDs from '../../SdrCtrlTab/useSDRStatusDs';
import useAutonomyConfigDs from '../../useAutonomyConfigDs';

export default function useSDRControls() {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { state: sdrStatus } = useSDRStatusDs();

  const setNominalCarrier = useCallback(
    (direction) => async (num) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRCarrierControl.${direction}.nominalCarrier`,
        value: 1e6 * parseFloat(num),
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Nominal Carrier', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const setOffset = useCallback(
    (direction) => async (num) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRCarrierControl.${direction}.offsetValue`,
        value: 1e3 * parseFloat(num),
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Offset Value', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const setRXFallbackBaudRate = useCallback(
    async (num) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRConfig.RXFallbackBaudRate`,
        value: num,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set RX Fallback Baud Rate', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const setLNAGain = useCallback(
    async (num) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRConfig.LNAgain`,
        value: parseFloat(num),
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set LNA Gain', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const setCACode = useCallback(
    async (mode) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRConfig.BeaconCACode`,
        value: mode,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set CA Code', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const setCorrFactor = useCallback(
    async (num) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRBaudRateControl.corrFactor`,
        value: parseFloat(num),
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Baudrate Corr Factor', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const sdrControlComponents = useMemo(
    () => [
      {
        type: 'input',
        options: {
          helperText: 'Uplink Nominal Carrier',
          numberInputProps: { inputProps: { scale: 6 } },
          value: sanitize(1e-6 * autonomyConfig?.SDRCarrierControl?.uplink?.nominalCarrier, 3, 0),
          handleSet: setNominalCarrier('uplink'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Uplink Nominal Carrier',
          value: sanitize(1e-6 * autonomyConfig?.SDRCarrierControl?.uplink?.nominalCarrier, 3),
          unit: 'MHz',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Uplink Offset Value',
          numberInputProps: { inputProps: { signed: true, scale: 6 } },
          value: sanitize(1e-3 * autonomyConfig?.SDRCarrierControl?.uplink?.offsetValue, 3, 0),
          handleSet: setOffset('uplink'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Uplink Offset Value',
          value: sanitize(1e-3 * autonomyConfig?.SDRCarrierControl?.uplink?.offsetValue, 3),
          unit: 'kHz',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Downlink Nominal Carrier',
          numberInputProps: { inputProps: { scale: 6 } },
          value: sanitize(1e-6 * autonomyConfig?.SDRCarrierControl?.downlink?.nominalCarrier, 3, 0),
          handleSet: setNominalCarrier('downlink'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Downlink Nominal Carrier',
          value: sanitize(1e-6 * autonomyConfig?.SDRCarrierControl?.downlink?.nominalCarrier, 3),
          unit: 'MHz',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Downlink Offset Value',
          numberInputProps: { inputProps: { signed: true, scale: 6 } },
          value: sanitize(1e-3 * autonomyConfig?.SDRCarrierControl?.downlink?.offsetValue, 3, 0),
          handleSet: setOffset('downlink'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Downlink Offset Value',
          value: sanitize(1e-3 * autonomyConfig?.SDRCarrierControl?.downlink?.offsetValue, 3),
          unit: 'kHz',
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'RX Fallback Baud Rate',
          options: baudRateOptions,
          selectedValue: autonomyConfig?.SDRConfig?.RXFallbackBaudRate ?? '',
          handleSet: setRXFallbackBaudRate,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Fallback Baud Rate Demand',
          value: baudRateFromEnum(autonomyConfig?.SDRConfig?.RXFallbackBaudRate),
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Actual Fallback Baud Rate',
          value: sdrStatus?.rxFallback?.baudRate ?? '-',
          unit: 'bps',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'LNA Gain',
          value: sdrStatus?.rxGeneral?.LNAgain,
          numberInputProps: { inputProps: { max: 255 } },
          handleSet: setLNAGain,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Actual LNA Gain',
          value: sdrStatus?.rxGeneral?.LNAgain ?? '-',
        },
      },

      {
        type: 'input',
        options: {
          helperText: 'CA Code',
          value: sdrStatus?.beacon?.CAcodeID,
          numberInputProps: { inputProps: { max: 255 } },
          handleSet: setCACode,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Actual CA Code',
          value: sdrStatus?.beacon?.CAcodeID ?? '-',
        },
      },

      {
        type: 'input',
        options: {
          helperText: 'Baudrate Corr Factor',
          numberInputProps: { inputProps: { signed: true, scale: 6 } },
          value: sanitize(autonomyConfig?.SDRBaudRateControl?.corrFactor, 3, 0),
          handleSet: setCorrFactor,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Baudrate Corr Factor',
          value: sanitize(autonomyConfig?.SDRBaudRateControl?.corrFactor, 3),
        },
      },
    ],
    [
      autonomyConfig.SDRCarrierControl.downlink.nominalCarrier,
      autonomyConfig.SDRCarrierControl.downlink.offsetValue,
      autonomyConfig.SDRCarrierControl.uplink.nominalCarrier,
      autonomyConfig.SDRCarrierControl.uplink.offsetValue,
      autonomyConfig.SDRConfig.RXFallbackBaudRate,
      autonomyConfig.SDRBaudRateControl.corrFactor,
      sdrStatus.beacon.CAcodeID,
      sdrStatus.rxGeneral.LNAgain,
      sdrStatus.rxFallback.baudRate,
      setNominalCarrier,
      setOffset,
      setRXFallbackBaudRate,
      setLNAGain,
      setCACode,
      setCorrFactor,
    ],
  );

  return { sdrControlComponents };
}
