import { TableFilters, TctmTable } from '@c3s/ui-radcube-message-logging';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React, { forwardRef, useContext, useEffect, useReducer, useRef, useState } from 'react';

import MultiSelectWithCheckbox from '#components/ui-helper/MultiSelectWithCheckbox';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

import { initialState, tableHeader } from './TCTMTabHelpers';

function reducer(state, action) {
  return { ...state, ...action };
}

const TCTMTab = () => {
  const tableRef = useRef();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();
  const [
    { version, startTime, endTime, Rev, SCID, VC, APID, Type, Red, Ack, Service, SubService },
    dispatch,
  ] = useReducer(reducer, initialState);
  const [filterVersion, setFilterVersion] = useState([]);
  const [filterAPID, setFilterAPID] = useState([]);
  const [filterACK, setFilterACK] = useState([]);
  // const [filterNACK, setFilterNACK] = useState([]);
  const [filterRED, setFilterRED] = useState([]);
  const [filterSrv, setFilterSrv] = useState([]);
  const [filterSbSrv, setFilterSbSrv] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [filterREV, setFilterREV] = useState([]);
  const [filterSCID, setFilterSCID] = useState([]);
  const [filterVC, setFilterVC] = useState([]);
  const [filterDirectionMask, setFilterDirectionMask] = useState({
    encoded: 0,
    decoded: 0,
  });

  useEffect(() => {
    async function getFilters() {
      const { result, status } = await rpcWithErrorHandler(
        `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessageFilters`,
        {
          protocol: 'TCTM',
        },
      );

      if (status === 'success' && Object.keys(result).includes('version')) {
        setFilterVersion(result.version?.map((label) => ({ label, value: label })));
        setFilterSCID(result['json.SCID']?.map((label) => ({ label, value: label })));
        setFilterREV(result['json.Rev']?.map((label) => ({ label, value: label })));
        setFilterVC(result['json.VC']?.map((label) => ({ label, value: label })));
        setFilterAPID(result['json.Packet.APID']?.map((label) => ({ label, value: label })));
        setFilterACK(result['json.Packet.Ack']?.map((label) => ({ label, value: label })));
        // setFilterNACK(result.json.Packet.Nack?.map(label => ({ label, value: label })));
        setFilterRED(result['json.Packet.Red']?.map((label) => ({ label, value: label })));
        setFilterSrv(result['json.Packet.Service']?.map((label) => ({ label, value: label })));
        setFilterSbSrv(result['json.Packet.SubService']?.map((label) => ({ label, value: label })));
        setFilterType(result['json.Packet.Type']?.map((label) => ({ label, value: label })));
      }
    }
    getFilters();
  }, [currentResGroup, rpcWithErrorHandler]);

  const onStartChange = (date) => dispatch({ startTime: date });

  const onEndChange = (date) => dispatch({ endTime: date });

  const refresh = () => tableRef.current.onQueryChange();

  const TCTMFilters = () => (
    <Grid container spacing={1} style={{ margin: '1em 1em 1em 4px' }}>
      <Grid item>
        <MultiSelectWithCheckbox
          title="SCID"
          dropdownArray={filterSCID}
          state={{ SCID: SCID.length ? SCID.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="REV"
          dropdownArray={filterREV}
          state={{ Rev: Rev.length ? Rev.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="VC"
          dropdownArray={filterVC}
          state={{ VC: VC.length ? VC.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="APID"
          dropdownArray={filterAPID}
          state={{ APID: APID.length ? APID.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="Red"
          dropdownArray={filterRED}
          state={{ Red: Red.length ? Red.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="ACK"
          dropdownArray={filterACK}
          state={{ Ack: Ack.length ? Ack.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="Type"
          dropdownArray={filterType}
          state={{ Type: Type.length ? Type.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="Service"
          dropdownArray={filterSrv}
          state={{ Service: Service.length ? Service.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <MultiSelectWithCheckbox
          title="Subservice"
          dropdownArray={filterSbSrv}
          state={{ SubService: SubService.length ? SubService.map((label) => ({ label, value: label })) : [] }}
          dispatch={dispatch}
        />
      </Grid>
    </Grid>
  );

  const tctmFilter = {
    transformation: filterDirectionMask,
    version: version.length ? version : [],
    timestamp: [startTime, endTime],
    json: {
      Rev: Rev.length ? Rev : [],
      SCID: SCID.length ? SCID : [],
      VC: VC.length ? VC : [],
      Packet: {
        APID: APID.length ? APID : [],
        Red: Red.length ? Red : [],
        Type: Type.length ? Type : [],
        Ack: Ack.length ? Ack : [],
        Service: Service.length ? Service : [],
        SubService: SubService.length ? SubService : [],
      },
    },
  };

  const getTctmMessages = async ({ page, pageSize, orderBy, orderDirection }) => {
    const { result, status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessages`,
      {
        protocol: 'TCTM',
        filter: tctmFilter,
        limit: pageSize,
        skip: pageSize * page,
        sort: {
          field: orderBy?.field,
          direction: orderDirection,
        },
      },
    );

    if (status === 'success') {
      return {
        data: result.data,
        page,
        totalCount: result.recordsTotal,
      };
    }
    return {
      data: [],
      page,
      totalCount: 0,
    };
  };

  const exportTctmMessages = async () => {
    const fileName = `tctm-log-${Date.now()}.csv`;

    const { status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/exportLoggedMessages`,
      {
        protocol: 'TCTM',
        filter: tctmFilter,
        sort: {
          direction: '',
        },
        bucketID: undefined,
        fileName,
      },
    );

    if (status === 'success') {
      enqueueSnackbar(`Filtered TCTM message log saved to MOC files as ${fileName}`, { variant: 'info' });
    }
  };

  const tctmActions = [
    {
      isFreeAction: true,
      icon: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} ref={ref} icon={['fas', 'file-download']} style={{ fontSize: 18 }} />
      )),
      tooltip: 'Create Archive',
      onClick: () => {
        exportTctmMessages();
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableFilters
          startTime={startTime}
          endTime={endTime}
          version={version.length ? version.map((label) => ({ label, value: label })) : []}
          filterDirectionMask={filterDirectionMask}
          setFilterDirectionMask={setFilterDirectionMask}
          filterVersion={filterVersion}
          dispatch={dispatch}
          tabSpecificFilters={TCTMFilters}
          refresh={refresh}
          onStartChange={onStartChange}
          onEndChange={onEndChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TctmTable
          tableRef={tableRef}
          actions={tctmActions}
          TableHeader={tableHeader}
          getRemoteData={getTctmMessages}
        />
      </Grid>
    </Grid>
  );
};

export default TCTMTab;
