import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import useTimeAgo from '@rooks/use-time-ago';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useWeatherDs from '../useWeatherDs';

const onOffOptions = new Map();
const connectOptions = new Map();
const enableOptions = new Map();

export default function useWeatherConnection() {
  const theme = useTheme();
  const { weatherState, weatherStationPath } = useWeatherDs();
  const [url, setUrl] = useState(weatherState.connectionURL);
  const [period, setPeriod] = useState(weatherState.updatePeriod);
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  onOffOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  onOffOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });
  enableOptions.set(0, { bgColor: theme.palette.error.main, label: 'DEAD - 0', color: 'white' });
  enableOptions.set(1, { bgColor: theme.palette.success.main, label: 'ALIVE - 1', color: 'white' });
  connectOptions.set(0, { bgColor: theme.palette.error.main, label: 'DISCONNECTED - 0', color: 'white' });
  connectOptions.set(1, { bgColor: theme.palette.success.main, label: 'CONNECTED - 1', color: 'white' });

  useEffect(() => setUrl(weatherState.connectionURL), [weatherState.connectionURL]);
  useEffect(() => setPeriod(weatherState.updatePeriod), [weatherState.updatePeriod]);
  const lastUpdated = useTimeAgo(weatherState.lastUpdated);

  const setUrlAndPeriod = useCallback(() => {
    try {
      rpcWithErrorHandler(`${weatherStationPath}/setUrlAndPeriod`, {
        url,
        updatePeriod: parseInt(period, 10),
      });
      enqueueSnackbar(`Connection requested to ${url} : ${period}`, {
        variant: 'info',
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, period, rpcWithErrorHandler, url, weatherStationPath]);

  const togglePeriodic = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(`${weatherStationPath}/enablePeriodic`, mode);
        enqueueSnackbar('Toggle periodic update requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, weatherStationPath],
  );

  const weatherConnectionComponents = useMemo(
    () => [
      {
        type: 'input-nosetter',
        options: {
          helperText: 'URL:',
          value: url,
          handleChange: setUrl,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current URL:',
          value: weatherState.connectionURL,
        },
      },
      {
        type: 'input-nosetter',
        options: {
          helperText: 'Period:',
          value: period,
          handleChange: setPeriod,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Period:',
          value: weatherState.updatePeriod,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Connected:',
          value: Number(weatherState.isConnected ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: connectOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Periodic Update:',
          button1Text: 'ENABLE',
          handleButton1Click: () => togglePeriodic(true),
          button2Text: 'DISABLE',
          handleButton2Click: () => togglePeriodic(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Periodic Enabled:',
          value: Number(weatherState.isPolling ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: onOffOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Periodic Status:',
          value: Number(weatherState.isUpdating ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: enableOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Last Updated:',
          value: lastUpdated,
        },
      },
    ],
    [
      lastUpdated,
      period,
      togglePeriodic,
      url,
      weatherState.updatePeriod,
      weatherState.connectionURL,
      weatherState.isConnected,
      weatherState.isPolling,
      weatherState.isUpdating,
    ],
  );

  return { weatherConnectionComponents, setUrlAndPeriod };
}
