import { DebugLedCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useDebugLedCard from './useDebugLedCard';

const DebugLedCardLogic = () => {
  const { turnDebugLedOn, turnDebugLedOff } = useDebugLedCard();

  return <DebugLedCard turnDebugLedOff={turnDebugLedOff} turnDebugLedOn={turnDebugLedOn} />;
};

export default DebugLedCardLogic;
