import { ColoredCircleIcon } from '@c3s/ui-components';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import useCurrentMission from '#hooks/useCurrentMission';
import useDsRecordState from '#hooks/useDsRecordState';

const statusColors = new Map([
  [undefined, 'primary'],
  [true, 'success'],
  [false, 'danger'],
]);
const statusMessages = new Map([
  [undefined, 'Status unknown. Backend provider is likely unavailable.'],
  [true, 'Connection is alive.'],
  [false, 'Connection is dead.'],
]);

const TestConnectionStatusCircle = ({ connectorKey }) => {
  const { missionKey } = useCurrentMission();
  const testerProviderBasePath = `rg/${missionKey}/@c3s/tester-provider`;

  const { record: connectorStatus, hasProvider } = useDsRecordState(`${testerProviderBasePath}/${connectorKey}/status`);

  const isConnectionAlive = hasProvider ? connectorStatus?.isAlive : undefined;

  return (
    <Tooltip placement="right" title={statusMessages.get(isConnectionAlive)}>
      <span>
        <ColoredCircleIcon status={statusColors.get(isConnectionAlive)} />
      </span>
    </Tooltip>
  );
};

TestConnectionStatusCircle.propTypes = {
  connectorKey: PropTypes.string.isRequired,
};

export default TestConnectionStatusCircle;
