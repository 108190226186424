import { useEffect, useState } from 'react';

import useGeneralProtocolEventListener from '#hooks/useGeneralProtocolEventListener';

export default function TMTableContainer() {
  const [rows, setRows] = useState([]);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {}, [rows]);

  const subUnsubArray = [
    useGeneralProtocolEventListener({
      protocol: 'TCTM',
      callback: (data) => {
        setRows((prevRows) => [data, ...prevRows].splice(0, 30));
      },
    }),
  ];

  function subscribeToTctm() {
    subUnsubArray.forEach((event) => {
      // eslint-disable-next-line no-unused-expressions
      event?.subscribe();
    });
  }
  function unsubscribeFromTctm() {
    subUnsubArray.forEach((event) => {
      // eslint-disable-next-line no-unused-expressions
      event?.unsubscribe();
    });
  }

  return { rows, setRows, isRunning, setIsRunning, subscribeToTctm, unsubscribeFromTctm };
}
