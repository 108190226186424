import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useHkAutonomyConfigDs from '../../useAutonomyConfigDs';
import useHkAutonomyStatusDs from '../../useAutonomyStatusDs';
import useHousekeepingDs from '../useHousekeepingDs';

const relayOptions = new Map();
const autoValueOptions = new Map();
const modeOptions = new Map();

export default function useMastLampControl() {
  const theme = useTheme();
  const { hkState } = useHousekeepingDs();
  const { autonomyConfig, configPath } = useHkAutonomyConfigDs();
  const { autonomyStatus } = useHkAutonomyStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  modeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO', color: 'white' });
  modeOptions.set('manual', { bgColor: theme.palette.warning.main, label: 'MANUAL', color: 'white' });
  relayOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  relayOptions.set(1, { bgColor: theme.palette.error.main, label: 'ERR - 1', color: 'white' });
  autoValueOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  autoValueOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });

  const setMode = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'mastLampPower.mode',
          value: mode,
        });
        enqueueSnackbar('Mode change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const setManualValue = useCallback(
    (value) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'mastLampPower.manualValue',
          value,
        });
        enqueueSnackbar('Manual Value change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const cameraLedOutput = hkState.outputs[1];

  const mastLampComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'Mode:',
          button1Text: 'AUTO',
          handleButton1Click: () => setMode('auto'),
          button2Text: 'MANUAL',
          handleButton2Click: () => setMode('manual'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current mode:',
          value: autonomyConfig.mastLampPower.mode,
          ValueComponent: GeneralStatusIndicator,
          options: modeOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Manual Value:',
          button1Text: 'ON',
          handleButton1Click: () => setManualValue(true),
          button2Text: 'OFF',
          handleButton2Click: () => setManualValue(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current MANUAL Value:',
          value: Number(autonomyConfig.mastLampPower.manualValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoValueOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Relay Drv. A fault',
          value: Number(hkState.errorFlags.relayDriverAFault ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: relayOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'AUTO Value:',
          value: Number(autonomyStatus.mastLampPower.autoValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoValueOptions,
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual Value:',
          value: Number(cameraLedOutput ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoValueOptions,
        },
      },
    ],
    [
      autonomyConfig.mastLampPower.mode,
      autonomyConfig.mastLampPower.manualValue,
      hkState.errorFlags.relayDriverAFault,
      cameraLedOutput,
      autonomyStatus.mastLampPower.autoValue,
      setMode,
      setManualValue,
    ],
  );

  return { mastLampComponents };
}
