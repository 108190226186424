import { Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import ConnectionIcon from './ConnectionIcon';
import styles from './ConnectionSettingsCard.styles';
import useConnectionCard from './useConnectionCard';

const useStyles = makeStyles(styles);

const ConnectionSettingsCard = () => {
  const classes = useStyles();
  const { onConnect, onDisconnect, connected, serialPort, setSerialPort, baudrate, setBaudrate } = useConnectionCard();

  const isSerialPortInvalid = !serialPort.startsWith('COM') && !serialPort.startsWith('/dev/');
  const isBaudrateInvalid = baudrate < 1 || baudrate > 115200;

  return (
    <Card className={classes.Card}>
      <CardHeader title="Connection settings" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
      <CardContent className={classes.CardContent}>
        <ConnectionIcon state={connected} />
        <TextField
          placeholder="COM<x> | /dev/ttyUSB<x>"
          value={serialPort}
          onChange={(event) => setSerialPort(event.target.value)}
          onFocus={(event) => event.target.select()}
          error={isSerialPortInvalid}
          label="Serial Port"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          className={classes.TextField}
          style={{ flex: 2 }}
        />
        <TextField
          placeholder="e.g. 115200"
          value={baudrate}
          onChange={(event) => setBaudrate(parseInt(event.target.value, 10))}
          onFocus={(event) => event.target.select()}
          error={isBaudrateInvalid}
          label="Baud Rate"
          InputLabelProps={{ shrink: true }}
          type="number"
          margin="normal"
          className={classes.TextField}
        />
      </CardContent>
      <CardContent className={classes.CardContent}>
        <Button size="small" color="default" variant="outlined" onClick={onDisconnect}>
          Disconnect
        </Button>
        <Button size="small" color="primary" variant="outlined" onClick={onConnect}>
          Connect
        </Button>
      </CardContent>
    </Card>
  );
};

export default ConnectionSettingsCard;
