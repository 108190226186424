import React from 'react';
import { Helmet } from 'react-helmet';

const PageTitle = 'Satellite Cluster';

const SatelliteClusterPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <div>
        <h1>{PageTitle}</h1>
        <p>Placeholder...</p>
      </div>
    </>
  );
};

export default SatelliteClusterPage;
