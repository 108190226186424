import {
  FileUploadsTable,
  RemoveAll,
  RemoveAllFailed,
  RemoveAllInProgress,
  RemoveAllSuccess,
} from '@c3s/ui-radcube-protocol-tctm';
import { Accordion, AccordionDetails, AccordionSummary, ButtonGroup, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

import useRows from '../useRows';

const UploadTable = () => {
  const {
    useStyles,
    open,
    setOpen,
    memoizedUploads,
    rpcWithErrorHandler,
    removeAllUploadSuccessPath,
    removeAllUploadPath,
    removeAllUploadFailedPath,
    removeAllUploadInProgressPath,
  } = useRows();

  const classes = useStyles();
  return (
    <Accordion square expanded={open} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setOpen((prev) => !prev)}>
        <Typography variant="h6">Upload Progress Table</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <FileUploadsTable rows={memoizedUploads} />
          </Grid>
          <Grid item className={classes.ModifierAutoMargin}>
            <ButtonGroup color="primary">
              <RemoveAll onClick={() => rpcWithErrorHandler(removeAllUploadPath, {})} />
              <RemoveAllFailed onClick={() => rpcWithErrorHandler(removeAllUploadFailedPath, {})} />
              <RemoveAllInProgress onClick={() => rpcWithErrorHandler(removeAllUploadInProgressPath, {})} />
              <RemoveAllSuccess onClick={() => rpcWithErrorHandler(removeAllUploadSuccessPath, {})} />
            </ButtonGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UploadTable;
