import { ControlCard } from '@c3s/ui-tracking-station';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import useWeatherConnection from './WeatherConnectionHooks/useWeatherConnection';
import styles from './WeatherStationMonitorTab.styles';
import useWeatherStatus from './WeatherStatusHooks/useWeatherStatus';
import useWindAlarmControl from './WindAlarmControlHooks/useWindAlarmControl';

const useStyles = makeStyles(styles);

const WeatherStationMonitorTab = () => {
  const { fullWidthButton } = useStyles();
  const { weatherConnectionComponents, setUrlAndPeriod } = useWeatherConnection();
  const { weatherStatusComponents } = useWeatherStatus();
  const { windAlarmComponents } = useWindAlarmControl();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <ControlCard title="Weather Connection" components={weatherConnectionComponents} style={{ width: 430 }} />
            <Button variant="contained" color="primary" onClick={setUrlAndPeriod} className={fullWidthButton}>
              Set URL and Period
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ControlCard title="Weather Status" components={weatherStatusComponents} style={{ width: 430 }} />
      </Grid>
      <Grid item>
        <ControlCard title="Wind Alarm Control" components={windAlarmComponents} style={{ width: 430 }} />
      </Grid>
    </Grid>
  );
};

export default WeatherStationMonitorTab;
