/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const tableHeader = [
  {
    field: 'transformation',
    title: 'Tr.',
    render: ({ transformation }) =>
      transformation === 'encoded' ? (
        <FontAwesomeIcon icon={['far', 'arrow-alt-circle-up']} />
      ) : (
        <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-down']} />
      ),
    cellStyle: {
      minWidth: 150,
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'timestamp',
    title: 'Timestamp',
    render: ({ timestamp }) =>
      DateTime.fromISO(timestamp || Date.now()).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
    cellStyle: {
      minWidth: 250,
    },
  },
  {
    field: 'chain',
    title: (
      <Tooltip title="Number of processors" placement="top">
        <span>#</span>
      </Tooltip>
    ),
    render: ({ chain }) => chain.length,
    cellStyle: {
      minWidth: 60,
    },
  },
  {
    field: 'version',
    title: 'Version',
    render: ({ version }) => version,
    cellStyle: {
      minWidth: 300,
    },
  },
];

export const initialState = {
  version: [],
  startTime: DateTime.local().minus({ days: 1 }).toMillis(),
  endTime: DateTime.local().plus({ days: 1 }).toMillis(),
};
