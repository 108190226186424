// eslint-disable-next-line import/no-cycle
import { reduceArrayFieldState } from './array-field-reducers';
import { reduceBytesFieldState } from './bytes-field-reducers';
import { reduceComplexFieldState } from './complex-field-reducers';
import { reduceStringFieldState } from './string-field-reducers';
// eslint-disable-next-line import/no-cycle
import { reduceStructuredFieldState } from './struct-field-reducers';

export function checkSignalMeta(signalMeta, providedEnumId) {
  const errors = [];

  switch (signalMeta.type) {
    case 'number':
      // TODO
      break;
    case 'boolean':
      // TODO
      break;
    case 'enum':
      if (
        (signalMeta.enum === '' || signalMeta.enum === null || signalMeta.enum === undefined) &&
        providedEnumId === undefined
      ) {
        errors.push(`Enum signal ${signalMeta.sid} has no associated enum and none was provided`);
      }
      break;
    case 'bytes':
      // TODO
      break;
    case 'array':
      // TODO
      break;
    case 'struct':
      // TODO
      break;
    case 'complex':
      // TODO
      break;
    case 'reserved':
      // TODO
      break;
    default:
      errors.push(`Signal ${signalMeta.sid} has invalid meta type '${signalMeta.type}'`);
  }
  return errors;
}

function reduceSimpleFieldState(value, internalState, action) {
  switch (action.type) {
    case 'set-value':
      return [action.value, action.value];
    default:
      throw new Error(`Invalid simple field action type: ${action.type}`);
  }
}

export function reduceFieldState(value, internalState, action) {
  if (action.fieldMeta === undefined) {
    return [null, undefined];
  }

  switch (action.fieldMeta.type) {
    case 'number':
    case 'boolean':
    case 'enum':
      return reduceSimpleFieldState(value, internalState, action);
    case 'bytes':
      return reduceBytesFieldState(value, internalState, action);
    case 'string':
      return reduceStringFieldState(value, internalState, action);
    case 'array':
      return reduceArrayFieldState(value, internalState, action);
    case 'struct':
      return reduceStructuredFieldState(value, internalState, action);
    case 'complex':
      return reduceComplexFieldState(value, internalState, action);
    case 'reserved':
      return [null, undefined];
    default:
      throw new Error(`Invalid field meta type: ${action.fieldMeta.type}`);
  }
}
