const codeExStateDictionary = new Map([
  [0x00, '-'],
  [0x01, 'Booting'],
  [0x02, 'Wait for Cmd'],
  [0x03, 'FW Update'],
  [0x04, 'Auto Safety'],
  [0x05, 'Cntrld. Safety'],
  [0x06, 'Op. FW'],
  [0x07, 'Safety FW'],
]);

export default codeExStateDictionary;
