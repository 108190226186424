import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
// eslint-disable-next-line import/prefer-default-export
export const tableHeader = [
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'transformation',
    title: 'Tr.',
    // eslint-disable-next-line react/prop-types
    render: ({ transformation }) =>
      transformation === 'encoded' ? (
        <FontAwesomeIcon icon={['far', 'arrow-alt-circle-up']} />
      ) : (
        <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-down']} />
      ),
  },
  {
    field: 'timestamp',
    title: 'Timestamp',
    render: ({ timestamp }) =>
      DateTime.fromISO(timestamp || Date.now()).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
    cellStyle: {
      minWidth: 300,
    },
  },
  {
    cellStyle: {
      minWidth: 150,
    },
    field: 'json.Service',
    title: 'Service',
  },
  {
    cellStyle: {
      minWidth: 150,
    },
    field: 'json.SubService',
    title: 'Subservice',
  },
  {
    cellStyle: {
      minWidth: 300,
    },
    field: 'version',
    title: 'Version',
  },
];

export const initialState = {
  version: [],
  startTime: DateTime.local().minus({ days: 1 }).toMillis(),
  endTime: DateTime.local().plus({ days: 1 }).toMillis(),
  Service: [],
  SubService: [],
};
