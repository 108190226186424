import { ConnectionSettingsCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useConnectionCard from './useConnectionCard';

const ConnectionCard = () => {
  const { onConnect, onDisconnect, ipAddress, setIpAddress, portNumber, setPortNumber } = useConnectionCard();
  return (
    <ConnectionSettingsCard
      connectToBusTester={onConnect}
      disconnectFromBusTester={onDisconnect}
      ipAddress={ipAddress}
      setIpAddress={setIpAddress}
      portNumber={portNumber}
      setPortNumber={setPortNumber}
    />
  );
};

export default ConnectionCard;
