import { TCTM_EPS_07_06 } from '@c3s/ui-radcube-protocol-tctm';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useToggle } from 'standard-hooks';

import CardHeader from '#components/ui-helper/CardHeaderWithStatus';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import useThrottle from '#hooks/useThrottle';
import styles from '#utils/styles';

import { initialState, reducer, setObcTm } from './EventReportService.helpers';

const useStyles = makeStyles(styles);

const EventReportService = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, newSetValue] = useThrottle(500, [state, dispatch]);

  const setObcTelemetryWithRef = useRef((val) => setObcTm({ telemetry: val, dispatch: newSetValue, enqueueSnackbar }));
  const subscribeToTelemetry = setObcTelemetryWithRef.current;

  const [open, toggleOpen] = useToggle(false);

  const { currentResGroup } = useContext(RgContext);
  const { dsClient } = useContext(DeepstreamContext);

  useEffect(() => {
    dsClient.event.subscribe(`rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`, subscribeToTelemetry);

    return function cleanup() {
      dsClient.event.unsubscribe(
        `rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`,
        subscribeToTelemetry,
      );
    };
  }, [currentResGroup, subscribeToTelemetry, dsClient]);

  return (
    <Accordion square expanded={open} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleOpen}>
        <Typography variant="h6">Event Report Service</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader
                error={value.err_07_06.status}
                lastUpdated={value.err_07_06.lastValidTimestamp}
                errorMsg={value.err_07_06.Msg}
                titleText="TCTM_EPS_07_06"
              />
              <CardContent>
                <TCTM_EPS_07_06 status={value.TM_07_06} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EventReportService;
