import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../../useAutonomyStatusDs';
import useSDRStatusDs from '../useSDRStatusDs';
import { baudRateFromEnum, baudRateOptions } from './helpers';

const BaudrateModeOptions = new Map();

export default function useBaudRate(direction) {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { state: sdrStatus } = useSDRStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const theme = useTheme();

  BaudrateModeOptions.set('manual', { bgColor: theme.palette.yellow.main, label: 'MANUAL', color: 'black' });
  BaudrateModeOptions.set('semi', { bgColor: theme.palette.info.main, label: 'SEMI-AUTO', color: 'white' });
  BaudrateModeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO', color: 'white' });

  const setBaudrateMode = useCallback(
    async (mode) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRBaudRateControl.${direction}.mode`,
        value: mode,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Baud Rate Mode', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler, direction],
  );

  const setManualBaudrate = useCallback(
    async (num) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRBaudRateControl.${direction}.manualValue`,
        value: num,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Baud Rate', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler, direction],
  );

  const baudRateComponents = useMemo(
    () => [
      {
        type: 'select',
        options: {
          helperText: 'Baud Rate Mode',
          options: [
            {
              label: 'MANUAL',
              value: 'manual',
            },
            {
              label: 'SEMI-AUTO',
              value: 'semi',
            },
            {
              label: 'AUTO',
              value: 'auto',
            },
          ],
          selectedValue: autonomyConfig?.SDRBaudRateControl?.[direction]?.mode ?? '',
          handleSet: setBaudrateMode,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Baud Rate Mode',
          value: autonomyConfig?.SDRBaudRateControl?.[direction]?.mode ?? 'manual',
          ValueComponent: GeneralStatusIndicator,
          options: BaudrateModeOptions,
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'Baud Rate Manual',
          options: baudRateOptions,
          selectedValue: autonomyConfig?.SDRBaudRateControl?.[direction]?.manualValue ?? '',
          handleSet: setManualBaudrate,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Manual Baud',
          value: baudRateFromEnum(autonomyConfig?.SDRBaudRateControl?.[direction]?.manualValue),
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Baud Rate Auto',
          value: baudRateFromEnum(autonomyStatus?.SDRBaudRateControl?.[direction]?.autoValue),
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Baud Rate Demand',
          value: baudRateFromEnum(autonomyStatus?.SDRBaudRateControl?.[direction]?.outputValue),
          unit: 'bps',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual SDR Baud Rate',
          value: direction === 'uplink' ? sdrStatus?.txChannel?.baudRate ?? '-' : sdrStatus?.rxNominal?.baudRate ?? '-',
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Last Packet Sent',
          value: new Date(sdrStatus?.lastPacketSent).toLocaleString(),
          unit: 's',
        },
      },
    ],
    [
      direction,
      autonomyConfig.SDRBaudRateControl,
      autonomyStatus.SDRBaudRateControl,
      sdrStatus.txChannel.baudRate,
      sdrStatus.rxNominal.baudRate,
      sdrStatus.lastPacketSent,
      setBaudrateMode,
      setManualBaudrate,
    ],
  );

  return { baudRateComponents };
}
