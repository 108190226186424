export function checkPathType(path) {
  if (!(path instanceof Array)) {
    throw new Error('Struct entry path must be an array of strings');
  }
}

export function getStructEntryOnPath(structEntry, path) {
  checkPathType(path);
  const [key, ...innerKeys] = path;

  if (key === undefined) {
    return structEntry;
  }

  return getStructEntryOnPath(structEntry?.[key], innerKeys);
}

export function setStructEntryOnPath(structEntry, path, value) {
  checkPathType(path);
  const [key, ...innerKeys] = path;

  if (key === undefined) {
    return value;
  }

  return {
    ...structEntry,
    [key]: setStructEntryOnPath(structEntry?.[key], innerKeys, value),
  };
}

export function getFieldInStructValue(structValue, fieldMeta) {
  return structValue !== undefined && fieldMeta !== undefined
    ? getStructEntryOnPath(structValue, fieldMeta.name.split('.'))
    : undefined;
}

export function getFieldInStructInternalState(structInternalState, fieldId) {
  return structInternalState?.fieldInternalStates?.[fieldId];
}
