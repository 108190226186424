import { FilterAndFcuCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useFilterAndFcuCard from './useFilterAndFcuCard';

const FilterAndFcuCardLogic = () => {
  const { onFilterSet, getFilterStatus, FilterFcuConfig, numCheckedItems, setFilterFcuConfig } = useFilterAndFcuCard();
  return (
    <FilterAndFcuCard
      onFilterSet={onFilterSet}
      getFilterStatus={getFilterStatus}
      FilterFcuConfig={FilterFcuConfig}
      numCheckedItems={numCheckedItems}
      setFilterFcuConfig={setFilterFcuConfig}
    />
  );
};

export default FilterAndFcuCardLogic;
