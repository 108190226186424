import { useContext, useEffect, useMemo, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

export default function useConnectionCard() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    dsClient,
    rpcWithErrorHandler,
    clientData: { userName },
  } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/tester-provider`;

  const connectionDsRecord = useDsRecord('access-port', 'control/connection');
  const connectionStatusRecord = useMemo(
    () => dsClient.record.getRecord(`rg/${currentResGroup}/@c3s/tester-provider/debug/access-port/status/connection`),
    [currentResGroup, dsClient.record],
  );

  const [serialPort, setSerialPort] = useState('COM<X> | /dev/ttyUSB0');
  const [baudrate, setBaudrate] = useState(115200);
  const [isAlive, setIsAlive] = useState(false);

  const persistState = () => {
    connectionDsRecord.set({ ...connectionDsRecord.get(), serialPort, baudrate });
  };

  useEffect(() => {
    connectionDsRecord.whenReady((record) => {
      setSerialPort(record.get()?.serialPort || '');
      setBaudrate(record.get()?.baudrate || 115200);
    });
    const subscriptionCallback = (record) => {
      setIsAlive(record.isAlive);
    };
    connectionStatusRecord.subscribe(subscriptionCallback, true);

    return function cleanup() {
      connectionStatusRecord.unsubscribe(subscriptionCallback);
    };
  }, [connectionDsRecord, connectionStatusRecord]);

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const onConnect = async () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/access-port/connect`,
      paramsObject: { userName, serialPort, baudrate },
      snackbarMessage: `Connected to ${serialPort}:${baudrate}`,
    });
    persistState();
  };

  const onDisconnect = async () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/access-port/disconnect`,
      paramsObject: { userName },
      snackbarMessage: `Disconnect requested from serial port.`,
    });
    persistState();
  };

  return {
    onConnect,
    onDisconnect,
    serialPort,
    setSerialPort,
    baudrate,
    setBaudrate,
    isAlive,
  };
}
