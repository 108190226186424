import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const tableHeader = [
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'transformation',
    title: 'Tr.',
    // eslint-disable-next-line react/prop-types
    render: ({ transformation }) =>
      transformation === 'encoded' ? (
        <FontAwesomeIcon icon={['far', 'arrow-alt-circle-up']} />
      ) : (
        <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-down']} />
      ),
  },
  {
    cellStyle: {
      minWidth: 300,
    },
    field: 'timestamp',
    title: 'Timestamp',
    render: ({ timestamp }) =>
      DateTime.fromISO(timestamp || Date.now()).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.IDE',
    title: 'IDE',
    render: ({ json }) => (json.IDE ? 1 : 0),
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.RTR',
    title: 'RTR',
    render: ({ json }) => (json.RTR ? 1 : 0),
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.DLC',
    title: 'DLC',
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.Identifier',
    title: 'Identifier',
  },
  {
    cellStyle: {
      minWidth: 300,
    },
    field: 'version',
    title: 'Version',
  },
];

export const initialState = {
  version: [],
  startTime: DateTime.local().minus({ days: 1 }).toMillis(),
  endTime: DateTime.local().plus({ days: 1 }).toMillis(),
  IDE: [],
  RTR: [],
  DLC: [],
  Identifier: [],
};
