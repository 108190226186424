import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import NonIdealState from '#components/ui-helper/NonIdealState';
import TestingContext from '#contexts/TestingContext';
import TestConnectorTable from '#services/testing/connector-table';

import AccessPortPage from './access-port';
import BusTesterPage from './bus-tester';
import GenericTestConnectorPage from './generic';
import OwlTesterPage from './owl-tester';
import RfLinkPage from './rf-link';

export const TestConnectorPage = ({ currentTestConnector }) => {
  const { testConnectors } = useContext(TestingContext);
  const testConnectorMeta = testConnectors[currentTestConnector];

  if (testConnectorMeta === undefined) {
    return <NonIdealState message={`No metadata available for test connector '${currentTestConnector}'.`} />;
  }

  switch (testConnectorMeta.connectorFeatureSet) {
    case 'access-port':
      return <AccessPortPage connectorKey={currentTestConnector} />;
    case 'bus-tester':
      return <BusTesterPage connectorKey={currentTestConnector} />;
    case 'rf-link':
      return <RfLinkPage connectorKey={currentTestConnector} />;
    case 'owl-tester':
      return <OwlTesterPage connectorKey={currentTestConnector} />;
    case null:
      return <GenericTestConnectorPage connectorKey={currentTestConnector} />;
    default:
      return (
        <NonIdealState
          message={`Test connector '${currentTestConnector}' has unsupported feature set '${testConnectorMeta.type}'.`}
        />
      );
  }
};

TestConnectorPage.propTypes = {
  currentTestConnector: PropTypes.string.isRequired,
};

export const TestConnectorSetupPage = () => {
  return <TestConnectorTable />;
};
