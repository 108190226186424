import { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

export default function usePowerCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/lclControl/setPower`;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const changeVoltageState = ({ selectedVoltage, selectedState }) => {
    processRpcResponse({
      endpoint: rpcUrl,
      paramsObject: {
        [`POWER_${selectedVoltage}_EN`]: selectedState,
        [`POWER_${selectedVoltage}_EN_VALID`]: true,
      },
      snackbarMessage: 'Change Voltage State requested',
    });
  };

  return { changeVoltageState };
}
