import { useState } from 'react';

import useDsRecordSubscription from './useDsRecordSubscription';

const useDsRecordState = (recordPath, initialLocalState) => {
  const [record, setRecordState] = useState(initialLocalState);

  const { setRecord, isReady, isDestroyed, hasProvider } = useDsRecordSubscription(
    recordPath,
    setRecordState,
    true,
    true,
  );

  return { record, setRecord, isReady, isDestroyed, hasProvider };
};

export default useDsRecordState;
