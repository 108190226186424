import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums'; // eslint-disable-line camelcase
/* eslint-disable camelcase */
import { validate_TCTM_RADMAG_05_02, validate_TCTM_RADMAG_05_04 } from '@c3s/ui-radcube-protocol-tctm';
import printf from 'printf';

const TestSubServiceNumberSet = new Set([
  TCTM.test_subservice_general_e.T_SUBSERVICE_GEN_CON_TEST_REPORT,
  TCTM.test_subservice_general_e.T_SUBSERVICE_GEN_DEVICE_INFO_REPORT,
]);

const getValues = (tmData) => {
  const retObj = {};
  Object.entries(tmData).forEach(([key, val]) => {
    retObj[key] = val.value;
  });
  return retObj;
};

export const setObcTm = ({ telemetry, dispatch, enqueueSnackbar }) => {
  // eslint-disable-next-line no-unused-expressions
  if (
    telemetry.json.Packet.APID === TCTM.apid_e.APID_RADMAG &&
    telemetry.json.Packet.Service === TCTM.service_e.SERVICE_TEST
  ) {
    const {
      json: {
        Packet: { SubService, Data },
      },
    } = telemetry;

    // eslint-disable-next-line no-unused-expressions
    TestSubServiceNumberSet.has(SubService)
      ? dispatch({
          type: `set${printf('%02X', telemetry.json.Packet.Service)}_${printf('%02X', SubService)}`,
          value: Data,
        })
      : enqueueSnackbar(`Unknown Subservice number at TestService.jsx: ${telemetry.json.Packet}`, {
          variant: 'error',
        });
  }
};

export const initialState = {
  TM_05_02: {},
  err_05_02: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_05_04: {},
  err_05_04: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
};

export const reducer = (state, action) => {
  const relevantValues = getValues(action.value);
  switch (action.type) {
    case 'set05_02':
      try {
        const validatedValues = validate_TCTM_RADMAG_05_02(relevantValues);
        return {
          ...state,
          TM_05_02: validatedValues.value,
          err_05_02: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_05_02: { ...state.err_05_02, status: 'err', Msg: err } };
      }
    case 'set05_04':
      try {
        const validatedValues = validate_TCTM_RADMAG_05_04(relevantValues);
        return {
          ...state,
          TM_05_04: validatedValues.value,
          err_05_04: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_05_04: { ...state.err_05_04, status: 'err', Msg: err } };
      }
    default:
      throw new Error();
  }
};
