import { useContext, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

export default function useConnectionCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/tester-provider`;

  const [command, setCommand] = useState('');

  const handleChange = (event) => {
    setCommand(event.target.value);
  };

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const onSend = async () => {
    await processRpcResponse({
      endpoint: `${rpcUrl}/access-port/sendConfigCommand`,
      paramsObject: command,
      snackbarMessage: `Sent Raw Command to Access Port`,
    });
  };

  return {
    handleChange,
    onSend,
    command,
  };
}
