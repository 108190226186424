import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useRef, useState } from 'react';

import NonIdealState from '#components/ui-helper/NonIdealState';

import useFileManagementRPCs from '../useFileManagementRPCs';
import DiskManagementControls from './disk-management/controls/DiskManagementControls';
import DirectDiskReadPanel from './disk-management/panels/DirectDiskReadPanel';
import DirectDiskWritePanel from './disk-management/panels/DirectDiskWritePanel';
import DiskInfoPanel from './disk-management/panels/DiskInfoPanel';
import UnmountDiskPanel from './disk-management/panels/UnmountDiskPanel';
import SatelliteFileOperationTab from './file-operations/controls/SatelliteFileOperationTab';
import CopyFilePanel from './file-operations/panels/CopyFilePanel';
import DirectFileReadPanel from './file-operations/panels/DirectFileReadPanel';
import DirectFileWritePanel from './file-operations/panels/DirectFileWritePanel';
import FileChecksumPanel from './file-operations/panels/FileChecksumPanel';
import FileReportPanel from './file-operations/panels/FileReportPanel';
import RemoveFilePanel from './file-operations/panels/RemoveFilePanel';
import RenameFilePanel from './file-operations/panels/RenameFilePanel';
import RequestProgressIndicatorsPanel from './file-operations/panels/RequestProgressIndicatorsPanel';
import SetFileAttributesPanel from './file-operations/panels/SetFileAttributesPanel';
import SatelliteFilesTable from './file-table/SatelliteFilesTable';
import FilesystemManagementControls from './filesystem-management/controls/FilesystemManagementControls';
import FormatFilesystemPanel from './filesystem-management/panels/FormatFilesystemPanel';
import ResetFilesystemPanel from './filesystem-management/panels/ResetFilesystemPanel';
import SatelliteFileListingPanel from './filesystem-management/panels/SatelliteFileListingPanel';
import { getDiskLabel } from './helpers';
import OperationModeControls from './operation-mode/controls/OperationModeControls';
import TargetPathSelect from './operation-mode/controls/TargetPathSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  fsAndDiskLevelControls: {
    marginTop: theme.spacing(1),
  },
  fsAndDiskLevelControlPanels: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  fileOperationPanels: {
    marginTop: theme.spacing(2),
  },
  fileOperationPanelContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.divider,
    padding: theme.spacing(2),
  },
  requestProgressIndicators: {
    marginTop: theme.spacing(2),
  },
  satelliteFilesTable: {
    marginTop: theme.spacing(2),
  },
}));

const SatelliteFilesPanel = () => {
  const { fileMgmtStatus, genericRPCCallback, setTargetPath } = useFileManagementRPCs();

  const [selectedDiskId, selectDisk] = useState(TCTM.tctm_disk_id_e.TCTM_eMMC);
  const [openPanels, setOpenPanels] = useState({
    [TCTM.tctm_disk_id_e.TCTM_MRAM]: null,
    [TCTM.tctm_disk_id_e.TCTM_eMMC]: null,
  });
  const [fileOperationPanels, setFileOperationPanels] = useState({
    [TCTM.tctm_disk_id_e.TCTM_MRAM]: [],
    [TCTM.tctm_disk_id_e.TCTM_eMMC]: [],
  });
  const [listingLevels, setListingLevels] = useState({
    [TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_0]: true,
    [TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_1]: false,
    [TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_2]: false,
  });

  const classes = useStyles(useTheme());

  const nextFileOperationPanelKey = useRef(0);

  const selectPanel = (panel) => {
    if (openPanels[selectedDiskId] === panel) {
      setOpenPanels({
        ...openPanels,
        [selectedDiskId]: null,
      });
    } else {
      setOpenPanels({
        ...openPanels,
        [selectedDiskId]: panel,
      });
    }
  };

  const toggleListingLevel = (level) => {
    setListingLevels({
      ...listingLevels,
      [level]: !listingLevels[level],
    });
  };

  const openFileOperationPanel = useCallback(
    (panelType, fileId) => {
      const panelKey = nextFileOperationPanelKey.current;
      nextFileOperationPanelKey.current += 1;
      setFileOperationPanels((prevFileOperationPanels) => ({
        ...prevFileOperationPanels,
        [selectedDiskId]: [
          ...prevFileOperationPanels[selectedDiskId].map((panel) => ({ ...panel, selected: false })),
          { panelKey, panelType, fileId, selected: true },
        ],
      }));
    },
    [selectedDiskId],
  );

  const selectFileOperationPanel = useCallback(
    (panelKey) => {
      setFileOperationPanels((prevFileOperationPanels) => ({
        ...prevFileOperationPanels,
        [selectedDiskId]: prevFileOperationPanels[selectedDiskId].map((panel) => ({
          ...panel,
          selected: panel.panelKey === panelKey,
        })),
      }));
    },
    [selectedDiskId],
  );

  const closeFileOperationPanel = useCallback(
    (panelKey) => {
      setFileOperationPanels((prevFileOperationPanels) => {
        const prevPanelsOfSelectedDisk = prevFileOperationPanels[selectedDiskId];
        const idx = prevPanelsOfSelectedDisk.findIndex((panel) => panel.panelKey === panelKey);
        // First, the case where this is the only tab
        if (idx === 0 && prevPanelsOfSelectedDisk.length === 1) {
          return {
            ...prevFileOperationPanels,
            [selectedDiskId]: [],
          };
        }
        // If the tab we're closing was the selected one, we need a new selected one
        if (prevPanelsOfSelectedDisk[idx].selected) {
          // If we're closing the last tab, select the previous one
          // (There should be at least two tabs if we got here)
          if (idx === prevPanelsOfSelectedDisk.length - 1) {
            return {
              ...prevFileOperationPanels,
              [selectedDiskId]: [
                ...prevPanelsOfSelectedDisk.slice(0, idx - 1),
                { ...prevPanelsOfSelectedDisk[idx - 1], selected: true },
              ],
            };
          }
          // Otherwise, select the next tab
          return {
            ...prevFileOperationPanels,
            [selectedDiskId]: [
              ...prevPanelsOfSelectedDisk.slice(0, idx),
              { ...prevPanelsOfSelectedDisk[idx + 1], selected: true },
              ...prevPanelsOfSelectedDisk.slice(idx + 2),
            ],
          };
        }
        // Otherwise we can simply dispose of the tab
        return {
          ...prevFileOperationPanels,
          [selectedDiskId]: [...prevPanelsOfSelectedDisk.slice(0, idx), ...prevPanelsOfSelectedDisk.slice(idx + 1)],
        };
      });
    },
    [selectedDiskId],
  );

  if (Object.keys(fileMgmtStatus).length === 0) {
    return (
      <Paper className={classes.root}>
        <Typography variant="h6">Satellite</Typography>
        <NonIdealState message="File management status is unavailable." />
      </Paper>
    );
  }

  const selectedFileOperationPanel = fileOperationPanels[selectedDiskId].find((panel) => panel.selected);

  return (
    <Paper className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={1}>
          <Typography variant="h6">Satellite</Typography>
        </Grid>
        <Grid item container xs direction="row-reverse" spacing={1}>
          <Grid item>
            <TargetPathSelect targetPath={fileMgmtStatus.target ?? 'pushToOperator'} selectTargetPath={setTargetPath} />
          </Grid>
          <Grid item>
            <OperationModeControls
              opMode={fileMgmtStatus.stats?.mode}
              diskStates={{
                [TCTM.tctm_disk_id_e.TCTM_MRAM]: fileMgmtStatus.disks?.[TCTM.tctm_disk_id_e.TCTM_MRAM]?.diskState,
                [TCTM.tctm_disk_id_e.TCTM_eMMC]: fileMgmtStatus.disks?.[TCTM.tctm_disk_id_e.TCTM_eMMC]?.diskState,
              }}
              selectOpMode={(newOpMode) => genericRPCCallback('setOpMode', { mode: newOpMode })}
              getFsStatus={() => genericRPCCallback('fileSystemStatus', {})}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.fsAndDiskLevelControls} container spacing={1} justify="space-between">
        <Grid item>
          <DiskManagementControls
            opMode={fileMgmtStatus.stats?.mode}
            diskId={selectedDiskId}
            availableDisks={[TCTM.tctm_disk_id_e.TCTM_MRAM, TCTM.tctm_disk_id_e.TCTM_eMMC]}
            diskState={fileMgmtStatus.disks?.[selectedDiskId]?.diskState}
            mountDisk={() => genericRPCCallback('mount', { diskID: selectedDiskId })}
            selectDisk={selectDisk}
            openPanel={openPanels[selectedDiskId]}
            selectPanel={selectPanel}
          />
        </Grid>
        <Grid item>
          <FilesystemManagementControls
            opMode={fileMgmtStatus.stats?.mode}
            diskId={selectedDiskId}
            openPanel={openPanels[selectedDiskId]}
            selectPanel={selectPanel}
          />
        </Grid>
      </Grid>
      <Collapse in={openPanels[selectedDiskId] !== null}>
        <Paper className={classes.fsAndDiskLevelControlPanels} elevation={0} variant="outlined">
          <SatelliteFilesControlPanel
            whichPanel={openPanels[selectedDiskId]}
            selectedDiskId={selectedDiskId}
            selectedDisk={fileMgmtStatus.disks?.[selectedDiskId]}
            listingLevels={listingLevels}
            toggleListingLevel={toggleListingLevel}
            genericRPCCallback={genericRPCCallback}
          />
        </Paper>
      </Collapse>
      <Collapse in={fileOperationPanels[selectedDiskId].length !== 0}>
        <Paper className={classes.fileOperationPanels} elevation={0} variant="outlined">
          <Tabs
            value={selectedFileOperationPanel?.panelKey}
            variant="scrollable"
            onChange={(evt, newValue) => selectFileOperationPanel(newValue)}
          >
            {fileOperationPanels[selectedDiskId].map(({ panelKey, panelType, fileId }) => (
              <SatelliteFileOperationTab
                key={panelKey}
                value={panelKey}
                panelType={panelType}
                fileId={fileId}
                close={() => closeFileOperationPanel(panelKey)}
              />
            ))}
          </Tabs>
          <Box className={classes.fileOperationPanelContainer}>
            <SatelliteFileOperationPanel
              diskId={selectedDiskId}
              fileId={selectedFileOperationPanel?.fileId ?? null}
              whichPanel={selectedFileOperationPanel?.panelType ?? null}
              files={fileMgmtStatus.files}
              close={() => closeFileOperationPanel(selectedFileOperationPanel?.panelKey)}
              genericRPCCallback={genericRPCCallback}
            />
          </Box>
        </Paper>
      </Collapse>
      <Collapse in={Object.keys(fileMgmtStatus.requests ?? {}).length !== 0}>
        <RequestProgressIndicatorsPanel
          className={classes.requestProgressIndicators}
          requests={fileMgmtStatus.requests}
          clearAll={() => genericRPCCallback('resetPendingRequests', {})}
        />
      </Collapse>
      <Box className={classes.satelliteFilesTable}>
        <SatelliteFilesTable
          files={fileMgmtStatus.files?.[selectedDiskId]}
          openFileOperationPanel={openFileOperationPanel}
        />
      </Box>
    </Paper>
  );
};

const SatelliteFilesControlPanel = ({
  whichPanel,
  selectedDiskId,
  selectedDisk,
  listingLevels,
  toggleListingLevel,
  genericRPCCallback,
}) => {
  if (selectedDiskId === null) {
    return <NonIdealState message="No disk selected." />;
  }

  switch (whichPanel) {
    case 'disk-info':
      return (
        <DiskInfoPanel
          diskId={selectedDiskId}
          disk={selectedDisk}
          updateDiskInfo={() => genericRPCCallback('diskInfo', { diskID: selectedDiskId })}
        />
      );
    case 'disk-dd-read':
      return (
        <DirectDiskReadPanel
          diskId={selectedDiskId}
          totalDiskSize={selectedDisk?.diskSize}
          read={(address, amount) =>
            genericRPCCallback('read', { diskID: selectedDiskId, absByteAddress: address, numberOfBytesToRead: amount })
          }
        />
      );
    case 'disk-dd-write':
      return (
        <DirectDiskWritePanel
          diskId={selectedDiskId}
          totalDiskSize={selectedDisk?.diskSize}
          write={(address, amount, data) =>
            genericRPCCallback('write', {
              diskID: selectedDiskId,
              absByteAddress: address,
              numberOfBytesToWrite: amount,
              data,
            })
          }
        />
      );
    case 'file-listing':
      return (
        <SatelliteFileListingPanel
          diskId={selectedDiskId}
          listingLevels={listingLevels}
          toggleListingLevel={toggleListingLevel}
          updateListing={() => {
            Object.entries(listingLevels).forEach(([reportType, isActive]) => {
              if (isActive) {
                genericRPCCallback('list', { diskID: selectedDiskId, reportType });
              }
            });
          }}
        />
      );
    case 'reset-fs':
      return (
        <ResetFilesystemPanel
          diskId={selectedDiskId}
          reset={() => genericRPCCallback('reset', { diskID: selectedDiskId })}
        />
      );
    case 'format-fs':
      return (
        <FormatFilesystemPanel
          diskId={selectedDiskId}
          format={(mode) => genericRPCCallback('format', { diskID: selectedDiskId, forced: mode })}
        />
      );
    case 'unmount-disk':
      return (
        <UnmountDiskPanel
          diskId={selectedDiskId}
          unmount={(mode) => genericRPCCallback('unmount', { diskID: selectedDiskId, forced: mode })}
        />
      );
    case null:
      return null;
    default:
      throw new Error('Unknown satellite FS management panel type');
  }
};

const SatelliteFileOperationPanel = ({ diskId, fileId, whichPanel, files, genericRPCCallback, close }) => {
  const checkFileIdExists = useCallback(
    (onDisk, id) => {
      if (files === undefined || files[onDisk] === undefined) {
        return undefined;
      }
      return Object.keys(files[onDisk]).includes(`${id}`);
    },
    [files],
  );

  const closePanelAction = [{ label: 'Close panel', action: close }];

  if (diskId === null) {
    return <NonIdealState message="No disk selected." actions={closePanelAction} />;
  }

  if (files === undefined || files[diskId] === undefined) {
    return (
      <NonIdealState message={`No information about files on ${getDiskLabel(diskId)}`} actions={closePanelAction} />
    );
  }

  if (!checkFileIdExists(diskId, fileId)) {
    return (
      <NonIdealState
        message={`No information about a file with ID ${fileId} on ${getDiskLabel(diskId)}.`}
        messageSecondary="It may have been renamed or removed."
        actions={closePanelAction}
      />
    );
  }

  switch (whichPanel) {
    case 'remove':
      return (
        <RemoveFilePanel
          diskId={diskId}
          fileId={fileId}
          remove={() => genericRPCCallback('remove', { diskID: diskId, fileID: fileId })}
          close={close}
        />
      );
    case 'edit-attributes':
      return (
        <SetFileAttributesPanel
          diskId={diskId}
          fileId={fileId}
          fileAttributes={files[diskId][fileId]?.fileAttributes}
          setAttributes={(isSystemFile, isProtectedFile) =>
            genericRPCCallback('setFileAttr', { diskID: diskId, fileID: fileId, isSystemFile, isProtectedFile })
          }
          close={close}
        />
      );
    case 'rename':
      return (
        <RenameFilePanel
          diskId={diskId}
          fileId={fileId}
          checkFileIdExists={checkFileIdExists}
          rename={(newFileId) =>
            genericRPCCallback('rename', { diskID: diskId, oldFileID: fileId, newFileID: newFileId })
          }
          close={close}
        />
      );
    case 'copy':
      return (
        <CopyFilePanel
          diskId={diskId}
          fileId={fileId}
          checkFileIdExists={checkFileIdExists}
          copy={(destinationDiskId, destinationFileId) =>
            genericRPCCallback('copy', {
              srcDiskID: diskId,
              srcFileID: fileId,
              dstDiskID: destinationDiskId,
              dstFileID: destinationFileId,
            })
          }
          close={close}
        />
      );
    case 'get-checksum':
      return (
        <FileChecksumPanel
          diskId={diskId}
          fileId={fileId}
          checksum={files[diskId][fileId]?.checksum}
          getChecksum={() => genericRPCCallback('checksum', { diskID: diskId, fileID: fileId })}
          close={close}
        />
      );
    case 'get-report':
      return (
        <FileReportPanel
          diskId={diskId}
          fileId={fileId}
          file={files[diskId][fileId]}
          getFileReport={() => genericRPCCallback('fileReport', { diskID: diskId, fileID: fileId })}
          close={close}
        />
      );
    case 'dd-write':
      return (
        <DirectFileWritePanel
          diskId={diskId}
          fileId={fileId}
          totalFileSize={files[diskId][fileId]?.fileSize}
          write={(address, amount, data) =>
            genericRPCCallback('fileWrite', {
              diskID: diskId,
              fileID: fileId,
              fileByteAddress: address,
              numberOfBytesToWrite: amount,
              data,
            })
          }
        />
      );
    case 'dd-read':
      return (
        <DirectFileReadPanel
          diskId={diskId}
          fileId={fileId}
          totalFileSize={files[diskId][fileId]?.fileSize}
          read={(address, amount) =>
            genericRPCCallback('fileRead', {
              diskID: diskId,
              fileID: fileId,
              fileByteAddress: address,
              numberOfBytesToRead: amount,
            })
          }
        />
      );
    case null:
      return null;
    default:
      throw new Error('Unknown satellite file operation panel type');
  }
};

export default SatelliteFilesPanel;
