import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

export default function useRoleCard() {
  const { enqueueSnackbar } = useSnackbar();
  const RoleDsRecord = useDsRecord('bustester', 'control/role');
  const [mockRole, setMockRole] = useState(1);
  const [dutRole, setDutRole] = useState(3);
  const [activeBus, setActiveBus] = useState('can');
  const { currentResGroup } = useContext(RgContext);

  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const rpcBaseUrl = `rg/${currentResGroup}/@c3s/bustester-provider/lclControl`;

  const rpcLCL = `${rpcBaseUrl}/setLcl`;
  const rpcPower = `${rpcBaseUrl}/setPower`;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const rolesInvalid =
    (mockRole + 1 === dutRole && dutRole % 2 === 0) ||
    (mockRole === dutRole + 1 && mockRole % 2 === 0) ||
    mockRole === dutRole;

  const persistState = () => {
    RoleDsRecord.set({ ...RoleDsRecord.get(), dutRole, mockRole, activeBus });
  };

  useEffect(() => {
    RoleDsRecord.whenReady((record) => {
      setMockRole(record.get()?.mockRole ?? 1);
      setDutRole(record.get()?.dutRole ?? 3);
      setActiveBus(record.get()?.activeBus ?? 'can');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPower = async () => {
    [0, 1, 2].forEach(async (lcl) => {
      processRpcResponse({
        endpoint: rpcLCL,
        paramsObject: { LCL_ID: lcl, Cmd: false },
        snackbarMessage: `Reset LCL with ID: ${lcl} requested`,
      });
    });
    processRpcResponse({
      endpoint: rpcPower,
      paramsObject: {
        POWER_3V3_EN: false,
        POWER_3V3_EN_VALID: true,
        POWER_5V0_EN: false,
        POWER_5V0_EN_VALID: true,
        POWER_UNREG_EN: false,
        POWER_UNREG_EN_VALID: true,
      },
      snackbarMessage: `Reset power requested`,
    });
    persistState();
  };

  return {
    resetPower,
    mockRole,
    dutRole,
    activeBus,
    rolesInvalid,
    setMockRole,
    setDutRole,
    setActiveBus,
  };
}
