import { hexStringToArray } from '#utils/helper';

// eslint-disable-next-line import/prefer-default-export
export function reduceBytesFieldState(value, internalState, action) {
  switch (action.type) {
    case 'set-field-text':
      return [hexStringToArray(action.value), { ...internalState, fieldText: action.value }];
    default:
      throw new Error(`Invalid bytes field action type: ${action.type}`);
  }
}
