import { ControlCard } from '@c3s/ui-tracking-station';
import { Grid } from '@material-ui/core';
import React from 'react';

import useAntennaHeater from './HkHooks/useAntennaHeater';
import useCabinetCooling from './HkHooks/useCabinetCooling';
import useCabinetFan from './HkHooks/useCabinetFan';
import useCabinetHeater from './HkHooks/useCabinetHeater';
import useServoControls from './MotorCtrlHooks/useServoControls';
import useRFFEControls from './RFFEHooks/useRFFEControls';
import useSDRControls from './SDRHooks/useSDRControls';
import useWindAlarmControls from './WindAlarmHooks/useWindAlarmControls';

const SetupMonitorTab = () => {
  const { antennaComponents } = useAntennaHeater();
  const { servoCtrlComponents } = useServoControls();
  const { cabinetHeaterComponents } = useCabinetHeater();
  const { cabinetCoolingComponents } = useCabinetCooling();
  const { cabinetFanComponents } = useCabinetFan();
  const { rffeControlComponents } = useRFFEControls();
  const { sdrControlComponents } = useSDRControls();
  const { windAlarmComponents } = useWindAlarmControls();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <ControlCard
          title="Antenna Heater Control"
          components={antennaComponents}
          style={{ width: 430, height: '100%' }}
        />
      </Grid>
      <Grid item>
        <ControlCard
          title="Cabinet Fan Control"
          components={cabinetFanComponents}
          style={{ width: 430, height: '100%' }}
        />
      </Grid>
      <Grid item>
        <ControlCard
          title="Cabinet Heater Control"
          components={cabinetHeaterComponents}
          style={{ width: 430, height: '100%' }}
        />
      </Grid>
      <Grid item>
        <ControlCard
          title="Cabinet Cooling Control"
          components={cabinetCoolingComponents}
          style={{ width: 430, height: '100%' }}
        />
      </Grid>
      <Grid item>
        <ControlCard
          title="Servo Power Control"
          components={servoCtrlComponents}
          style={{ width: 430, height: '100%' }}
        />
      </Grid>
      <Grid item>
        <ControlCard title="RFFE Control" components={rffeControlComponents} style={{ width: 430, height: '100%' }} />
      </Grid>
      <Grid item>
        <ControlCard
          title="SDR Carrier & Baud Control"
          components={sdrControlComponents}
          style={{ width: 430, height: '100%' }}
        />
      </Grid>
      <Grid item>
        <ControlCard title="Wind Alarm" components={windAlarmComponents} style={{ width: 430, height: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default SetupMonitorTab;
