import PropTypes from 'prop-types';
import React from 'react';

import elevationMarkingsURL from '../../../../../../assets/images/elevation_markings.svg';
import DialIndicator from './DialIndicator';

const ElevationIndicator = ({ elevation, diameter }) => {
  return <DialIndicator dialDegrees={elevation} diameter={diameter} markingsBgURL={elevationMarkingsURL} />;
};

ElevationIndicator.propTypes = {
  elevation: PropTypes.number.isRequired,
  diameter: PropTypes.number,
};

ElevationIndicator.defaultProps = {
  diameter: 128,
};

export default ElevationIndicator;
