import React from 'react';

import BEACONTab from '#services/message-logging/tab-panel/tabs/BEACONTab';
import CANTab from '#services/message-logging/tab-panel/tabs/CANTab';
import ETHTab from '#services/message-logging/tab-panel/tabs/ETHTab';
import OverviewTab from '#services/message-logging/tab-panel/tabs/OverviewTab';
import RawTab from '#services/message-logging/tab-panel/tabs/RawTab';
import SSPTab from '#services/message-logging/tab-panel/tabs/SSPTab';
import TCTMTab from '#services/message-logging/tab-panel/tabs/TCTMTab';

export default [
  {
    name: 'Overview',
    hash: 'overview',
    container: <OverviewTab />,
  },
  {
    name: 'Raw',
    hash: 'raw',
    container: <RawTab />,
  },
  {
    name: 'Ethernet',
    hash: 'ethernet-protocol',
    container: <ETHTab />,
  },
  {
    name: 'CAN',
    hash: 'can-protocol',
    container: <CANTab />,
  },
  {
    name: 'SSP',
    hash: 'ssp-protocol',
    container: <SSPTab />,
  },
  {
    name: 'TCTM',
    hash: 'tctm-protocol',
    container: <TCTMTab />,
  },
  {
    name: 'BEACON',
    hash: 'beacon-protocol',
    container: <BEACONTab />,
  },
];

export const spaceSegment = [
  {
    name: 'TCTM',
    hash: 'tctm-protocol',
    container: <TCTMTab />,
  },
  {
    name: 'BEACON',
    hash: 'beacon-protocol',
    container: <BEACONTab />,
  },
];
