const SubsystemList = [
  {
    value: 1,
    label: 'OBC1',
  },
  {
    value: 2,
    label: 'OBC2',
  },
  {
    value: 3,
    label: 'COM1',
  },
  {
    value: 4,
    label: 'COM2',
  },
  {
    value: 5,
    label: 'EPS1',
  },
  {
    value: 6,
    label: 'EPS2',
  },
  {
    value: 7,
    label: 'AUX1',
  },
  {
    value: 8,
    label: 'AUX2',
  },
  {
    value: 9,
    label: 'ADCS',
  },
  {
    value: 10,
    label: 'RADMAG',
  },
];

export { SubsystemList as default };
