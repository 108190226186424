import React from 'react';

import CabinetHkTab from '#services/tracking-station/station-management/tabs/CabinetHkTab';
import FFTMonitorTab from '#services/tracking-station/station-management/tabs/FFTMonitorTab';
import MotorCtrlTab from '#services/tracking-station/station-management/tabs/MotorCtrlTab';
import RffeTab from '#services/tracking-station/station-management/tabs/RffeTab';
import SdrCtrlTab from '#services/tracking-station/station-management/tabs/SdrCtrlTab';
import SetupTab from '#services/tracking-station/station-management/tabs/SetupTab';
import WeatherTab from '#services/tracking-station/station-management/tabs/WeatherTab';

export default [
  {
    name: 'Cabinet Housekeeping',
    hash: 'cabinet',
    container: <CabinetHkTab />,
  },
  {
    name: 'Motor Controller',
    hash: 'motor',
    container: <MotorCtrlTab />,
  },
  {
    name: 'Weather Station',
    hash: 'weather',
    container: <WeatherTab />,
  },
  {
    name: 'RF Frontend',
    hash: 'rffe',
    container: <RffeTab />,
  },
  {
    name: 'SDR Control',
    hash: 'sdr',
    container: <SdrCtrlTab />,
  },
  {
    name: 'FFT Monitor',
    hash: 'fft',
    container: <FFTMonitorTab />,
  },
  {
    name: 'Setup',
    hash: 'setup',
    container: <SetupTab />,
  },
];
