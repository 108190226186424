import { BackendRoleSettingsCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useRoleCard from './useRoleCard';

const RoleCard = () => {
  const {
    resetPower,
    mockRole,
    dutRole,
    activeBus,
    rolesInvalid,
    setMockRole,
    setDutRole,
    setActiveBus,
  } = useRoleCard();

  return (
    <BackendRoleSettingsCard
      onRolesSet={resetPower}
      mockRole={mockRole}
      dutRole={dutRole}
      activeBus={activeBus}
      rolesInvalid={rolesInvalid}
      setMockRole={setMockRole}
      setDutRole={setDutRole}
      setActiveBus={setActiveBus}
    />
  );
};

export default RoleCard;
