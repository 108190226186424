import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../../useAutonomyStatusDs';
import useHousekeepingDs from '../useHousekeepingDs';

const enableOptions = new Map();
const windOptions = new Map();
const servoPowerOptions = new Map();

export default function useMainServoControl() {
  const theme = useTheme();
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { hkState } = useHousekeepingDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  enableOptions.set(0, { bgColor: theme.palette.grey.main, label: 'DISABLED - 0', color: 'white' });
  enableOptions.set(1, { bgColor: theme.palette.grey.main, label: 'ENABLED - 1', color: 'white' });
  windOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  windOptions.set(1, { bgColor: theme.palette.error.main, label: 'ALARM - 1', color: 'white' });
  servoPowerOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  servoPowerOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });

  const ServoPowerOutput = hkState.inputs[7];

  const setTrajTimeOffset = useCallback(
    (timeOffset) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'motorControl.trajTimeOffset',
          value: timeOffset,
        });
        enqueueSnackbar('Trajectory time offset change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const mainServoComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'Flip Enable:',
          value: Number(autonomyConfig.motorControl.flipEnable ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: enableOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Wind alarm state:',
          value: Number(autonomyStatus.windAlarm.alarmValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: windOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Servo Power:',
          value: Number(ServoPowerOutput ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: servoPowerOptions,
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Trajectory time offset [ms]:',
          numberInputProps: { inputProps: { signed: true } },
          value: autonomyConfig.motorControl.trajTimeOffset ?? 0,
          handleSet: setTrajTimeOffset,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current trajectory time offset:',
          value: Number(autonomyConfig.motorControl.trajTimeOffset ?? 0),
          unit: 'ms',
        },
      },
    ],
    [
      autonomyConfig.motorControl.flipEnable,
      autonomyStatus.windAlarm.alarmValue,
      ServoPowerOutput,
      autonomyConfig.motorControl.trajTimeOffset,
      setTrajTimeOffset,
    ],
  );

  return { mainServoComponents };
}
