import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import useTimeAgo from '@rooks/use-time-ago';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useHousekeepingDs from '../useHousekeepingDs';

const onOffOptions = new Map();
const connectOptions = new Map();
const enableOptions = new Map();

export default function useHkConnection() {
  const theme = useTheme();
  const { hkState, hkPathRoot } = useHousekeepingDs();
  const [IP, setIP] = useState(hkState.networkConfig.IP);
  const [port, setPort] = useState(hkState.networkConfig.port);
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const lastUpdated = useTimeAgo(hkState.lastUpdated);

  onOffOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  onOffOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });
  enableOptions.set(0, { bgColor: theme.palette.error.main, label: 'DEAD - 0', color: 'white' });
  enableOptions.set(1, { bgColor: theme.palette.success.main, label: 'ALIVE - 1', color: 'white' });
  connectOptions.set(0, { bgColor: theme.palette.error.main, label: 'DISCONNECTED - 0', color: 'white' });
  connectOptions.set(1, { bgColor: theme.palette.success.main, label: 'CONNECTED - 1', color: 'white' });

  useEffect(() => {
    setIP(hkState.networkConfig.IP);
  }, [hkState.networkConfig.IP]);

  useEffect(() => {
    setPort(hkState.networkConfig.port);
  }, [hkState.networkConfig.port]);

  const connect = useCallback(() => {
    try {
      rpcWithErrorHandler(`${hkPathRoot}/connect`, {
        IP,
        port,
      });
      enqueueSnackbar(`Connection to ${IP}:${port} requested`, { variant: 'info' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }, [IP, enqueueSnackbar, hkPathRoot, port, rpcWithErrorHandler]);

  const disconnect = useCallback(() => {
    try {
      rpcWithErrorHandler(`${hkPathRoot}/disconnect`, {});
      enqueueSnackbar('Disconnection requested', { variant: 'info' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, hkPathRoot, rpcWithErrorHandler]);

  const handleConnection = useCallback(
    (mode) => {
      if (mode === 'connect') {
        connect();
      } else {
        disconnect();
      }
    },
    [connect, disconnect],
  );

  const toggleHeartbeat = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(`${hkPathRoot}/enableHeartbeat`, mode);
        enqueueSnackbar('Toggle heartbeat requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, hkPathRoot, rpcWithErrorHandler],
  );

  const togglePeriodic = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(`${hkPathRoot}/enablePeriodic`, mode);
        enqueueSnackbar('Toggle periodic update requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, hkPathRoot, rpcWithErrorHandler],
  );

  const hkConnectionComponents = useMemo(
    () => [
      {
        type: 'input-nosetter',
        options: {
          helperText: 'IP:',
          value: IP,
          handleChange: setIP,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current IP:',
          value: hkState.networkConfig.IP,
        },
      },
      {
        type: 'input-nosetter',
        options: {
          helperText: 'Port:',
          value: port,
          handleChange: setPort,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Port:',
          value: hkState.networkConfig.port,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Connect:',
          button1Text: 'CONNECT',
          handleButton1Click: () => handleConnection('connect'),
          button2Text: 'DISCONNECT',
          handleButton2Click: () => handleConnection('disconnect'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Connected:',
          value: Number(hkState.isConnected ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: connectOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Heartbeat:',
          button1Text: 'ENABLE',
          handleButton1Click: () => toggleHeartbeat(true),
          button2Text: 'DISABLE',
          handleButton2Click: () => toggleHeartbeat(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Heartbeat Enabled:',
          value: Number(hkState.isPinging ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: onOffOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Heartbeat Status:',
          value: Number(hkState.isAlive ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: enableOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Periodic Update:',
          button1Text: 'ENABLE',
          handleButton1Click: () => togglePeriodic(true),
          button2Text: 'DISABLE',
          handleButton2Click: () => togglePeriodic(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Periodic Enabled:',
          value: Number(hkState.isPolling ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: onOffOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Periodic Status:',
          value: Number(hkState.isUpdating ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: enableOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Last Updated:',
          value: lastUpdated,
        },
      },
    ],
    [
      IP,
      handleConnection,
      hkState.isAlive,
      hkState.isConnected,
      hkState.isPinging,
      hkState.isPolling,
      hkState.isUpdating,
      hkState.networkConfig.IP,
      hkState.networkConfig.port,
      port,
      toggleHeartbeat,
      togglePeriodic,
      lastUpdated,
    ],
  );

  return { hkConnectionComponents };
}
