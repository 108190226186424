import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useAxios from '#hooks/useAxios';

import InitialState from './InitialState';
import Reducer from './Reducer';

const acceptMimeType = 'application/json';
const baseURL = process.env.GATSBY_FILE_SERVER;

function changeExtToBin(filename) {
  if (typeof filename === 'string') {
    const ext = filename.split('.').slice(-1)[0];
    const base = filename.split('.').slice(0, -1).join('.');

    if (ext === 'lz77' || ext === 'huff') {
      return `${base}.bin`;
    }
  }
  return filename;
}

function changeExtToLZ77(filename) {
  if (typeof filename === 'string') {
    const base = filename.split('.').slice(0, -1).join('.');

    return `${base}.lz77`;
  }
  return filename;
}

const useFileServerApi = () => {
  const [fileServerState, dispatch] = useReducer(Reducer, InitialState);
  const { fetchData, isAxiosFetching: isFileServerLoading } = useAxios();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const processRPCName = useMemo(() => `rg/${currentResGroup}/@c3s/file-handler/process`, [currentResGroup]);
  const compressRPCName = useMemo(() => `rg/${currentResGroup}/@c3s/file-compression-provider/compress`, [
    currentResGroup,
  ]);
  const decompressRPCName = useMemo(() => `rg/${currentResGroup}/@c3s/file-compression-provider/decompress`, [
    currentResGroup,
  ]);

  const fetchFileList = useCallback(
    async ({ skip, limit, jwt, bucketId }) => {
      try {
        const config = {
          method: 'GET',
          url: `${baseURL}/${bucketId}?skip=${skip}&limit=${limit}`,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${jwt}`,
            Accept: acceptMimeType,
          },
        };
        return await fetchData(config);
      } catch ({ message }) {
        throw new Error(message);
      }
    },
    [fetchData],
  );

  const deleteFile = async ({ id, jwt, bucketId }) => {
    try {
      const config = {
        method: 'DELETE',
        url: `${baseURL}/${bucketId}/${id}`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${jwt}`,
          Accept: acceptMimeType,
        },
      };
      return await fetchData(config);
    } catch ({ message }) {
      throw new Error(message);
    }
  };

  const processFile = ({ id, bucketId, ...additionalInfo }) =>
    rpcWithErrorHandler(processRPCName, { bucketID: bucketId, fileID: id, ...additionalInfo });

  const zipFile = ({ id, filename, bucketId }) =>
    rpcWithErrorHandler(compressRPCName, {
      source: { bucketID: bucketId, fileID: id },
      destination: { bucketID: bucketId, fileName: changeExtToLZ77(filename) },
      compressionType: 'LZ77',
    });

  const unzipFile = ({ id, filename, bucketId }) =>
    rpcWithErrorHandler(decompressRPCName, {
      source: { bucketID: bucketId, fileID: id },
      destination: { bucketID: bucketId, fileName: changeExtToBin(filename) },
    });

  const updateFileMetadata = async ({ id, filename, jwt, bucketId }) => {
    try {
      const config = {
        method: 'PATCH',
        url: `${baseURL}/${bucketId}/${id}`,
        data: { filename },
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${jwt}`,
          Accept: acceptMimeType,
        },
      };
      return await fetchData(config);
    } catch ({ message }) {
      throw new Error(message);
    }
  };

  const uploadFile = async ({ formData, jwt, bucketId, onUploadProgress }) => {
    try {
      const config = {
        method: 'POST',
        url: `${baseURL}/${bucketId}`,
        data: formData,
        withCredentials: true,
        onUploadProgress,
        headers: {
          Authorization: `Bearer ${jwt}`,
          Accept: acceptMimeType,
        },
      };
      return await fetchData(config);
    } catch ({ message }) {
      throw new Error(message);
    }
  };

  const uploadFileStream = async ({ data, filename, filetype, jwt, bucketId, onUploadProgress }) => {
    try {
      const config = {
        method: 'POST',
        url: `${baseURL}/stream/${bucketId}/${filename}`,
        data,
        withCredentials: true,
        onUploadProgress,
        headers: {
          Accept: acceptMimeType,
          Authorization: `Bearer ${jwt}`,
          'Content-Type': filetype,
        },
      };
      return await fetchData(config);
    } catch ({ message }) {
      throw new Error(message);
    }
  };

  useEffect(() => {
    dispatch({ type: 'setFileServerLoading', isFileServerLoading });
  }, [isFileServerLoading]);

  return {
    ...fileServerState,
    fetchFileList,
    deleteFile,
    processFile,
    zipFile,
    unzipFile,
    updateFileMetadata,
    uploadFile,
    uploadFileStream,
  };
};

export default useFileServerApi;
