import { useCallback, useEffect, useRef } from 'react';

export default function useThrottle(delayMs, [value, setValue]) {
  const lastExecutionTimeInMsRef = useRef(0);
  const scheduledTimeoutHandleRef = useRef(0);

  const newSetValue = useCallback(
    (update, isImmediate = false) => {
      function executeUpdate() {
        lastExecutionTimeInMsRef.current = Date.now();
        setValue(update);
      }

      const timeUntilUpdateInMs = delayMs - (Date.now() - lastExecutionTimeInMsRef.current);
      if (isImmediate || timeUntilUpdateInMs <= 0) {
        executeUpdate();
      } else {
        clearTimeout(scheduledTimeoutHandleRef.current);
        scheduledTimeoutHandleRef.current = setTimeout(executeUpdate, timeUntilUpdateInMs);
      }
    },
    [delayMs, setValue],
  );

  useEffect(() => {
    return () => {
      clearTimeout(scheduledTimeoutHandleRef.current);
    };
  });

  return [value, newSetValue];
}
