import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    paddingBottom: 0,
  },
  subheader: {
    lineHeight: 'initial',
    marginTop: theme.spacing(2),
  },
  requestId: {
    fontFamily: 'monospace',
  },
}));

const RequestProgressIndicatiorsPanel = ({ requests, clearAll }) => {
  const classes = useStyles();
  return (
    <List
      className={classes.root}
      dense
      subheader={
        <ListSubheader className={classes.subheader} disableSticky>
          <Grid container justify="space-between" alignItems="baseline">
            <Grid item>
              <Typography>Request Progress</Typography>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={clearAll}>
                Clear All
              </Button>
            </Grid>
          </Grid>
        </ListSubheader>
      }
    >
      {Object.entries(requests ?? {}).map(([requestId, requestProgress]) => (
        <ListItem key={requestId}>
          <ListItemIcon>
            <Typography className={classes.requestId}>{requestId}</Typography>
          </ListItemIcon>
          <ListItemText>
            <LinearProgress
              variant="determinate"
              value={
                (requestProgress.progress.length === 0
                  ? 0
                  : requestProgress.progress.filter((item) => item === 1).length / requestProgress.progress.length) *
                100
              }
            />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

RequestProgressIndicatiorsPanel.propTypes = {
  requests: PropTypes.objectOf(
    PropTypes.shape({ progress: PropTypes.arrayOf(PropTypes.number), requestType: PropTypes.number }),
  ).isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default RequestProgressIndicatiorsPanel;
