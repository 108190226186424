import globalStyles, { normalFontSize } from '#utils/styles';

export default (theme) => ({
  ...globalStyles(theme),
  container: {
    display: 'flex',
    fontSize: normalFontSize,
    paddingRight: theme.spacing(1),
    alignItems: 'center',
  },
  placeholder: {
    flexBasis: `${12 + theme.spacing(0.5)}px`,
    paddingRight: theme.spacing(0.5),
  },
  title: {
    marginRight: 'auto',
    fontWeight: 'bold',
    fontSize: normalFontSize,
    paddingRight: theme.spacing(0.5),
  },
  icon: {
    paddingRight: theme.spacing(0.5),
  },
  setFlexBasis76: {
    flexBasis: 76,
  },
  value: {
    marginLeft: 'auto',
    '&:not(:last-child)': {
      paddingRight: theme.spacing(1),
    },
  },
  ValueCell: {
    fontSize: normalFontSize,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  Cell: {
    fontSize: normalFontSize,
    padding: 'unset',
  },
});
