import { useContext } from 'react';

import RgContext from '#contexts/RgContext';

import useDsEventListener from './useDsEventListener';

export default function useGeneralProtocolEventListener({ protocol, callback }) {
  const { currentResGroup } = useContext(RgContext);
  return useDsEventListener(
    `rg/${currentResGroup}/@c3s/${protocol.toLowerCase()}-transcoder-provider/decoded${protocol}`,
    callback ? (data) => callback(data) : undefined,
  );
}
