import { Button, Card, CardContent, CardHeader, MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const initialStationObj = {
  station: 0,
  stationCluster: 1,
  stationName: '',
  owner: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  country: '',
  city: '',
  lat: '',
  long: '',
  alt: '',
  parkingAzimuth: '',
  parkingElevation: '',
};

const SelectStation = ({ stationObj, setStationObj }) => (
  <TextField
    id="stationSelect"
    name="stationSelect"
    value={stationObj.station}
    onChange={(event) => {
      setStationObj((rest) => ({ ...rest, station: event.target.value }));
    }}
    label="Select Station to configure"
    select
  >
    <MenuItem value={0}>C3S Lurdy (UHF)</MenuItem>
    <MenuItem value={1}>MTA-EK (UHF)</MenuItem>
  </TextField>
);

const StationNameField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Station Name"
      id="stationName"
      name="stationName"
      value={stationObj.stationName}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.stationName = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const StationClusterSelect = ({ stationObj, setStationObj }) => (
  <TextField
    id="stationClusterSelect"
    name="stationClusterSelect"
    value={stationObj.stationCluster}
    onChange={(event) => {
      setStationObj((rest) => ({ ...rest, stationCluster: event.target.value }));
    }}
    label="Station Cluster"
    select
  >
    <MenuItem value={1}>C3S Tracking Stations</MenuItem>
  </TextField>
);

const OwnerEntityField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Owner"
      id="owner"
      name="owner"
      value={stationObj.owner}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.owner = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const ContactPersonField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Contact Person"
      id="contactName"
      name="contactName"
      value={stationObj.contactName}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.contactName = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const ContactEmailField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Contact Email"
      id="contactEmail"
      name="contactEmail"
      value={stationObj.contactEmail}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.contactEmail = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const ContactPhoneField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Contact Phone Number"
      id="contactPhone"
      name="contactPhone"
      value={stationObj.contactPhone}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.contactPhone = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const CountryField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Country"
      id="country"
      name="country"
      value={stationObj.country}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.country = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const CityField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="City"
      id="city"
      name="city"
      value={stationObj.city}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.city = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const LatitudeField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Latitude"
      id="lat"
      name="lat"
      value={stationObj.lat}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.lat = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const LongitudeField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Longitude"
      id="long"
      name="long"
      value={stationObj.long}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.long = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const AltitudeField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Altitude"
      id="alt"
      name="alt"
      value={stationObj.alt}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.alt = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const ParkingAzimuthField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Parking Azimuth"
      id="parkingAzimuth"
      name="parkingAzimuth"
      value={stationObj.parkingAzimuth}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.parkingAzimuth = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const ParkingElevationField = ({ stationObj, setStationObj }) => {
  return (
    <TextField
      label="Parking Elevation"
      id="parkingElevation"
      name="parkingElevation"
      value={stationObj.parkingElevation}
      onChange={(event) => {
        const newStationObj = { ...stationObj };
        newStationObj.parkingElevation = event.target.value;
        setStationObj(newStationObj);
      }}
    />
  );
};

const AngleCorrectionField = () => <p>Angle correction is enabled</p>;

const GnssReceiverField = () => <p>GNSS receiver is enabled</p>;

const SpectrumMonitoringField = () => <p>Spectrum monitoring is enabled</p>;

const AddStation = () => {
  const [stationObj, setStationObj] = useState(initialStationObj);

  function onSubmit() {}

  return (
    <Card>
      <CardHeader title="Select Station" />
      <CardContent>
        <SelectStation stationObj={stationObj} setStationObj={setStationObj} />
      </CardContent>
      <CardHeader title="General Settings" />
      <CardContent>
        <StationNameField stationObj={stationObj} setStationObj={setStationObj} />
        <StationClusterSelect stationObj={stationObj} setStationObj={setStationObj} />
      </CardContent>
      <CardHeader title="Administrative Settings" />
      <CardContent>
        <OwnerEntityField stationObj={stationObj} setStationObj={setStationObj} />
        <ContactPersonField stationObj={stationObj} setStationObj={setStationObj} />
        <ContactEmailField stationObj={stationObj} setStationObj={setStationObj} />
        <ContactPhoneField stationObj={stationObj} setStationObj={setStationObj} />
      </CardContent>
      <CardHeader title="Location settings (QTH)" />
      <CardContent>
        <CountryField stationObj={stationObj} setStationObj={setStationObj} />
        <CityField stationObj={stationObj} setStationObj={setStationObj} />
        <LatitudeField stationObj={stationObj} setStationObj={setStationObj} />
        <LongitudeField stationObj={stationObj} setStationObj={setStationObj} />
        <AltitudeField stationObj={stationObj} setStationObj={setStationObj} />
      </CardContent>
      <CardHeader title="Position Controller Settings" />
      <CardContent>
        <ParkingAzimuthField stationObj={stationObj} setStationObj={setStationObj} />
        <ParkingElevationField stationObj={stationObj} setStationObj={setStationObj} />
      </CardContent>
      <CardHeader title="Angle Correction" />
      <CardContent>
        <AngleCorrectionField />
      </CardContent>
      <CardHeader title="Packet modem settings" />
      <CardContent>
        <GnssReceiverField />
        <SpectrumMonitoringField />
      </CardContent>
      <CardContent>
        <Button color="primary" variant="outlined" onClick={onSubmit}>
          Send
        </Button>
      </CardContent>
    </Card>
  );
};

SelectStation.propTypes = {
  stationObj: PropTypes.shape({
    station: PropTypes.number,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
StationNameField.propTypes = {
  stationObj: PropTypes.shape({
    stationName: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
StationClusterSelect.propTypes = {
  stationObj: PropTypes.shape({
    stationCluster: PropTypes.number,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
OwnerEntityField.propTypes = {
  stationObj: PropTypes.shape({
    owner: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
ContactPersonField.propTypes = {
  stationObj: PropTypes.shape({
    contactName: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
ContactEmailField.propTypes = {
  stationObj: PropTypes.shape({
    contactEmail: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
ContactPhoneField.propTypes = {
  stationObj: PropTypes.shape({
    contactPhone: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
CountryField.propTypes = {
  stationObj: PropTypes.shape({
    country: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
CityField.propTypes = {
  stationObj: PropTypes.shape({
    city: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
LatitudeField.propTypes = {
  stationObj: PropTypes.shape({
    lat: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
LongitudeField.propTypes = {
  stationObj: PropTypes.shape({
    long: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
AltitudeField.propTypes = {
  stationObj: PropTypes.shape({
    alt: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
ParkingAzimuthField.propTypes = {
  stationObj: PropTypes.shape({
    parkingAzimuth: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};
ParkingElevationField.propTypes = {
  stationObj: PropTypes.shape({
    parkingElevation: PropTypes.string,
  }).isRequired,
  setStationObj: PropTypes.func.isRequired,
};

export default AddStation;
