import { RffeStatus } from '@c3s/ui-tracking-station';
import React from 'react';

import useRffeStatusCard from './useRffeStatusCard';

const RffeStatusCard = () => {
  const { status } = useRffeStatusCard();
  return <RffeStatus status={status} />;
};

export default RffeStatusCard;
