import { AutomaticReportingCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import { periodMax, periodMin } from './AutoReportCard.constants';
import useAutoReportCard from './useAutoReportCard';

const AutoReportCard = () => {
  const {
    disableAutoReport,
    setAutoReport,
    getReport,
    AutoReportPeriodTimeInMs,
    setAutoReportPeriodTimeInMs,
    isPeriodInvalid,
  } = useAutoReportCard();

  return (
    <AutomaticReportingCard
      disableAutoReport={disableAutoReport}
      setAutoReport={setAutoReport}
      getReport={getReport}
      AutoReportPeriodTimeInMs={AutoReportPeriodTimeInMs}
      setAutoReportPeriodTimeInMs={setAutoReportPeriodTimeInMs}
      isPeriodInvalid={isPeriodInvalid}
      periodMin={periodMin}
      periodMax={periodMax}
    />
  );
};

export default AutoReportCard;
