export const apidToNumber = {
  OBC: 1,
  COM: 2,
  EPS: 3,
  AUX: 4,
  ADCS: 5,
  RADMAG: 6,
};

export const numberToApid = {
  1: 'OBC',
  2: 'COM',
  3: 'EPS',
  4: 'AUX',
  5: 'ADCS',
  6: 'RADMAG',
};

export const numbertoSubSysHex = {
  1: 0x0a,
  2: 0x0c,
  3: 0x12,
  4: 0x14,
  5: 0x1a,
  6: 0x1c,
  7: 0x22,
  8: 0x24,
  9: 0x2a,
  10: 0x32,
};

export const redundancyToNumber = {
  OBC1: 0x0a,
  OBC2: 0x0c,
  COM1: 0x12,
  COM2: 0x14,
  EPS1: 0x1a,
  EPS2: 0x1c,
  AUX1: 0x22,
  AUX2: 0x24,
  ADCS1: 0x2a,
  ADCS2: 0x2a, // same. No ADCS2
  RADMAG1: 0x32,
  RADMAG2: 0x32, // same. No RADMAG2
};

export const redundancyToIdentifier = {
  OBC1: 0x006,
  OBC2: 0x007,
  EPS1: 0x008,
  EPS2: 0x009,
  COM1: 0x00a,
  COM2: 0x00b,
  ADCS: 0x00c,
  AUX1: 0x00e,
  AUX2: 0x00f,
  RADMAG: 0xfff,
};

export const numberToRedundancy = {
  0x0a: 'OBC1',
  0x0c: 'OBC2',
  0x12: 'COM1',
  0x14: 'COM2',
  0x1a: 'EPS1',
  0x1c: 'EPS2',
  0x22: 'AUX1',
  0x24: 'AUX2',
  0x2a: 'ADCS',
  0x32: 'RADMAG',
};

export function selectBus(bus) {
  const buses = {
    can: 0,
    mlvds: 1,
  };
  return buses[bus.toLowerCase()];
}
