import merge from 'deepmerge';
import { useCallback, useContext, useMemo, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const sdrDefaults = {
  system: {
    preamblePattern: '',
    filterHalfLength: 0,
    filterBandwidthTimeProduct: 0,
    sampleRate: 0,
    rxChannelCount: 2,
  },
  txChannel: {
    TXLOfreq: 0,
    PAgain: 0,
    scaleFactor: 0,
    baudRate: 0,
    packetInterleaveTime: 0,
    syncWord: 0,
    preambleLength: 0,
    payloadLength: 0,
  },
  rxGeneral: {
    RXLOfreq: 0,
    LNAgain: 0,
    preambleDetectionThreshold: 0,
    RSSIthreshold: 0,
    minPreambleLength: 0,
    syncWord: '',
    synchronizerResolution: 0,
    synchronizerBandwidth: 0,
    payloadLength: 0,
  },
  rxFallback: {
    baudRate: 0,
    AFCrange: 0,
  },
  rxNominal: {
    baudRate: 0,
    AFCrange: 0,
  },
  beacon: {
    CAcodeID: 0,
    baudRate: 0,
    CAThreshold: 0,
  },
  fft: {
    enable: true,
    size: 1024,
    window: 'none',
  },
  lastPacketSent: 0,
};

export default function useSDRStatusDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, setState] = useState(sdrDefaults);
  const statusPathRoot = useMemo(() => `rg/${currentResGroup}/@c3s/rflink-provider`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    setState(merge(sdrDefaults, newRecord));
  }, []);

  useDsRecordSubscription(`${statusPathRoot}/SDRStatus`, handleChange);

  return { state };
}
