import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';

import AccessPortConfigurationPage from './configuration';
import TctmProtocolPage from './protocols/tctm';

const AccessPortPage = ({ connectorKey }) => {
  return (
    <Router>
      <TctmProtocolPage path="protocols/tctm" connectorKey={connectorKey} />
      <AccessPortConfigurationPage path="configuration" />
    </Router>
  );
};

AccessPortPage.propTypes = {
  connectorKey: PropTypes.string.isRequired,
};

export default AccessPortPage;
