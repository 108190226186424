import { EthBusSelect } from '@c3s/ui-radcube-connector-bustester';
import { SSPAddressSelect } from '@c3s/ui-radcube-protocol-ssp';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const CommBusSspProtocolSettings = ({
  expanded,
  toggleExpanded,
  disableBusSelect,
  ethBus,
  defaultsRecord,
  setEthBus,
  sspSource,
  setSspSource,
  sspDestination,
  setSspDestination,
}) => {
  const [connectionType, setConnectionType] = useState('eth');
  const { dsClient } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);

  useEffect(() => {
    const getTctmSettings = async () => {
      const testerProviderRecord = await dsClient.record.snapshot(
        `rg/${currentResGroup}/@c3s/tester-provider/debug/bustester/status/connection`,
      );
      setConnectionType(testerProviderRecord.connectionType);
    };
    getTctmSettings();
  }, [currentResGroup, dsClient.record]);

  const classes = useStyles();
  return (
    <Accordion square expanded={expanded} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleExpanded}>
        <Typography variant="h6">Bustester and SSP Protocol Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={1}>
            <EthBusSelect
              label="Communication Bus"
              value={ethBus}
              onChange={(value) => {
                defaultsRecord.set('ethBus', value);
                setEthBus(value);
              }}
              isDisabled={connectionType === 'serial' || disableBusSelect}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={1}>
            <SSPAddressSelect
              label="SSP Source"
              value={sspSource}
              onChange={(value) => {
                defaultsRecord.set('sspSource', value);
                setSspSource(value);
              }}
              error={sspSource === sspDestination}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={1}>
            <SSPAddressSelect
              label="SSP Destination"
              value={sspDestination}
              onChange={(value) => {
                defaultsRecord.set('sspDestination', value);
                setSspDestination(value);
              }}
              error={sspSource === sspDestination}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

CommBusSspProtocolSettings.defaultProps = {
  disableBusSelect: false,
};

CommBusSspProtocolSettings.propTypes = {
  disableBusSelect: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  ethBus: PropTypes.number.isRequired,
  defaultsRecord: PropTypes.shape({
    set: PropTypes.func,
  }).isRequired,
  setEthBus: PropTypes.func.isRequired,
  sspSource: PropTypes.number.isRequired,
  setSspSource: PropTypes.func.isRequired,
  sspDestination: PropTypes.number.isRequired,
  setSspDestination: PropTypes.func.isRequired,
};

export default memo(CommBusSspProtocolSettings);
