import Typography from '@material-ui/core/Typography';
import { curveStep } from '@visx/curve';
import { LegendOrdinal } from '@visx/legend';
import { Axis, DataContext, DataProvider, LineSeries, XYChart } from '@visx/xychart';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';

const NextPassBaudGraphLegend = ({ ITURules }) => {
  const { colorScale } = useContext(DataContext);

  return (
    <LegendOrdinal
      direction="row"
      itemMargin="8px 8px 8px 0"
      scale={colorScale}
      shape="line"
      labelFormat={(item) =>
        parseInt(item, 10) === -1 ? 'Default' : ITURules?.[parseInt(item, 10)]?.name ?? `Rule ${item}`
      }
    />
  );
};

NextPassBaudGraphLegend.propTypes = {
  ITURules: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
};

const NextPassBaudGraph = () => {
  const { autonomyConfig } = useAutonomyConfigDs();

  if (autonomyConfig.nextPassBaudRainbow === undefined) {
    return <Typography align="center">No data.</Typography>;
  }

  const defaultBauds = autonomyConfig.nextPassBaudRainbow.default;
  const reducedBauds = autonomyConfig.nextPassBaudRainbow.rainbow;

  const rainbowLines = [];
  for (let i = 0; i < reducedBauds[0].maxBaudArray.length; i += 1) {
    rainbowLines.push(
      <LineSeries
        key={reducedBauds[0].maxBaudArray[i].ruleIndex}
        dataKey={reducedBauds[0].maxBaudArray[i].ruleIndex}
        data={reducedBauds}
        xAccessor={(d) => new Date(d.timestamp)}
        yAccessor={(d) => d.maxBaudArray[i].baud}
      />,
    );
  }

  return (
    <DataProvider xScale={{ type: 'time' }} yScale={{ type: 'linear' }}>
      <XYChart height={150} margin={{ bottom: 24, left: 8, right: 48, top: 8 }}>
        <Axis orientation="bottom" />
        <Axis orientation="right" />
        <LineSeries
          dataKey={-1}
          data={defaultBauds}
          xAccessor={(d) => new Date(d.timestamp)}
          yAccessor={(d) => d.maxBaud.baud}
          curve={curveStep}
        />
        {rainbowLines}
      </XYChart>
      <NextPassBaudGraphLegend ITURules={autonomyConfig.ITURules} />
    </DataProvider>
  );
};

export default NextPassBaudGraph;
