import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import PacketBuilder from '#components/page-content/packet-builder/PacketBuilder';
import DeepstreamContext from '#contexts/DeepstreamContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useCurrentMissionStructMeta from '#hooks/useCurrentMissionStructMeta';
import useSnackbar from '#hooks/useSnackbar';

const GenericTestConnectorProtocolPage = ({ connectorKey, protocol }) => {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { missionKey } = useCurrentMission();

  const { enqueueSnackbar } = useSnackbar();

  const handlePacketSubmit = useCallback(
    async (packet) => {
      const rpcUrl = `rg/${missionKey}/@c3s/tester-provider/${connectorKey}/send`;
      const ret = await rpcWithErrorHandler(rpcUrl, { packet });
      if (ret.status !== 'error') {
        enqueueSnackbar('Command sent', { variant: 'info' });
      }
    },
    [missionKey, connectorKey, rpcWithErrorHandler, enqueueSnackbar],
  );

  const packetToBuildStructMeta = useCurrentMissionStructMeta(protocol);

  return (
    <Accordion square defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="packet-builder-header">
        <Typography variant="h6">{packetToBuildStructMeta?.displayName ?? 'Command Packet'} Builder</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PacketBuilder packetId={protocol} submitPacket={handlePacketSubmit} submitLabel="Send Single Command" />
      </AccordionDetails>
    </Accordion>
  );
};

GenericTestConnectorProtocolPage.propTypes = {
  connectorKey: PropTypes.string.isRequired,
  protocol: PropTypes.string.isRequired,
};

export default GenericTestConnectorProtocolPage;
