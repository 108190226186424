import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useHousekeepingDs from '../useHousekeepingDs';

const pdmcPowerOptions = new Map();
const pdmcStateOptions = new Map();

export default function usePdmcControl() {
  const theme = useTheme();
  const { hkState, hkPathRoot } = useHousekeepingDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  pdmcPowerOptions.set(0, { bgColor: theme.palette.success.main, label: 'RUN - 0', color: 'white' });
  pdmcPowerOptions.set(1, { bgColor: theme.palette.error.main, label: 'STOP - 1', color: 'white' });

  pdmcStateOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  pdmcStateOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });

  const resetPdmc = useCallback(() => {
    try {
      rpcWithErrorHandler(`${hkPathRoot}/setPCOff`, null);
      enqueueSnackbar('PDMC Reset requested', { variant: 'info' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, hkPathRoot, rpcWithErrorHandler]);

  const pc1Output = hkState.outputs[15];
  const pc1Input = hkState.inputs[15];

  const pdmcComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'PDMC Power:',
          value: Number(pc1Output ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: pdmcPowerOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'PDMC State:',
          value: Number(pc1Input ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: pdmcStateOptions,
        },
      },
    ],
    [pc1Input, pc1Output],
  );

  return { pdmcComponents, resetPdmc };
}
