import { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

const NAME = '@c3s/housekeeping-provider';

export default function useCabinetHkControlCard() {
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const handleSetOutput = async (channels) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/updateOutputBits`, { channels });
    enqueueSnackbar('Updating output bits', { variant: 'info' });
  };

  const handleEnableOutput = async (channels) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setOutputsEnabled`, { channels });
    enqueueSnackbar('Updating output enable bits', { variant: 'info' });
  };

  return { handleSetOutput, handleEnableOutput };
}
