import useProtocolMessage from '#hooks/useProtocolMessage';

import useAbsoluteTimeEventListener from './useAbsoluteTimeEventListener';

export default function useAbsoluteTimeMessage() {
  return useProtocolMessage({
    listener: useAbsoluteTimeEventListener,
    transform: (x) => x.AbsoluteTime,
    initialBody: {
      SID: -1,
      value: { currentTime: 0, milliseconds: 0 },
    },
  });
}
