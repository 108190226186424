import { useCallback, useContext, useMemo, useReducer } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const reducer = (s, a) => {
  if (typeof a === 'function') {
    return { ...s, ...a(s) };
  }
  return { ...s, ...a };
};

const initialState = {
  lastUpdated: 0,
  isPinging: false,
  isAlive: false,
  isPolling: false,
  isUpdating: false,
  isConnected: false,
  temperature: {
    indoor: null,
    outdoor: null,
    antenna: null,
  },
  errorFlags: {
    relayDriverAFault: true,
    relayDriverBFault: true,
    outdoorTempSensorShortCircuitError: true,
    outdoorTempSensorOpenCircuitError: true,
    antennaTempSensorShortCircuitError: true,
    antennaTempSensorOpenCircuitError: true,
  },
  networkConfig: {
    IP: '',
    port: '',
  },
  inputs: [false, false, false, false, false],
  outputs: Array(16).fill(false),
};

export default function useHouseKeepingDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const hkPathRoot = useMemo(() => `rg/${currentResGroup}/@c3s/housekeeping-provider`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    if (newRecord?.outputs?.length) {
      dispatch({ outputs: newRecord.outputs });
    }
    if (newRecord?.inputs?.length) {
      dispatch({ inputs: newRecord.inputs });
    }
    if (newRecord?.errorFlags) {
      dispatch((prevState) => ({ errorFlags: { ...prevState.errorFlags, ...newRecord.errorFlags } }));
    }
    if (newRecord?.temperature) {
      dispatch((prevState) => ({ temperature: { ...prevState.temperature, ...newRecord.temperature } }));
    }
    if ('lastUpdated' in newRecord) {
      dispatch({ lastUpdated: newRecord.lastUpdated });
    }
    if ('isPinging' in newRecord) {
      dispatch({ isPinging: newRecord.isPinging });
    }
    if ('isAlive' in newRecord) {
      dispatch({ isAlive: newRecord.isAlive });
    }
    if ('isPolling' in newRecord) {
      dispatch({ isPolling: newRecord.isPolling });
    }
    if ('isUpdating' in newRecord) {
      dispatch({ isUpdating: newRecord.isUpdating });
    }
    if ('isConnected' in newRecord) {
      dispatch({ isConnected: newRecord.isConnected });
    }
    if ('networkConfig' in newRecord) {
      dispatch((prevState) => ({ networkConfig: { ...prevState.networkConfig, ...newRecord.networkConfig } }));
    }
  }, []);

  useDsRecordSubscription(`${hkPathRoot}/status`, handleChange);

  return { hkState: state, hkPathRoot };
}
