import { RawTable, TableFilters } from '@c3s/ui-radcube-message-logging';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core/';
import React, { forwardRef, useContext, useEffect, useReducer, useRef, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

import { initialState, tableHeader } from './RawTabHelpers';

function reducer(state, action) {
  return { ...state, ...action };
}

const RawTab = () => {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();
  const [{ version, startTime, endTime }, dispatch] = useReducer(reducer, initialState);
  const [filterVersion, setFilterVersion] = useState([]);
  const tableRef = useRef();

  const [filterDirectionMask, setFilterDirectionMask] = useState({
    encoded: 0,
    decoded: 0,
  });

  useEffect(() => {
    async function getFilters() {
      const { result, status } = await rpcWithErrorHandler(
        `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessageFilters`,
        {
          protocol: 'RAW',
        },
      );
      if (status === 'success' && Object.keys(result).includes('version')) {
        setFilterVersion(result.version.map((label) => ({ label, value: label })));
      }
    }
    getFilters();
  }, [currentResGroup, rpcWithErrorHandler]);

  function refresh() {
    tableRef.current.onQueryChange();
  }

  function onStartChange(date) {
    dispatch({ startTime: date });
  }

  function onEndChange(date) {
    dispatch({ endTime: date });
  }

  const getDetails = async (protocol, id) => {
    const { result } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessageItem`,
      {
        protocol,
        id,
      },
    );
    return { protocol, response: result };
  };

  const rawFilter = {
    version: version.length ? version : [],
    timestamp: [startTime, endTime],
    transformation: filterDirectionMask,
  };

  const getRawMessages = async ({ page, pageSize, orderBy, orderDirection }) => {
    const { result, status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessages`,
      {
        protocol: 'RAW',
        filter: rawFilter,
        limit: pageSize,
        skip: pageSize * page,
        sort: {
          field: orderBy?.field,
          direction: orderDirection,
        },
      },
    );

    if (status === 'success') {
      return {
        data: result.data.map((d) => ({ ...d, getDetails })),
        page,
        totalCount: result.recordsTotal,
      };
    }

    return {
      data: [],
      page,
      totalCount: 0,
    };
  };

  const exportRawMessages = async () => {
    const fileName = `raw-log-${Date.now()}.csv`;

    const { status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/exportLoggedMessages`,
      {
        protocol: 'RAW',
        filter: rawFilter,
        sort: {
          direction: '',
        },
        bucketID: undefined,
        fileName,
      },
    );

    if (status === 'success') {
      enqueueSnackbar(`Filtered raw message log saved to MOC files as ${fileName}`, { variant: 'info' });
    }
  };

  const rawActions = [
    {
      isFreeAction: true,
      icon: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} ref={ref} icon={['fas', 'file-download']} style={{ fontSize: 18 }} />
      )),
      tooltip: 'Create Archive',
      onClick: () => {
        exportRawMessages();
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableFilters
          startTime={startTime}
          endTime={endTime}
          version={version.length ? version.map((label) => ({ label, value: label })) : []}
          filterDirectionMask={filterDirectionMask}
          setFilterDirectionMask={setFilterDirectionMask}
          filterVersion={filterVersion}
          dispatch={dispatch}
          refresh={refresh}
          onStartChange={onStartChange}
          onEndChange={onEndChange}
        />
      </Grid>
      <Grid item xs={12}>
        <RawTable TableHeader={tableHeader} tableRef={tableRef} getRemoteData={getRawMessages} actions={rawActions} />
      </Grid>
    </Grid>
  );
};

export default RawTab;
