import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

const periodMin = 100;
const periodMax = 5000;

export default function useAutoReportCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/functionManagement`;

  const autoReportDsRecord = useDsRecord('bustester', 'control/autoreport');
  const [AutoReportPeriodTimeInMs, setAutoReportPeriodTimeInMs] = useState(1000);

  const isPeriodInvalid = AutoReportPeriodTimeInMs < periodMin || AutoReportPeriodTimeInMs > periodMax;

  const persistState = () => {
    autoReportDsRecord.set({ ...autoReportDsRecord.get(), AutoReportPeriodTimeInMs });
  };

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  useEffect(() => {
    autoReportDsRecord.whenReady((record) => {
      setAutoReportPeriodTimeInMs(record.get()?.AutoReportPeriodTimeInMs ?? 1000);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disableAutoReport = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/setReportPeriod`,
      paramsObject: {
        AutoReportPeriodTimeInMs: 0,
      },
      snackbarMessage: 'Disable Auto Report requested',
    });
    processRpcResponse({
      endpoint: `${rpcUrl}/getReport`,
      paramsObject: null,
      snackbarMessage: 'Get Auto Report requested',
    });
  };

  const setAutoReport = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/setReportPeriod`,
      paramsObject: {
        AutoReportPeriodTimeInMs,
      },
      snackbarMessage: 'Set Auto Report requested',
    });
  };

  const getReport = () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/getReport`,
      paramsObject: null,
      snackbarMessage: 'Get Auto Report requested',
    });
  };

  return {
    disableAutoReport,
    setAutoReport,
    getReport,
    AutoReportPeriodTimeInMs,
    setAutoReportPeriodTimeInMs,
    isPeriodInvalid,
  };
}
