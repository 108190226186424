import { PowerSwitchCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import usePowerSwitchCard from './usePowerSwitchCard';

const Component = () => {
  const { setLcl, getStatus, dutRole } = usePowerSwitchCard();

  return <PowerSwitchCard setLcl={setLcl} getPowerStatus={getStatus} dutRole={dutRole} />;
};

export default Component;
