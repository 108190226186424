import { ColoredCircleIcon } from '@c3s/ui-components';
import { Button, Card, CardActions, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import GridDescriptionList from '#components/ui-helper/GridDescriptionList';

const statusColors = new Map([
  [undefined, 'primary'],
  [true, 'success'],
  [false, 'danger'],
]);

const useStyles = makeStyles((theme) => ({
  connecitonCardActions: {
    padding: theme.spacing(2),
    '&>Button': {
      flex: 1,
    },
  },
}));

const ConnectionCard = ({ connectorMeta, connectionStatus, connect, disconnect, hasTesterProvider }) => {
  const classes = useStyles();
  const connectionProviderData = [
    { label: 'Connection Provider', value: hasTesterProvider ? 'Available' : 'Unavailable' },
    {
      label: 'Connection State',
      // eslint-disable-next-line no-nested-ternary
      value: hasTesterProvider ? (connectionStatus?.isAlive ? 'Connected' : 'Disconnected') : undefined,
    },
  ];

  let connectionData;
  switch (connectorMeta?.connection?.type) {
    case 'tcp':
      connectionData = [
        { label: 'Host', value: connectionStatus?.ipAddress ?? connectorMeta?.connection?.ip },
        { label: 'Port', value: connectionStatus?.portNumber ?? connectorMeta?.connection?.port },
        ...connectionProviderData,
      ];
      break;
    case 'serial':
      connectionData = [
        { label: 'Device', value: connectionStatus?.serialport ?? connectorMeta?.connection?.dev },
        { label: 'Baud Rate', value: connectionStatus?.baudrate ?? connectorMeta?.connection?.baud },
        ...connectionProviderData,
      ];
      break;
    default:
      break;
  }

  return (
    <Card>
      <CardHeader
        title={
          <>
            <ColoredCircleIcon status={statusColors.get(hasTesterProvider ? connectionStatus?.isAlive : undefined)} />{' '}
            Connection Status
          </>
        }
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <GridDescriptionList data={connectionData} />
      </CardContent>
      <CardActions className={classes.connecitonCardActions}>
        <Button size="small" color="default" variant="outlined" onClick={disconnect}>
          Disconnect
        </Button>
        <Button size="small" color="primary" variant="outlined" onClick={connect}>
          Connect
        </Button>
      </CardActions>
    </Card>
  );
};

ConnectionCard.propTypes = {
  connectorMeta: PropTypes.shape({
    connection: PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string, PropTypes.number)),
  }).isRequired,
  connectionStatus: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]))
    .isRequired,
  connect: PropTypes.func.isRequired,
  disconnect: PropTypes.func.isRequired,
  hasTesterProvider: PropTypes.bool.isRequired,
};

export default ConnectionCard;
