const BusList = [
  { value: 0, label: 'CAN' },
  { value: 1, label: 'MLVDS' },
];

export function resolveSspBusLabelById(id) {
  const element = BusList.find((el) => el.value === id);
  return element === undefined ? 'Unknown' : element.label;
}

export default BusList;
