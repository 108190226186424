import { EthTable, TableFilters } from '@c3s/ui-radcube-message-logging';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React, { forwardRef, useContext, useEffect, useReducer, useRef, useState } from 'react';

import MultiSelectWithCheckbox from '#components/ui-helper/MultiSelectWithCheckbox';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

import { initialState, tableHeader } from './ETHTabHelpers';

function reducer(state, action) {
  return { ...state, ...action };
}

const ETHTab = () => {
  const tableRef = useRef();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();
  const [{ version, startTime, endTime, Service, SubService }, dispatch] = useReducer(reducer, initialState);
  const [filterVersion, setFilterVersion] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [filterSubService, setFilterSubService] = useState([]);
  const [filterDirectionMask, setFilterDirectionMask] = useState({
    encoded: 0,
    decoded: 0,
  });

  useEffect(() => {
    async function getFilters() {
      const { result, status } = await rpcWithErrorHandler(
        `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessageFilters`,
        {
          protocol: 'ETH',
        },
      );

      if (status === 'success' && Object.keys(result).includes('version')) {
        setFilterVersion(result.version?.map((label) => ({ label, value: label })));
        setFilterService(result['json.Service']?.map((label) => ({ label, value: label })));
        setFilterSubService(result['json.SubService']?.map((label) => ({ label, value: label })));
      }
    }
    getFilters();
  }, [rpcWithErrorHandler, currentResGroup]);

  function ETHFilters() {
    return (
      <Grid container spacing={1} style={{ margin: '1em 1em 1em 4px' }}>
        <Grid item>
          <MultiSelectWithCheckbox
            title="Service"
            dropdownArray={filterService}
            state={{ Service: Service.length ? Service.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="SubService"
            dropdownArray={filterSubService}
            state={{ SubService: SubService.length ? SubService.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
    );
  }

  function onStartChange(date) {
    dispatch({ startTime: date });
  }

  function onEndChange(date) {
    dispatch({ endTime: date });
  }

  function refresh() {
    tableRef.current.onQueryChange();
  }

  const ethFilter = {
    transformation: filterDirectionMask,
    version: version.length ? version : [],
    timestamp: [startTime, endTime],
    json: {
      Service: Service.length ? Service : [],
      SubService: SubService.length ? SubService : [],
    },
  };

  const getEthMessages = async ({ page, pageSize, orderBy, orderDirection }) => {
    const { result, status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessages`,
      {
        protocol: 'ETH',
        filter: ethFilter,
        limit: pageSize,
        skip: pageSize * page,
        sort: {
          field: orderBy?.field,
          direction: orderDirection,
        },
      },
    );
    if (status === 'success') {
      return {
        data: result.data,
        page,
        totalCount: result.recordsTotal,
      };
    }

    return {
      data: [],
      page,
      totalCount: 0,
    };
  };

  const exportEthMessages = async () => {
    const fileName = `eth-log-${Date.now()}.csv`;

    const { status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/exportLoggedMessages`,
      {
        protocol: 'ETH',
        filter: ethFilter,
        sort: {
          direction: '',
        },
        bucketID: undefined,
        fileName,
      },
    );

    if (status === 'success') {
      enqueueSnackbar(`Filtered ETH message log saved to MOC files as ${fileName}`, { variant: 'info' });
    }
  };

  const ethActions = [
    {
      isFreeAction: true,
      icon: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} ref={ref} icon={['fas', 'file-download']} style={{ fontSize: 18 }} />
      )),
      tooltip: 'Create Archive',
      onClick: () => {
        exportEthMessages();
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableFilters
          startTime={startTime}
          endTime={endTime}
          version={version.length ? version.map((label) => ({ label, value: label })) : []}
          filterDirectionMask={filterDirectionMask}
          setFilterDirectionMask={setFilterDirectionMask}
          filterVersion={filterVersion}
          dispatch={dispatch}
          tabSpecificFilters={ETHFilters}
          refresh={refresh}
          onStartChange={onStartChange}
          onEndChange={onEndChange}
        />
      </Grid>
      <Grid item xs={12}>
        <EthTable TableHeader={tableHeader} tableRef={tableRef} actions={ethActions} getRemoteData={getEthMessages} />
      </Grid>
    </Grid>
  );
};

export default ETHTab;
