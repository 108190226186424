import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecordState from '#hooks/useDsRecordState';
import useSnackbar from '#hooks/useSnackbar';

const useFileManagementRPCs = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const { currentResGroup } = useContext(RgContext);

  const baseRpc = useMemo(() => `rg/${currentResGroup}/@c3s/file-management-provider`, [currentResGroup]);

  const { record: fileMgmtStatus } = useDsRecordState(`${baseRpc}/status`, {});

  const genericRPCCallback = useCallback(
    async (path, params, successMessage) => {
      const { status } = await rpcWithErrorHandler(`${baseRpc}/${path}`, params);
      if (status === 'success' && successMessage) {
        enqueueSnackbar(successMessage, { variant: 'info' });
      }
    },
    [baseRpc, enqueueSnackbar, rpcWithErrorHandler],
  );

  const setTargetPath = useCallback(
    async (path) => {
      const { status } = await rpcWithErrorHandler(`${baseRpc}/setTargetPath`, { rg: currentResGroup, path });
      if (status === 'success') {
        enqueueSnackbar('File Mgmt target path changed.', { variant: 'info' });
      }
    },
    [baseRpc, currentResGroup, enqueueSnackbar, rpcWithErrorHandler],
  );

  return {
    fileMgmtStatus,
    genericRPCCallback,
    setTargetPath,
  };
};

export default useFileManagementRPCs;
