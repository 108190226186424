import downloadURI from './downloadURI';

export function saveStringToFile(mimeType, filename, string) {
  const file = new File([string], filename, { type: mimeType });
  const fileURL = URL.createObjectURL(file);
  downloadURI(fileURL, filename);
  URL.revokeObjectURL(fileURL);
}

export function saveDataToJsonFile(filename, data) {
  saveStringToFile('application/json', filename, JSON.stringify(data));
}
