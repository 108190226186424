import styles from '#utils/styles';

const AccessPortPageWrapperStyles = (theme) => {
  return {
    ...styles(theme),
    StickyWrapper: {
      '&:last-child': {
        marginBottom: theme.spacing(1),
      },
    },
    sticky: {
      zIndex: 1,
      position: 'sticky',
      top: 0,
    },
  };
};

export default AccessPortPageWrapperStyles;
