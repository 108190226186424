import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../../useAutonomyStatusDs';
import useSDRStatusDs from '../useSDRStatusDs';

const TLEModeOptions = new Map();
const AFCModeOptions = new Map();

export default function useLinkFreq(direction) {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { state: sdrStatus } = useSDRStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const theme = useTheme();

  TLEModeOptions.set('off', { bgColor: theme.palette.secondary.main, label: 'OFF - 0', color: 'white' });
  TLEModeOptions.set('on', { bgColor: theme.palette.success.main, label: 'TLE - 1', color: 'white' });
  TLEModeOptions.set('lost', { bgColor: theme.palette.grey.main, label: 'Lost', color: 'white' });

  AFCModeOptions.set('off', { bgColor: theme.palette.secondary.main, label: 'OFF - 0', color: 'white' });
  AFCModeOptions.set('on', { bgColor: theme.palette.success.main, label: 'AFC - 1', color: 'white' });
  AFCModeOptions.set('lost', { bgColor: theme.palette.grey.main, label: 'Lost', color: 'white' });

  const setOffset = useCallback(
    async (num) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRCarrierControl.${direction}.offsetValue`,
        value: 1e3 * parseFloat(num),
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Offset Value', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler, direction],
  );

  const setTLEMode = useCallback(
    async (mode) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRCarrierControl.${direction}.TLEMode`,
        value: mode,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set TLE Mode', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler, direction],
  );

  const setAFCMode = useCallback(
    async (mode) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRCarrierControl.${direction}.AFCMode`,
        value: mode,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set AFC Mode', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler, direction],
  );

  const linkFreqComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'Nominal Carrier',
          value: sanitize(1e-6 * autonomyConfig?.SDRCarrierControl?.[direction]?.nominalCarrier, 3),
          unit: 'MHz',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Set Offset Value',
          numberInputProps: { inputProps: { signed: true, scale: 6 } },
          value: sanitize(1e-3 * autonomyConfig?.SDRCarrierControl?.[direction]?.offsetValue, 3, 0),
          handleSet: setOffset,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Offset Value',
          value: sanitize(1e-3 * autonomyConfig?.SDRCarrierControl?.[direction]?.offsetValue, 3),
          unit: 'kHz',
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'TLE Mode',
          options: [
            {
              label: 'OFF - 0',
              value: 'off',
            },
            {
              label: 'TLE - 1',
              value: 'on',
            },
          ],
          selectedValue: autonomyConfig?.SDRCarrierControl?.[direction]?.TLEMode,
          handleSet: setTLEMode,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current TLE Mode:',
          value: autonomyConfig?.SDRCarrierControl?.[direction]?.TLEMode ?? 'lost',
          ValueComponent: GeneralStatusIndicator,
          options: TLEModeOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'TLE Doppler',
          value: sanitize(1e-3 * autonomyStatus?.SDRCarrierControl?.[direction]?.TLEDopplerFreq, 3),
          unit: 'kHz',
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'AFC Mode',
          options: [
            {
              label: 'OFF - 0',
              value: 'off',
            },
            {
              label: 'AFC - 1',
              value: 'on',
            },
          ],
          selectedValue: autonomyConfig?.SDRCarrierControl?.[direction]?.AFCMode,
          handleSet: setAFCMode,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current AFC Mode:',
          value: autonomyConfig?.SDRCarrierControl?.[direction]?.AFCMode ?? 'lost',
          ValueComponent: GeneralStatusIndicator,
          options: AFCModeOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'AFC Doppler',
          value: sanitize(1e-3 * autonomyStatus?.SDRCarrierControl?.[direction]?.AFCDopplerFreq, 3),
          unit: 'kHz',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Doppler Value',
          value: sanitize(1e-3 * autonomyStatus?.SDRCarrierControl?.[direction]?.dopplerValue, 3),
          unit: 'kHz',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Carrier Value',
          value: sanitize(1e-6 * autonomyStatus?.SDRCarrierControl?.[direction]?.carrierValue, 6),
          unit: 'MHz',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual Carrier',
          value:
            direction === 'uplink'
              ? sanitize(1e-6 * sdrStatus?.txChannel?.TXLOfreq, 6)
              : sanitize(1e-6 * sdrStatus?.rxGeneral?.RXLOfreq, 6),
          unit: 'MHz',
        },
      },
    ],
    [
      direction,
      autonomyConfig.SDRCarrierControl,
      setTLEMode,
      setAFCMode,
      setOffset,
      sdrStatus.txChannel.TXLOfreq,
      sdrStatus.rxGeneral.RXLOfreq,
      autonomyStatus.SDRCarrierControl,
    ],
  );

  return { linkFreqComponents };
}
