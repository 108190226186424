import { ColoredCircleIcon } from '@c3s/ui-components';
import { Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ConnectionSettingsCard.styles';

const useStyles = makeStyles(styles);

const ConnectionSettingsCard = ({
  connectToAccessPort,
  disconnectFromAccessPort,
  serialPort,
  baudrate,
  setSerialPort,
  isConnected,
  setBaudrate,
}) => {
  const classes = useStyles();

  const isSerialPortInvalid = !serialPort.startsWith('COM') && !serialPort.startsWith('/dev');
  const isBaudrateInvalid = baudrate < 1 || baudrate > 460800;

  return (
    <Card className={classes.Card}>
      <CardHeader
        title={
          <>
            <ColoredCircleIcon status={isConnected ? 'success' : 'danger'} /> Connection Settings
          </>
        }
        titleTypographyProps={{ variant: 'h6' }}
        className={classes.CardHeader}
      />
      <CardContent className={classes.CardContent}>
        <TextField
          placeholder="COM<x> | /dev/ttyUSB<x>"
          value={serialPort}
          onChange={(event) => setSerialPort(event.target.value)}
          onFocus={(event) => event.target.select()}
          error={isSerialPortInvalid}
          label="Serial Port"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          className={classes.TextField}
          style={{ flex: 2 }}
        />
        <TextField
          placeholder="e.g. 115200"
          value={baudrate}
          onChange={(event) => setBaudrate(parseInt(event.target.value, 10))}
          onFocus={(event) => event.target.select()}
          error={isBaudrateInvalid}
          label="Baud Rate"
          InputLabelProps={{ shrink: true }}
          type="number"
          margin="normal"
          className={classes.TextField}
        />
      </CardContent>
      <CardContent className={classes.CardContent}>
        <Button size="small" color="default" variant="outlined" onClick={disconnectFromAccessPort}>
          Disconnect
        </Button>
        <Button size="small" color="primary" variant="outlined" onClick={connectToAccessPort}>
          Connect
        </Button>
      </CardContent>
    </Card>
  );
};
ConnectionSettingsCard.defaultProps = {
  isConnected: false,
};

ConnectionSettingsCard.propTypes = {
  isConnected: PropTypes.bool,
  connectToAccessPort: PropTypes.func.isRequired,
  disconnectFromAccessPort: PropTypes.func.isRequired,
  serialPort: PropTypes.string.isRequired,
  baudrate: PropTypes.number.isRequired,
  setSerialPort: PropTypes.func.isRequired,
  setBaudrate: PropTypes.func.isRequired,
};

export default ConnectionSettingsCard;
