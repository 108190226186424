import { VerificationTable } from '@c3s/ui-radcube-protocol-tctm';
import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';

const VerificationTab = () => {
  const { currentResGroup } = useContext(RgContext);
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const getData = async ({ page, pageSize, orderBy, orderDirection }) => {
    const { result, status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessages`,
      {
        protocol: 'TCTM',
        filter: {
          transformation: {
            encoded: 0,
            decoded: 0,
          },
          version: [],
          timestamp: [0, Date.now()],
          json: {
            Rev: [],
            SCID: [],
            VC: [],
            Packet: {
              APID: [],
              Red: [],
              Type: [1],
              Ack: [1, 2, 3],
              Service: [],
              SubService: [],
            },
          },
        },
        limit: pageSize,
        skip: pageSize * page,
        sort: {
          field: orderBy?.field,
          direction: orderDirection,
        },
      },
    );
    return status === 'success'
      ? { page, data: result.data, totalCount: result.recordsTotal }
      : { page, data: [], totalCount: 0 };
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={12}>
        <VerificationTable getData={getData} />
      </Grid>
    </Grid>
  );
};

export default VerificationTab;
