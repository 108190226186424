import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useSnackbar from '#hooks/useSnackbar';

import RFLinkPageWrapperStyles from './RFLinkPageWrapperStyles';

const useStyles = makeStyles(RFLinkPageWrapperStyles);

const RFLinkPageWrapper = ({ children }) => {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { enqueueSnackbar } = useSnackbar();

  const { missionKey } = useCurrentMission();

  const tctmCreateFunction = useCallback(
    async (paramsObject, snackbarMessage) => {
      const tcJson = paramsObject.SSPFrame.Data.TCFrame;

      const rpcUrl = `rg/${missionKey}/@c3s/rflink-provider/sendToRFDriver`;
      const ret = await rpcWithErrorHandler(rpcUrl, tcJson);
      if (ret.status !== 'error') {
        enqueueSnackbar(
          <span>
            {snackbarMessage}
            <br /># of Samples: {ret.result.samplesSent}
          </span>,
          { variant: 'info' },
        );
      }
    },
    [missionKey, rpcWithErrorHandler, enqueueSnackbar],
  );

  const fileTransferRpc = { rpc: `rg/${missionKey}/@c3s/rflink-provider/sendToRFDriver`, format: 'tc' };

  const classes = useStyles();

  return (
    <RgContext.Provider
      value={{ currentResGroup: missionKey, tcProtocolStack: ['TCFrame', 'TC'], tctmCreateFunction, fileTransferRpc }}
    >
      <div className={classes.Wrapper}>{children}</div>
    </RgContext.Provider>
  );
};

RFLinkPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RFLinkPageWrapper;
