import { useContext, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useRole from '#hooks/useRole';
import useSnackbar from '#hooks/useSnackbar';

const GPIOCard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    RBF: false,
    DSW_01: false,
    DSW_02: false,
    LCL_01_EN: false,
    LCL_02_EN: false,
    LCL_03_EN: false,
    LCL_04_EN: false,
    LCL_05_EN: false,
    LCL_06_EN: false,
    LCL_07_EN: false,
    LCL_08_EN: false,
    LCL_09_EN: false,
    LCL_10_EN: false,
    LCL_11_EN: false,
    LCL_12_EN: false,
  });
  const { dutRole } = useRole();

  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/functionManagement`;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const setGpio = () => {
    processRpcResponse({ endpoint: `${rpcUrl}/setGpio`, paramsObject: data, snackbarMessage: 'Set Gpio requested' });
  };

  const getGpio = () => {
    processRpcResponse({ endpoint: `${rpcUrl}/getGpio`, paramsObject: null, snackbarMessage: 'Get Gpio requested' });
  };
  return { data, setData, setGpio, getGpio, dutRole };
};

export default GPIOCard;
