import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const targetPathOptions = [
  { label: 'Operator Queue', value: 'pushToOperator' },
  { label: 'Bus Tester / Access Port', value: 'sendToBusTester' },
  { label: 'RF Driver', value: 'sendToRFDriver' },
];

const TargetPathSelect = ({ targetPath, selectTargetPath, ...otherMuiBtnProps }) => {
  const targetPathLastPart = targetPath.match(/(.+\/)*(?<path>.+)$/).groups.path;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTargetPath, setSelectedTargetPath] = useState(targetPathLastPart);

  const pickTargetPath = (event, index) => {
    setSelectedTargetPath(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonGroup>
        <Button
          aria-haspopup="true"
          aria-controls="target-path-menu"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          endIcon={<ArrowDropDownIcon />}
          variant="outlined"
          {...otherMuiBtnProps}
        >
          {targetPathOptions.find((option) => option.value === selectedTargetPath).label}
        </Button>
        <Button
          variant="contained"
          // color="secondary"
          // disabled={selectedTargetPath === targetPathLastPart}
          onClick={() => selectTargetPath(selectedTargetPath)}
        >
          Set
        </Button>
      </ButtonGroup>
      <Menu id="target-path-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {targetPathOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedTargetPath}
            onClick={(event) => pickTargetPath(event, option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

TargetPathSelect.propTypes = {
  targetPath: PropTypes.string.isRequired,
  selectTargetPath: PropTypes.func.isRequired,
};

export default TargetPathSelect;
