import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const FileAttributesCell = ({ attrs }) => {
  if (attrs === undefined) {
    return 'N/A';
  }

  const { isSystemFile, isProtectedFile, isDedicatedFile, dedicatedFunction } = attrs;

  if (!isSystemFile && !isProtectedFile && !isDedicatedFile) {
    return <>&ndash;</>;
  }

  return (
    <>
      {isSystemFile && (
        <Tooltip title="System File" aria-label="system file">
          <span>
            <FontAwesomeIcon icon={['fas', 'cog']} />
          </span>
        </Tooltip>
      )}
      {isProtectedFile && (
        <Tooltip title="Protected File" aria-label="protected file">
          <span>
            <FontAwesomeIcon icon={['fas', 'shield-alt']} />
          </span>
        </Tooltip>
      )}
      {isDedicatedFile && (
        <Tooltip title={`Dedicated File: ${dedicatedFunction}`} aria-label="dedicated file">
          <span>
            <FontAwesomeIcon icon={['fas', 'star']} />
          </span>
        </Tooltip>
      )}
    </>
  );
};

FileAttributesCell.propTypes = {
  attrs: PropTypes.shape({
    isSystemFile: PropTypes.bool.isRequired,
    isProtectedFile: PropTypes.bool.isRequired,
    isDedicatedFile: PropTypes.bool.isRequired,
    dedicatedFunction: PropTypes.string.isRequired,
  }),
};

FileAttributesCell.defaultProps = {
  attrs: undefined,
};

export default FileAttributesCell;
