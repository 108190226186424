import { ResetCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useResetCard from './useResetCard';

const ResetCardLogic = () => {
  const { resetBustester } = useResetCard();

  return <ResetCard reset={resetBustester} />;
};

export default ResetCardLogic;
