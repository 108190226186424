import {
  TCTM_OBC_0B_0A,
  TCTM_OBC_0B_0B,
  TCTM_OBC_0B_0C,
  TCTM_OBC_0B_0F,
  TCTM_OBC_0B_08,
  TCTM_OBC_0B_11,
  TCTM_OBC_0B_14,
  TCTM_OBC_0B_17,
  TCTM_OBC_0B_19,
} from '@c3s/ui-radcube-protocol-tctm';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useToggle } from 'standard-hooks';

import CardHeader from '#components/ui-helper/CardHeaderWithStatus';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import useThrottle from '#hooks/useThrottle';
import styles from '#utils/styles';

import { initialState, reducer, setObcTm } from './FileManagementService.helpers';

const useStyles = makeStyles(styles);

const FileManagementService = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, newSetValue] = useThrottle(500, [state, dispatch]);

  const setObcTelemetryWithRef = useRef((val) => setObcTm({ telemetry: val, dispatch: newSetValue, enqueueSnackbar }));
  const subscribeToTelemetry = setObcTelemetryWithRef.current;

  const [open, toggleOpen] = useToggle(false);

  const { currentResGroup } = useContext(RgContext);
  const { dsClient } = useContext(DeepstreamContext);

  useEffect(() => {
    dsClient.event.subscribe(`rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`, subscribeToTelemetry);

    return function cleanup() {
      dsClient.event.unsubscribe(
        `rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`,
        subscribeToTelemetry,
      );
    };
  }, [currentResGroup, subscribeToTelemetry, dsClient]);

  return (
    <Accordion square expanded={open} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleOpen}>
        <Typography variant="h6">File Management Service</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <CardHeader
                error={value.err_0B_08.status}
                lastUpdated={value.err_0B_08.lastValidTimestamp}
                errorMsg={value.err_0B_08.Msg}
                titleText="TCTM_OBC_0B_08"
              />
              <CardContent>
                <TCTM_OBC_0B_08 status={value.TM_0B_08} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <CardHeader
                error={value.err_0B_0A.status}
                lastUpdated={value.err_0B_0A.lastValidTimestamp}
                errorMsg={value.err_0B_0A.Msg}
                titleText="TCTM_OBC_0B_0A"
              />
              <CardContent>
                <TCTM_OBC_0B_0A status={value.TM_0B_0A} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader
                error={value.err_0B_0B.status}
                lastUpdated={value.err_0B_0B.lastValidTimestamp}
                errorMsg={value.err_0B_0B.Msg}
                titleText="TCTM_OBC_0B_0B"
              />
              <CardContent>
                <TCTM_OBC_0B_0B status={value.TM_0B_0B} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_0B_0C.status}
                lastUpdated={value.err_0B_0C.lastValidTimestamp}
                errorMsg={value.err_0B_0C.Msg}
                titleText="TCTM_OBC_0B_0C"
              />
              <CardContent>
                <TCTM_OBC_0B_0C status={value.TM_0B_0C} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <CardHeader
                error={value.err_0B_0F.status}
                lastUpdated={value.err_0B_0F.lastValidTimestamp}
                errorMsg={value.err_0B_0F.Msg}
                titleText="TCTM_OBC_0B_0F"
              />
              <CardContent>
                <TCTM_OBC_0B_0F status={value.TM_0B_0F} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_0B_11.status}
                lastUpdated={value.err_0B_11.lastValidTimestamp}
                errorMsg={value.err_0B_11.Msg}
                titleText="TCTM_OBC_0B_11"
              />
              <CardContent>
                <TCTM_OBC_0B_11 status={value.TM_0B_11} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Card>
              <CardHeader
                error={value.err_0B_14.status}
                lastUpdated={value.err_0B_14.lastValidTimestamp}
                errorMsg={value.err_0B_14.Msg}
                titleText="TCTM_OBC_0B_14"
              />
              <CardContent>
                <TCTM_OBC_0B_14 status={value.TM_0B_14} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={5} md={5} lg={5}>
            <Card>
              <CardHeader
                error={value.err_0B_17.status}
                lastUpdated={value.err_0B_17.lastValidTimestamp}
                errorMsg={value.err_0B_17.Msg}
                titleText="TCTM_OBC_0B_17"
              />
              <CardContent>
                <TCTM_OBC_0B_17 status={value.TM_0B_17} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_0B_19.status}
                lastUpdated={value.err_0B_19.lastValidTimestamp}
                errorMsg={value.err_0B_19.Msg}
                titleText="TCTM_OBC_0B_19"
              />
              <CardContent>
                <TCTM_OBC_0B_19 status={value.TM_0B_19} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FileManagementService;
