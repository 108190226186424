import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums'; // eslint-disable-line camelcase
/* eslint-disable camelcase */
import { validate_TCTM_COM_06_09 } from '@c3s/ui-radcube-protocol-tctm';
import printf from 'printf';

const DaqSubServiceNumberSet = new Set([TCTM.data_acquisition_subservice_e.DA_SUBSERVICE_DAQ_CHANNEL_REPORT]);

const getValues = (tmData) => {
  const retObj = {};
  Object.entries(tmData).forEach(([key, val]) => {
    retObj[key] = val.value;
  });
  return retObj;
};

export const setObcTm = ({ telemetry, dispatch, enqueueSnackbar }) => {
  // eslint-disable-next-line no-unused-expressions
  if (
    telemetry.json.Packet.APID === TCTM.apid_e.APID_COM &&
    telemetry.json.Packet.Service === TCTM.service_e.SERVICE_DATA_ACQUISITION
  ) {
    const {
      json: {
        Packet: { SubService, Data },
      },
    } = telemetry;

    // eslint-disable-next-line no-unused-expressions
    DaqSubServiceNumberSet.has(SubService)
      ? dispatch({
          type: `set${printf('%02X', telemetry.json.Packet.Service)}_${printf('%02X', SubService)}`,
          value: Data,
        })
      : enqueueSnackbar(`Unknown Subservice number at DaqService.jsx: ${telemetry.json.Packet}`, {
          variant: 'error',
        });
  }
};

export const initialState = {
  TM_06_09: {},
  err_06_09: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
};

export const reducer = (state, action) => {
  const relevantValues = getValues(action.value);
  switch (action.type) {
    case 'set06_09':
      try {
        const validatedValues = validate_TCTM_COM_06_09(relevantValues);
        return {
          ...state,
          TM_06_09: validatedValues.value,
          err_06_09: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_06_09: { ...state.err_06_09, status: 'err', Msg: err } };
      }
    default:
      throw new Error();
  }
};
