import { SyncCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import { numSyncInitMax, numSyncInitMin, numSyncMax, numSyncMin } from './MlvdsSyncServiceCard.constants';
import useMlvdsSyncServiceCard from './useMlvdsSyncServiceCard';

const MlvdsSyncServiceCard = () => {
  const {
    disableSync,
    enableSync,
    getSyncStatus,
    NumberOfMlvdsSyncToSend,
    MlvdsSyncAsObc,
    MlvdsSyncInfinite,
    MlvdsSyncCounterFixed,
    MlvdsSyncCounterInitValue,
    isNumberOfSyncValid,
    isInitValueValid,
    setNumberOfMlvdsSyncToSend,
    setMlvdsSyncAsObc,
    setMlvdsSyncInfinite,
    setMlvdsSyncCounterFixed,
    setMlvdsSyncCounterInitValue,
  } = useMlvdsSyncServiceCard();

  return (
    <SyncCard
      comBus="mlvds"
      numSyncMin={numSyncMin}
      numSyncMax={numSyncMax}
      numSyncInitMin={numSyncInitMin}
      numSyncInitMax={numSyncInitMax}
      disableSync={disableSync}
      enableSync={enableSync}
      getSyncStatus={getSyncStatus}
      NumberOfSyncToSend={NumberOfMlvdsSyncToSend}
      SyncAsObc={MlvdsSyncAsObc}
      SyncInfinite={MlvdsSyncInfinite}
      SyncCounterFixed={MlvdsSyncCounterFixed}
      SyncCounterInitValue={MlvdsSyncCounterInitValue}
      isNumberOfSyncValid={isNumberOfSyncValid}
      isInitValueValid={isInitValueValid}
      setNumberOfSyncToSend={setNumberOfMlvdsSyncToSend}
      setSyncAsObc={setMlvdsSyncAsObc}
      setSyncInfinite={setMlvdsSyncInfinite}
      setSyncCounterFixed={setMlvdsSyncCounterFixed}
      setSyncCounterInitValue={setMlvdsSyncCounterInitValue}
    />
  );
};

export default MlvdsSyncServiceCard;
