import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import ConnectionCard from '#components/page-content/test-connectors/status/ConnectionCard';
import TestingContext from '#contexts/TestingContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useDsRecordState from '#hooks/useDsRecordState';

const GenericTestConnectorSetupPage = ({ connectorKey }) => {
  const { missionKey } = useCurrentMission();
  const testerProviderBasePath = `rg/${missionKey}/@c3s/tester-provider`;

  const { testConnectors, connectToTestConnector, disconnectFromTestConnector } = useContext(TestingContext);
  const connectorMeta = testConnectors[connectorKey];

  const { record: connectorStatus, hasProvider } = useDsRecordState(
    `${testerProviderBasePath}/${connectorKey}/status`,
    {},
  );

  return (
    <Grid container spacing={1}>
      <Grid item md={3}>
        <ConnectionCard
          connectorKey={connectorKey}
          connectorMeta={connectorMeta}
          connectionStatus={connectorStatus}
          connect={() => connectToTestConnector(connectorKey)}
          disconnect={() => disconnectFromTestConnector(connectorKey)}
          hasTesterProvider={hasProvider}
        />
      </Grid>
    </Grid>
  );
};

GenericTestConnectorSetupPage.propTypes = {
  connectorKey: PropTypes.string.isRequired,
};

export default GenericTestConnectorSetupPage;
