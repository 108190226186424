import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

import { numSyncInitMax, numSyncInitMin, numSyncMax, numSyncMin } from './CanSyncServiceCard.constants';

export default function useCanSyncServiceCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/canSync`;
  const canSyncDsRecord = useDsRecord('bustester', 'control/cansync');

  const [NumberOfCanSyncToSend, setNumberOfCanSyncToSend] = useState(1);
  const [CanSyncAsObc, setCanSyncAsObc] = useState(0);
  const [CanSyncInfinite, setCanSyncInfinite] = useState(false);
  const [CanSyncCounterFixed, setCanSyncCounterFixed] = useState(false);
  const [CanSyncCounterInitValue, setCanSyncCounterInitValue] = useState(0);

  const isNumberOfSyncValid = NumberOfCanSyncToSend < numSyncMin || NumberOfCanSyncToSend > numSyncMax;
  const isInitValueValid = CanSyncCounterInitValue < numSyncInitMin || CanSyncCounterInitValue > numSyncInitMax;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const persistState = () => {
    canSyncDsRecord.set({
      ...canSyncDsRecord.get(),
      NumberOfCanSyncToSend,
      CanSyncAsObc,
      CanSyncCounterFixed,
      CanSyncCounterInitValue,
      CanSyncInfinite,
    });
  };

  useEffect(() => {
    canSyncDsRecord.whenReady((record) => {
      setNumberOfCanSyncToSend(record.get()?.NumberOfCanSyncToSend ?? numSyncMin);
      setCanSyncAsObc(record.get()?.CanSyncAsObc || 0);
      setCanSyncInfinite(record.get()?.CanSyncInfinite || false);
      setCanSyncCounterFixed(record.get()?.CanSyncCounterFixed || false);
      setCanSyncCounterInitValue(record.get()?.CanSyncCounterInitValue || 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableSync = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/disableSync`,
      paramsObject: {
        Red: CanSyncAsObc,
      },
      snackbarMessage: 'Disable Can Sync Service requested',
    });
  };

  const enableSync = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/enableSync`,
      paramsObject: {
        Red: CanSyncAsObc,
        IsCounterFix: CanSyncCounterFixed,
        CounterInitValue: CanSyncCounterInitValue,
        NumberOfSyncToSend: CanSyncInfinite ? 0xffffffff : NumberOfCanSyncToSend,
      },
      snackbarMessage: 'Enable Can Sync Service requested',
    });
  };

  const getSyncStatus = () =>
    processRpcResponse({
      endpoint: `${rpcUrl}/getStatus`,
      paramsObject: null,
      snackbarMessage: 'Get Can Sync Status requested',
    });

  return {
    disableSync,
    enableSync,
    getSyncStatus,
    NumberOfCanSyncToSend,
    CanSyncAsObc,
    CanSyncInfinite,
    CanSyncCounterFixed,
    CanSyncCounterInitValue,
    isNumberOfSyncValid,
    isInitValueValid,
    numSyncMin,
    numSyncMax,
    numSyncInitMin,
    numSyncInitMax,
    setNumberOfCanSyncToSend,
    setCanSyncAsObc,
    setCanSyncInfinite,
    setCanSyncCounterFixed,
    setCanSyncCounterInitValue,
  };
}
