import React from 'react';
import { Helmet } from 'react-helmet';

import TCQueuePageWrapper from '#components/page-content/telecommand-queue/TCQueuePageWrapper';
import TabPanel from '#components/page-fragments/TabPanel';
import tabs from '#connectors/tctm.constants';

const PageTitle = 'TCTM Protocol';

const TctmProtocolPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <TCQueuePageWrapper>
        <TabPanel tabs={tabs} />
      </TCQueuePageWrapper>
    </>
  );
};

export default TctmProtocolPage;
