import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

export default function useConnectionCard() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    rpcWithErrorHandler,
    clientData: { userName },
  } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/tester-provider`;

  const connectionDsRecord = useDsRecord('bustester', 'control/connection');
  const [ipAddress, setIpAddress] = useState('127.0.0.1');
  const [portNumber, setPortNumber] = useState(5500);

  const persistState = () => {
    connectionDsRecord.set({ ...connectionDsRecord.get(), ipAddress, portNumber });
  };

  useEffect(() => {
    connectionDsRecord.whenReady((record) => {
      setIpAddress(record.get()?.ipAddress || '127.0.0.1');
      setPortNumber(record.get()?.portNumber || 5500);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const onConnect = async () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/bustester/connect`,
      paramsObject: { userName, ipAddress, portNumber },
      snackbarMessage: `Connected to ${ipAddress}:${portNumber}`,
    });
    persistState();
  };

  const onDisconnect = async () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/bustester/disconnect`,
      paramsObject: { userName },
      snackbarMessage: `Disconnect requested`,
    });
    persistState();
  };

  return {
    onConnect,
    onDisconnect,
    ipAddress,
    setIpAddress,
    portNumber,
    setPortNumber,
  };
}
