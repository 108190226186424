import useProtocolMessage from '#hooks/useProtocolMessage';

import useLclAndPowerStatusEventListener from './useLclAndPowerStatusEventListener';

export default function useLclAndPowerStatusMessage() {
  return useProtocolMessage({
    listener: useLclAndPowerStatusEventListener,
    transform: (x) => x.LclAndPowerStatus,
    initialBody: {
      SID: -1,
      value: {
        LCL_A_STATE: 0,
        LCL_A_VOLT_ACT: 0,
        LCL_A_CUR_ACT: 0,
        LCL_B_STATE: 0,
        LCL_B_VOLT_ACT: 0,
        LCL_B_CUR_ACT: 0,
        LCL_C_STATE: 0,
        LCL_C_VOLT_ACT: 0,
        LCL_C_CUR_ACT: 0,
        POWER_3V3_STATE: 0,
        POWER_3V3_FAULT: 0,
        POWER_5V0_STATE: 0,
        POWER_5V0_FAULT: 0,
        POWER_UNREG_STATE: 0,
        POWER_UNREG_FAULT: 0,
      },
    },
  });
}
