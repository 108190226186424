import printf from 'printf';
import { useMemo } from 'react';

import useWeatherDs from '../useWeatherDs';

export default function useWeatherStatus() {
  const { weatherState } = useWeatherDs();

  const weatherStatusComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'Rel. Humidity:',
          value: printf('%.01f', weatherState.relativeHumidity),
          unit: '%',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Bar. altitude:',
          value: printf('%.01f', weatherState.barometricAltitude),
          unit: 'm',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Pressure:',
          value: printf('%.01f', weatherState.pressure),
          unit: 'hPa',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Outdoor Temp.:',
          value: printf('%+.01f', weatherState.temperatureOutdoor),
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Windchill Temp.:',
          value: printf('%+.01f', weatherState.temperatureWindchill),
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Dew point Temp.:',
          value: printf('%+.01f', weatherState.temperatureDewpoint),
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'System Temp.:',
          value: printf('%+.01f', weatherState.temperatureSystem),
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Actual Wind Speed.:',
          value: printf('%.01f', weatherState.windSpeed),
          unit: 'km/h',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Avg. Wind Speed.:',
          value: printf('%.01f', weatherState.windSpeed),
          unit: 'km/h',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Wind Gust.:',
          value: printf('%.01f', weatherState.windGust),
          unit: 'km/h',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Wind Direction.:',
          value: printf('%.01f', weatherState.windDir),
          unit: 'deg',
        },
      },
    ],
    [
      weatherState.barometricAltitude,
      weatherState.pressure,
      weatherState.relativeHumidity,
      weatherState.temperatureDewpoint,
      weatherState.temperatureOutdoor,
      weatherState.temperatureSystem,
      weatherState.temperatureWindchill,
      weatherState.windDir,
      weatherState.windGust,
      weatherState.windSpeed,
    ],
  );

  return { weatherStatusComponents };
}
