import useProtocolMessage from '#hooks/useProtocolMessage';

import useRelativeTimeEventListener from './useRelativeTimeEventListener';

export default function useRelativeTimeMessage() {
  return useProtocolMessage({
    listener: useRelativeTimeEventListener,
    transform: (x) => x.RelativeTime,
    initialBody: {
      SID: -1,
      value: 0,
    },
  });
}
