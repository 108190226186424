import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import NonIdealState from '#components/ui-helper/NonIdealState';
import useCurrentMission from '#hooks/useCurrentMission';

import StructuredField from './field-inputs/StructuredField';
import { reduceStructuredFieldState } from './field-state/struct-field-reducers';

const useStyles = makeStyles((theme) => ({
  packetBuilderInputs: {},
  sidePanelButtons: {
    flexGrow: 0,
    flexBasis: '64px',
  },
  jsonPreview: {
    position: 'sticky',
    top: theme.spacing(2),
  },
}));

function reducePacketBuilderState(state, action) {
  const { packetValue: prevPacketValue, packetInternalState: prevPacketInternalState } = state;

  switch (action.type) {
    case 'field-action-in-struct': {
      const [packetValue, packetInternalState] = reduceStructuredFieldState(
        prevPacketValue,
        prevPacketInternalState,
        action,
      );
      return { packetValue, packetInternalState };
    }
    default:
      throw new Error(`Unknown packet builder action: \`${action.type}\``);
  }
}

const PacketBuilder = ({ packetId, submitPacket, submitLabel }) => {
  const classes = useStyles();

  const [{ packetValue, packetInternalState }, dispatchPacketAction] = useReducer(reducePacketBuilderState, {
    packetValue: {},
    packetInternalStates: {},
  });

  const { missionKey, missionMeta } = useCurrentMission();

  if (missionMeta === undefined) {
    return <NonIdealState message={`Metadata unavaliable for mission '${missionKey}'`} />;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item className={classes.packetBuilderInputs} xs>
          <StructuredField
            structId={packetId}
            fieldValue={packetValue}
            fieldInternalState={packetInternalState}
            dispatchFieldAction={dispatchPacketAction}
          />
        </Grid>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Grid item xs={3}>
          <div className={classes.jsonPreview}>
            <Typography variant="overline">JSON Preview</Typography>
            <pre>{JSON.stringify(packetValue, null, 2)}</pre>
          </div>
        </Grid>
        <Grid item className={classes.sidePanelButtons} xs container direction="column">
          <Grid item>
            <Tooltip title="Show JSON Preview">
              <IconButton>
                <CodeIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Show Favorites (not implemented yet)">
              <span>
                <IconButton disabled>
                  <StarOutlineIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={2} textAlign="center">
        <Button variant="contained" color="primary" onClick={() => submitPacket(packetValue)}>
          {submitLabel}
        </Button>
      </Box>
    </div>
  );
};

PacketBuilder.propTypes = {
  packetId: PropTypes.string.isRequired,
  submitPacket: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
};

PacketBuilder.defaultProps = {
  submitLabel: 'Create',
};

export default PacketBuilder;
