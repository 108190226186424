import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import usePanelStyles from '../../../usePanelStyles';

const RenameFilePanel = ({ diskId, fileId, checkFileIdExists, rename }) => {
  const [newFileId, setNewFileId] = useState('');
  const classes = usePanelStyles();

  const alreadyExists = checkFileIdExists(diskId, newFileId);

  return (
    <>
      <Typography className={classes.panelTitle}>
        Rename file {fileId} on {diskIDOptions.find((option) => option.value === diskId).label}
      </Typography>
      <Grid container spacing={2} alignItems="flex-end" justify="space-between">
        <Grid item>
          <TextField
            margin="dense"
            label="New File ID"
            inputProps={{ type: 'number' }}
            error={alreadyExists}
            value={newFileId}
            onChange={(event) => setNewFileId(event.target.value)}
          />
        </Grid>
        <Grid item>
          {alreadyExists && (
            <Typography variant="body2" color="error">
              A file with ID {newFileId} already exists on{' '}
              {diskIDOptions.find((option) => option.value === diskId).label}
            </Typography>
          )}
          <Button variant="contained" color="primary" disabled={alreadyExists} onClick={() => rename(newFileId)}>
            Rename
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

RenameFilePanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  fileId: PropTypes.number.isRequired,
  checkFileIdExists: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
};

export default RenameFilePanel;
