// eslint-disable-next-line camelcase
import { _ETH_TypeDefs } from '@c3s/misc_radcube_eth_packets_enums';

import useProtocolEventListener from '#hooks/useProtocolEventListener';

export default function useBusConfigEventListener(callback) {
  return useProtocolEventListener({
    protocol: 'ETH',
    filter: ({ Service, SubService }) =>
      Service === _ETH_TypeDefs.eth_service_e.ETH_SERVICE_BUS_CONFIG &&
      SubService === _ETH_TypeDefs.eth_bus_config_subservice_e.ETH_BUS_CONFIG_SUBSERVICE_CONFIG_REPORT,
    callback,
  });
}
