import { ControlCard } from '@c3s/ui-tracking-station';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import React from 'react';

import useBaudRate from './BaudRate/useBaudRate';
import useDuration from './Duration/useDuration';
import useITURules from './ITURules/useITURules';
import useLinkFreq from './LinkFreq/useLinkFreq';
import useMonitor from './Monitor/useMonitor';
import NextPassBaudGraph from './NextPassBaudRate/NextPassBaudGraph';

const SdrMonitorTab = () => {
  const { monitorComponents } = useMonitor();
  const { baudRateComponents: txBaudRateComponents } = useBaudRate('uplink');
  const { baudRateComponents: rxBaudRateComponents } = useBaudRate('downlink');
  const { linkFreqComponents: uplinkFreqComponents } = useLinkFreq('uplink');
  const { linkFreqComponents: downlinkFreqComponents } = useLinkFreq('downlink');
  const { durationComponents: uplinkDurationComponents } = useDuration('uplink');
  const { durationComponents: downlinkDurationComponents } = useDuration('downlink');
  const { ITURulesComponents } = useITURules();

  return (
    <Grid container spacing={1} direction="row">
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Baudrates for Next Pass" titleTypographyProps={{ align: 'center', variant: 'h6' }} />
              <CardContent>
                <NextPassBaudGraph />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <ControlCard title="Uplink Frequency" components={uplinkFreqComponents} style={{ height: '100%' }} />
          </Grid>
          <Grid item xs={4}>
            <ControlCard title="Uplink Baudrate" components={txBaudRateComponents} style={{ height: '100%' }} />
          </Grid>
          <Grid item xs={4}>
            <ControlCard title="Uplink Duration" components={uplinkDurationComponents} style={{ height: '100%' }} />
          </Grid>
          <Grid item xs={4}>
            <ControlCard title="Downlink Frequency" components={downlinkFreqComponents} style={{ height: '100%' }} />
          </Grid>
          <Grid item xs={4}>
            <ControlCard title="Downlink Baudrate" components={rxBaudRateComponents} style={{ height: '100%' }} />
          </Grid>
          <Grid item xs={4}>
            <ControlCard title="Downlink Duration" components={downlinkDurationComponents} style={{ height: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <ControlCard title="ITU Rules" components={ITURulesComponents} style={{ height: '100%' }} />
          </Grid>
          <Grid item>
            <ControlCard title="SDR Config Monitor" components={monitorComponents} style={{ height: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SdrMonitorTab;
