import { CommunicationBusCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useCommunicationBusCard from './useCommunicationBusCard';

const Component = () => {
  const {
    setCanBusToA,
    setCanBusToB,
    setComMlvdsBusToA,
    setComMlvdsBusToB,
    setPayloadMlvdsBusToA,
    setPayloadMlvdsBusToB,
  } = useCommunicationBusCard();

  return (
    <CommunicationBusCard
      SetCanBusToA={setCanBusToA}
      SetCanBusToB={setCanBusToB}
      SetComMlvdsBusToA={setComMlvdsBusToA}
      SetComMlvdsBusToB={setComMlvdsBusToB}
      SetPayloadMlvdsBusToA={setPayloadMlvdsBusToA}
      SetPayloadMlvdsBusToB={setPayloadMlvdsBusToB}
    />
  );
};

export default Component;
