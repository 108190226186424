import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

const numSyncMin = 1;
const numSyncMax = 10000;
const numSyncInitMin = 0;
const numSyncInitMax = 10000;

export default function useMlvdsSyncServiceCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcBaseUrl = `rg/${currentResGroup}/@c3s/bustester-provider/mlvdsSync`;
  const mlvdsSyncDsRecord = useDsRecord('bustester', 'control/mlvdssync');

  const [NumberOfMlvdsSyncToSend, setNumberOfMlvdsSyncToSend] = useState(1);
  const [MlvdsSyncAsObc, setMlvdsSyncAsObc] = useState(0);
  const [MlvdsSyncInfinite, setMlvdsSyncInfinite] = useState(false);
  const [MlvdsSyncCounterFixed, setMlvdsSyncCounterFixed] = useState(false);
  const [MlvdsSyncCounterInitValue, setMlvdsSyncCounterInitValue] = useState(0);

  const isNumberOfSyncValid = NumberOfMlvdsSyncToSend < numSyncMin || NumberOfMlvdsSyncToSend > numSyncMax;
  const isInitValueValid = MlvdsSyncCounterInitValue < numSyncInitMin || MlvdsSyncCounterInitValue > numSyncInitMax;

  const persistState = () => {
    mlvdsSyncDsRecord.set({
      ...mlvdsSyncDsRecord.get(),
      NumberOfMlvdsSyncToSend,
      MlvdsSyncAsObc,
      MlvdsSyncCounterFixed,
      MlvdsSyncCounterInitValue,
      MlvdsSyncInfinite,
    });
  };

  useEffect(() => {
    mlvdsSyncDsRecord.whenReady((record) => {
      setNumberOfMlvdsSyncToSend(record.get()?.NumberOfMlvdsSyncToSend ?? numSyncMin);
      setMlvdsSyncAsObc(record.get()?.MlvdsSyncAsObc || 0);
      setMlvdsSyncInfinite(record.get()?.MlvdsSyncInfinite || false);
      setMlvdsSyncCounterFixed(record.get()?.MlvdsSyncCounterFixed || false);
      setMlvdsSyncCounterInitValue(record.get()?.MlvdsSyncCounterInitValue || 0);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const disableSync = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcBaseUrl}/disableSync`,
      paramsObject: {
        Red: MlvdsSyncAsObc,
      },
      snackbarMessage: 'Disable M-LVDS Sync service requested',
    });
  };

  const enableSync = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcBaseUrl}/enableSync`,
      paramsObject: {
        Red: MlvdsSyncAsObc,
        IsCounterFix: MlvdsSyncCounterFixed,
        CounterInitValue: MlvdsSyncCounterInitValue,
        NumberOfSyncToSend: MlvdsSyncInfinite ? 0xffffffff : NumberOfMlvdsSyncToSend,
      },
      snackbarMessage: 'Enable M-LVDS Sync service requested',
    });
  };

  const getSyncStatus = () => {
    processRpcResponse({
      endpoint: `${rpcBaseUrl}/getStatus`,
      paramsObject: null,
      snackbarMessage: 'Get M-LVDS Sync service status requested',
    });
  };

  return {
    disableSync,
    enableSync,
    getSyncStatus,
    NumberOfMlvdsSyncToSend,
    MlvdsSyncAsObc,
    MlvdsSyncInfinite,
    MlvdsSyncCounterFixed,
    MlvdsSyncCounterInitValue,
    numSyncMin,
    numSyncMax,
    numSyncInitMin,
    numSyncInitMax,
    isNumberOfSyncValid,
    isInitValueValid,
    setNumberOfMlvdsSyncToSend,
    setMlvdsSyncAsObc,
    setMlvdsSyncInfinite,
    setMlvdsSyncCounterFixed,
    setMlvdsSyncCounterInitValue,
  };
}
