import { blue, grey } from '@material-ui/core/colors';

export const bigFontSize = '1em';
export const normalFontSize = '0.92em';
export const smallFontSize = '0.8em';

const styles = (theme) => {
  return {
    ButtonMaxWidth50: {
      maxWidth: '50px',
    },
    ButtonLabel: {
      '&:disabled': {
        color: 'black',
      },
    },
    Card: {
      borderRadius: 'unset',
      boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
      margin: [0, `${theme.spacing(2)}px`, `${theme.spacing(1)}px`, 0].join(' '),

      '&:last-child': {
        marginRight: 'unset',
      },
    },
    CardHeader: {
      padding: theme.spacing(2),
    },
    CardContent: {
      display: 'flex',
      padding: 'unset',
      paddingLeft: theme.spacing(1),
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
      '&>*': {
        flex: 1,
        margin: [0, `${theme.spacing(1)}px`, `${theme.spacing(1)}px`, 0].join(' '),
        '&:first-child': {
          marginLeft: theme.spacing(2),
        },
        '&:last-child': {
          marginRight: theme.spacing(2),
        },
      },
    },
    Checkbox: {
      padding: 'unset',
    },
    errorColor: {
      color: '#fd397a',
    },
    successColor: {
      color: '#0abb87',
    },
    fontAwesomeIconHolder: {
      lineHeight: 0,
    },
    FormControlLabel: {
      flex: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '& span:last-child': {
        fontSize: normalFontSize,
      },
    },
    StatusPanelLink: {
      color: blue[500],
      '&:focus': {
        outline: 'unset',
      },
      '&:hover': {
        color: blue[500],
      },
    },
    table: {
      width: '100%',
      '& > thead > tr > th': {
        padding: '4px 8px',
      },
      '& > tbody > tr > td': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '4px 8px',
        '&:last-child': {
          width: '100%',
        },
      },
      '& > tbody > tr': {
        '&:nth-child(even):hover': {
          backgroundColor: grey['200'],
        },
        '&:nth-child(odd):hover': {
          backgroundColor: grey['200'],
        },
        '&:last-child': {
          backgroundColor: 'white',
        },
      },
    },
    thName: {
      width: '100%',
      textAlign: 'left',
    },
    thExtension: {
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      textAlign: 'center',
    },
    thContentType: {
      minWidth: 160,
      width: 160,
      textAlign: 'center',
    },
    thSize: {
      minWidth: 60,
      width: 60,
      maxWidth: 60,
      textAlign: 'center',
    },
    thProgress: {
      minWidth: 300,
      textAlign: 'center',
    },
    thUploadedOn: {
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      textAlign: 'center',
    },
    thUploadedBy: {
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      textAlign: 'center',
    },
    thAction: {
      minWidth: 120,
      textAlign: 'center',
    },
    TableRow: {
      height: 24,
    },
    FullProgressTableCell: {
      textAlign: 'center',
      color: '#4caf50',
    },
  };
};

export default styles;
