module.exports = {
  antennaHeaterControl: {
    autoValue: false,
  },
  cabinetCoolingControl: {
    autoValue: false,
  },
  cabinetFanControl: {
    autoValue: false,
  },
  cabinetHeaterControl: {
    autoValue: false,
  },
  mastLampPower: {
    autoValue: true,
    sunRiseTime: 0,
    sunSetTime: 0,
    isNight: false,
  },
  RFFEPower: {
    autoValue: true,
  },
  servoPower: {
    autoValue: true,
  },
  motorControl: {
    trackingValue: {
      azAngle: 0,
      elAngle: 0,
    },
    autoValue: {
      azAngle: 0,
      elAngle: 0,
    },
    outputValue: {
      azAngle: 0,
      elAngle: 0,
    },
  },
  windAlarm: {
    alarmValue: false,
  },
  RFFEControl: {
    rxTxOutputValue: 'off',
    PAPowerAutoValue: 0,
    PAPowerOutputValue: 0,
  },
  SDRBaudRateControl: {
    uplink: {
      autoValue: 0,
      outputValue: 0,
    },
    downlink: {
      RX2AutoValue: 0,
      RX2OutputValue: 0,
    },
  },
};
