import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import printf from 'printf';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useRffeDs from '../useRffeDs';

const modeOptions = new Map();
const manualvalueOptions = new Map();

export default function useRffeFanControl() {
  const theme = useTheme();
  const { rffeState, noResponse } = useRffeDs();
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  modeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO', color: 'white' });
  modeOptions.set('manual', { bgColor: theme.palette.yellow.main, label: 'MANUAL', color: 'black' });
  modeOptions.set('lost', { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });

  manualvalueOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  manualvalueOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'black' });
  manualvalueOptions.set(2, { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });

  const getValue = useCallback(
    (val) => {
      if (noResponse) return 2;
      return Number(val ?? 0);
    },
    [noResponse],
  );

  const getString = useCallback(
    (val) => {
      if (noResponse) return 'lost';
      return val;
    },
    [noResponse],
  );

  const setMode = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.fanMode',
          value: mode,
        });
        enqueueSnackbar('FAN Mode change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [rpcWithErrorHandler, configPath, enqueueSnackbar],
  );

  const setManualValue = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.fanManualValue',
          value: mode,
        });
        enqueueSnackbar('FAN Manual Value change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [rpcWithErrorHandler, configPath, enqueueSnackbar],
  );

  const rffeFanComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'FAN Mode:',
          button1Text: 'MANUAL',
          handleButton1Click: () => setMode('manual'),
          button2Text: 'AUTO',
          handleButton2Click: () => setMode('auto'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current FAN Mode:',
          value: getString(autonomyConfig.RFFEControl.fanMode),
          ValueComponent: GeneralStatusIndicator,
          options: modeOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'FAN Actual Mode:',
          value: getString(rffeState.PAFanMode).toLowerCase(),
          ValueComponent: GeneralStatusIndicator,
          options: modeOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'FAN Manual Value:',
          button1Text: 'ENABLE',
          handleButton1Click: () => setManualValue(true),
          button2Text: 'DISABLE',
          handleButton2Click: () => setManualValue(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current FAN Manual Mode:',
          value: getValue(autonomyConfig.RFFEControl.fanManualValue),
          ValueComponent: GeneralStatusIndicator,
          options: manualvalueOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'FAN State:',
          value: getValue(rffeState.isPAFanPowered),
          ValueComponent: GeneralStatusIndicator,
          options: manualvalueOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'FAN Current:',
          value: printf('%.03f', rffeState.PAFanCurrent),
          unit: 'A',
        },
      },
    ],
    [
      autonomyConfig.RFFEControl.fanManualValue,
      autonomyConfig.RFFEControl.fanMode,
      getString,
      getValue,
      rffeState.PAFanCurrent,
      rffeState.PAFanMode,
      rffeState.isPAFanPowered,
      setManualValue,
      setMode,
    ],
  );

  return { rffeFanComponents };
}
