import { CanStatusCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useCanAbsTimeStatusMessage from '../../hooks/useCanAbsTimeStatusMessage';
import useCanSyncStatusMessage from '../../hooks/useCanSyncStatusMessage';

const CanStatus = () => {
  const { value: CanAbsTimeReport } = useCanAbsTimeStatusMessage().body;
  const { value: CanSyncReport } = useCanSyncStatusMessage().body;

  return <CanStatusCard CanSyncReport={CanSyncReport} CanAbsTimeReport={CanAbsTimeReport} />;
};

export default CanStatus;
