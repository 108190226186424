import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  textarea: {
    fontFamily: '"Roboto Mono", ui-monospace, monospace',
    resize: 'vertical',
  },
}));

const StringField = ({ fieldMeta, fieldInternalState, dispatchFieldAction, ...otherInputProps }) => {
  const classes = useStyles();

  const match = fieldMeta?.ctype?.match(/(?<elementCtype>\w+)\s*\[(?<capacityStr>[0-9]+)\]/);
  const { capacityStr } = match?.groups ?? {};
  const capacity = parseInt(capacityStr, 10);

  const handleChange = (event) => {
    dispatchFieldAction({
      type: 'set-field-text',
      capacity,
      value: event.target.value,
    });
  };

  const { inputProps: innerInputProps, ...fwdInputProps } = otherInputProps;

  return (
    <OutlinedInput
      multiline
      notched
      rowsMin={1}
      placeholder="Enter UTF-8 text"
      {...fwdInputProps}
      inputProps={{
        ...innerInputProps,
        className: classes.textarea,
      }}
      value={fieldInternalState}
      onChange={handleChange}
    />
  );
};

StringField.propTypes = {
  fieldMeta: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object])).isRequired,
  fieldInternalState: PropTypes.string.isRequired,
  dispatchFieldAction: PropTypes.func.isRequired,
};

export default StringField;
