import merge from 'deepmerge';
import { useCallback, useContext, useMemo, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

import autonomyStatusDefaults from './autonomyStatusDefaults';

export default function useAutonomyStatusDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, setState] = useState(autonomyStatusDefaults);

  const statusPath = useMemo(() => `rg/${currentResGroup}/@c3s/gsts-autonomy-provider/status`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    setState(merge(autonomyStatusDefaults, newRecord));
  }, []);

  useDsRecordSubscription(statusPath, handleChange);

  return {
    autonomyStatus: state,
  };
}
