import { ConnectionAndBusStatusCard } from '@c3s/ui-radcube-connector-bustester';
import React, { useContext, useEffect, useRef, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import SubsystemList from '#satellites/RADCUBE/SubsystemList';

import useAbsoluteTimeMessage from '../../hooks/useAbsoluteTimeMessage';
import useAutoReportPeriodTimeInMsMessage from '../../hooks/useAutoReportPeriodTimeInMsMessage';
import useBusConfigMessage from '../../hooks/useBusConfigMessage';
import useRelativeTimeMessage from '../../hooks/useRelativeTimeMessage';

const MainStatus = () => {
  const { value: AbsoluteTimeReport } = useAbsoluteTimeMessage().body;
  const { value: RelativeTimeReport } = useRelativeTimeMessage().body;
  const { value: AutoReportPeriodTimeInMsReport } = useAutoReportPeriodTimeInMsMessage().body;
  const { value: BusConfigReport } = useBusConfigMessage().body;
  const { dsClient } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);

  const dsConnectionStatusRecord = dsClient.record.getRecord(
    `rg/${currentResGroup}/@c3s/tester-provider/debug/bustester/status/connection`,
  );

  const dsRecord = useDsRecord('bustester', 'control/role');

  const [dutRole, setDutRole] = useState(3);
  const [mockRole, setMockRole] = useState(1);
  const [connectionString, setConnectionString] = useState('disconnected');
  const [connectionIsAlive, setConnectionIsAlive] = useState(false);

  const dsSubscription = useRef((newRecord) => {
    setDutRole(newRecord?.dutRole ?? 3);
    setMockRole(newRecord?.mockRole ?? 1);
  });

  const dsConnectionSubscription = useRef((newRecord) => {
    setConnectionIsAlive(newRecord?.isAlive || false);
    if (newRecord?.isAlive && newRecord?.ipAddress && newRecord?.portNumber) {
      const { ipAddress, portNumber } = newRecord;
      setConnectionString(`${ipAddress} : ${portNumber}`);
    } else {
      setConnectionString(`disconnected`);
    }
  });

  useEffect(() => {
    dsRecord.subscribe(dsSubscription.current, true);
    dsConnectionStatusRecord.subscribe(dsConnectionSubscription.current, true);
    return function cleanup() {
      dsRecord.discard();
      dsConnectionStatusRecord.discard();
    };
  }, [dsRecord, dsConnectionStatusRecord]);

  const mockRoleString = SubsystemList.find((element) => element.value === mockRole).label;
  const dutRoleString = SubsystemList.find((element) => element.value === dutRole).label;

  return (
    <ConnectionAndBusStatusCard
      connectionIsAlive={connectionIsAlive}
      connectionString={connectionString}
      mockRoleString={mockRoleString}
      dutRoleString={dutRoleString}
      RelativeTimeReport={RelativeTimeReport}
      AbsoluteTimeReport={AbsoluteTimeReport}
      AutoReportPeriodTimeInMsReport={AutoReportPeriodTimeInMsReport}
      BusConfigReport={BusConfigReport}
    />
  );
};

export default MainStatus;
