import { useContext } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordState from '#hooks/useDsRecordState';

const NAME = '@c3s/rf-fe-provider';

export default function useRffeStatusCard() {
  const { currentResGroup } = useContext(RgContext);

  const { record: status } = useDsRecordState(`rg/${currentResGroup}/${NAME}/status`, null);

  return { status };
}
