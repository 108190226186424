import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

export default function useCanHeartbeatStatusCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/canHeartbeat`;
  const CanHbDsRecord = useDsRecord('bustester', 'control/canheartbeat');
  const [CanHeartbeatMainRed, setCanHeartbeatMainRed] = useState(0);
  const [CanHeartbeatAsSubsystem, setCanHeartbeatAsSubsystem] = useState(2);
  const [CanHeartbeatMessage, setCanHeartbeatMessage] = useState('');

  const isCanHeartbeatMessageInvalid = CanHeartbeatMessage === '';

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const persistState = () => {
    CanHbDsRecord.set({
      ...CanHbDsRecord.get(),
      CanHeartbeatMainRed,
      CanHeartbeatAsSubsystem,
      CanHeartbeatMessage,
    });
  };

  useEffect(() => {
    CanHbDsRecord.whenReady((record) => {
      setCanHeartbeatMainRed(record.get()?.CanHeartbeatMainRed || 0);
      setCanHeartbeatAsSubsystem(record.get()?.CanHeartbeatAsSubsystem ?? 2);
      setCanHeartbeatMessage(record.get()?.CanHeartbeatMessage || '');
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disableHeartbeat = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/disableHeartbeat`,
      paramsObject: {
        APID: CanHeartbeatAsSubsystem,
        Red: CanHeartbeatMainRed,
      },
      snackbarMessage: 'Disable CAN Heartbeat Service requested',
    });
  };

  const enableHeartbeat = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/enableHeartbeat`,
      paramsObject: {
        APID: CanHeartbeatAsSubsystem,
        Red: CanHeartbeatMainRed,
        heartbeat: isCanHeartbeatMessageInvalid
          ? []
          : CanHeartbeatMessage.replace(/\s+/g, '')
              .match(/.{1,2}/g)
              .map((value) => parseInt(value, 16)),
      },
      snackbarMessage: 'Enable CAN Heartbeat Service requested',
    });
  };

  const getHeartbeatStatus = () => null;

  return {
    enableHeartbeat,
    disableHeartbeat,
    CanHeartbeatMainRed,
    CanHeartbeatAsSubsystem,
    CanHeartbeatMessage,
    isCanHeartbeatMessageInvalid,
    getHeartbeatStatus,
    setCanHeartbeatMainRed,
    setCanHeartbeatAsSubsystem,
    setCanHeartbeatMessage,
  };
}
