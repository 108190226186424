import merge from 'deepmerge';
import { useCallback, useContext, useMemo, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const beaconDefaults = {
  RSSI: 0,
  json: {
    BEACON_PAYLOAD: {
      SAT_OP_MODE: 0,
    },
  },
};

export default function useLatestBeacon() {
  const { currentResGroup } = useContext(RgContext);

  const [state, setState] = useState(beaconDefaults);

  const beaconPath = useMemo(() => `rg/${currentResGroup}/@c3s/rflink-provider/receivedByRFLinkBeacon`, [
    currentResGroup,
  ]);

  const handleChange = useCallback((newRecord) => {
    setState(merge(beaconDefaults, newRecord));
  }, []);

  useDsRecordSubscription(beaconPath, handleChange);

  return { state };
}
