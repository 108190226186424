import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import BustesterPageWrapper from '#components/page-content/bus-tester/BustesterPageWrapper';
import TabPanel from '#components/page-fragments/TabPanel';
import tabs from '#connectors/tctm.constants';
import TestingContext from '#contexts/TestingContext';

const PageTitle = 'TCTM Protocol';

const TctmProtocolPage = ({ connectorKey }) => {
  const { testConnectors } = useContext(TestingContext);

  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <BustesterPageWrapper withStatus connectorCmdProtocolStack={testConnectors[connectorKey]?.commandProtocolStack}>
        <TabPanel tabs={tabs} />
      </BustesterPageWrapper>
    </>
  );
};

TctmProtocolPage.propTypes = {
  connectorKey: PropTypes.string.isRequired,
};

export default TctmProtocolPage;
