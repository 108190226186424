import React, { useState } from 'react';

import { CLUSTER_DATA } from '../../../data/StationClusterDemo';
import { ColoredCircleIcon } from '../../ui-helper';
import DynamicTable from '../../ui-helper/DynamicTable';

const StationClusterTable = () => {
  const [contentState, setContentState] = useState(CLUSTER_DATA);
  const tableHeader = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'numeberOfMembers',
      title: 'No. of Members',
    },
    {
      field: 'status',
      title: 'Status',
      render: (row) => (
        <div style={{ paddingLeft: '3em' }}>
          <ColoredCircleIcon status={row.status} /> {row.statusText}
        </div>
      ),
    },
  ];

  return (
    <DynamicTable
      maxRows={1000}
      columns={tableHeader}
      title="HTTP SERVERS"
      defaultRow={CLUSTER_DATA[0]}
      contentState={contentState}
      setContentState={setContentState}
    />
  );
};

export default StationClusterTable;
