import { FilterStatusCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useBusConfigMessage from '../../hooks/useBusConfigMessage';

const FilterStatus = () => {
  const { value: BusConfigReport } = useBusConfigMessage().body;
  const {
    CAN_ABS_TIME_DIST_SERV_FILTER_EN,
    CAN_BUS_MNG_SERV_FILTER_EN,
    CAN_DATA_STREAM_SERV_FILTER_EN,
    CAN_DEV_CMD_SERV_FILTER_EN,
    CAN_NODE_MNG_SERV_FILTER_EN,
    CAN_SYNC_MSG_SERV_FILTER_EN,
    CAN_TCTM_STREAM_SERV_FILTER_EN,
    CAN_TIME_SYNC_SERV_FILTER_EN,
    CAN_STREAM_SERV_FCU_EN,
    OBC_COM_DIR,
    OBC_COM_FCU_EN,
    OBC_PL_FCU_EN,
  } = BusConfigReport;

  const BusFilters = [
    CAN_STREAM_SERV_FCU_EN,
    CAN_DATA_STREAM_SERV_FILTER_EN,
    CAN_TCTM_STREAM_SERV_FILTER_EN,
    CAN_SYNC_MSG_SERV_FILTER_EN,
    CAN_DEV_CMD_SERV_FILTER_EN,
    CAN_ABS_TIME_DIST_SERV_FILTER_EN,
    CAN_NODE_MNG_SERV_FILTER_EN,
    CAN_TIME_SYNC_SERV_FILTER_EN,
    CAN_BUS_MNG_SERV_FILTER_EN,
    OBC_COM_FCU_EN,
    OBC_COM_DIR,
    OBC_PL_FCU_EN,
  ];

  const titleArray = [
    'Can Stream',
    'Data Stream',
    'TCTM Stream',
    'Sync Msg',
    'Dev Cmd',
    'Abs Time Dist',
    'Node Mgmt',
    'Time Sync',
    'Bus Mgmt',
    'OBC-COM FCU',
    'OBC COM Dir',
    'OBC-PL FCU',
  ];

  return <FilterStatusCard BusFilters={BusFilters} titles={titleArray} />;
};

export default FilterStatus;
