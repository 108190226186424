// eslint-disable-next-line camelcase
import { _CAN_TypeDefs } from '@c3s/misc_radcube_can_packets_enums';

import useProtocolEventListener from '#hooks/useProtocolEventListener';

const serviceIDs = new Set(Object.values(_CAN_TypeDefs.can_node_management_service_id_e));

export default function useNodeManagementEventListener(callback) {
  return useProtocolEventListener({
    protocol: 'CAN',
    filter: ({ Identifier }) => serviceIDs.has(Identifier),
    callback,
  });
}
