import Grid from '@material-ui/core/Grid';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';
import PropTypes from 'prop-types';
import React from 'react';

import SatelliteFileOperationButtons from './SatelliteFileOperationButtons';

const SatelliteFilesTableToolbar = ({ openFileOperationPanel }) => {
  const { state } = useGridSlotComponentProps();
  const selValue = state.selection[0];
  const selectedFileId = selValue !== undefined ? Number(selValue) : null;

  return (
    <GridToolbarContainer>
      <Grid container justify="space-between">
        <Grid item>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport />
        </Grid>
        <Grid item>
          <SatelliteFileOperationButtons fileId={selectedFileId} openFileOperationPanel={openFileOperationPanel} />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

SatelliteFilesTableToolbar.propTypes = {
  openFileOperationPanel: PropTypes.func.isRequired,
};

export default SatelliteFilesTableToolbar;
