export function hexStringToArray(string) {
  const byteArray = string
    .replace(/\s+/g, (match) => {
      return '0'.repeat(match.length - 1);
    })
    .replace(/0x/g, '')
    .match(/.{1,2}/g);

  return byteArray !== null
    ? byteArray.map((number) => {
        return parseInt(number, 16);
      })
    : [];
}

export function hexStringToInteger(hexString) {
  return parseInt(hexString.replace(/\s+/g, ''), 16) || 0;
}

export function bitMaskHexa(bitMaskArray) {
  if (bitMaskArray == null || bitMaskArray.length === 0) {
    return '0x00';
  }
  return `0x${parseInt(bitMaskArray.join(''), 2).toString(16).padStart(2, '0').toUpperCase()}`;
}

export function combineIntArrayToHex(IntArray) {
  if (IntArray == null || IntArray.length === 0) {
    return '0x00';
  }
  const hexArray = IntArray.map((item) => item.toString(16).padStart(2, '0'));
  return `0x ${hexArray.join(' ').toUpperCase()}`;
}

export function fromEntries(iterable) {
  return [...iterable].reduce(
    (obj, { 0: key, 1: val }) =>
      Object.assign(obj, {
        [key]: val,
      }),
    {},
  );
}

export function pluck(object, property) {
  return Object.fromEntries(
    Object.keys(object).map((key) => {
      if (object[key][property] !== undefined) {
        return [key, object[key][property]];
      }
      return [key, object[key]];
    }),
  );
}

// https://medium.com/free-code-camp/pipe-and-compose-in-javascript-5b04004ac937
export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

export function sanitize(value, precision, fallback = '-') {
  const potentialFloat = parseFloat(value);
  if (!Number.isNaN(potentialFloat)) {
    if (precision) {
      return Number(potentialFloat.toFixed(precision));
    }
    return Number(potentialFloat);
  }
  return fallback;
}
