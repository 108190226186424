import printf from 'printf';
import { useMemo } from 'react';

import useRffeDs from '../useRffeDs';

export default function usePAStatus() {
  const { rffeState } = useRffeDs();

  const paStatusComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'Last Packet Power:',
          value: printf('%.01f', rffeState.lastPacketPower),
          unit: 'W',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'SWR Actual Packet:',
          value: printf('%.01f', rffeState.SWRActualPacket),
          unit: '%',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'SWR Last Packet:',
          value: printf('%.01f', rffeState.SWRLastPacket),
          unit: '%',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'PA Current:',
          value: printf('%.02f', parseFloat(rffeState.PACurrent) / 1000),
          unit: 'A',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'PA Temperature:',
          value: printf('%.01f', rffeState.PATemperature),
          unit: '°C',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual PA Power:',
          value: printf('%.01f', rffeState.PAOutputPower),
          unit: 'W',
        },
      },
    ],
    [
      rffeState.PACurrent,
      rffeState.PAOutputPower,
      rffeState.PATemperature,
      rffeState.SWRActualPacket,
      rffeState.SWRLastPacket,
      rffeState.lastPacketPower,
    ],
  );

  return { paStatusComponents };
}
