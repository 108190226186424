// eslint-disable-next-line camelcase
import { _ETH_TypeDefs } from '@c3s/misc_radcube_eth_packets_enums';

import useProtocolEventListener from '#hooks/useProtocolEventListener';

export default function useCanSyncStatusEventListener(callback) {
  return useProtocolEventListener({
    protocol: 'ETH',
    filter: ({ Service, SubService }) =>
      (Service === _ETH_TypeDefs.eth_service_e.ETH_SERVICE_FUNCTION_MNG &&
        SubService === _ETH_TypeDefs.eth_function_mng_subservice_e.ETH_FUNCTION_MNG_SUBSERVICE_GEN_REPORT) ||
      (Service === _ETH_TypeDefs.eth_service_e.ETH_SERVICE_CAN_SYNC &&
        SubService === _ETH_TypeDefs.eth_can_sync_subservice_e.ETH_CAN_SYNC_SUBSERVICE_STATUS_REPORT),
    callback,
  });
}
