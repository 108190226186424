import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const UnmountDiskPanel = ({ diskId, unmount }) => {
  const [forced, setForced] = useState(false);

  return (
    <>
      <Typography>Unmount {diskIDOptions.find((option) => option.value === diskId).label}</Typography>
      <Grid container spacing={1} alignItems="baseline">
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox checked={forced} onChange={(event) => setForced(event.target.checked)} name="forced-unmount" />
            }
            label="Force Unmount"
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={() => unmount(forced)}>
            Unmount
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

UnmountDiskPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  unmount: PropTypes.func.isRequired,
};

export default UnmountDiskPanel;
