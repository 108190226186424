import {
  FileDeleteConfirmDialog,
  FileMetadataDialog,
  FileProcessDialog,
  FileTable,
  FileTableCard,
  UploadCard,
} from '@c3s/ui-fileserver-stories';
import Grid from '@material-ui/core/Grid';
import Bluebird from 'bluebird';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import useDsEventListener from '#hooks/useDsEventListener';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';
import useSnackbar from '#hooks/useSnackbar';

import useFileServerApi from './file-server/useFileServerApi';

const bucketId = '5d077d12d9a5194f986a8b48';
const fileHandlerRecordPath = 'skipdb/rg/moc/@c3s/file-handler/done';
const fileCompressionEventBaseName = 'rg/moc/@c3s/file-compression-provider/';
const baseURL = process.env.GATSBY_FILE_SERVER;
const FileStorageTab = () => {
  const [cookies] = useCookies(['auth0.accessToken']);
  const { enqueueSnackbar } = useSnackbar();
  const [Files, setFiles] = useState([]);
  const [Uploading, setUploading] = useState(false);
  const [UploadProgress, setUploadProgress] = useState([]);
  const [RecordsArray, setRecordsArray] = useState([]);
  const [RecordsTotal, setRecordsTotal] = useState(10);
  const [RecordsPerPage, setRecordsPerPage] = useState(5);
  const [PageNumber, setPageNumber] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(null);
  const [selectedMetadata, setSelectedMetadata] = useState({ id: '0', filename: '' });

  const {
    fetchFileList,
    deleteFile,
    processFile,
    updateFileMetadata,
    uploadFileStream,
    zipFile,
    unzipFile,
  } = useFileServerApi();

  const jwt = useMemo(() => cookies['auth0.accessToken'], [cookies]);

  const getFileListHandler = useCallback(
    async ({ skip, limit }) => {
      try {
        const { data } = await fetchFileList({ skip, limit, jwt, bucketId });
        setRecordsArray(data.records);
        setRecordsTotal(() => {
          setPageNumber((prevPageNumber) => {
            const TotalPages = Math.ceil(data.recordsTotal / RecordsPerPage) - 1;
            return Math.max(Math.min(TotalPages, prevPageNumber), 0);
          });
          return data.recordsTotal;
        });
      } catch (error) {
        enqueueSnackbar('Unable to download the file list.', { variant: 'error' });
      }
    },
    [RecordsPerPage, enqueueSnackbar, fetchFileList, jwt],
  );

  const fileList = useCallback(async () => {
    await getFileListHandler({ bucketId, skip: PageNumber * RecordsPerPage, limit: RecordsPerPage });
  }, [PageNumber, RecordsPerPage, getFileListHandler]);

  const uploadFileHandler = async (file, index) => {
    try {
      const onUploadProgress = (progressEvent) => {
        setUploadProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[index] = (progressEvent.loaded / file.size) * 100;
          return newProgress;
        });
      };
      if (UploadProgress[index] !== 100) {
        await uploadFileStream({
          data: file,
          filename: file.name,
          filetype: file.type,
          jwt,
          bucketId,
          onUploadProgress,
        });
      }
    } catch (error) {
      enqueueSnackbar('Unable to upload the file.', { variant: 'error' });
    }
  };

  const updateFileMetadataHandler = async ({ id, filename }) => {
    try {
      await updateFileMetadata({ id, filename, jwt, bucketId });
      enqueueSnackbar(`File metadata updated (${filename}).`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to update file metadata', { variant: 'error' });
    }
  };

  const onDialogOpen = (dialogName) => (newMetadata) => {
    setSelectedMetadata(newMetadata);
    setDialogOpen(dialogName);
  };

  const onDelete = async (id) => {
    try {
      setDialogOpen(null);
      enqueueSnackbar('File deletion request sent.', { variant: 'info' });
      await deleteFile({ jwt, id, bucketId });
      enqueueSnackbar('File deleted.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to delete the selected file.', { variant: 'error' });
    } finally {
      await fileList();
    }
  };

  const onProcess = async (additionalInfo) => {
    try {
      setDialogOpen(null);
      const ret = await processFile({ id: selectedMetadata.id, bucketId, ...additionalInfo });
      if (ret.status !== 'error') {
        enqueueSnackbar('File process request sent.', { variant: 'info' });
      }
    } finally {
      await fileList();
    }
  };

  const onZip = async ({ id, filename }) => {
    try {
      const ret = await zipFile({ id, filename, bucketId });
      if (ret.status !== 'error') {
        enqueueSnackbar('File compress request sent.', { variant: 'info' });
      }
    } finally {
      await fileList();
    }
  };

  const onUnzip = async ({ id, filename }) => {
    try {
      const ret = await unzipFile({ id, filename, bucketId });

      if (ret.status !== 'error') {
        enqueueSnackbar('File decompress request sent.', { variant: 'info' });
      }
    } finally {
      await fileList();
    }
  };

  const onClear = () => {
    setUploadProgress([]);
    setFiles([]);
  };

  const onAdd = (e) => {
    const filesArray = Array.from(e.target.files);
    setFiles((PrevFilesArray) => PrevFilesArray.concat(filesArray));
  };

  const onRemove = (item) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(item, 1);
      return newFiles;
    });
    setUploadProgress((preProgress) => {
      const newProgress = [...preProgress];
      newProgress.splice(item, 1);
      return newProgress;
    });
  };

  async function onUpload() {
    setUploading(true);
    await Bluebird.map(Files, async (file, index) => uploadFileHandler(file, index), { concurrency: 4 });
    setUploading(false);
    await fileList();
  }

  const onDialogClose = () => {
    setDialogOpen(null);
  };

  const onDialogSubmit = async (newMetadata) => {
    setDialogOpen(null);
    await updateFileMetadataHandler(newMetadata);
    await fileList();
  };

  const processSubscription = useCallback(
    async ({ sessionID }) => {
      enqueueSnackbar(`File processing finished (session: ${sessionID}).`, { variant: 'success' });
      fileList();
    },
    [enqueueSnackbar, fileList],
  );

  const compressDoneSubscription = useCallback(
    async ({ fileName }) => {
      enqueueSnackbar(`File compression finished: ${fileName}`, { variant: 'success' });
      fileList();
    },
    [enqueueSnackbar, fileList],
  );

  const compressErrorSubscription = useCallback(
    async ({ fileName, error }) => {
      enqueueSnackbar(`File compression failed: ${fileName} (${error})`, { variant: 'error' });
      fileList();
    },
    [enqueueSnackbar, fileList],
  );

  useDsRecordSubscription(fileHandlerRecordPath, processSubscription, false);
  useDsEventListener(`${fileCompressionEventBaseName}done`, compressDoneSubscription);
  useDsEventListener(`${fileCompressionEventBaseName}error`, compressErrorSubscription);

  useEffect(() => {
    fileList();
  }, [fileList]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <UploadCard
            rows={Files}
            disabled={Uploading}
            onClear={onClear}
            onAdd={onAdd}
            onRemove={onRemove}
            onUpload={onUpload}
            progress={UploadProgress}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FileTableCard>
            <FileTable
              rows={RecordsArray}
              onDelete={onDialogOpen('delete')}
              onEdit={onDialogOpen('edit')}
              onProcess={onDialogOpen('process')}
              onZip={onZip}
              onUnzip={onUnzip}
              DownloadUrl={`${baseURL}/${bucketId}`}
              RecordsPerPage={RecordsPerPage}
              RecordsTotal={RecordsTotal}
              PageNumber={PageNumber}
              setRecordsPerPage={setRecordsPerPage}
              setPageNumber={setPageNumber}
            />
            <FileMetadataDialog
              open={dialogOpen === 'edit'}
              onClose={onDialogClose}
              onSubmit={onDialogSubmit}
              metadata={selectedMetadata}
            />
            <FileDeleteConfirmDialog
              open={dialogOpen === 'delete'}
              onClose={onDialogClose}
              onDelete={onDelete}
              metadata={selectedMetadata}
            />
            <FileProcessDialog
              open={dialogOpen === 'process'}
              onClose={onDialogClose}
              onSubmit={onProcess}
              metadata={{}}
            />
          </FileTableCard>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(FileStorageTab);
