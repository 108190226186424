import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import React from 'react';

const NonIdealState = ({ icon, message, messageSecondary, actions }) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>{icon ?? <ErrorIcon fontSize="large" />}</Grid>
      <Grid item>
        <Typography variant="body1">{message}</Typography>
      </Grid>
      {messageSecondary && (
        <Grid item>
          <Typography variant="body2">{messageSecondary}</Typography>
        </Grid>
      )}
      {(actions ?? []).length !== 0 && (
        <Grid item container direction="row" justify="center" spacing={1}>
          {actions.map(({ label, action }) => (
            <Grid key={label} item>
              <Button variant="outlined" onClick={action}>
                {label}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

NonIdealState.propTypes = {
  icon: PropTypes.element,
  message: PropTypes.string.isRequired,
  messageSecondary: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
};
NonIdealState.defaultProps = {
  icon: <ErrorIcon fontSize="large" />,
  messageSecondary: '',
  actions: [],
};

export default NonIdealState;
