import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PropTypes from 'prop-types';
import React from 'react';

const MountUnmountButton = ({ diskState, mountDisk, unmountDisk, ...otherMuiBtnProps }) => {
  return (
    <ButtonGroup {...otherMuiBtnProps}>
      {diskState !== TCTM.tctm_fs_disk_state_e.TCTM_FS_DISK_STATE_MOUNTED && (
        <Button variant="contained" color="primary" onClick={mountDisk}>
          Mount
        </Button>
      )}
      {diskState !== TCTM.tctm_fs_disk_state_e.TCTM_FS_DISK_STATE_UNMOUNTED && (
        <Button variant="outlined" onClick={unmountDisk}>
          Unmount
        </Button>
      )}
    </ButtonGroup>
  );
};

MountUnmountButton.propTypes = {
  diskState: PropTypes.oneOf(Object.values(TCTM.tctm_fs_disk_state_e)),
  mountDisk: PropTypes.func.isRequired,
  unmountDisk: PropTypes.func.isRequired,
};

MountUnmountButton.defaultProps = {
  diskState: undefined,
};

export default MountUnmountButton;
