import React from 'react';
import { Helmet } from 'react-helmet';

import StatusTable from '#services/tracking-station/status-table';

const PageTitle = 'Station Catalog';

const StationCatalogPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <div>
        <StatusTable />
      </div>
    </>
  );
};

export default StationCatalogPage;
