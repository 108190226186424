import { RffeControl } from '@c3s/ui-tracking-station';
import React from 'react';

import useRffeControlCard from './useRffeControlCard';

const RffeControlCard = () => {
  const props = useRffeControlCard();
  return <RffeControl {...props} />;
};

export default RffeControlCard;
