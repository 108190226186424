import useProtocolMessage from '#hooks/useProtocolMessage';

import useCanAbsTimeStatusEventListener from './useCanAbsTimeStatusEventListener';

export default function useCanAbsTimeStatusMessage() {
  return useProtocolMessage({
    listener: useCanAbsTimeStatusEventListener,
    transform: (x) => x.CanAbsTimeStatus,
    initialBody: {
      SID: -1,
      value: Array(2).fill({
        IsEnabled: 0,
        PeriodTimeInMs: 0,
      }),
    },
  });
}
