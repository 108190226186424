import { Grid, Typography } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Select from 'react-select';

import TabPanel from '#components/page-fragments/TabPanel';
import RgContext from '#contexts/RgContext';
import styles from '#utils/styles';

import tabs from './station-management.constants';
import theme from './station-management.theme';

const PageTitle = 'Station Management';
const useStyles = makeStyles(styles);

const stationList = [
  {
    label: 'C3S Lurdy Station',
    value: 'lurdy-ts',
  },
  {
    label: 'ELTE Station',
    value: 'elte-ts',
  },
];

const StationManagementPage = () => {
  const classes = useStyles();
  const [selectedStation, setSelectedStation] = useState(stationList[0]);

  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <div>
        <Grid container spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item xs={2}>
            <Typography variant="h6" className={classes.StatusTitle}>
              {PageTitle}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Select
              label="Select station"
              options={stationList}
              value={selectedStation}
              onChange={setSelectedStation}
            />
          </Grid>
        </Grid>
        <ThemeProvider theme={theme}>
          <RgContext.Provider value={{ currentResGroup: selectedStation.value }}>
            <TabPanel tabs={tabs} />
          </RgContext.Provider>
        </ThemeProvider>
      </div>
    </>
  );
};

export default StationManagementPage;
