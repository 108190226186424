/* eslint-disable no-unused-expressions */
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useMemo, useReducer, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useToggle } from 'standard-hooks';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import useFileServerApi from '#services/file-storage/file-server/useFileServerApi';
import styles from '#utils/styles';

const useStyles = makeStyles(styles);
const bucketId = '5d077d12d9a5194f986a8b48';
const reducer = (s, a) => ({ ...s, ...a });

export default function useFileTransferControlState() {
  const [open, setOpen] = useToggle(true);
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const {
    user: { email },
  } = useAuth0();
  const [mode, setMode] = useState('upload');
  const { currentResGroup, fileTransferRpc } = useContext(RgContext);
  const rpcBase = `rg/${currentResGroup}/@c3s/file-transfer-provider`;
  const uploadRpcPoint = `${rpcBase}/upload`;
  const downloadRpcPoint = `${rpcBase}/download`;
  const [uploadState, uploadDispatch] = useReducer(reducer, {
    ...fileTransferRpc,
    SCID: 2,
    source: {},
    destination: {},
    userID: email,
  });
  const [downloadState, downloadDispatch] = useReducer(reducer, {
    ...fileTransferRpc,
    SCID: 2,
    source: { fileID: 0, diskID: 0 },
    destination: { fileID: '', bucketID: bucketId },
    userID: email,
  });
  const [cookies] = useCookies(['auth0.accessToken']);
  const jwt = useMemo(() => cookies['auth0.accessToken'], [cookies]);
  const { enqueueSnackbar } = useSnackbar();
  const { fetchFileList } = useFileServerApi();

  const getFileListHandler = async ({ skip, limit }) => {
    try {
      const { data } = await fetchFileList({ skip, limit, jwt, bucketId });
      return data.records.map((fileObj) => ({
        label: fileObj.filename,
        value: {
          bucketID: bucketId,
          fileID: fileObj._id,
        },
      }));
    } catch (error) {
      enqueueSnackbar('Unable to download the file list.', { variant: 'error' });
    }
    return null;
  };

  const onUpload = async () => {
    enqueueSnackbar('Upload File request sent.', { variant: 'info' });
    await rpcWithErrorHandler(uploadRpcPoint, uploadState);
  };
  const onDownload = async () => {
    enqueueSnackbar('Download File request sent.', { variant: 'info' });
    await rpcWithErrorHandler(downloadRpcPoint, downloadState);
  };

  const onCreate = () => (mode === 'upload' ? onUpload() : onDownload());

  return {
    open,
    setOpen,
    useStyles,
    uploadState,
    downloadState,
    getFileListHandler,
    uploadDispatch,
    downloadDispatch,
    onCreate,
    setMode,
  };
}
