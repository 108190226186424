import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import bytes from 'bytes';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import GridDescriptionList from '#components/ui-helper/GridDescriptionList';
import TimeAgo from '#components/ui-helper/TimeAgo';

import usePanelStyles from '../../../usePanelStyles';
import { getBaseFileId, getFileIdExtension } from '../../helpers';

const FileReportPanel = ({ diskId, fileId, file, getFileReport }) => {
  const classes = usePanelStyles();
  const { fileSize, fileAttributes, fileCreated, fileModified, sectorListSize, sectorList, lastUpdated } = file;

  const data = [
    { label: 'Base ID', value: getBaseFileId(fileId), gridWidth: 4 },
    { label: 'Extension', value: getFileIdExtension(fileId), gridWidth: 4 },
    {
      label: 'Attributes',
      value: (
        <FileAttributeChips
          isSystem={fileAttributes?.isSystemFile}
          isProtected={fileAttributes?.isProtectedFile}
          isDedicated={fileAttributes?.isDedicatedFile}
          dedicatedFunction={fileAttributes?.dedicatedFunction}
        />
      ),
      gridWidth: 4,
    },
    { label: 'Size', value: `${bytes.format(fileSize)} (${fileSize} bytes)`, gridWidth: 4 },
    { label: 'Created', value: fileCreated, gridWidth: 4 },
    { label: 'Last Modified', value: fileModified, gridWidth: 4 },
    { label: 'Sector List Size', value: sectorListSize, gridWidth: 4 },
    { label: 'On Sectors', value: sectorList?.join(', '), gridWidth: 8 },
  ];

  return (
    <>
      <Typography className={classes.panelTitle}>
        File {fileId} on {diskIDOptions.find((option) => option.value === diskId).label}
      </Typography>
      <GridDescriptionList data={data} />
      <Grid container className={classes.panelActionRow} spacing={2} alignItems="baseline" justify="flex-end">
        <Grid item>
          <Typography variant="body2">
            Last updated <TimeAgo date={parseISO(lastUpdated)} />
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={getFileReport}>
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

FileReportPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  fileId: PropTypes.number.isRequired,
  file: PropTypes.shape({
    fileSize: PropTypes.number,
    fileAttributes: PropTypes.shape({
      isSystemFile: PropTypes.bool,
      isProtectedFile: PropTypes.bool,
      isDedicatedFile: PropTypes.bool,
      dedicatedFunction: PropTypes.string,
    }),
    fileCreated: PropTypes.string,
    fileModified: PropTypes.string,
    sectorListSize: PropTypes.number,
    sectorList: PropTypes.arrayOf(PropTypes.number),
    lastUpdated: PropTypes.string,
  }).isRequired,
  getFileReport: PropTypes.func.isRequired,
};

const FileAttributeChips = ({ isSystem, isProtected, isDedicated, dedicatedFunction }) => {
  if (!isSystem && !isProtected && !isDedicated) {
    return <>&ndash;</>;
  }

  return (
    <>
      {isSystem && <Chip icon={<FontAwesomeIcon icon={['fas', 'cog']} />} label="System File" size="small" />}
      {isProtected && (
        <Chip icon={<FontAwesomeIcon icon={['fas', 'shield-alt']} />} label="Protected File" size="small" />
      )}
      {isDedicated && (
        <Chip
          icon={<FontAwesomeIcon icon={['fas', 'star']} />}
          label={`Dedicated File: ${dedicatedFunction}`}
          size="small"
        />
      )}
    </>
  );
};

FileAttributeChips.propTypes = {
  isSystem: PropTypes.bool,
  isProtected: PropTypes.bool,
  isDedicated: PropTypes.bool,
  dedicatedFunction: PropTypes.string,
};

FileAttributeChips.defaultProps = {
  isSystem: false,
  isProtected: false,
  isDedicated: false,
  dedicatedFunction: undefined,
};

export default FileReportPanel;
