import { makeStyles } from '@material-ui/core';

const usePanelStyles = makeStyles((theme) => ({
  panelTitle: {
    marginBottom: theme.spacing(1),
  },
  panelActionRow: {
    marginTop: theme.spacing(1),
  },
}));

export default usePanelStyles;
