/* eslint-disable no-nested-ternary */
import { GpioStatusCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useRole from '#hooks/useRole';

import useGpioExpReportMessage from '../../hooks/useGpioExpReportMessage';

const GpioStatus = () => {
  const { value: BusConfigReport } = useGpioExpReportMessage().body;
  const { dutRole } = useRole();

  return <GpioStatusCard BusConfigReport={BusConfigReport} dutRole={dutRole} />;
};

export default GpioStatus;
