import React from 'react';
import { Helmet } from 'react-helmet';

import TabPanel from '#components/page-fragments/TabPanel';
import tabs from '#services/message-logging/message-logging.constants';

const PageTitle = 'Message Logging';

const MessageLoggingPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <TabPanel tabs={tabs} />
    </>
  );
};

export default MessageLoggingPage;
