// eslint-disable-next-line camelcase
import { _TCTM_TypeDefs } from '@c3s/misc_radcube_tctm_packets_enums';

const { tctm_rf_baudrate_e: baudRates } = _TCTM_TypeDefs; // eslint-disable-line camelcase

export const baudRateOptions = Object.entries(baudRates).map(([label, value]) => ({ label, value }));

export function baudRateFromEnum(enumValue) {
  const baudAsString = Object.entries(baudRates)
    .find(([, value]) => value === enumValue)?.[0]
    .split('_')
    .pop();

  return baudAsString ? parseInt(baudAsString, 10) : 0;
}
