import React from 'react';

import BusConfigTab from '#connectors/bus-tester/control-tabpanel/tabs/BusConfigTab';
import CanAbsTimeDistTab from '#connectors/bus-tester/control-tabpanel/tabs/CanAbsTimeDistTab';
import CanHeartbeatTab from '#connectors/bus-tester/control-tabpanel/tabs/CanHeartbeatTab';
import CanSyncTab from '#connectors/bus-tester/control-tabpanel/tabs/CanSyncTab';
import FunctionMgmtTab from '#connectors/bus-tester/control-tabpanel/tabs/FunctionMgmtTab';
import GeneralTab from '#connectors/bus-tester/control-tabpanel/tabs/GeneralTab';
import MlvdsHeartbeatTab from '#connectors/bus-tester/control-tabpanel/tabs/MlvdsHeartbeatTab';
import MlvdsSyncTab from '#connectors/bus-tester/control-tabpanel/tabs/MlvdsSyncTab';
import PowerControlTab from '#connectors/bus-tester/control-tabpanel/tabs/PowerControlTab';

export default [
  {
    name: 'General',
    hash: 'general',
    container: <GeneralTab />,
  },
  {
    name: 'Power Control',
    hash: 'power-control',
    container: <PowerControlTab />,
  },
  {
    name: 'CAN Synchronization',
    hash: 'can-synchronization',
    container: <CanSyncTab />,
  },
  {
    name: 'CAN Heartbeat',
    hash: 'can-heartbeat',
    container: <CanHeartbeatTab />,
  },
  {
    name: 'CAN Absolute Time Distribution',
    hash: 'can-abs-time-distribution',
    container: <CanAbsTimeDistTab />,
  },
  {
    name: 'MLVDS Synchronization',
    hash: 'mlvds-synchronization',
    container: <MlvdsSyncTab />,
  },
  {
    name: 'MLVDS Heartbeat',
    hash: 'mlvds-heartbeat',
    container: <MlvdsHeartbeatTab />,
  },
  {
    name: 'Bus Configuration',
    hash: 'bus-configuration',
    container: <BusConfigTab />,
  },
  {
    name: 'Function Management',
    hash: 'function-management',
    container: <FunctionMgmtTab />,
  },
];
