import merge from 'deepmerge';
import { useCallback, useContext, useMemo, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

import autonomyConfigDefaults from './autonomyConfigDefaults';

export default function useAutonomyConfigDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, setState] = useState(autonomyConfigDefaults);
  const configPath = useMemo(() => `rg/${currentResGroup}/@c3s/gsts-autonomy-provider/config`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    setState(merge(autonomyConfigDefaults, newRecord));
  }, []);

  useDsRecordSubscription(configPath, handleChange);

  return { autonomyConfig: state, configPath };
}
