// eslint-disable-next-line camelcase
import { _BEACON_TypeDefs } from '@c3s/misc_radcube_beacon_packets_enums';

const { beacon_sat_opmode_e: opModes } = _BEACON_TypeDefs; // eslint-disable-line camelcase

export const opModeOptions = Object.entries(opModes).map(([label, value]) => ({ label, value }));

export function opModeFromEnum(enumValue) {
  return Object.entries(opModes).find(([, value]) => value === enumValue)?.[0] ?? 'unknown mode';
}
