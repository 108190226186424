import { TranscoderLogTable } from '@c3s/ui-radcube-message-logging';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/SaveAlt';
import React, { forwardRef, useCallback, useContext, useReducer } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';
import useSnackbar from '#hooks/useSnackbar';

import LabelAttacherCard from '../../LabelAttacher';
import useTableHeader from './OverviewTabHelper';

const initialState = {
  configs: {},
  stats: {},
  rows: [],
};

function makeRows(configs, stats) {
  Object.entries(configs).map(([protocol, params]) => ({ protocol, ...params, ...stats[protocol] }));
}

function reducer(state, action) {
  switch (action.type) {
    case 'configs':
      return {
        ...state,
        configs: action.value,
        rows: makeRows(action.value, state.stats),
      };
    case 'stats':
      return {
        ...state,
        stats: action.value,
        rows: makeRows(state.configs, action.value),
      };
    default:
      return state;
  }
}

const OverviewTab = () => {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const TableHeader = useTableHeader();
  const [{ rows }, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  const handleConfigChange = useCallback((value) => {
    dispatch({ type: 'configs', value });
  }, []);

  const handleStatChange = useCallback((value) => {
    dispatch({ type: 'stats', value });
  }, []);

  useDsRecordSubscription(`rg/${currentResGroup}/@c3s/log-writer-provider/configuration`, handleConfigChange);
  useDsRecordSubscription(`rg/${currentResGroup}/@c3s/log-writer-provider/statistics`, handleStatChange);

  const tableActions = [
    {
      isFreeAction: true,
      icon: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} ref={ref} icon={['fas', 'file-download']} style={{ fontSize: 18 }} />
      )),
      tooltip: 'Create Archive',
      onClick: () => {
        enqueueSnackbar('Archive is not implemented', {
          variant: 'warning',
        });
      },
    },
    {
      icon: SaveIcon,
      tooltip: 'Export inbound into CSV',
      isFreeAction: true,
      onClick: () => {
        enqueueSnackbar('Save is not implemented.', {
          variant: 'warning',
        });
      },
    },
    {
      icon: DeleteIcon,
      tooltip: 'Drop all inbound',
      isFreeAction: true,
      onClick: () => {
        rpcWithErrorHandler(`rg/${currentResGroup}/@c3s/log-writer-provider/dropAllLogCollection`, {});
        enqueueSnackbar('All log collection was deleted.', {
          variant: 'info',
        });
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LabelAttacherCard />
      </Grid>
      <Grid item xs={12}>
        <TranscoderLogTable tableActions={tableActions} TableHeader={TableHeader} rows={rows} />
      </Grid>
    </Grid>
  );
};

export default OverviewTab;
