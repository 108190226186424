import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const useStyles = makeStyles(() => ({
  textarea: {
    fontFamily: 'Consolas, monospace',
    resize: 'vertical',
  },
}));

const ComplexField = ({ fieldInternalState, dispatchFieldAction, ...otherInputProps }) => {
  const classes = useStyles();

  const { fieldText } = fieldInternalState;

  const handleChange = useCallback(
    (event) => {
      dispatchFieldAction({
        type: 'set-field-text',
        value: event.target.value,
      });
    },
    [dispatchFieldAction],
  );

  const { inputProps: innerInputProps, ...fwdInputProps } = otherInputProps;

  return (
    <OutlinedInput
      multiline
      notched
      rows={4}
      placeholder="Enter JSON value"
      {...fwdInputProps}
      inputProps={{
        ...innerInputProps,
        className: classes.textarea,
      }}
      value={fieldText}
      onChange={handleChange}
    />
  );
};

ComplexField.propTypes = {
  fieldMeta: PropTypes.shape({
    sid: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  fieldInternalState: PropTypes.shape({ fieldText: PropTypes.string, parseError: PropTypes.string }),
  dispatchFieldAction: PropTypes.func.isRequired,
};

ComplexField.defaultProps = {
  fieldInternalState: { fieldText: '', parseError: null },
};

export default ComplexField;
