import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { ColoredCircleIcon } from '#components/ui-helper';

import StatusValueStyles from './StatusValueStyles';

const useStyles = makeStyles(StatusValueStyles);

const SingleValueDisplay = ({ status, title, value, link }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <ColoredCircleIcon status={status} />
      </div>
      {link ? (
        <Link className={`${classes.title} ${classes.StatusPanelLink}`} to={link}>{`${title}:`}</Link>
      ) : (
        <div className={classes.title}>{`${title}:`}</div>
      )}
      <div className={classes.value}>{value}</div>
    </div>
  );
};

SingleValueDisplay.defaultProps = {
  link: null,
};

SingleValueDisplay.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  link: PropTypes.string,
};

const DoubleValueDisplay = ({ title, link, status1, status2, value1, value2 }) => {
  const classes = useStyles();

  return (
    <>
      {link ? (
        <th className={classes.Cell}>
          <Link className={`${classes.StatusPanelLink}`} to={link}>{`${title}:`}</Link>
        </th>
      ) : (
        <th className={classes.Cell}>{`${title}:`}</th>
      )}
      <td className={classes.Cell}>
        <div className={classes.ValueCell}>
          <ColoredCircleIcon status={status1} /> {value1}
        </div>
      </td>
      <td className={classes.Cell}>
        <div className={classes.ValueCell}>
          <ColoredCircleIcon status={status2} />
          {value2}
        </div>
      </td>
    </>
  );
};

DoubleValueDisplay.defaultProps = {
  link: null,
};

DoubleValueDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  status1: PropTypes.string.isRequired,
  status2: PropTypes.string.isRequired,
  value1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export { SingleValueDisplay as default, DoubleValueDisplay };
