import React from 'react';

import ConnectionSettingsCard from './ConnectionSettingsCard';
import useConnectionCard from './useConnectionCard';

const ConnectionCard = () => {
  const { onConnect, onDisconnect, serialPort, setSerialPort, baudrate, setBaudrate, isAlive } = useConnectionCard();
  return (
    <ConnectionSettingsCard
      connectToAccessPort={onConnect}
      disconnectFromAccessPort={onDisconnect}
      serialPort={serialPort}
      setSerialPort={setSerialPort}
      baudrate={baudrate}
      setBaudrate={setBaudrate}
      isConnected={isAlive}
    />
  );
};

export default ConnectionCard;
