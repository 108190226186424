import Bluebird from 'bluebird';
import { useCallback, useContext, useMemo, useReducer } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';
import useSnackbar from '#hooks/useSnackbar';

const reducer = (s, a) => {
  if (typeof a === 'function') {
    return { ...s, ...a(s) };
  }
  return { ...s, ...a };
};

const initialState = {
  lastUpdated: 0,
  isPinging: false,
  isAlive: false,
  isPolling: false,
  isUpdating: false,
  isConnected: false,
  networkConfig: {
    IP: '10.15.100.1',
    port: 502,
  },
  status: {
    ACT_POSITION: 0,
    ACT_SPEED: 0,
    USER_STATUS: {
      IN_MOTION: false,
      INTERNAL_FAULT: false,
      INTERNAL_WARNING: false,
      LIMIT_SWITCH_POS_HARD: false,
      LIMIT_SWITCH_NEG_HARD: false,
      LIMIT_SWITCH_POS_SOFT: false,
      LIMIT_SWITCH_NEG_SOFT: false,
    },
  },
  error: [],
  warning: [],
};

const epsilon = 6000;

export default function useMotorCtrlAzDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const configPath = useMemo(() => `rg/${currentResGroup}/@c3s/gsts-autonomy-provider/config`, [currentResGroup]);
  const motorCtrlAzPath = useMemo(() => `rg/${currentResGroup}/@c3s/motor-controller-provider/az`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    if ('lastUpdated' in newRecord) {
      dispatch({ lastUpdated: newRecord.lastUpdated });
    }
    if ('isPinging' in newRecord) {
      dispatch({ isPinging: newRecord.isPinging });
    }
    if ('isAlive' in newRecord) {
      dispatch({ isAlive: newRecord.isAlive });
    }
    if ('isPolling' in newRecord) {
      dispatch({ isPolling: newRecord.isPolling });
    }
    if ('isUpdating' in newRecord) {
      dispatch({ isUpdating: newRecord.isUpdating });
    }
    if ('isConnected' in newRecord) {
      dispatch({ isConnected: newRecord.isConnected });
    }
    if ('status' in newRecord) {
      dispatch((prevState) => ({ status: { ...prevState.status, ...newRecord.status } }));
    }
    if ('networkConfig' in newRecord) {
      dispatch((prevState) => ({ networkConfig: { ...prevState.networkConfig, ...newRecord.networkConfig } }));
    }
    if ('error' in newRecord) {
      dispatch({ error: newRecord.error });
    }
    if ('warning' in newRecord) {
      dispatch({ warning: newRecord.warning });
    }
  }, []);

  useDsRecordSubscription(`${motorCtrlAzPath}/status`, handleChange);

  const motionStopAz = useCallback(async () => {
    const statuses = await Bluebird.all([
      rpcWithErrorHandler(configPath, {
        path: 'motorControl.azMode',
        value: 'stop',
      }),
      rpcWithErrorHandler(`${motorCtrlAzPath}/stop`, {}),
    ]);

    if (statuses.every(({ status }) => status === 'success')) {
      enqueueSnackbar('AZ Motion Stop requested', { variant: 'info' });
    }
  }, [rpcWithErrorHandler, configPath, motorCtrlAzPath, enqueueSnackbar]);

  return {
    motorCtrlAz: state,
    motorCtrlAzPath,
    motionStopAz,
    noResponseAz: Date.now() - state.lastUpdated >= epsilon,
  };
}
