// eslint-disable-next-line import/prefer-default-export
export function reduceComplexFieldState(value, internalState, action) {
  switch (action.type) {
    case 'set-field-text':
      try {
        const json = JSON.parse(action.value);
        return [json, { fieldText: action.value, validationError: null }];
      } catch (err) {
        if (err instanceof SyntaxError) {
          return [null, { fieldText: action.fieldText, validationError: err.message }];
        }
        throw err;
      }
    default:
      throw new Error(`Invalid complex field action type: ${action.type}`);
  }
}
