import { getStructEntryOnPath, setStructEntryOnPath } from '../helpers';
// eslint-disable-next-line import/no-cycle
import { reduceFieldState } from '.';

function checkFieldMetaInAction(action) {
  if (action.fieldId === undefined) {
    throw new Error('Field id in struct reducer cannot be `undefined`');
  }
  if (action.fieldMeta === undefined) {
    throw new Error('Field metadata in struct reducer cannot be `undefined`');
  }
  if (action.fieldMeta.name === undefined) {
    throw new Error('Field key in struct reducer cannot be `undefined`');
  }
}

function getInnerFunctionHierarchySelections(functionHierarchy, upperLevelEntry, structFieldInternalStates) {
  const [{ key: currentLevelKey, field: currentLevelFieldId }, ...innerFunctionHierarchy] = functionHierarchy;
  const currentLevelEntry = upperLevelEntry?.[currentLevelKey];

  if (upperLevelEntry === undefined || currentLevelEntry === undefined) {
    const restIsUndefined = functionHierarchy.map(() => undefined);
    return { ids: restIsUndefined, entries: restIsUndefined, payload: undefined };
  }

  const selectionId = structFieldInternalStates?.[currentLevelFieldId];
  const selection = currentLevelEntry.ids[selectionId];

  if (innerFunctionHierarchy.length === 0) {
    return { ids: [selectionId], entries: [currentLevelEntry], payload: selection };
  }

  const {
    ids: innerSelectionIds,
    entries: innerSelectedEntires,
    payload: selectedPayload,
  } = getInnerFunctionHierarchySelections(innerFunctionHierarchy, selection, structFieldInternalStates);

  return {
    ids: [selectionId, ...innerSelectionIds],
    entries: [currentLevelEntry, ...innerSelectedEntires],
    payload: selectedPayload,
  };
}

export function getFunctionHierarchySelections(structMeta, structFieldInternalStates) {
  if (structMeta === undefined || !(structMeta.functionHierarchy instanceof Array)) {
    return { ids: [undefined], entries: [undefined], payload: undefined };
  }

  return getInnerFunctionHierarchySelections(structMeta.functionHierarchy, structMeta, structFieldInternalStates);
}

function actionAffectsFuncHierarchySelections(action) {
  if (action.structMeta?.functionHierarchy === undefined) {
    return false;
  }
  return action.structMeta.functionHierarchy.some(({ field: fieldId }) => fieldId === action.fieldAction.fieldId);
}

function actionAffectsFuncHierarchyPayload(action) {
  if (action.structMeta?.functionHierarchy === undefined) {
    return false;
  }
  return action.structMeta.payloadId === action.fieldAction.fieldId;
}

function updatePayloadStatesFromFuncHierarchy(structMeta, structValue, structInternalState) {
  const { ids: structFuncHierarchySelectionIds } = getFunctionHierarchySelections(
    structMeta,
    structInternalState.fieldInternalStates,
  );
  return [
    setStructEntryOnPath(
      structValue,
      structMeta.fields[structMeta.payloadId].name.split('.'),
      getStructEntryOnPath(structInternalState.cachedPayloadValues, structFuncHierarchySelectionIds),
    ),
    {
      ...structInternalState,
      payloadInternalState: getStructEntryOnPath(
        structInternalState.cachedPayloadInternalStates,
        structFuncHierarchySelectionIds,
      ),
    },
  ];
}

function updatePayloadInFuncHierarchy(structMeta, prevStructInternalState, payloadValue, payloadInternalState) {
  const structHasFuncHierarchy =
    structMeta?.functionHierarchy instanceof Array && structMeta?.functionHierarchy.length > 0;

  if (structHasFuncHierarchy) {
    const { ids: structFuncHierarchySelectionIds } = getFunctionHierarchySelections(
      structMeta,
      prevStructInternalState.fieldInternalStates,
    );

    if (structFuncHierarchySelectionIds.every((id) => id !== undefined)) {
      return {
        ...prevStructInternalState,
        cachedPayloadValues: setStructEntryOnPath(
          prevStructInternalState.cachedPayloadValues,
          structFuncHierarchySelectionIds,
          payloadValue,
        ),
        cachedPayloadInternalStates: setStructEntryOnPath(
          prevStructInternalState.cachedPayloadInternalStates,
          structFuncHierarchySelectionIds,
          payloadInternalState,
        ),
      };
    }
  }

  return prevStructInternalState;
}

function performFieldActionInStruct(prevStructValue, prevStructInternalState, action) {
  const { fieldAction } = action;
  checkFieldMetaInAction(fieldAction);

  const { fieldId } = fieldAction;
  const fieldPath = fieldAction.fieldMeta.name.split('.'); // Support multiply nested struct field ('Data.TCFrame')

  const [newValue, newInternalState] = reduceFieldState(
    getStructEntryOnPath(prevStructValue, fieldPath),
    prevStructInternalState?.fieldInternalStates?.[fieldId],
    fieldAction,
  );
  let newStructValue = setStructEntryOnPath(prevStructValue, fieldPath, newValue);
  let newStructInternalState = setStructEntryOnPath(
    prevStructInternalState,
    ['fieldInternalStates', fieldId],
    newInternalState,
  );

  if (actionAffectsFuncHierarchySelections(action)) {
    [newStructValue, newStructInternalState] = updatePayloadStatesFromFuncHierarchy(
      action.structMeta,
      newStructValue,
      newStructInternalState,
    );
  }
  if (actionAffectsFuncHierarchyPayload(action)) {
    newStructInternalState = updatePayloadInFuncHierarchy(
      action.structMeta,
      newStructInternalState,
      newValue,
      newInternalState,
    );
  }

  return [newStructValue, newStructInternalState];
}

export function reduceStructuredFieldState(prevStructValue, prevStructInternalState, action) {
  switch (action.type) {
    case 'field-action-in-struct': {
      const [structValue, structInternalState] = performFieldActionInStruct(
        prevStructValue,
        prevStructInternalState,
        action,
      );
      return [structValue, structInternalState];
    }
    default:
      throw new Error(`Unknown struct action: \`${action.type}\``);
  }
}
