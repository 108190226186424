import { useEffect } from 'react';

import useCurrentMission from '#hooks/useCurrentMission';

const useCurrentMissionEnumMeta = (enumId) => {
  const { missionMeta, fetchEnum } = useCurrentMission();

  const enumMeta = missionMeta?.enums?.[enumId];

  useEffect(() => {
    if (enumMeta === undefined) {
      if (typeof enumId !== 'undefined' && enumId !== null && enumId !== '') {
        fetchEnum(enumId);
      }
    }
  }, [enumMeta, fetchEnum, enumId]);

  return enumMeta;
};

export default useCurrentMissionEnumMeta;
