import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import { fsOperationModeOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const OperationModeSelect = ({ opMode, selectOpMode, ...otherMuiBtnProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOpMode, setSelectedOpMode] = useState(opMode);

  const pickOperationMode = (event, index) => {
    setSelectedOpMode(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonGroup>
        <Button
          aria-haspopup="true"
          aria-controls="opmode-menu"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          endIcon={<ArrowDropDownIcon />}
          variant="outlined"
          {...otherMuiBtnProps}
        >
          {fsOperationModeOptions.find((option) => option.value === selectedOpMode)?.label ?? 'Select OpMode'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={selectedOpMode === opMode}
          onClick={() => selectOpMode(selectedOpMode)}
        >
          Set
        </Button>
      </ButtonGroup>
      <Menu id="opmode-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {fsOperationModeOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedOpMode}
            onClick={(event) => pickOperationMode(event, option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

OperationModeSelect.propTypes = {
  opMode: PropTypes.oneOf(Object.values(TCTM.tctm_fs_operation_mode_e)),
  selectOpMode: PropTypes.func.isRequired,
};

OperationModeSelect.defaultProps = {
  opMode: undefined,
};

export default OperationModeSelect;
