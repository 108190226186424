import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import NonIdealState from '#components/ui-helper/NonIdealState';
import useCurrentMissionStructMeta from '#hooks/useCurrentMissionStructMeta';

import { getFunctionHierarchySelections } from '../field-state/struct-field-reducers';
import { getFieldInStructInternalState, getFieldInStructValue } from '../helpers';
// eslint-disable-next-line import/no-cycle
import Field from './Field';
// eslint-disable-next-line import/no-cycle
import FunctionHierarchySelector from './FunctionHierarchySelector';

const useStyles = makeStyles((theme) => ({
  structFieldset: {
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderWidth: 1,
    marginTop: theme.spacing(1),
  },
  structFields: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '16px',
  },
  funcHierarchyColumn: {
    minWidth: '17.5em',
    flexGrow: 1,
    flexBasis: '17.5em',
    flexShrink: 1,
  },
  payloadColumn: {
    minWidth: '32em',
    flexGrow: 1,
    flexBasis: '74.25%',
    flexShrink: 1,
  },
}));

const StructuredField = ({
  structId,
  fieldValue: structValue,
  fieldInternalState: structInternalState,
  dispatchFieldAction,
  label,
}) => {
  const classes = useStyles();
  const structMeta = useCurrentMissionStructMeta(structId);

  const functionHierarchy = structMeta?.functionHierarchy;
  const hasFunctionHierarchy = functionHierarchy instanceof Array && functionHierarchy.length > 0;
  const payloadFieldMeta = structMeta?.fields?.[structMeta.payloadId];

  const { entries: selectedFuncHierarchyEntries, payload: selectedPayload } = getFunctionHierarchySelections(
    structMeta,
    structInternalState?.fieldInternalStates,
  );

  const funcHierarchyFields = useMemo(
    () => (hasFunctionHierarchy ? functionHierarchy.map(({ field: fieldId }) => fieldId) : []),
    [functionHierarchy, hasFunctionHierarchy],
  );

  const dispatchFieldActionInStruct = useCallback(
    (action) => {
      dispatchFieldAction({
        type: 'field-action-in-struct',
        structMeta,
        fieldAction: action,
      });
    },
    [dispatchFieldAction, structMeta],
  );

  if (structId === undefined) {
    return null;
  }
  if (structMeta === undefined) {
    return (
      <NonIdealState
        message={
          <span>
            Metadata for struct <code>{structId}</code> is undefined
          </span>
        }
      />
    );
  }

  let payloadInputs = null;
  if (hasFunctionHierarchy) {
    payloadInputs = (
      <div className={classes.structFields}>
        <div className={classes.funcHierarchyColumn}>
          <div className={classes.structFields}>
            <FunctionHierarchySelector
              functionHierarchyFields={funcHierarchyFields}
              selectedFuncHierarchyEntries={selectedFuncHierarchyEntries}
              structMeta={structMeta}
              structValue={structValue}
              structInternalState={structInternalState}
              dispatchFieldActionInStruct={dispatchFieldActionInStruct}
            />
          </div>
        </div>
        <div className={classes.payloadColumn}>
          <Field
            structId={selectedPayload?.struct}
            fieldId={structMeta.payloadId}
            fieldMeta={payloadFieldMeta}
            fieldValue={getFieldInStructValue(structValue, payloadFieldMeta)}
            fieldInternalState={getFieldInStructInternalState(structInternalState, structMeta?.payloadId)}
            dispatchFieldAction={dispatchFieldActionInStruct}
            fullWidth
          />
        </div>
      </div>
    );
  } else if (structMeta.payloadId !== undefined) {
    payloadInputs = (
      <Field
        fieldId={structMeta.payloadId}
        fieldMeta={payloadFieldMeta}
        fieldValue={getFieldInStructValue(structMeta, payloadFieldMeta)}
        fieldInternalState={getFieldInStructInternalState(structInternalState, structMeta?.payloadId)}
        dispatchFieldAction={dispatchFieldActionInStruct}
        fullWidth
      />
    );
  }

  return (
    <Box className={classes.structFieldset} component="fieldset">
      <Typography component="legend" variant="overline">
        {label ?? structMeta.displayName ?? structMeta.name}
      </Typography>
      <div className={classes.structFields}>
        {structMeta.fields &&
          Object.entries(structMeta.fields)
            .filter(
              ([fieldId]) =>
                !(hasFunctionHierarchy && funcHierarchyFields.map((f) => f.toString()).includes(fieldId)) &&
                fieldId !== structMeta?.payloadId,
            )
            .map(([fieldId, fieldMeta]) => (
              <Field
                key={fieldId}
                fieldId={fieldId}
                fieldMeta={fieldMeta}
                fieldValue={getFieldInStructValue(structValue, fieldMeta)}
                fieldInternalState={getFieldInStructInternalState(structInternalState, fieldId)}
                dispatchFieldAction={dispatchFieldActionInStruct}
                fullWidth
              />
            ))}
      </div>
      {payloadInputs}
    </Box>
  );
};

StructuredField.propTypes = {
  structId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fieldValue: PropTypes.objectOf(PropTypes.oneOf(PropTypes.number, PropTypes.array, PropTypes.object)).isRequired,
  fieldInternalState: PropTypes.objectOf(PropTypes.oneOf(PropTypes.number, PropTypes.array, PropTypes.object))
    .isRequired,
  dispatchFieldAction: PropTypes.func.isRequired,
  label: PropTypes.string,
};

StructuredField.defaultProps = {
  label: undefined,
};

export default StructuredField;
