import { FormControl, IconButton, Input, InputAdornment, InputLabel, Typography } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import CustomMaskedInput from '#components/ui-helper/CustomMaskedInput';
import useCurrentMissionEnumMeta from '#hooks/useCurrentMissionEnumMeta';

import { isSecretKeyInvalid } from './helpers';

const SecretKeyField = ({ scid, vc, vcName, secretKey, setSecretKey }) => {
  const [keyVisible, setKeyVisible] = useState(false);

  return (
    <FormControl key={`${scid}_${vc}`} fullWidth margin="dense">
      <InputLabel htmlFor={`secretKey_${scid}_${vc}`} shrink>
        {`VC: ${vcName}`}
      </InputLabel>
      <Input
        type={keyVisible ? 'text' : 'password'}
        value={secretKey}
        error={isSecretKeyInvalid(secretKey)}
        onChange={(event) => setSecretKey(event.target.value)}
        onFocus={(event) => event.target.select()}
        id={`secretKey_${scid}_${vc}`}
        name={`secretKey_${scid}_${vc}`}
        inputComponent={CustomMaskedInput}
        inputProps={{
          mask: Array(32)
            .fill([/[a-f0-9]/i, /[a-f0-9]/i, `\u2009`])
            .flat()
            .slice(0, -1),
          pipe: (value) => value.toUpperCase().trim(),
        }}
        startAdornment={<InputAdornment position="start">0x</InputAdornment>}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setKeyVisible((visible) => !visible)}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              {keyVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        style={{ fontFamily: 'monospace' }}
      />
    </FormControl>
  );
};

SecretKeyField.propTypes = {
  scid: PropTypes.number.isRequired,
  vc: PropTypes.number.isRequired,
  vcName: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  setSecretKey: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  secretKeyFieldset: {
    border: 'solid 1px',
    borderColor: theme.palette.divider,
  },
}));

const SecretKeyInputs = ({ secretKeys, dispatch }) => {
  const classes = useStyles();

  const scidEnum = useCurrentMissionEnumMeta('tctm_scid_e');
  const scidOptions = useMemo(
    () => scidEnum?.items.map(({ displayName, numericValue }) => [numericValue, displayName]) ?? [],
    [scidEnum],
  );

  const vcEnum = useCurrentMissionEnumMeta('tctm_virtual_channel_e');
  const vcOptions = useMemo(
    () => vcEnum?.items.map(({ displayName, numericValue }) => [numericValue, displayName]) ?? [],
    [vcEnum],
  );

  return (
    <>
      {scidOptions.map(([scid, scidName]) => (
        <fieldset className={classes.secretKeyFieldset}>
          <Typography component="legend" variant="overline">
            Spacecraft: {scidName}
          </Typography>
          {vcOptions.map(([vc, vcName]) => (
            <SecretKeyField
              scid={scid}
              vc={vc}
              vcName={vcName}
              secretKey={secretKeys?.[scid]?.[vc] ?? ''}
              setSecretKey={(value) => dispatch({ type: `setSecretKeys`, scid, vc, value })}
            />
          ))}
        </fieldset>
      ))}
    </>
  );
};

SecretKeyInputs.propTypes = {
  secretKeys: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default React.memo(SecretKeyInputs);
