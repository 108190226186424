/* eslint-disable react/react-in-jsx-scope */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Switch, Tooltip } from '@material-ui/core';
import bytes from 'bytes';
import React, { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

export default function useTableHeader() {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const onLoggingSwitch = ({ row, event }) => {
    const { protocol } = row;
    processRpcResponse({
      endpoint: `rg/${currentResGroup}/setLoggingByProtocol`,
      paramsObject: {
        protocol,
        logging: event.target.checked,
      },
      snackbarMessage: 'On Logging Switch requested',
    });
  };

  const onCollectionDrop = ({ row }) => {
    const { protocol } = row;
    processRpcResponse({
      endpoint: `rg/${currentResGroup}/@c3s/log-writer-provider/dropLogCollection`,
      paramsObject: {
        protocol,
      },
      snackbarMessage: 'Collection dropped',
    });
  };

  return [
    {
      field: 'name',
      title: 'Protocol Name',
    },
    {
      field: 'documents',
      title: 'Documents',
      render: ({ documents = '-' }) => documents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
    },
    {
      field: 'avgDocumentSize',
      title: 'Average Document Size',
      render: ({ avgDocumentSize = 0 }) => bytes(avgDocumentSize, { thousandsSeparator: ' ', unitSeparator: ' ' }),
    },
    {
      field: 'totalDocumentSize',
      title: 'Total Document Size',
      render: ({ totalDocumentSize = 0 }) => bytes(totalDocumentSize, { thousandsSeparator: ' ', unitSeparator: ' ' }),
    },
    {
      field: 'isLogging',
      title: 'Enable',
      render: (row) => (
        <Switch
          checked={row.isLogging}
          disabled={!row.isLoggingEditable}
          onChange={(event) => onLoggingSwitch({ row, event })}
          inputProps={{
            'aria-label': 'Switch A',
          }}
        />
      ),
    },
    {
      field: 'action',
      title: 'Actions',
      render: (row) => {
        return (
          row.isCollectionDropAllowed && (
            <Tooltip title="Drop collection" aria-label="Drop Collection">
              <Button onClick={() => onCollectionDrop({ row })}>
                <FontAwesomeIcon icon={['fas', 'trash']} size="lg" />
              </Button>
            </Tooltip>
          )
        );
      },
    },
  ];
}
