import { useCallback, useContext, useMemo, useReducer } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const reducer = (s, a) => ({ ...s, ...a });

const initialState = {
  inputs: Array(16).fill(false),
};

export default function useHousekeepingDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const hkPathRoot = useMemo(() => `rg/${currentResGroup}/@c3s/housekeeping-provider`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    if (newRecord?.inputs?.length) dispatch({ inputs: newRecord.inputs });
  }, []);

  useDsRecordSubscription(`${hkPathRoot}/status`, handleChange);

  return {
    hkState: state,
  };
}
