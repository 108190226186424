import { Router } from '@reach/router';
import React from 'react';

import AutomatedTestPage from '#client-pages/debug-and-testing/services/automated-test';
import TestingFileServerPage from '#client-pages/debug-and-testing/services/file-server';
import TestingMessageLoggingPage from '#client-pages/debug-and-testing/services/message-logging';
import { TestConnectorPage, TestConnectorSetupPage } from '#client-pages/debug-and-testing/test-connectors';
import EgseConfigurationPage from '#client-pages/operations/ground-segment/egse';
import StationCatalogPage from '#client-pages/operations/ground-segment/tracking-stations/station-catalog';
import StationClustersPage from '#client-pages/operations/ground-segment/tracking-stations/station-clusters';
import StationManagementPage from '#client-pages/operations/ground-segment/tracking-stations/station-management';
import TctmProtocolPage from '#client-pages/operations/space-segment/protocols/tctm';
import SatelliteClusterPage from '#client-pages/operations/space-segment/satellites/satellite-clusters';
import SatelliteControlPage from '#client-pages/operations/space-segment/satellites/satellite-control';
import SatelliteManagementPage from '#client-pages/operations/space-segment/satellites/satellite-management';
import SpaceSegmentFileServerPage from '#client-pages/operations/space-segment/services/file-server';
import SpaceSegmentMessageLoggingPage from '#client-pages/operations/space-segment/services/message-logging';
import SpacecraftDatabasePage from '#client-pages/operations/space-segment/services/spacecraft-database';
import NonIdealState from '#components/ui-helper/NonIdealState';

const SpaceSegmentPage = () => {
  return (
    <Router>
      <SatelliteManagementPage path="satellites/satellite-management" />
      <SatelliteControlPage path="satellites/satellite-control" />
      <SatelliteClusterPage path="satellites/satellite-clusters" />
      <TctmProtocolPage path="protocols/tctm" />
      <SpaceSegmentFileServerPage path="services/file-server" />
      <SpaceSegmentMessageLoggingPage path="services/message-logging" />
      <SpacecraftDatabasePage path="services/spacecraft-database" />
    </Router>
  );
};

const GroundSegmentPage = () => {
  return (
    <Router>
      <StationManagementPage path="tracking-stations/station-management" />
      <StationCatalogPage path="tracking-stations/station-catalog" />
      <StationClustersPage path="tracking-stations/station-clusters" />
      <EgseConfigurationPage path="egse" />
    </Router>
  );
};

const DebugAndTestingPage = () => {
  return (
    <Router>
      <TestConnectorSetupPage path="test-connectors" />
      <TestConnectorPage path="test-connectors/:currentTestConnector/*" />
      <TestingFileServerPage path="services/file-server" />
      <TestingMessageLoggingPage path="services/message-logging" />
      <AutomatedTestPage path="automated-test" />
      <EgseConfigurationPage path="egse" />
    </Router>
  );
};

const MissionPage = () => {
  return (
    <Router>
      <SpaceSegmentPage path="space-segment/*" />
      <GroundSegmentPage path="ground-segment/*" />
      <DebugAndTestingPage path="debug-and-testing/*" />
    </Router>
  );
};

const MissionsPage = () => {
  return (
    <Router basepath="/missions">
      <MissionPage path="/:currentMission/*" />
      <NonIdealState default message="none" />
    </Router>
  );
};

export default MissionsPage;
