import { HeartbeatCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useMlvdsHeartbeatServiceCard from './useMlvdsHeartbeatServiceCard';

const MlvdsHeartbeatServiceCard = () => {
  const {
    enableHeartbeat,
    disableHeartbeat,
    getHeartbeatStatus,
    MlvdsHeartbeatMainRed,
    MlvdsHeartbeatAsSubsystem,
    MlvdsHeartbeatMessage,
    isMlvdsHeartbeatMessageInvalid,
    setMlvdsHeartbeatMainRed,
    setMlvdsHeartbeatAsSubsystem,
    setMlvdsHeartbeatMessage,
  } = useMlvdsHeartbeatServiceCard();
  return (
    <HeartbeatCard
      comBus="mlvds"
      enableHeartbeat={enableHeartbeat}
      disableHeartbeat={disableHeartbeat}
      getHeartbeatStatus={getHeartbeatStatus}
      HeartbeatMainRed={MlvdsHeartbeatMainRed}
      HeartbeatAsSubsystem={MlvdsHeartbeatAsSubsystem}
      HeartbeatMessage={MlvdsHeartbeatMessage}
      isHeartbeatMessageInvalid={isMlvdsHeartbeatMessageInvalid}
      setHeartbeatMainRed={setMlvdsHeartbeatMainRed}
      setHeartbeatAsSubsystem={setMlvdsHeartbeatAsSubsystem}
      setHeartbeatMessage={setMlvdsHeartbeatMessage}
    />
  );
};

export default MlvdsHeartbeatServiceCard;
