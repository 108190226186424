import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { AutoSizer, Grid } from 'react-virtualized';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  editorToolbar: {
    minHeight: 32,
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.divider,
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  editorToolbarBtn: {
    padding: 6,
  },
  editorContainer: {
    padding: theme.spacing(1),
    height: 200,
  },
  fileOpenInput: {
    display: 'none',
  },
}));

function rowColToOffset(row, col, colCount) {
  return row * colCount + col;
}

function offsetToRowCol(offset, colCount) {
  return [Math.floor(offset / colCount), offset % colCount];
}

const HexEditor = ({ octets, setOctet, setBuffer, columnCount }) => {
  const classes = useStyles();

  const editorContainer = useRef(null);
  const inputGrid = useRef(null);

  const setOctetFromHex = useCallback(
    (row, col, octetStr) => {
      setOctet(row * columnCount + col, parseInt(octetStr, 16));
    },
    [setOctet, columnCount],
  );

  const selectNeighbouringOctetInput = useCallback(
    (input, row, col, step) => {
      // Check whether we're trying to select something nonexistent
      const targetOffset = rowColToOffset(row, col, columnCount) + step;
      if (targetOffset < 0 || targetOffset >= octets.length) {
        return;
      }

      // Have the virtualized grid scroll to the target input
      // so that it has a rendered DOM node
      const [targetRow, targetCol] = offsetToRowCol(targetOffset, columnCount);
      inputGrid.current.scrollToCell({ rowIndex: targetRow, columnIndex: targetCol });

      // Find the target node relative to the currently active one and select it
      const inputs = Array.from(editorContainer.current.querySelectorAll('input'));
      inputs[inputs.indexOf(input) + step].select();
    },
    [editorContainer, inputGrid, octets.length, columnCount],
  );

  const selectPreviousOctetInput = useCallback(
    (input, row, col) => {
      selectNeighbouringOctetInput(input, row, col, -1);
    },
    [selectNeighbouringOctetInput],
  );

  const selectNextOctetInput = useCallback(
    (input, row, col) => {
      selectNeighbouringOctetInput(input, row, col, 1);
    },
    [selectNeighbouringOctetInput],
  );

  const handleTabKey = useCallback(
    (event, row, col) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        if (event.shiftKey) {
          selectPreviousOctetInput(event.target, row, col);
        } else {
          selectNextOctetInput(event.target, row, col);
        }
      }
    },
    [selectPreviousOctetInput, selectNextOctetInput],
  );

  const handleCellChange = useCallback(
    (event, row, col) => {
      const val = event.target.value;

      // Prevent accidentally writing a thrid char into a byte
      // from turning it to FF
      if (parseInt(val, 16).toString(16).length > 2) {
        return;
      }

      setOctetFromHex(row, col, val);

      // Move to the next byte when this one has both digits typed
      if (parseInt(val, 16).toString(16).length > 1) {
        selectNextOctetInput(event.target, row, col);
      }
    },
    [setOctetFromHex, selectNextOctetInput],
  );

  const handleFileOpen = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (file !== undefined) {
        file.arrayBuffer().then((buf) => setBuffer(buf));
      }
    },
    [setBuffer],
  );

  const cellRenderer = useCallback(
    ({ columnIndex, key, rowIndex, style }) => {
      const data = octets[rowIndex * columnCount + columnIndex];

      if (data !== undefined) {
        return (
          <div key={key} style={style}>
            <InputBase
              fullWidth
              style={{ fontFamily: 'monospace' }}
              value={data.toString(16).padStart(2, '0').toUpperCase()}
              onClick={(event) => {
                event.target.select();
              }}
              onKeyDown={(event) => {
                handleTabKey(event, rowIndex, columnIndex);
              }}
              onChange={(event) => handleCellChange(event, rowIndex, columnIndex)}
            />
          </div>
        );
      }
      return null;
    },
    [columnCount, octets, handleTabKey, handleCellChange],
  );

  return (
    <div className={classes.root}>
      <Toolbar className={classes.editorToolbar} variant="dense">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <input accept="*" className={classes.fileOpenInput} type="file" onChange={handleFileOpen} />
          <IconButton className={classes.editorToolbarBtn} component="span">
            <FolderOpenIcon fontSize="small" />
          </IconButton>
        </label>
      </Toolbar>
      <div ref={editorContainer} className={classes.editorContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <Grid
              ref={inputGrid}
              cellRenderer={cellRenderer}
              columnCount={columnCount}
              columnWidth={25}
              height={height}
              rowCount={Math.ceil(octets.length / columnCount)}
              rowHeight={20}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

HexEditor.propTypes = {
  octets: PropTypes.instanceOf(Uint8ClampedArray).isRequired,
  setOctet: PropTypes.func.isRequired,
  setBuffer: PropTypes.func.isRequired,
  columnCount: PropTypes.number,
};
HexEditor.defaultProps = {
  columnCount: 8,
};

export default HexEditor;
