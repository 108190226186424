import { BustesterAbsoluteTimeCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useAbsTimeCard from './useAbsTimeCard';

const AbsTimeCard = () => {
  const { setAbsoluteTime, getAbsoluteTime } = useAbsTimeCard();

  return (
    <BustesterAbsoluteTimeCard setBustesterAbsoluteTime={setAbsoluteTime} getBustesterAbsoluteTime={getAbsoluteTime} />
  );
};

export default AbsTimeCard;
