import { useMemo } from 'react';

import useSDRStatusDs from '../useSDRStatusDs';

function valid(value) {
  if (value == null || Number.isNaN(value)) {
    return null;
  }
  return value;
}

export default function useMonitor() {
  const { state: sdrStatus } = useSDRStatusDs();

  const monitorComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'Preamble Pattern',
          value: sdrStatus?.system?.preamblePattern ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Gauss Filter (Half) Length',
          value: sdrStatus?.system?.filterHalfLength ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Filter Shape Factor',
          value: sdrStatus?.system?.filterBandwidthTimeProduct ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Sample Rate',
          value: valid(1e-6 * sdrStatus?.system?.sampleRate) ?? '-',
          unit: 'MHz',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Channel Count',
          value: sdrStatus?.system?.rxChannelCount ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'TX Local Osc Freq',
          value: valid(1e-6 * sdrStatus?.txChannel?.TXLOfreq) ?? '-',
          unit: 'MHz',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'PA Gain',
          value: sdrStatus?.txChannel?.PAgain ?? '-',
          unit: 'dB',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Output Scale Factor',
          value: sdrStatus?.txChannel?.scaleFactor ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'TX Baud Rate',
          value: sdrStatus?.txChannel?.baudRate ?? '-',
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'TX Packet Interleave Time',
          value: sdrStatus?.txChannel?.packetInterleaveTime ?? '-',
          unit: 's',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Sync Word',
          value: sdrStatus?.txChannel?.syncWord ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'TX Preamble Length',
          value: sdrStatus?.txChannel?.preambleLength ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'TX Payload Length',
          value: sdrStatus?.txChannel?.payloadLength ?? '-',
          unit: 'bytes',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Local Osc Freq',
          value: valid(1e-6 * sdrStatus?.rxGeneral?.RXLOfreq) ?? '-',
          unit: 'MHz',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'LNA Gain',
          value: sdrStatus?.rxGeneral?.LNAgain ?? '-',
          unit: 'dB',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Preamble Detection Threshold',
          value: sdrStatus?.rxGeneral?.preambleDetectionThreshold ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RSSI Threshold',
          value: sdrStatus?.rxGeneral?.RSSIthreshold ?? '-',
          unit: 'dB',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Min Preamble Length',
          value: sdrStatus?.rxGeneral?.minPreambleLength ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Sync Word',
          value: sdrStatus?.rxGeneral?.syncWord ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Synchronizer Resolution',
          value: sdrStatus?.rxGeneral?.synchronizerResolution ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Synchronizer Bandwidth',
          value: sdrStatus?.rxGeneral?.synchronizerBandwidth ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Payload Length',
          value: sdrStatus?.rxGeneral?.payloadLength ?? '-',
          unit: 'bytes',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Fallback Baud Rate',
          value: sdrStatus?.rxFallback?.baudRate ?? '-',
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Fallback AFC Range',
          value: sdrStatus?.rxFallback?.AFCrange ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Nominal Baud Rate',
          value: sdrStatus?.rxNominal?.baudRate ?? '-',
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'RX Nominal AFC Range',
          value: sdrStatus?.rxNominal?.AFCrange ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Beacon CA Code ID',
          value: sdrStatus?.beacon?.CAcodeID ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Beacon Baud Rate',
          value: sdrStatus?.beacon?.baudRate ?? '-',
          unit: 'bps',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Beacon CA Threshold',
          value: sdrStatus?.beacon?.CAThreshold ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'FFT',
          value: sdrStatus?.fft?.enable ? 'Enabled' : 'Disabled',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'FFT Size',
          value: sdrStatus?.fft?.size ?? '-',
          unit: '-',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'FFT Window',
          value: sdrStatus?.fft?.window ?? '-',
          unit: '-',
        },
      },
    ],
    [sdrStatus],
  );

  return { monitorComponents };
}
