export default (uri, name) => {
  // Source: https://stackoverflow.com/a/23013574
  const link = document.createElement('a');

  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
