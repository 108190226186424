// eslint-disable-next-line import/no-cycle
import { reduceFieldState } from '.';

function checkFieldMetaInAction(action) {
  if (action.fieldId === undefined) {
    throw new Error('Field id in array reducer cannot be `undefined`');
  }
  if (action.fieldMeta === undefined) {
    throw new Error('Field metadata in array reducer cannot be `undefined`');
  }
}

function updateElementInArray(array, index, value) {
  const newArray = array?.slice() ?? [];
  newArray[index] = value;
  return newArray;
}

function performElementActionInArray(prevArrayValue, prevArrayInternalState, action) {
  const { elementAction, index } = action;
  checkFieldMetaInAction(elementAction);

  const [newValue, newInternalState] = reduceFieldState(
    prevArrayValue?.[index],
    prevArrayInternalState?.elementInternalStates?.[index],
    elementAction,
  );

  return [
    updateElementInArray(prevArrayValue, index, newValue),
    {
      ...prevArrayInternalState,
      elementInternalStates: updateElementInArray(
        prevArrayInternalState?.elementInternalStates,
        index,
        newInternalState,
      ),
    },
  ];
}

// eslint-disable-next-line import/prefer-default-export
export function reduceArrayFieldState(prevArrayValue, prevArrayInternalState, action) {
  switch (action.type) {
    case 'element-action-in-array': {
      return performElementActionInArray(prevArrayValue, prevArrayInternalState, action);
    }
    default:
      throw new Error(`Unknown array action: \`${action.type}\``);
  }
}
