import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const ConnectionIcon = ({ state }) => {
  const { successColor, errorColor } = useStyles();

  return <FontAwesomeIcon icon={['fas', 'plug']} size="lg" className={state ? successColor : errorColor} />;
};

ConnectionIcon.propTypes = {
  state: PropTypes.bool.isRequired,
};

export default ConnectionIcon;
