import { LabelAttacher } from '@c3s/ui-radcube-message-logging';
import React, { useCallback, useContext, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSnapshot from '#hooks/useDsRecordSnapshot';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';
import useSnackbar from '#hooks/useSnackbar';

export default () => {
  const { currentResGroup } = useContext(RgContext);
  const recordPath = `rg/${currentResGroup}/@c3s/log-writer-provider/labels`;
  const [lastLabelArray, setLastLabelArray] = useState([]);
  const [labelArray, setLabelArray] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const loadInitials = useCallback((record) => {
    setLastLabelArray(record);
    setLabelArray(Array.from(record).map((label) => ({ label, value: label })));
  }, []);
  useDsRecordSnapshot(recordPath, loadInitials);

  const handleChange = useCallback((newRecord) => {
    setLastLabelArray(newRecord || false);
  }, []);
  const { setRecord } = useDsRecordSubscription(recordPath, handleChange);

  function setLogLabels(content) {
    const arrayToSend = content ? labelArray?.map((label) => label.value) : [];
    setRecord(arrayToSend);
    enqueueSnackbar(content ? 'Labels set' : 'Labels cleared', { variant: 'info' });
  }

  return (
    <LabelAttacher
      labelArray={labelArray}
      lastLabelArray={Object.keys(lastLabelArray).length ? lastLabelArray : []}
      setLabelArray={setLabelArray}
      setLogLabels={setLogLabels}
    />
  );
};
