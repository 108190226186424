import { useCallback, useContext, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

export default function useTCTable() {
  const { currentResGroup } = useContext(RgContext);

  const recordPath = `skipdb/rg/${currentResGroup}/@c3s/tctm-transcoder-provider/encodedTCTM`;

  const [rows, setRows] = useState([]);

  const onRecordUpdated = useCallback((newRow) => {
    if (newRow instanceof Object) {
      setRows((prevArray) => [newRow, ...prevArray].splice(0, 30));
    }
  }, []);

  const { isAttached: isRunning, setIsAttached: setIsRunning } = useDsRecordSubscription(
    recordPath,
    onRecordUpdated,
    false,
    false,
  );

  return { rows, setRows, isRunning, setIsRunning };
}
