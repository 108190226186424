import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { getFieldDisplayTypeFromFieldMeta } from '../../../../data/MissionMeta';
// eslint-disable-next-line import/no-cycle
import Field from './Field';

const useStyles = makeStyles((theme) => ({
  arrayFieldset: {
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderWidth: 1,
    marginTop: theme.spacing(1),
  },
  arrayFields: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '16px',
  },
}));

const ArrayField = ({
  fieldId,
  fieldMeta,
  fieldValue: arrayValue,
  fieldInternalState: arrayInternalState,
  dispatchFieldAction,
  label,
}) => {
  const classes = useStyles();

  const match = fieldMeta?.ctype?.match(/(?<elementCtype>\w+)\s*\[(?<capacityStr>[0-9]+)\]/);
  const { elementCtype, capacityStr } = match?.groups ?? {};
  const capacity = parseInt(capacityStr, 10);

  const dispatchElementActionInArray = useCallback(
    (index, action) => {
      dispatchFieldAction({
        type: 'element-action-in-array',
        index,
        elementAction: action,
      });
    },
    [dispatchFieldAction],
  );

  const elementFieldMeta = {
    ...fieldMeta,
    ctype: elementCtype,
    type: getFieldDisplayTypeFromFieldMeta(fieldMeta?.enum, elementCtype),
  };

  return (
    <Box className={classes.arrayFieldset} component="fieldset">
      <Typography component="legend" variant="overline">
        {label ?? fieldMeta?.displayName}
      </Typography>
      <div className={classes.arrayFields}>
        {range(capacity).map((idx) => (
          <Field
            fieldId={fieldId}
            fieldMeta={{ ...elementFieldMeta, displayName: elementFieldMeta.displayName.replace(/array/i, idx) }}
            fieldValue={arrayValue?.[idx]}
            fieldInternalState={arrayInternalState?.elementInternalStates?.[idx]}
            dispatchFieldAction={(action) => dispatchElementActionInArray(idx, action)}
          />
        ))}
      </div>
    </Box>
  );
};

ArrayField.propTypes = {
  fieldId: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  fieldMeta: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object])).isRequired,
  fieldValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object])).isRequired,
  fieldInternalState: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]))
    .isRequired,
  dispatchFieldAction: PropTypes.func.isRequired,
  label: PropTypes.string,
};

ArrayField.defaultProps = {
  label: undefined,
};

export default ArrayField;
