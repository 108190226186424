import { _ETH_TypeDefs as ETH } from '@c3s/misc_radcube_eth_packets_enums';
import { _SSP_TypeDefs as SSP } from '@c3s/misc_radcube_ssp_packets_enums';

const ethBusSet = new Set([ETH.eth_ssp_bus_e.ETH_SSP_BUS_CAN, ETH.eth_ssp_bus_e.ETH_SSP_BUS_MLVDS]);

const sspSubSystemSet = new Set([
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_OBC_1,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_OBC_2,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_COM_1,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_COM_2,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_EPS_1,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_EPS_2,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_AUX_1,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_AUX_2,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_ADCS_1,
  SSP.ssp_subsystem_address_e.SSP_ADDRESS_RADMAG_1,
]);

const ethSetValues = ethBusSet.values();
export const defaultEth = ethSetValues.next().value;
export const defaultEthRadmag = ethSetValues.next().value;
const sspSubsystemSetValues = sspSubSystemSet.values();
export const defaultSspSrc = sspSubsystemSetValues.next().value;
export const defaultSspDst = sspSubsystemSetValues.next().value;
