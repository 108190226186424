import { MlvdsStatusCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useMlvdsHeartBeatStatusMessage from '../../hooks/useMlvdsHeartBeatStatusMessage';
import useMlvdsSyncStatusMessage from '../../hooks/useMlvdsSyncStatusMessage';

const MlvdsStatus = () => {
  const { value: MlvdsHeartBeatStatusReport } = useMlvdsHeartBeatStatusMessage().body;
  const { value: MlvdsSyncStatusReport } = useMlvdsSyncStatusMessage().body;

  return (
    <MlvdsStatusCard
      MlvdsHeartBeatStatusReport={MlvdsHeartBeatStatusReport}
      MlvdsSyncStatusReport={MlvdsSyncStatusReport}
    />
  );
};

export default MlvdsStatus;
