import { useCallback, useContext, useMemo, useReducer } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const reducer = (s, a) => {
  if (typeof a === 'function') {
    return { ...s, ...a(s) };
  }
  return { ...s, ...a };
};

const initialState = {
  windAlarm: {
    windSpeed: 0,
    autoAlarmValue: false,
    alarmValue: false,
    alarmMode: 'auto',
  },
};

export default function useAutonomyStatusDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const statusPath = useMemo(() => `rg/${currentResGroup}/@c3s/gsts-autonomy-provider/status`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    if (newRecord?.windAlarm) {
      dispatch((prevState) => ({ windAlarm: { ...prevState.windAlarm, ...newRecord.windAlarm } }));
    }
  }, []);

  useDsRecordSubscription(statusPath, handleChange);

  return {
    autonomyStatus: state,
  };
}
