import { Grid } from '@material-ui/core';
import React from 'react';

import WeatherStationMonitorTab from './WeatherStationMonitorTab';

const WeatherTab = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <WeatherStationMonitorTab />
      </Grid>
    </Grid>
  );
};

export default WeatherTab;
