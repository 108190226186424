import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import bytes from 'bytes';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DataRangeSelect from '../../../common-components/controls/DataRangeSelect';
import usePanelStyles from '../../../usePanelStyles';
import { getDiskLabel } from '../../helpers';

const DirectFileReadPanel = ({ diskId, fileId, totalFileSize, read }) => {
  const [range, setRange] = useState({ address: 0, amount: 0 });

  const classes = usePanelStyles();

  const { address, amount } = range;

  return (
    <>
      <Typography className={classes.panelTitle}>
        Direct Read from file {fileId} on {getDiskLabel(diskId)}
      </Typography>
      <DataRangeSelect range={range} totalSize={totalFileSize} setRange={setRange} />
      <Grid container className={classes.panelActionRow} spacing={2} alignItems="baseline" justify="flex-end">
        <Grid item>
          <Typography variant="body2">
            Will read {bytes.format(amount)} starting from offset {address}
            {totalFileSize && `, which is ${Math.abs((amount / totalFileSize) * 100).toPrecision(4)}% of file`}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => read(address, amount)}>
            Read
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

DirectFileReadPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  fileId: PropTypes.number.isRequired,
  totalFileSize: PropTypes.number,
  read: PropTypes.func.isRequired,
};

DirectFileReadPanel.defaultProps = {
  totalFileSize: undefined,
};

export default DirectFileReadPanel;
