import { useCallback, useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';

/**
 * Listens to a deepstream event while the enclosing component is mounted.
 *
 * @param {string} type Name of event (case-sensitive).
 * @param {import("@deepstream/client/dist/event/event-handler").EventHandler?} callback Method to execute whenever the event fires.
 */
export default function useDsEventListener(type, callback) {
  const { dsClient } = useContext(DeepstreamContext);

  const [isSubscribed, setIsSubscribed] = useState(true);

  useEffect(() => {
    if (isSubscribed) {
      dsClient.event.subscribe(type, callback);

      return () => {
        dsClient.event.unsubscribe(type, callback);
      };
    }
    return undefined;
  }, [dsClient, type, callback, isSubscribed]);

  const subscribe = useCallback(() => setIsSubscribed(true), []);
  const unsubscribe = useCallback(() => setIsSubscribed(false), []);

  return { isSubscribed, subscribe, unsubscribe, setIsSubscribed };
}
