import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useContext, useState } from 'react';

import PacketBuilder from '#components/page-content/packet-builder/PacketBuilder';
import RgContext from '#contexts/RgContext';
import styles from '#utils/styles';

import ETHFrameBuilder from './subcomponents/ETHFrameBuilder';
import SSPFrameBuilder from './subcomponents/SSPFrameBuilder';

const useStyles = makeStyles(styles);

const TCPacketBuilder = () => {
  const classes = useStyles();

  const { tcProtocolStack, tctmCreateFunction } = useContext(RgContext);

  const [ethFrame, setEthFrame] = useState({ Bus: 0 });
  const [sspFrame, setSspFrame] = useState({
    Src: 10,
    Dst: 26,
    Type: 64,
  });

  const mockRecordForETH = {
    get: () => ethFrame,
    set: setEthFrame,
  };

  const mockRecordForSSP = {
    get: () => sspFrame,
    set: setSspFrame,
  };

  const handlePacketSubmit = useCallback(
    (TCFrame) => {
      const structuredMessage = {
        ...ethFrame,
        SSPFrame: {
          ...sspFrame,
          Data: {
            TCFrame,
          },
        },
      };
      tctmCreateFunction(structuredMessage, 'Telecommand sent');
    },
    [ethFrame, sspFrame, tctmCreateFunction],
  );

  return (
    <Accordion square defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="packet-builder-header">
        <Typography variant="h6">Telecommand Packet Builder</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {tcProtocolStack.map((p) => p.toLowerCase()).includes('eth') && (
          <ETHFrameBuilder classes={classes} Frame={ethFrame} dsRecord={mockRecordForETH} />
        )}
        {tcProtocolStack.map((p) => p.toLowerCase()).includes('ssp') && (
          <SSPFrameBuilder classes={classes} Frame={sspFrame} dsRecord={mockRecordForSSP} />
        )}
        <Box mt={2}>
          {/* TODO change this when packets have struct metas */}
          <PacketBuilder
            packetId={tcProtocolStack.filter((p) => !/(eth|ssp)/i.test(p))[0]}
            submitPacket={handlePacketSubmit}
            submitLabel="Send Single Command"
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TCPacketBuilder;
