import { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

export default function useDebugLedCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/functionManagement`;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const switchDebugLed = (ledState) => {
    processRpcResponse({
      endpoint: `${rpcUrl}/switchDebugLed`,
      paramsObject: { switchDebugLED: ledState },
      snackbarMessage: 'Switch Debug Led requested',
    });
  };
  const turnDebugLedOn = () => switchDebugLed(1);
  const turnDebugLedOff = () => switchDebugLed(0);

  return { turnDebugLedOff, turnDebugLedOn };
}
