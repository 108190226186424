import { PowerStatusCard } from '@c3s/ui-radcube-connector-bustester';
import React, { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';

import useCanHeartBeatStatusMessage from '../../hooks/useCanHeartBeatStatusMessage';
import useLclAndPowerStatusMessage from '../../hooks/useLclAndPowerStatusMessage';
import lclCoeffs from './lclCoefficients';

const PowerStatus = () => {
  const { value: CanHeartBeatStatusReport } = useCanHeartBeatStatusMessage().body;
  const { value: LclAndPowerStatusReport } = useLclAndPowerStatusMessage().body;
  const {
    clientData: { userName },
    dsClient,
  } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const [dutRole, setDutRole] = useState(2);

  const lclA = lclCoeffs(
    dutRole,
    'LCL_A',
    LclAndPowerStatusReport.LCL_A_VOLT_ACT,
    LclAndPowerStatusReport.LCL_A_CUR_ACT,
  );
  const lclB = lclCoeffs(
    dutRole,
    'LCL_B',
    LclAndPowerStatusReport.LCL_B_VOLT_ACT,
    LclAndPowerStatusReport.LCL_B_CUR_ACT,
  );
  const lclC = lclCoeffs(
    dutRole,
    'LCL_C',
    LclAndPowerStatusReport.LCL_C_VOLT_ACT,
    LclAndPowerStatusReport.LCL_C_CUR_ACT,
  );

  const dsPath = `username/${userName}/rg/${currentResGroup}/@c3s/moc-ui/debug/bustester/control/role`;
  const dsRecord = dsClient.record.getRecord(dsPath);

  function dsSubscription(newRecord) {
    setDutRole(newRecord?.dutRole ?? 2);
  }

  useEffect(() => {
    dsRecord.subscribe(dsSubscription, true);
    return function cleanup() {
      dsRecord.discard();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PowerStatusCard
      LclAndPowerStatusReport={LclAndPowerStatusReport}
      lclA={lclA}
      lclB={lclB}
      lclC={lclC}
      CanHeartBeatStatusReport={CanHeartBeatStatusReport}
    />
  );
};

export default PowerStatus;
