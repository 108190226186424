import { useCallback, useContext, useMemo, useReducer } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const reducer = (s, a) => {
  if (typeof a === 'function') {
    return { ...s, ...a(s) };
  }
  return { ...s, ...a };
};

const initialState = {
  lastUpdated: 0,
  isPinging: false,
  isAlive: false,
  isPolling: false,
  isUpdating: false,
  isConnected: false,

  PAOutputPower: 0,
  RFMode: 'OFF',
  SWRLastForwardPower: 0,
  SWRLastReversePower: 0,
  version: {
    hardwareId: '',
    deviceId: '',
    softwareId: '',
  },
  networkConfig: {
    IP: '10.15.100.4',
    port: 10001,
  },
  lastPacketPower: 0,
  SWRActualPacket: 0,
  SWRLastPacket: 0,
  PACurrent: 0,
  PATemperature: 0,
  PAFanMode: 'AUTO',
  isPAFanPowered: false,
  PAFanCurrent: 0,
};

const epsilon = 6000;

export default function useRffeDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const rffePath = useMemo(() => `rg/${currentResGroup}/@c3s/rf-fe-provider`, [currentResGroup]);

  const handleRffeChange = useCallback((newRecord) => {
    if ('lastUpdated' in newRecord) dispatch({ lastUpdated: newRecord.lastUpdated });
    if ('isPinging' in newRecord) dispatch({ isPinging: newRecord.isPinging });
    if ('isAlive' in newRecord) dispatch({ isAlive: newRecord.isAlive });
    if ('isPolling' in newRecord) dispatch({ isPolling: newRecord.isPolling });
    if ('isUpdating' in newRecord) dispatch({ isUpdating: newRecord.isUpdating });
    if ('isConnected' in newRecord) dispatch({ isConnected: newRecord.isConnected });
    if ('boardTemperatureLimit' in newRecord) dispatch({ boardTemperatureLimit: newRecord.boardTemperatureLimit });
    if ('PATemperatureLimit' in newRecord) dispatch({ PATemperatureLimit: newRecord.PATemperatureLimit });
    if ('PACoolingTime' in newRecord) dispatch({ PACoolingTime: newRecord.PACoolingTime });
    if ('SWRLastForwardPower' in newRecord) dispatch({ SWRLastForwardPower: newRecord.SWRLastForwardPower });
    if ('SWRLastReversePower' in newRecord) dispatch({ SWRLastReversePower: newRecord.SWRLastReversePower });
    if ('version' in newRecord) dispatch((prevState) => ({ version: { ...prevState.version, ...newRecord.version } }));
    if ('PAOutputPower' in newRecord) dispatch({ PAOutputPower: newRecord.PAOutputPower });
    if ('lastPacketPower' in newRecord) dispatch({ lastPacketPower: newRecord.lastPacketPower });
    if ('SWRActualPacket' in newRecord) dispatch({ SWRActualPacket: newRecord.SWRActualPacket });
    if ('SWRLastPacket' in newRecord) dispatch({ SWRLastPacket: newRecord.SWRLastPacket });
    if ('PACurrent' in newRecord) dispatch({ PACurrent: newRecord.PACurrent });
    if ('PATemperature' in newRecord) dispatch({ PATemperature: newRecord.PATemperature });
    if ('PAFanMode' in newRecord) dispatch({ PAFanMode: newRecord.PAFanMode });
    if ('isPAFanPowered' in newRecord) dispatch({ isPAFanPowered: newRecord.isPAFanPowered });
    if ('PAFanCurrent' in newRecord) dispatch({ PAFanCurrent: newRecord.PAFanCurrent });
    if ('RFMode' in newRecord) dispatch({ RFMode: newRecord.RFMode });
    if ('networkConfig' in newRecord)
      dispatch((prevState) => ({ networkConfig: { ...prevState.networkConfig, ...newRecord.networkConfig } }));
  }, []);

  useDsRecordSubscription(`${rffePath}/status`, handleRffeChange);

  return {
    rffeState: state,
    rffePath,
    noResponse: Date.now() - state.lastUpdated > epsilon,
  };
}
