import useProtocolMessage from '#hooks/useProtocolMessage';

import useGpioExpReportEventListener from './useGpioExpReportEventListener';

export default function useGpioExpReportMessage() {
  return useProtocolMessage({
    listener: useGpioExpReportEventListener,
    transform: ({
      LCL_01_EN,
      LCL_02_EN,
      LCL_03_EN,
      LCL_04_EN,
      LCL_05_EN,
      LCL_06_EN,
      LCL_07_EN,
      LCL_08_EN,
      LCL_09_EN,
      LCL_10_EN,
      LCL_11_EN,
      LCL_12_EN,
      DSW_01,
      DSW_02,
      RBF,
    }) => ({
      SID: -1,
      value: {
        LCL_01_EN,
        LCL_02_EN,
        LCL_03_EN,
        LCL_04_EN,
        LCL_05_EN,
        LCL_06_EN,
        LCL_07_EN,
        LCL_08_EN,
        LCL_09_EN,
        LCL_10_EN,
        LCL_11_EN,
        LCL_12_EN,
        DSW_01,
        DSW_02,
        RBF,
      },
    }),
    initialBody: {
      SID: -1,
      value: {
        LCL_01_EN: {
          SID: -1,
          value: 0,
        },
        LCL_02_EN: {
          SID: -1,
          value: 0,
        },
        LCL_03_EN: {
          SID: -1,
          value: 0,
        },
        LCL_04_EN: {
          SID: -1,
          value: 0,
        },
        LCL_05_EN: {
          SID: -1,
          value: 0,
        },
        LCL_06_EN: {
          SID: -1,
          value: 0,
        },
        LCL_07_EN: {
          SID: -1,
          value: 0,
        },
        LCL_08_EN: {
          SID: -1,
          value: 0,
        },
        LCL_09_EN: {
          SID: -1,
          value: 0,
        },
        LCL_10_EN: {
          SID: -1,
          value: 0,
        },
        LCL_11_EN: {
          SID: -1,
          value: 0,
        },
        LCL_12_EN: {
          SID: -1,
          value: 0,
        },
        DSW_01: {
          SID: -1,
          value: 0,
        },
        DSW_02: {
          SID: -1,
          value: 0,
        },
        RBF: {
          SID: -1,
          value: 0,
        },
      },
    },
  });
}
