import React from 'react';
import { Helmet } from 'react-helmet';

import AccessPortPageWrapper from '#components/page-content/access-port/AccessPortPageWrapper';
import GeneralTab from '#connectors/access-port/control-tabpanel/GeneralTab';

const PageTitle = 'Access Port Configuration';

const AccessPortConfigurationPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <AccessPortPageWrapper>
        <GeneralTab />
      </AccessPortPageWrapper>
    </>
  );
};

export default AccessPortConfigurationPage;
