import Grid from '@material-ui/core/Grid';
import React from 'react';

import RffeControlCard from './RffeControlCard';
import RffeMonitorTab from './RffeMonitorTab';
import RffeStatusCard from './RffeStatusCard';

const RffeTab = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <RffeMonitorTab />
    </Grid>
    <Grid item xs={12}>
      <RffeStatusCard />
    </Grid>
    <Grid item xs={12}>
      <RffeControlCard />
    </Grid>
  </Grid>
);

export default RffeTab;
