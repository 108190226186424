import { Grid } from '@material-ui/core';
import React from 'react';

import CanSyncServiceCard from './Cards/CanSync';

const CanSyncTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <CanSyncServiceCard />
    </Grid>
  </Grid>
);

export default CanSyncTab;
