import printf from 'printf';
import { useMemo } from 'react';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useRffeDs from '../useRffeDs';

export default function usePAConstants() {
  const { rffeState } = useRffeDs();
  const { autonomyConfig } = useAutonomyConfigDs();

  const paConstantComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'Board Temp Limit:',
          value: autonomyConfig.RFFEControl.boardTemperatureLimit
            ? printf('%d', autonomyConfig.RFFEControl.boardTemperatureLimit)
            : '-',
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'PA Temp Limit:',
          value: autonomyConfig.RFFEControl.PATemperatureLimit
            ? printf('%d', autonomyConfig.RFFEControl.PATemperatureLimit)
            : '-',
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'PA Cooling Time:',
          value: autonomyConfig.RFFEControl.PACoolingTime
            ? printf('%d', autonomyConfig.RFFEControl.PACoolingTime)
            : '-',
          unit: 'min',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Last Forw. Power:',
          value: printf('%.01f', rffeState.SWRLastForwardPower),
          unit: 'W',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Last Reverse Power:',
          value: printf('%.01f', rffeState.SWRLastReversePower),
          unit: 'W',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Hardware ID:',
          value: rffeState.version.hardwareId,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Device ID:',
          value: rffeState.version.deviceId,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Software ID:',
          value: rffeState.version.softwareId,
        },
      },
    ],
    [
      autonomyConfig.RFFEControl.PACoolingTime,
      autonomyConfig.RFFEControl.PATemperatureLimit,
      autonomyConfig.RFFEControl.boardTemperatureLimit,
      rffeState.SWRLastForwardPower,
      rffeState.SWRLastReversePower,
      rffeState.version.deviceId,
      rffeState.version.hardwareId,
      rffeState.version.softwareId,
    ],
  );

  return { paConstantComponents };
}
