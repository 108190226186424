import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import { diskIDOptions, fsFormatModeOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const FormatFilesystemPanel = ({ diskId, format }) => {
  const [formatMode, setFormatMode] = useState(TCTM.tctm_fs_format_mode_e.TCTM_FS_FORMAT_NORMAL);

  return (
    <>
      <Typography>Format Filesystem on {diskIDOptions.find((option) => option.value === diskId).label}</Typography>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item>
          <FormControl margin="dense">
            <InputLabel id="fs-mgmt-format-mode-label">Mode</InputLabel>
            <Select
              id="fs-mgmt-format-mode-select"
              labelId="fs-mgmt-format-mode-label"
              value={formatMode}
              onChange={(event) => setFormatMode(event.target.value)}
            >
              {fsFormatModeOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl margin="dense">
            <Button variant="contained" color="secondary" onClick={() => format(formatMode)}>
              Format
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

FormatFilesystemPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  format: PropTypes.func.isRequired,
};

export default FormatFilesystemPanel;
