import React from 'react';
import { Helmet } from 'react-helmet';

import EgsePageWrapper from '#components/page-content/egse/EgsePageWrapper';
import General from '#connectors/egse/General';

const PageTitle = 'EGSE Control';

const EgseConfigurationPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <EgsePageWrapper>
        <General />
      </EgsePageWrapper>
    </>
  );
};

export default EgseConfigurationPage;
