import useProtocolMessage from '#hooks/useProtocolMessage';

import useBusConfigEventListener from './useBusConfigEventListener';

export default function useBusConfigMessage() {
  return useProtocolMessage({
    listener: useBusConfigEventListener,

    // TODO: GSMOC-961, GSMOC-963
    transform: ({
      CAN_ABS_TIME_DIST_SERV_FILTER_EN,
      CAN_BUS_MNG_SERV_FILTER_EN,
      CAN_DATA_STREAM_SERV_FILTER_EN,
      CAN_DEV_CMD_SERV_FILTER_EN,
      CAN_NODE_MNG_SERV_FILTER_EN,
      CAN_STREAM_SERV_FCU_EN,
      CAN_SYNC_MSG_SERV_FILTER_EN,
      CAN_TCTM_STREAM_SERV_FILTER_EN,
      CAN_TIME_SYNC_SERV_FILTER_EN,
      OBC_COM_DIR,
      OBC_COM_FCU_EN,
      OBC_PL_FCU_EN,
      ActiveCanBus,
      ActiveComMlvdsBus,
      ActivePayloadMlvdsBus,
      /* eslint-disable camelcase */
      can_config,
      mlvds_obc_com_config,
      mlvds_obc_pl_config,
      /* eslint-enable camelcase */
    }) => ({
      SID: -1,
      value: {
        CAN_ABS_TIME_DIST_SERV_FILTER_EN,
        CAN_BUS_MNG_SERV_FILTER_EN,
        CAN_DATA_STREAM_SERV_FILTER_EN,
        CAN_DEV_CMD_SERV_FILTER_EN,
        CAN_NODE_MNG_SERV_FILTER_EN,
        CAN_SYNC_MSG_SERV_FILTER_EN,
        CAN_TCTM_STREAM_SERV_FILTER_EN,
        CAN_TIME_SYNC_SERV_FILTER_EN,
        CAN_STREAM_SERV_FCU_EN,
        OBC_COM_DIR,
        OBC_COM_FCU_EN,
        OBC_PL_FCU_EN,
        ActiveCanBus,
        ActiveComMlvdsBus,
        ActivePayloadMlvdsBus,
        can_config,
        mlvds_obc_com_config,
        mlvds_obc_pl_config,
      },
    }),

    initialBody: {
      SID: -1,
      value: {
        CAN_ABS_TIME_DIST_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_BUS_MNG_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_DATA_STREAM_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_DEV_CMD_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_NODE_MNG_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_SYNC_MSG_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_TCTM_STREAM_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_TIME_SYNC_SERV_FILTER_EN: {
          SID: -1,
          value: 0,
        },
        CAN_STREAM_SERV_FCU_EN: {
          SID: -1,
          value: 0,
        },
        OBC_COM_DIR: {
          SID: -1,
          value: 0,
        },
        OBC_COM_FCU_EN: {
          SID: -1,
          value: 0,
        },
        OBC_PL_FCU_EN: {
          SID: -1,
          value: 0,
        },
        ActiveCanBus: {
          SID: -1,
          value: 0,
        },
        ActiveComMlvdsBus: {
          SID: -1,
          value: 0,
        },
        ActivePayloadMlvdsBus: {
          SID: -1,
          value: 0,
        },
        can_config: {
          SID: -1,
          value: {},
        },
        mlvds_obc_com_config: {
          SID: -1,
          value: {},
        },
        mlvds_obc_pl_config: {
          SID: -1,
          value: {},
        },
      },
    },
  });
}
