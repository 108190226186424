import { Router } from '@reach/router';
import React from 'react';

import RfLinkConfigurationPage from './configuration';
import TctmProtocolPage from './protocols/tctm';

const RfLinkPage = () => {
  return (
    <Router>
      <Router path="protocols">
        <TctmProtocolPage path="tctm" />
      </Router>

      <RfLinkConfigurationPage path="configuration" />
    </Router>
  );
};

export default RfLinkPage;
