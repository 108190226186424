module.exports = {
  antennaHeaterControl: {
    tempHighLimit: null,
    tempLowLimit: null,
    manualValue: false,
    mode: 'manual',
  },
  cabinetFanControl: {
    tempFanLimit: null,
    manualValue: false,
    mode: 'manual',
  },
  cabinetHeaterControl: {
    tempHighLimit: null,
    tempLowLimit: null,
    manualValue: false,
    mode: 'manual',
  },
  cabinetCoolingControl: {
    tempHighLimit: null,
    tempLowLimit: null,
    manualValue: false,
    mode: 'manual',
  },
  mastLampPower: {
    manualValue: false,
    mode: 'manual',
  },
  motorControl: {
    flipEnable: false,
    lastUpdated: 0,
    azParkPosition: null,
    elParkPosition: null,
    azMode: 'park',
    elMode: 'park',
    azManualPosition: null,
    elManualPosition: null,
  },
  RFFEControl: {
    PAPowerManualValue: 0,
    PAPowerMode: 'auto',
    rxTxMode: 'auto',
    rxTxAutoValue: 'off',
    rxTxManualValue: 'off',
    fanMode: 'auto',
    fanManualValue: false,
    PACoolingTime: null,
    PATemperatureLimit: null,
    boardTemperatureLimit: null,
  },
  RFFEPower: {
    powerOffRequest: false,
    manualValue: false,
    mode: 'manual',
    RFAttenValue: false,
  },
  servoPower: {
    mode: 'manual',
    lastUpdated: 0,
    manualValue: false,
  },
  windAlarm: {
    alarmMode: 'manual',
    manualAlarmValue: 0,
    windLimit: 0,
    hysteresisTime: 0,
    windType: 'act',
    alarmValue: 0,
    autoAlarmValue: 0,
    windSpeed: 0,
  },
  SDRCarrierControl: {
    uplink: {
      nominalCarrier: 0,
      offsetValue: 0,
      TLEMode: 'off',
      AFCMode: 'off',
    },
    downlink: {
      nominalCarrier: 0,
      offsetValue: 0,
      TLEMode: 'off',
      AFCMode: 'off',
    },
  },
  SDRBaudRateControl: {
    uplink: {
      mode: 'manual',
      manualValue: 0,
    },
    downlink: {
      RX1OutputValue: 0,
      RX2Mode: 'manual',
      RX2ManualValue: 0,
    },
    corrFactor: 1,
  },
  SDRConfig: {
    RXFallbackBaudRate: 0,
    LNAgain: 0,
    BeaconCACode: 0,
  },
  ITURules: [],
};
