import useProtocolMessage from '#hooks/useProtocolMessage';

import useNodeManagementEventListener from './useNodeManagementEventListener';

export default function useNodeManagementMessage() {
  return useProtocolMessage({
    listener: useNodeManagementEventListener,
    initialBody: {
      Identifier: 0,
      Data: {
        codeExState: { SID: -1, value: 0 },
        relativeTime: { SID: -1, value: 0 },
        eventFIFOcntr: { SID: -1, value: 0 },
        daqFIFOcntr: { SID: -1, value: 0 },
        status: { SID: -1, value: 0 },
      },
    },
  });
}
