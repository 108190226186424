import { useEffect, useRef, useState } from 'react';

import useDsRecord from './useDsRecord';

export default function useRole() {
  const [mockRole, setMockRole] = useState(1);
  const [dutRole, setDutRole] = useState(3);
  const [activeBus, setActiveBus] = useState('can');

  const dsRecord = useDsRecord('bustester', 'control/role');

  const dsSubscription = useRef((newRecord) => {
    setMockRole(newRecord?.mockRole ?? 1);
    setDutRole(newRecord?.dutRole ?? 3);
    setActiveBus(newRecord?.activeBus || 'can');
  });

  useEffect(() => {
    dsRecord.subscribe(dsSubscription.current, true);
    return function cleanup() {
      dsRecord.discard();
    };
  }, []);

  return { mockRole, dutRole, activeBus };
}
