import { grey, yellow } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f5f5f5',
    },
    grey: {
      main: grey[500],
    },
    yellow: {
      main: yellow[500],
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 0,
        fontSize: '0.75rem',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '0.875rem',
      },
      h6: {
        fontSize: '1rem',
        textTransform: 'unset',
        color: 'inherit',
      },
      caption: {
        fontSize: '0.65rem',
        lineHeight: 1,
      },
    },
    MuiButton: {
      outlined: {
        padding: '2px 8px',
        fontSize: '0.7rem',
        borderRadius: 'unset',
      },
    },
    MuiInputBase: {
      input: {
        padding: '2px 0 2px',
        fontSize: '0.75rem',
      },
    },
  },
});

export default theme;
