import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

const SatelliteFileOperationTab = ({ value, panelType, fileId, close, ...otherTabProps }) => {
  return (
    <Tab
      value={value}
      label={
        <Grid container alignItems="baseline">
          <Grid item>
            {panelType} for file {fileId}
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              component="span"
              onClick={(event) => {
                event.stopPropagation(); // Don't let the click select the tab we're closing
                close();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      }
      {...otherTabProps}
    />
  );
};
SatelliteFileOperationTab.propTypes = {
  value: PropTypes.number.isRequired,
  panelType: PropTypes.string.isRequired,
  fileId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

export default SatelliteFileOperationTab;
