import {
  TCTM_RADMAG_08_0C,
  TCTM_RADMAG_08_30,
  TCTM_RADMAG_08_31,
  TCTM_RADMAG_08_32,
  TCTM_RADMAG_08_34,
} from '@c3s/ui-radcube-protocol-tctm';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useToggle } from 'standard-hooks';

import CardHeader from '#components/ui-helper/CardHeaderWithStatus';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import useThrottle from '#hooks/useThrottle';
import styles from '#utils/styles';

import { initialState, reducer, setObcTm } from './FunctionManagementService.helpers';

const useStyles = makeStyles(styles);

const FunctionManagementService = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, newSetValue] = useThrottle(500, [state, dispatch]);

  const setObcTelemetryWithRef = useRef((val) => setObcTm({ telemetry: val, dispatch: newSetValue, enqueueSnackbar }));
  const subscribeToTelemetry = setObcTelemetryWithRef.current;

  const [open, toggleOpen] = useToggle(false);

  const { currentResGroup } = useContext(RgContext);
  const { dsClient } = useContext(DeepstreamContext);

  useEffect(() => {
    dsClient.event.subscribe(`rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`, subscribeToTelemetry);

    return function cleanup() {
      dsClient.event.unsubscribe(
        `rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`,
        subscribeToTelemetry,
      );
    };
  }, [currentResGroup, subscribeToTelemetry, dsClient]);

  return (
    <Accordion square expanded={open} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleOpen}>
        <Typography variant="h6">Function Management Service</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={3}>
            <Card>
              <CardHeader
                error={value.err_08_0C.status}
                lastUpdated={value.err_08_0C.lastValidTimestamp}
                errorMsg={value.err_08_0C.Msg}
                titleText="TCTM_RADMAG_08_0C"
              />
              <CardContent>
                <TCTM_RADMAG_08_0C status={value.TM_08_0C} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Card>
              <CardHeader
                error={value.err_08_30.status}
                lastUpdated={value.err_08_30.lastValidTimestamp}
                errorMsg={value.err_08_30.Msg}
                titleText="TCTM_RADMAG_08_30"
              />
              <CardContent>
                <TCTM_RADMAG_08_30 status={value.TM_08_30} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Card>
              <CardHeader
                error={value.err_08_32.status}
                lastUpdated={value.err_08_32.lastValidTimestamp}
                errorMsg={value.err_08_32.Msg}
                titleText="TCTM_RADMAG_08_32"
              />
              <CardContent>
                <TCTM_RADMAG_08_32 status={value.TM_08_32} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Card>
              <CardHeader
                error={value.err_08_34.status}
                lastUpdated={value.err_08_34.lastValidTimestamp}
                errorMsg={value.err_08_34.Msg}
                titleText="TCTM_RADMAG_08_34"
              />
              <CardContent>
                <TCTM_RADMAG_08_34 status={value.TM_08_34} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader
                error={value.err_08_31.status}
                lastUpdated={value.err_08_31.lastValidTimestamp}
                errorMsg={value.err_08_31.Msg}
                titleText="TCTM_RADMAG_08_31"
              />
              <CardContent>
                <TCTM_RADMAG_08_31 status={value.TM_08_31} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FunctionManagementService;
