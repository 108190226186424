import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums'; // eslint-disable-line camelcase
/* eslint-disable camelcase */
import {
  validate_TCTM_EPS_09_0C,
  validate_TCTM_EPS_09_0F,
  validate_TCTM_EPS_09_03,
  validate_TCTM_EPS_09_06,
  validate_TCTM_EPS_09_09,
  validate_TCTM_EPS_09_12,
  validate_TCTM_EPS_09_15,
  validate_TCTM_EPS_09_18,
} from '@c3s/ui-radcube-protocol-tctm';
import printf from 'printf';

const ParameterSubServiceNumberSet = new Set([
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_BAT_BALANCER_MIN_CELL_VOLTAGE_REPORT,
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_BAT_BALANCER_ENABLE_DELTA_VOLTAGE_REPORT,
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_BAT_BALANCER_DISABLE_DELTA_VOLTAGE_REPORT,
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_BAT_HEATER_HYSTERESIS_LIMITS_REPORT,
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_BAT_HEATER_MAX_HEAT_POWER_REPORT,
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_BAT_HEATER_CHANNEL_TABLE_REPORT,
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_MPPTX_CELL_FIX_VOLTAGE_REPORT,
  TCTM.parameter_management_subservice_eps_e.PAR_EPS_MPPTX_MAX_CHARGE_CURRENT_REPORT,
]);

const getValues = (tmData) => {
  const retObj = {};
  Object.entries(tmData).forEach(([key, val]) => {
    retObj[key] = val.value;
  });
  return retObj;
};

export const setObcTm = ({ telemetry, dispatch, enqueueSnackbar }) => {
  // eslint-disable-next-line no-unused-expressions
  if (
    telemetry.json.Packet.APID === TCTM.apid_e.APID_EPS &&
    telemetry.json.Packet.Service === TCTM.service_e.SERVICE_PARAMETER_MANAGEMENT
  ) {
    const {
      json: {
        Packet: { SubService, Data },
      },
    } = telemetry;

    // eslint-disable-next-line no-unused-expressions
    ParameterSubServiceNumberSet.has(SubService)
      ? dispatch({
          type: `set${printf('%02X', telemetry.json.Packet.Service)}_${printf('%02X', SubService)}`,
          value: Data,
        })
      : enqueueSnackbar(`Unknown Subservice number at TestService.jsx: ${telemetry.json.Packet}`, {
          variant: 'error',
        });
  }
};

export const initialState = {
  TM_09_03: {},
  err_09_03: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_09_06: {},
  err_09_06: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_09_09: {},
  err_09_09: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_09_0C: {},
  err_09_0C: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_09_0F: {},
  err_09_0F: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_09_12: {},
  err_09_12: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_09_15: {},
  err_09_15: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_09_18: {},
  err_09_18: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
};

export const reducer = (state, action) => {
  const relevantValues = getValues(action.value);
  switch (action.type) {
    case 'set09_03':
      try {
        const validatedValues = validate_TCTM_EPS_09_03(relevantValues);
        return {
          ...state,
          TM_09_03: validatedValues.value,
          err_09_03: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_03: { ...state.err_09_03, status: 'err', Msg: err } };
      }
    case 'set09_06':
      try {
        const validatedValues = validate_TCTM_EPS_09_06(relevantValues);
        return {
          ...state,
          TM_09_06: validatedValues.value,
          err_09_06: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_06: { ...state.err_09_06, status: 'err', Msg: err } };
      }
    case 'set09_09':
      try {
        const validatedValues = validate_TCTM_EPS_09_09(relevantValues);
        return {
          ...state,
          TM_09_09: validatedValues.value,
          err_09_09: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_09: { ...state.err_09_09, status: 'err', Msg: err } };
      }
    case 'set09_0C':
      try {
        const validatedValues = validate_TCTM_EPS_09_0C(relevantValues);
        return {
          ...state,
          TM_09_0C: validatedValues.value,
          err_09_0C: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_0C: { ...state.err_09_0C, status: 'err', Msg: err } };
      }
    case 'set09_0F':
      try {
        const validatedValues = validate_TCTM_EPS_09_0F(relevantValues);
        return {
          ...state,
          TM_09_0F: validatedValues.value,
          err_09_0F: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_0F: { ...state.err_09_0F, status: 'err', Msg: err } };
      }
    case 'set09_12':
      try {
        const validatedValues = validate_TCTM_EPS_09_12(relevantValues);
        return {
          ...state,
          TM_09_12: validatedValues.value,
          err_09_12: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_12: { ...state.err_09_12, status: 'err', Msg: err } };
      }
    case 'set09_15':
      try {
        const validatedValues = validate_TCTM_EPS_09_15(relevantValues);
        return {
          ...state,
          TM_09_15: validatedValues.value,
          err_09_15: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_15: { ...state.err_09_15, status: 'err', Msg: err } };
      }
    case 'set09_18':
      try {
        const validatedValues = validate_TCTM_EPS_09_18(relevantValues);
        return {
          ...state,
          TM_09_18: validatedValues.value,
          err_09_18: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_09_18: { ...state.err_09_18, status: 'err', Msg: err } };
      }
    default:
      throw new Error();
  }
};
