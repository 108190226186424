import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const BooleanField = ({ value, setValue, ...otherInputProps }) => {
  const handleChange = useCallback(
    (event) => {
      setValue(event.target.checked);
    },
    [setValue],
  );

  return <FormControlLabel control={<Checkbox value={value} onChange={handleChange} />} {...otherInputProps} />;
};

BooleanField.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
};

BooleanField.defaultProps = {
  value: undefined,
};

export default BooleanField;
