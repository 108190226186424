import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useMemo } from 'react';

import useMotorCtrlElDs from '../useMotorCtrlElDs';

const motionOptions = new Map();
const faultOptions = new Map();
const warningOptions = new Map();
const errLimitOptions = new Map();
const warningLimitOptions = new Map();

export default function useServoElStatus() {
  const theme = useTheme();
  const { motorCtrlEl, noResponseEl } = useMotorCtrlElDs();

  motionOptions.set(0, { bgColor: theme.palette.success.main, label: 'STOPPED - 0', color: 'white' });
  motionOptions.set(1, { bgColor: theme.palette.yellow.main, label: 'MOTION - 1', color: 'black' });
  motionOptions.set(2, { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });
  faultOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  faultOptions.set(1, { bgColor: theme.palette.error.main, label: 'FAULT - 1', color: 'white' });
  faultOptions.set(2, { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });
  warningOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  warningOptions.set(1, { bgColor: theme.palette.yellow.main, label: 'WARNING - 1', color: 'black' });
  warningOptions.set(2, { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });
  errLimitOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  errLimitOptions.set(1, { bgColor: theme.palette.error.main, label: 'LIMIT - 1', color: 'white' });
  errLimitOptions.set(2, { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });
  warningLimitOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  warningLimitOptions.set(1, { bgColor: theme.palette.yellow.main, label: 'LIMIT - 1', color: 'black' });
  warningLimitOptions.set(2, { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });

  const getValue = useCallback(
    (val) => {
      if (noResponseEl) {
        return 2;
      }
      return Number(val ?? 0);
    },
    [noResponseEl],
  );

  const servoElStatusComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'In motion:',
          value: getValue(motorCtrlEl.status.USER_STATUS.IN_MOTION),
          ValueComponent: GeneralStatusIndicator,
          options: motionOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Internal Fault:',
          value: getValue(motorCtrlEl.status.USER_STATUS.INTERNAL_FAULT),
          ValueComponent: GeneralStatusIndicator,
          options: faultOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Internal Warning:',
          value: getValue(motorCtrlEl.status.USER_STATUS.INTERNAL_WARNING),
          ValueComponent: GeneralStatusIndicator,
          options: warningOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'LSW Positive Hard:',
          value: getValue(motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_POS_HARD),
          ValueComponent: GeneralStatusIndicator,
          options: errLimitOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'LSW Positive Soft:',
          value: getValue(motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_POS_SOFT),
          ValueComponent: GeneralStatusIndicator,
          options: warningLimitOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'LSW Negative Hard:',
          value: getValue(motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_NEG_HARD),
          ValueComponent: GeneralStatusIndicator,
          options: errLimitOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'LSW Negative Soft:',
          value: getValue(motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_NEG_SOFT),
          ValueComponent: GeneralStatusIndicator,
          options: warningLimitOptions,
        },
      },
    ],
    [
      getValue,
      motorCtrlEl.status.USER_STATUS.INTERNAL_FAULT,
      motorCtrlEl.status.USER_STATUS.INTERNAL_WARNING,
      motorCtrlEl.status.USER_STATUS.IN_MOTION,
      motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_NEG_HARD,
      motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_NEG_SOFT,
      motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_POS_HARD,
      motorCtrlEl.status.USER_STATUS.LIMIT_SWITCH_POS_SOFT,
    ],
  );

  return { servoElStatusComponents, errors: motorCtrlEl?.error ?? [], warnings: motorCtrlEl?.warning ?? [] };
}
