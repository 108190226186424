import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditAttributesOutlinedIcon from '@material-ui/icons/EditAttributesOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PropTypes from 'prop-types';
import React from 'react';

const SatelliteFileOperationButtons = ({ fileId, openFileOperationPanel }) => {
  return (
    <>
      <Tooltip title="Get File Report">
        <span>
          <IconButton
            aria-label="Get File Report"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('get-report', fileId)}
            disabled={fileId === null}
          >
            <InfoOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Get File Checksum">
        <span>
          <IconButton
            aria-label="Get Checksum"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('get-checksum', fileId)}
            disabled={fileId === null}
          >
            <PlaylistAddCheckOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Rename File">
        <span>
          <IconButton
            aria-label="Rename"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('rename', fileId)}
            disabled={fileId === null}
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Edit File Attributes">
        <span>
          <IconButton
            aria-label="Edit Attributes"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('edit-attributes', fileId)}
            disabled={fileId === null}
          >
            <EditAttributesOutlinedIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Copy File">
        <span>
          <IconButton
            aria-label="Copy File"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('copy', fileId)}
            disabled={fileId === null}
          >
            <FileCopyOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Direct Read">
        <span>
          <IconButton
            aria-label="Direct Read"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('dd-read', fileId)}
            disabled={fileId === null}
          >
            <VisibilityOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Direct Write">
        <span>
          <IconButton
            aria-label="Direct Write"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('dd-write', fileId)}
            disabled={fileId === null}
          >
            <BuildOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Remove Permanently">
        <span>
          <IconButton
            aria-label="Remove Permanently"
            size="small"
            color="primary"
            onClick={() => openFileOperationPanel('remove', fileId)}
            disabled={fileId === null}
          >
            <DeleteForeverOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    </>
  ); // TODO better icons for file DD
};

SatelliteFileOperationButtons.propTypes = {
  fileId: PropTypes.number,
  openFileOperationPanel: PropTypes.func.isRequired,
};

SatelliteFileOperationButtons.defaultProps = {
  fileId: null,
};

export default SatelliteFileOperationButtons;
