import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

const ConnectionCardContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    rpcWithErrorHandler,
    clientData: { userName },
  } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/egse-dump-provider`;

  const connectionDsRecord = useDsRecord('egse', 'connection');
  const [connected, setConnected] = useState(false);
  const [serialPort, setSerialPort] = useState('COM<X> | /dev/ttyUSB0');
  const [baudrate, setBaudrate] = useState(9600);

  useEffect(() => {
    connectionDsRecord.subscribe((record) => {
      setConnected(record?.connected || false);
      setSerialPort(record?.serialPort || '');
      setBaudrate(record?.baudrate || 9600);
    }, true);

    return function cleanup() {
      connectionDsRecord.discard();
    };
  }, [connectionDsRecord]);

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const onConnect = async () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/connect`,
      paramsObject: { userName, serialPort, baudrate },
      snackbarMessage: `Connected to ${serialPort}:${baudrate}`,
    });
  };

  const onDisconnect = async () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/disconnect`,
      paramsObject: { userName },
      snackbarMessage: `Disconnect requested from serial port.`,
    });
  };

  return {
    onConnect,
    onDisconnect,
    connected,
    serialPort,
    setSerialPort,
    baudrate,
    setBaudrate,
  };
};

export default ConnectionCardContainer;
