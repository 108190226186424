import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import useTimeAgo from '@rooks/use-time-ago';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useMotorCtrlElDs from '../useMotorCtrlElDs';

const onOffOptions = new Map();
const connectOptions = new Map();
const enableOptions = new Map();

export default function useServoElConnection() {
  const theme = useTheme();
  const { motorCtrlEl, motorCtrlElPath } = useMotorCtrlElDs();
  const [IP, setIP] = useState(motorCtrlEl.networkConfig.IP);
  const [port, setPort] = useState(motorCtrlEl.networkConfig.port);
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  useEffect(() => setIP(motorCtrlEl.networkConfig.IP), [motorCtrlEl.networkConfig.IP]);
  useEffect(() => setPort(motorCtrlEl.networkConfig.port), [motorCtrlEl.networkConfig.port]);

  const lastUpdated = useTimeAgo(motorCtrlEl.lastUpdated);

  onOffOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  onOffOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });
  enableOptions.set(0, { bgColor: theme.palette.error.main, label: 'DEAD - 0', color: 'white' });
  enableOptions.set(1, { bgColor: theme.palette.success.main, label: 'ALIVE - 1', color: 'white' });
  connectOptions.set(0, { bgColor: theme.palette.error.main, label: 'DISCONNECTED - 0', color: 'white' });
  connectOptions.set(1, { bgColor: theme.palette.success.main, label: 'CONNECTED - 1', color: 'white' });

  const connect = useCallback(() => {
    try {
      rpcWithErrorHandler(`${motorCtrlElPath}/connect`, {
        IP,
        port,
      });
      enqueueSnackbar(`Connection requested to ${IP}/${port}`, {
        variant: 'info',
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, IP, port, motorCtrlElPath, rpcWithErrorHandler]);

  const disconnect = useCallback(() => {
    try {
      rpcWithErrorHandler(`${motorCtrlElPath}/disconnect`, {});
      enqueueSnackbar('Disconnection requested', { variant: 'info' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, motorCtrlElPath, rpcWithErrorHandler]);

  const handleConnection = useCallback(
    (mode) => {
      if (mode === 'connect') {
        connect();
      } else {
        disconnect();
      }
    },
    [connect, disconnect],
  );

  const toggleHeartbeat = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(`${motorCtrlElPath}/enableHeartbeat`, mode);
        enqueueSnackbar('Toggle heartbeat requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, motorCtrlElPath, rpcWithErrorHandler],
  );

  const togglePeriodic = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(`${motorCtrlElPath}/enablePeriodic`, mode);
        enqueueSnackbar('Toggle periodic update requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, motorCtrlElPath, rpcWithErrorHandler],
  );

  const servoElConnectionComponents = useMemo(
    () => [
      {
        type: 'input-nosetter',
        options: {
          helperText: 'IP:',
          value: IP,
          handleChange: setIP,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current IP:',
          value: motorCtrlEl.networkConfig.IP,
        },
      },
      {
        type: 'input-nosetter',
        options: {
          helperText: 'Port:',
          value: port,
          handleChange: setPort,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Port:',
          value: motorCtrlEl.networkConfig.port,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Connect:',
          button1Text: 'CONNECT',
          handleButton1Click: () => handleConnection('connect'),
          button2Text: 'DISCONNECT',
          handleButton2Click: () => handleConnection('disconnect'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Connected:',
          value: Number(motorCtrlEl.isConnected ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: connectOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Heartbeat:',
          button1Text: 'ENABLE',
          handleButton1Click: () => toggleHeartbeat(true),
          button2Text: 'DISABLE',
          handleButton2Click: () => toggleHeartbeat(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Heartbeat Enabled:',
          value: Number(motorCtrlEl.isPinging ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: onOffOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Heartbeat Status:',
          value: Number(motorCtrlEl.isAlive ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: enableOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'Periodic Update:',
          button1Text: 'ENABLE',
          handleButton1Click: () => togglePeriodic(true),
          button2Text: 'DISABLE',
          handleButton2Click: () => togglePeriodic(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Periodic Enabled:',
          value: Number(motorCtrlEl.isPolling ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: onOffOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Periodic Status:',
          value: Number(motorCtrlEl.isUpdating ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: enableOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Last Updated:',
          value: lastUpdated,
        },
      },
    ],
    [
      IP,
      handleConnection,
      lastUpdated,
      motorCtrlEl.isAlive,
      motorCtrlEl.isConnected,
      motorCtrlEl.isPinging,
      motorCtrlEl.isPolling,
      motorCtrlEl.isUpdating,
      motorCtrlEl.networkConfig.IP,
      motorCtrlEl.networkConfig.port,
      port,
      toggleHeartbeat,
      togglePeriodic,
    ],
  );

  return { servoElConnectionComponents };
}
