import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useSnackbar from '#hooks/useSnackbar';

import EgsePageWrapperStyles from './EgsePageWrapperStyles';

const useStyles = makeStyles(EgsePageWrapperStyles);

const EgsePageWrapper = ({ children }) => {
  const { missionKey } = useCurrentMission();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { enqueueSnackbar } = useSnackbar();

  const egseAction = useCallback(
    async (action, paramsObject, snackbarMessage) => {
      const rpcBaseUrl = `rg/${missionKey}/@c3s/egse-dump-provider`;
      const ret = await rpcWithErrorHandler(`${rpcBaseUrl}/${action}`, paramsObject);
      if (ret.status !== 'error') {
        enqueueSnackbar(snackbarMessage, { variant: 'info' });
      }
    },
    [missionKey, rpcWithErrorHandler, enqueueSnackbar],
  );

  const classes = useStyles();

  return (
    <RgContext.Provider value={{ currentResGroup: missionKey, egseAction }}>
      <div className={classes.Wrapper}>{children}</div>
    </RgContext.Provider>
  );
};

EgsePageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EgsePageWrapper;
