import { Accordion, AccordionDetails } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import CanStatus from '../cards/CanStatus';
import FilterStatus from '../cards/FilterStatus';
import GPIOStatus from '../cards/GPIOStatus';
import MainStatus from '../cards/MainStatus';
import MlvdsStatus from '../cards/MlvdsStatus';
import PowerStatus from '../cards/PowerStatus';
import StatusPanelStyles from './StatusPanel.styles';
import StatusPanelTitle from './StatusPanelTitle';
import useStatusPanel from './useStatusPanel';

const useStyles = makeStyles(StatusPanelStyles);

const StatusComponentArray = [MainStatus, PowerStatus, CanStatus, MlvdsStatus, FilterStatus, GPIOStatus];

const StatusPanel = ({ toggleShallStickStatus, ShallStickStatus }) => {
  const {
    isExpanded,
    toggleExpanded,
    connectionString,
    connectionIsAlive,
    AutoReportPeriodTimeInMs,
    isAutoReportSet,
  } = useStatusPanel();
  const { accordionRoot, details, GridShrink } = useStyles();

  return (
    <Accordion square expanded={isExpanded} onChange={toggleExpanded} className={accordionRoot}>
      <StatusPanelTitle
        isExpanded={isExpanded}
        connectionString={connectionString}
        ShallStickStatus={ShallStickStatus}
        toggleShallStickStatus={toggleShallStickStatus}
        connectionIsAlive={connectionIsAlive}
        AutoReportPeriodTimeInMs={AutoReportPeriodTimeInMs}
        isAutoReportSet={isAutoReportSet}
      />
      <AccordionDetails className={details}>
        <Grid container spacing={1} justify="space-between">
          {StatusComponentArray.map((C) => (
            <Grid item className={GridShrink} key={C.toString()}>
              <C />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

StatusPanel.propTypes = {
  toggleShallStickStatus: PropTypes.func.isRequired,
  ShallStickStatus: PropTypes.bool.isRequired,
};

export default StatusPanel;
