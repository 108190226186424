import { Grid } from '@material-ui/core';
import React from 'react';

import CommunicationBusCard from './Cards/BusConfig/CommunicationBusCard';
import FilterAndFcuCard from './Cards/BusConfig/FilterAndFcuCard';

const BusConfigTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <CommunicationBusCard />
    </Grid>
    <Grid item md={6}>
      <FilterAndFcuCard />
    </Grid>
  </Grid>
);

export default BusConfigTab;
