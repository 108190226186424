import {
  COMDataAcquisitionServiceDropDown,
  COMEventReportingServiceDropDown,
  COMFunctionManagementServiceDropDown,
  COMHousekeepingReportingServiceDropDown,
  COMTestServiceDropDown,
} from '@c3s/ui-radcube-protocol-tctm';

export const components = [
  COMHousekeepingReportingServiceDropDown,
  COMTestServiceDropDown,
  COMDataAcquisitionServiceDropDown,
  COMEventReportingServiceDropDown,
  COMFunctionManagementServiceDropDown,
];
export const initialOpenState = Array(components.length).fill(false);

export const reducer = (state, { index, value }) => {
  const newState = Array(components.length).fill(false);
  newState[index] = value;
  return newState;
};
