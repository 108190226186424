import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';

export default function useServoControls() {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const setParkAz = useCallback(
    (pos) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'motorControl.azParkPosition',
          value: parseFloat(pos),
        });
        enqueueSnackbar('AZ Park Position change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );
  const setParkEl = useCallback(
    (pos) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'motorControl.elParkPosition',
          value: parseFloat(pos),
        });
        enqueueSnackbar('EL Park Position change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const toggleFlip = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'motorControl.flipEnable',
          value: mode,
        });
        enqueueSnackbar('Flip change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const servoCtrlComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'Flip:',
          button1Text: 'ENABLE',
          handleButton1Click: () => toggleFlip(true),
          button2Text: 'DISABLE',
          handleButton2Click: () => toggleFlip(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Flip State:',
          value: autonomyConfig.motorControl.flipEnable ? 'ENABLED' : 'DISABLED',
          unit: '-',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'AZ Park Position:',
          numberInputProps: { inputProps: { min: -180, max: 180, scale: 1 } },
          value: autonomyConfig.motorControl.azParkPosition ?? 0,
          handleSet: setParkAz,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current AZ Park Pos:',
          value: sanitize(autonomyConfig?.motorControl?.azParkPosition, 1),
          unit: 'deg',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'EL Park Position:',
          numberInputProps: { inputProps: { min: -90, max: 90, scale: 1 } },
          value: autonomyConfig.motorControl.elParkPosition ?? 0,
          handleSet: setParkEl,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current EL Park Pos:',
          value: sanitize(autonomyConfig?.motorControl?.elParkPosition, 1),
          unit: 'deg',
        },
      },
    ],
    [
      autonomyConfig.motorControl.azParkPosition,
      autonomyConfig.motorControl.elParkPosition,
      autonomyConfig.motorControl.flipEnable,
      setParkAz,
      setParkEl,
      toggleFlip,
    ],
  );

  return { servoCtrlComponents };
}
