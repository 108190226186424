import PropTypes from 'prop-types';
import React from 'react';

import compassMarkingsURL from '../../../../../../assets/images/compass_markings.svg';
import DialIndicator from './DialIndicator';

const AzimuthIndicator = ({ azimuth, diameter }) => {
  return <DialIndicator dialDegrees={azimuth} diameter={diameter} markingsBgURL={compassMarkingsURL} />;
};

AzimuthIndicator.propTypes = {
  azimuth: PropTypes.number.isRequired,
  diameter: PropTypes.number,
};

AzimuthIndicator.defaultProps = {
  diameter: 128,
};

export default AzimuthIndicator;
