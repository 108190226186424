import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'standard-hooks';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const useRows = () => {
  const [open, setOpen] = useToggle(false);
  const [uploads, setUploads] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { rpcWithErrorHandler, dsClient } = useContext(DeepstreamContext);

  const { currentResGroup } = useContext(RgContext);
  const baseRpc = `rg/${currentResGroup}/@c3s/file-transfer-provider`;
  const removeAllUploadSuccessPath = `${baseRpc}/removeAllUploadSuccess`;
  const removeAllUploadPath = `${baseRpc}/removeAllUpload`;
  const removeAllUploadFailedPath = `${baseRpc}/removeAllUploadFailed`;
  const removeAllUploadInProgressPath = `${baseRpc}/removeAllUploadInProgress`;
  const removeAllDownloadPath = `${baseRpc}/removeAllDownload`;
  const removeAllDownloadSuccessPath = `${baseRpc}/removeAllDownloadSuccess`;
  const removeAllDownloadFailedPath = `${baseRpc}/removeAllDownloadFailed`;
  const removeAllDownloadInProgressPath = `${baseRpc}/removeAllDownloadInProgress`;
  const removeOne = `${baseRpc}/remove`;
  const terminate = `${baseRpc}/stop`;
  const rowRecordPath = `${baseRpc}/summary`;

  const handleChange = useCallback(
    (newRecord) => {
      const uploadRecordData = newRecord.uploads || [];
      const downloadRecordData = newRecord.downloads || [];
      const uploadRowData = uploadRecordData.map((row) => ({
        ...row,
        onTerminate: () => {
          rpcWithErrorHandler(terminate, { mongoID: row.mongoID });
          enqueueSnackbar('Terminate one element requested.', { variant: 'info' });
        },
        onRemove: () => {
          rpcWithErrorHandler(removeOne, { mongoID: row.mongoID });
          enqueueSnackbar('Remove one element requested.', { variant: 'info' });
        },
      }));
      const downloadRowData = downloadRecordData.map((row) => ({
        ...row,
        onTerminate: () => {
          rpcWithErrorHandler(terminate, { mongoID: row.mongoID });
          enqueueSnackbar('Terminate one element requested.', { variant: 'info' });
        },
        onRemove: () => {
          rpcWithErrorHandler(removeOne, { mongoID: row.mongoID });
          enqueueSnackbar('Remove one element requested.', { variant: 'info' });
        },
      }));
      setUploads(uploadRowData);
      setDownloads(downloadRowData);
    },
    [enqueueSnackbar, removeOne, rpcWithErrorHandler, terminate],
  );

  useEffect(() => {
    const dsRecord = dsClient.record.getRecord(rowRecordPath);
    dsRecord.subscribe(handleChange, true);
    return () => {
      dsRecord.discard();
    };
  }, [dsClient, handleChange, rowRecordPath]);

  const memoizedUploads = useMemo(() => uploads, [uploads]);
  const memoizedDownloads = useMemo(() => downloads, [downloads]);

  return {
    useStyles,
    open,
    setOpen,
    memoizedUploads,
    memoizedDownloads,
    rpcWithErrorHandler,
    removeAllUploadSuccessPath,
    removeAllUploadPath,
    removeAllUploadFailedPath,
    removeAllUploadInProgressPath,
    removeAllDownloadPath,
    removeAllDownloadSuccessPath,
    removeAllDownloadFailedPath,
    removeAllDownloadInProgressPath,
  };
};

export default useRows;
