import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FileDetailCell = ({ value }) => {
  const classes = useStyles();
  return (
    <span className={classes.root} title={value}>
      {value}
    </span>
  );
};

FileDetailCell.propTypes = {
  value: PropTypes.string,
};

FileDetailCell.defaultProps = {
  value: undefined,
};

export default FileDetailCell;
