import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import { formatISO, isValid } from 'date-fns';

export const satelliteFileControlPanels = [
  'fs-status',
  'disk-info',
  'disk-dd-read',
  'disk-dd-write',
  'file-listing',
  'reset-fs',
  'format-fs',
  'unmount-disk',
];

export const satelliteFileOperations = [
  'remove',
  'edit-attributes',
  'rename',
  'copy',
  'get-checksum',
  'get-report',
  'dd-write',
  'dd-read',
];

export const FIDExtensions = {
  1: '.key',
  2: '.afw',
  3: '.rfw',
  4: '.adcsfw',
  5: '.pdaq',
  6: '.pev',
  7: '.rdaq',
  8: '.bat',
  9: '.brep',
  10: '.comp',
  11: '.adcscfg',
  12: '.adcserr',
  13: '.mpptchar',
  14: '.adcswheel',
  15: '.ftslog',
  16: '.genbin',
  17: '.pevfil',
};

export function getBaseFileId(fileId) {
  // eslint-disable-next-line no-bitwise
  return fileId & 0x3ff;
}

export function getFileIdExtension(fileId) {
  // eslint-disable-next-line no-bitwise
  return FIDExtensions[fileId >> 10];
}

export function getDiskLabel(diskId) {
  return diskIDOptions.find((option) => option.value === diskId)?.label ?? 'Unknown';
}

export function formatTimestamp(date) {
  if (date === null || date === undefined) {
    return 'N/A';
  }
  if (!(date instanceof Date) || !isValid(date)) {
    return '?';
  }
  return formatISO(date);
}
