import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    backgroundColor: theme.palette.background.paper,
    backgroundSize: '100%',
    position: 'relative',
    boxShadow: theme.shadows[1],
  },
  dial: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.main,
    width: 2,
    top: 1,
    left: 'calc(50% - 1px)',
    transformOrigin: 'bottom center',
  },
  degreesValue: {
    position: 'absolute',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '0px 1px 1px rgba(0,0,0,0.15)',
    width: 48,
    top: '65%',
    left: 'calc(50% - 24px)',
  },
}));

const DialIndicator = ({ dialDegrees, diameter, markingsBgURL }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ width: diameter, height: diameter, backgroundImage: `url('${markingsBgURL}')` }}
    >
      <div className={classes.dial} style={{ height: diameter / 2 - 1, transform: `rotate(${dialDegrees}deg)` }} />
      <span className={classes.degreesValue}>{dialDegrees}&deg;</span>
    </div>
  );
};

DialIndicator.propTypes = {
  dialDegrees: PropTypes.number.isRequired,
  diameter: PropTypes.number,
  markingsBgURL: PropTypes.string.isRequired,
};

DialIndicator.defaultProps = {
  diameter: 128,
};

export default DialIndicator;
