import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import { diskIDOptions, diskStateOptions } from '@c3s/ui-radcube-protocol-tctm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import ErrorIcon from '@material-ui/icons/Error';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import MemoryIcon from '@material-ui/icons/Memory';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useIconStyles = makeStyles(() => ({
  indicatorIcon: {
    verticalAlign: 'middle',
  },
}));

const OperationModePanel = ({ opMode, diskStates }) => {
  return (
    <Grid container spacing={2} alignItems="baseline">
      <Grid item>
        <OpModeIndicator opMode={opMode} />
      </Grid>
      {Object.entries(diskStates ?? {}).map(([diskId, diskState]) => (
        <Grid item key={diskId}>
          <DiskStateIndicator diskId={Number(diskId)} diskState={diskState} />
        </Grid>
      ))}
    </Grid>
  );
};

OperationModePanel.propTypes = {
  opMode: PropTypes.oneOf(Object.values(TCTM.tctm_fs_operation_mode_e)),
  diskStates: PropTypes.objectOf(PropTypes.oneOf(Object.values(TCTM.tctm_fs_disk_state_e))),
};

OperationModePanel.defaultProps = {
  opMode: undefined,
  diskStates: {},
};

const OpModeIndicator = ({ opMode }) => {
  const classes = useIconStyles();

  const label = 'OpMode';

  switch (opMode) {
    case TCTM.tctm_fs_operation_mode_e.TCTM_FS_OPMODE_NORMAL:
      return (
        <SmallStateIndicator
          icon={<InsertDriveFileOutlinedIcon className={classes.indicatorIcon} />}
          label={label}
          state="Normal"
        />
      );
    case TCTM.tctm_fs_operation_mode_e.TCTM_FS_OPMODE_SERVICE:
      return (
        <SmallStateIndicator icon={<BuildIcon className={classes.indicatorIcon} />} label={label} state="Service" />
      );
    default:
      return (
        <SmallStateIndicator
          icon={<ErrorIcon className={classes.indicatorIcon} />}
          label={label}
          state="Invalid Value"
        />
      );
  }
};

OpModeIndicator.propTypes = {
  opMode: PropTypes.oneOf(Object.values(TCTM.tctm_fs_operation_mode_e)),
};

OpModeIndicator.defaultProps = {
  opMode: undefined,
};

const DiskStateIndicator = ({ diskId, diskState }) => {
  const classes = useIconStyles();
  const diskLabel = diskIDOptions.find((option) => option.value === diskId)?.label ?? 'Unknown disk';
  const diskStateLabel = diskStateOptions.find((option) => option.value === diskState)?.label ?? 'Unknown';

  return (
    <SmallStateIndicator
      icon={<MemoryIcon className={classes.indicatorIcon} />}
      label={diskLabel}
      state={diskStateLabel}
    />
  );
  /*
  switch (diskState) {
    case TCTM.tctm_fs_disk_state_e.TCTM_FS_DISK_STATE_ERROR:
      return <Chip variant="outlined" color="error" icon={<MemoryIcon />} label={chipLabel} />;
    case TCTM.tctm_fs_disk_state_e.TCTM_FS_DISK_STATE_MOUNTED:
      return <Chip variant="outlined" color="textPrimary" icon={<MemoryIcon />} label={chipLabel} />;
    case TCTM.tctm_fs_disk_state_e.TCTM_FS_DISK_STATE_UNMOUNTED:
      return <Chip variant="outlined" color="secondary" icon={<MemoryIcon />} label={chipLabel} />;
    case TCTM.tctm_fs_disk_state_e.TCTM_FS_DISK_STATE_UNITIALISED:
      return <Chip variant="outlined" color="textPrimary" icon={<MemoryIcon />} label={chipLabel} />;
    default:
      return <Chip variant="outlined" color="error" icon={<ErrorIcon />} label="Invalid Disk State" />;
  } */
};

DiskStateIndicator.propTypes = {
  diskId: PropTypes.oneOf(Object.values(TCTM.tctm_disk_id_e)).isRequired,
  diskState: PropTypes.oneOf(Object.values(TCTM.tctm_fs_disk_state_e)),
};

DiskStateIndicator.defaultProps = {
  diskState: undefined,
};

const useSmallStateIndicatorStyles = makeStyles(() => ({
  icon: { alignSelf: 'center', paddingRight: 2 },
  label: { lineHeight: '1em' },
  stateLabel: { lineHeight: '1em' },
}));

const SmallStateIndicator = ({ icon, label, state }) => {
  const classes = useSmallStateIndicatorStyles();
  return (
    <Grid container alignItems="center">
      <Grid item className={classes.icon}>
        {icon}
      </Grid>
      <Grid item>
        <Typography variant="caption" className={classes.label}>
          {label}
        </Typography>
        <Typography variant="body2" className={classes.stateLabel}>
          {state}
        </Typography>
      </Grid>
    </Grid>
  );
};

SmallStateIndicator.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default OperationModePanel;
