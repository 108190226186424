import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';

import TablePaginationActions from './TablePaginationActions';

const useStyles = makeStyles((theme) => ({
  expandedRow: {
    borderBottom: 'none',
  },
  expandedRowCell: {
    borderBottom: 'none',
  },
  tcJsonDetail: {
    fontSize: '0.75em',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    resize: 'vertical',
    overflow: 'auto',
  },
}));

const ExpanderCell = ({ isExpanded, getToggleExpandedProps }) => (
  <IconButton size="small" {...getToggleExpandedProps()}>
    {isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
  </IconButton>
);

ExpanderCell.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  getToggleExpandedProps: PropTypes.func.isRequired,
};

const ExpanderHeaderCell = ({ isAllRowsExpanded, getToggleAllRowsExpandedProps }) => (
  <ExpanderCell isExpanded={isAllRowsExpanded} getToggleExpandedProps={getToggleAllRowsExpandedProps} />
);

ExpanderHeaderCell.propTypes = {
  isAllRowsExpanded: PropTypes.bool.isRequired,
  getToggleAllRowsExpandedProps: PropTypes.func.isRequired,
};

const ExpanderBodyCell = ({ row }) => (
  <ExpanderCell isExpanded={row.isExpanded} getToggleExpandedProps={row.getToggleRowExpandedProps} />
);

ExpanderBodyCell.propTypes = {
  row: PropTypes.shape({
    isExpanded: PropTypes.bool.isRequired,
    getToggleRowExpandedProps: PropTypes.func.isRequired,
  }).isRequired,
};

const RemoveItemCell = ({ remove }) => (
  <IconButton size="small" title="Clear item from TC fifo" onClick={remove}>
    <ClearIcon fontSize="small" />
  </IconButton>
);

RemoveItemCell.propTypes = {
  remove: PropTypes.func.isRequired,
};

const EnhancedTable = ({ columns, data, fetchData, clearFifoItem, pageCount: controlledPageCount }) => {
  const classes = useStyles();

  const renderRemoveItemCell = useCallback(({ row }) => <RemoveItemCell remove={() => clearFifoItem(row.original)} />, [
    clearFifoItem,
  ]);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    useExpanded,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((cols) => [
        {
          Header: ExpanderHeaderCell,
          id: 'item-expand',
          Cell: ExpanderBodyCell,
        },
        ...cols,
        {
          Header: '',
          id: 'item-remove',
          Cell: renderRemoveItemCell,
        },
      ]);
    },
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Render the UI for your table
  return (
    <TableContainer>
      <Table {...getTableProps()} stickyHeader>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.id !== 'selection' ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <>
                <TableRow {...row.getRowProps()} className={row.isExpanded ? classes.expandedRow : null}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()} className={row.isExpanded ? classes.expandedRowCell : null}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {row.isExpanded && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <pre className={classes.tcJsonDetail}>{JSON.stringify(row.original.item, null, 4)}</pre>
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: data.length }]}
              count={controlledPageCount}
              rowsPerPage={pageSize}
              page={pageIndex}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchData: PropTypes.func.isRequired,
  clearFifoItem: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
};

export default EnhancedTable;
