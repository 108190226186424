import Grid from '@material-ui/core/Grid';
import React from 'react';

import ControlDropdown from './ControlDropdown/ControlDropdown';
import DownloadTable from './DownloadTable/DownloadTable';
import UploadTable from './UploadTable/UploadTable';

const FileTransferTab = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={12}>
        <ControlDropdown />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <UploadTable />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DownloadTable />
      </Grid>
    </Grid>
  );
};

export default FileTransferTab;
