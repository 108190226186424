import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';

export default function useCabinetHeater() {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const setTempHigh = useCallback(
    (temp) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'cabinetHeaterControl.tempHighLimit',
          value: temp,
        });
        enqueueSnackbar('Temperature High Limit change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const setTempLow = useCallback(
    (temp) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'cabinetHeaterControl.tempLowLimit',
          value: temp,
        });
        enqueueSnackbar('Temperature Low Limit change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const cabinetHeaterComponents = useMemo(
    () => [
      {
        type: 'input',
        options: {
          helperText: 'Temperature High Limit:',
          numberInputProps: { inputProps: { min: -30, max: 100, scale: 1 } },
          value: autonomyConfig.cabinetHeaterControl.tempHighLimit ?? 0,
          handleSet: setTempHigh,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Temp. High Limit:',
          value: sanitize(autonomyConfig?.cabinetHeaterControl?.tempHighLimit, 1),
          unit: '°C',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Temperature Low Limit:',
          numberInputProps: { inputProps: { min: -30, max: 100, scale: 1 } },
          value: autonomyConfig.cabinetHeaterControl.tempLowLimit ?? 0,
          handleSet: setTempLow,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Temp. Low Limit:',
          value: sanitize(autonomyConfig?.cabinetHeaterControl?.tempLowLimit, 1),
          unit: '°C',
        },
      },
    ],
    [
      autonomyConfig.cabinetHeaterControl.tempHighLimit,
      autonomyConfig.cabinetHeaterControl.tempLowLimit,
      setTempHigh,
      setTempLow,
    ],
  );

  return { cabinetHeaterComponents };
}
