import { Button, Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import React from 'react';

import { isAnySecretKeyInvalid } from './helpers';
import SecretKeyInputs from './SecretKeyInputs';
import TctmSettingsInputs from './TctmSettingsInputs';
import useTctmTranscoderSettings from './useTctmTranscoderSettings';

const TctmTranscoderSettingsPanel = () => {
  const { state, dispatch, persistState } = useTctmTranscoderSettings();

  return (
    <Card elevation={2}>
      <CardHeader title="TCTM Transcoder Settings" titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <TctmSettingsInputs {...state} dispatch={dispatch} />
      </CardContent>
      <Divider variant="middle" />
      <CardContent>
        <Typography variant="body1">Secret Keys</Typography>
        <SecretKeyInputs secretKeys={state.secretKeys} dispatch={dispatch} />
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          disabled={isAnySecretKeyInvalid(state.secretKeys)}
          color="primary"
          variant="outlined"
          onClick={persistState}
        >
          Set
        </Button>
      </CardActions>
    </Card>
  );
};

export default TctmTranscoderSettingsPanel;
