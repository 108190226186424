import PropTypes from 'prop-types';
import React from 'react';

import { getFieldInStructInternalState, getFieldInStructValue } from '../helpers';
// eslint-disable-next-line import/no-cycle
import Field from './Field';

const FunctionHierarchySelector = ({
  functionHierarchyFields,
  selectedFuncHierarchyEntries,
  structMeta,
  structValue,
  structInternalState,
  dispatchFieldActionInStruct,
  disabled,
}) => {
  const [fieldId, ...innerFunctionHierarchyFields] = functionHierarchyFields;
  const [entry, ...innerSelectedFuncHierarchyEntries] = selectedFuncHierarchyEntries;

  const fieldMeta = structMeta?.fields?.[fieldId];
  const selectionValue = structInternalState?.fieldInternalStates?.[fieldId];

  return (
    <>
      <Field
        fieldId={fieldId}
        fieldMeta={{ ...fieldMeta, type: 'enum' }}
        fieldValue={getFieldInStructValue(structValue, fieldMeta)}
        fieldInternalState={getFieldInStructInternalState(structInternalState, fieldId)}
        enumId={entry?.enum ?? null}
        enumFilter={entry?.implemented}
        dispatchFieldAction={dispatchFieldActionInStruct}
        disabled={disabled}
        fullWidth
        margin="dense"
      />
      {innerFunctionHierarchyFields.length > 0 && (
        <FunctionHierarchySelector
          functionHierarchyFields={innerFunctionHierarchyFields}
          selectedFuncHierarchyEntries={innerSelectedFuncHierarchyEntries}
          structMeta={structMeta}
          structValue={structValue}
          structInternalState={structInternalState}
          dispatchFieldActionInStruct={dispatchFieldActionInStruct}
          disabled={disabled || selectionValue === undefined}
        />
      )}
    </>
  );
};

FunctionHierarchySelector.propTypes = {
  functionHierarchyFields: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedFuncHierarchyEntries: PropTypes.shape({
    enum: PropTypes.number.isRequired,
    ids: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  structMeta: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object]),
  ).isRequired,
  structValue: PropTypes.objectOf(PropTypes.oneOf(PropTypes.number, PropTypes.array, PropTypes.object)),
  structInternalState: PropTypes.objectOf(PropTypes.oneOf(PropTypes.number, PropTypes.array, PropTypes.object)),
  dispatchFieldActionInStruct: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FunctionHierarchySelector.defaultProps = {
  disabled: false,
  structValue: undefined,
  structInternalState: undefined,
};

export default FunctionHierarchySelector;
