import { useMemo } from 'react';

export default function useFFTControl() {
  const fftControlComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'Enable',
          button1Text: 'ENABLE',
          handleButton1Click: () => {},
          button2Text: 'DISABLE',
          handleButton2Click: () => {},
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Size',
          value: 128,
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'Window',
          options: [
            {
              label: 'None/Rect',
              value: 'none',
            },
            {
              label: 'Hann',
              value: 'hann',
            },
            {
              label: 'Blackman-Harris',
              value: 'blackmanhharris',
            },
          ],
          selectedValue: 'none',
          handleSet: () => {},
        },
      },
    ],
    [],
  );

  return { fftControlComponents };
}
