import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import printf from 'printf';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../useAutonomyStatusDs';

const windTypeOptions = new Map();
const alarmModeOptions = new Map();
const autoAlarmValueOptions = new Map();

export default function useWindAlarmControl() {
  const theme = useTheme();
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  windTypeOptions.set('act', { bgColor: theme.palette.success.main, label: 'ACTUAL', color: 'white' });
  windTypeOptions.set('avg', { bgColor: theme.palette.success.main, label: 'AVG', color: 'white' });
  windTypeOptions.set('gust', { bgColor: theme.palette.success.main, label: 'GUST', color: 'white' });
  autoAlarmValueOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  autoAlarmValueOptions.set(1, { bgColor: theme.palette.error.main, label: 'ALARM - 1', color: 'white' });
  alarmModeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO', color: 'white' });
  alarmModeOptions.set('manual', { bgColor: theme.palette.yellow.main, label: 'MANUAL', color: 'black' });

  const setWindType = useCallback(
    (type) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'windAlarm.windType',
          value: type,
        });
        enqueueSnackbar('Wind Type set', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const setMode = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'windAlarm.alarmMode',
          value: mode,
        });
        enqueueSnackbar('Alarm Mode set', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const setManualValue = useCallback(
    (value) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'windAlarm.manualAlarmValue',
          value,
        });
        enqueueSnackbar('Manual Value change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const windAlarmComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'Mode:',
          button1Text: 'MANUAL',
          handleButton1Click: () => setMode('manual'),
          button2Text: 'AUTO',
          handleButton2Click: () => setMode('auto'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Alarm Mode:',
          value: autonomyConfig.windAlarm.alarmMode,
          ValueComponent: GeneralStatusIndicator,
          options: alarmModeOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'MANUAL Value:',
          button1Text: 'ALARM',
          handleButton1Click: () => setManualValue(true),
          button2Text: 'OFF',
          handleButton2Click: () => setManualValue(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Manual Alarm Value:',
          value: Number(autonomyConfig.windAlarm.manualAlarmValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoAlarmValueOptions,
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'Wind Type:',
          options: [
            {
              label: 'Actual',
              value: 'act',
            },
            {
              label: 'Average',
              value: 'avg',
            },
            {
              label: 'Gust',
              value: 'gust',
            },
          ],
          selectedValue: autonomyConfig.windAlarm.windType,
          handleSet: setWindType,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Wind Type:',
          value: autonomyConfig.windAlarm.windType,
          ValueComponent: GeneralStatusIndicator,
          options: windTypeOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Wind speed:',
          value: printf('%.01f', autonomyStatus.windAlarm.windSpeed),
          unit: 'km/h',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Wind Limit:',
          value: printf('%.01f', autonomyConfig.windAlarm.windLimit),
          unit: 'km/h',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Auto Alarm Value:',
          value: Number(autonomyStatus.windAlarm.autoAlarmValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoAlarmValueOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Wind Alarm Value:',
          value: Number(autonomyStatus.windAlarm.alarmValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoAlarmValueOptions,
        },
      },
    ],
    [
      autonomyConfig.windAlarm.alarmMode,
      autonomyConfig.windAlarm.manualAlarmValue,
      autonomyConfig.windAlarm.windLimit,
      autonomyConfig.windAlarm.windType,
      autonomyStatus.windAlarm.alarmValue,
      autonomyStatus.windAlarm.autoAlarmValue,
      autonomyStatus.windAlarm.windSpeed,
      setMode,
      setWindType,
      setManualValue,
    ],
  );

  return { windAlarmComponents };
}
