import { useCallback, useRef, useState } from 'react';

const useBinaryDataState = () => {
  const [data, setData] = useState(Uint8ClampedArray.from([]));

  const buf = useRef(null);

  const resize = useCallback(
    (newLength) => {
      setData((prevData) => {
        if (newLength === prevData.length) {
          return prevData;
        }
        if (newLength < prevData.length) {
          return prevData.slice(0, newLength);
        }
        buf.current = new ArrayBuffer(newLength);
        const newData = new Uint8ClampedArray(buf.current);
        newData.set(prevData);
        return newData;
      });
    },
    [buf],
  );

  const setOctet = useCallback(
    (idx, octet) => {
      setData((prevData) => {
        prevData.set([octet], idx);
        return new Uint8ClampedArray(buf.current);
      });
    },
    [buf],
  );

  const setBuffer = useCallback(
    (newBuffer) => {
      buf.current = newBuffer;
      setData(new Uint8ClampedArray(buf.current));
    },
    [buf],
  );

  return {
    data,
    resize,
    setOctet,
    setBuffer,
  };
};

export default useBinaryDataState;
