import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import usePanelStyles from '../../../usePanelStyles';

const CopyFilePanel = ({ diskId, fileId, checkFileIdExists, copy }) => {
  const [destinationDiskId, setDestinationDiskId] = useState(diskId);
  const [destinationFileId, setDestinationFileId] = useState('');
  const classes = usePanelStyles();

  const alreadyExists = checkFileIdExists(destinationDiskId, destinationFileId);

  return (
    <>
      <Typography className={classes.panelTitle}>
        Copy file {fileId} on {diskIDOptions.find((option) => option.value === diskId).label}
      </Typography>
      <Grid container spacing={2} alignItems="flex-end" justify="space-between">
        <Grid item container spacing={1}>
          <Grid item>
            <FormControl margin="dense">
              <InputLabel id="fs-copy-destination-disk-label">Destination Disk</InputLabel>
              <Select
                id="fs-copy-destination-disk-select"
                labelId="fs-copy-destination-disk-label"
                value={destinationDiskId}
                onChange={(event) => setDestinationDiskId(event.target.value)}
              >
                {diskIDOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              margin="dense"
              label="Destination File ID"
              inputProps={{ type: 'number' }}
              error={alreadyExists}
              value={destinationFileId}
              onChange={(event) => setDestinationFileId(event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item>
          {alreadyExists && (
            <Typography variant="body2" color="error">
              A file with ID {destinationFileId} already exists on{' '}
              {diskIDOptions.find((option) => option.value === destinationDiskId).label}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={alreadyExists}
            onClick={() => copy(destinationDiskId, destinationFileId)}
          >
            Copy
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

CopyFilePanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  fileId: PropTypes.number.isRequired,
  checkFileIdExists: PropTypes.func.isRequired,
  copy: PropTypes.func.isRequired,
};

export default CopyFilePanel;
