import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Duration } from 'luxon';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import useNodeManagementMessage from '../hooks/useNodeManagementMessage';
import HeartBeatStyles from './HeartbeatReport.styles';
import codeExStateDictionary from './HeartBeatReportDictionary';

const useStyles = makeStyles(HeartBeatStyles);

const HeartBeatReport = React.memo(({ identifier, title }) => {
  const classes = useStyles();
  const { Identifier, Data } = useNodeManagementMessage().body;
  const [report, setReport] = useState(Data);

  useEffect(() => {
    if (Identifier === identifier) setReport(Data);
  }, [Data, Identifier, identifier]);

  const { codeExState, relativeTime, eventFIFOcntr, daqFIFOcntr, status } = report;
  const relativeTimeText = Duration.fromMillis(relativeTime.value * 10).toFormat(`hh 'h' mm 'm' ss 's'`);
  return (
    <TableRow className={classes.TableRow}>
      <TableCell className={`${classes.Cell} ${classes.KeyCell}`}>{title}</TableCell>
      <TableCell className={`${classes.Cell} ${classes.ValueCell}`}>
        {codeExStateDictionary.get(codeExState.value)}
      </TableCell>
      <TableCell className={`${classes.Cell} ${classes.ValueCell}`}>{relativeTimeText}</TableCell>
      <TableCell className={`${classes.Cell} ${classes.ValueCell}`}>{eventFIFOcntr.value}</TableCell>
      <TableCell className={`${classes.Cell} ${classes.ValueCell}`}>{daqFIFOcntr.value}</TableCell>
      <TableCell className={`${classes.Cell} ${classes.ValueCell}`}>{status.value}</TableCell>
    </TableRow>
  );
});

HeartBeatReport.propTypes = {
  title: PropTypes.string.isRequired,
  identifier: PropTypes.number.isRequired,
};

export default HeartBeatReport;
