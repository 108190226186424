const Reducer = (state, action) => {
  switch (action.type) {
    case 'setFileServerLoading':
      return { ...state, isFileServerLoading: action.isFileServerLoading };
    case 'setFileServerError':
      return {
        ...state,
        fileServerError: action.fileServerError,
      };
    default:
      throw new Error();
  }
};

export default Reducer;
