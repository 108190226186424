import useProtocolMessage from '#hooks/useProtocolMessage';

import useAutoReportPeriodTimeInMsEventListener from './useAutoReportPeriodTimeInMsEventListener';

export default function useAutoReportPeriodTimeInMsMessage() {
  return useProtocolMessage({
    listener: useAutoReportPeriodTimeInMsEventListener,
    transform: (x) => x.AutoReportPeriodTimeInMs,
    initialBody: {
      SID: -1,
      value: 0,
    },
  });
}
