import { Grid } from '@material-ui/core';
import React from 'react';

import AbsTimeCard from './Cards/FunctionMgmt/AbsTimeCard';
import AutoReportCard from './Cards/FunctionMgmt/AutoReportCard';
import DebugLedCard from './Cards/FunctionMgmt/DebugLedCard';
import GPIOCard from './Cards/FunctionMgmt/GPIOCard';
import ResetCard from './Cards/FunctionMgmt/ResetCard';

const FunctionMgmtTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <AutoReportCard />
    </Grid>
    <Grid item md={3}>
      <AbsTimeCard />
    </Grid>
    <Grid item md={3}>
      <DebugLedCard />
    </Grid>
    <Grid item md={3}>
      <ResetCard />
    </Grid>
    <Grid item md={12}>
      <GPIOCard />
    </Grid>
  </Grid>
);

export default FunctionMgmtTab;
