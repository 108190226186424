import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import React from 'react';

import { satelliteFileControlPanels } from '../../helpers';
import DiskSelect from './DiskSelect';
import MountUnmountButton from './MountUnmountButton';

const DiskManagementControls = ({
  opMode,
  diskId,
  availableDisks,
  diskState,
  mountDisk,
  selectDisk,
  openPanel,
  selectPanel,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <ButtonGroup aria-label="Disk Selection and Info">
          <DiskSelect disk={diskId} availableDisks={availableDisks} selectDisk={selectDisk} />
          <Button
            selected={openPanel === 'disk-info'}
            onClick={() => selectPanel('disk-info')}
            disabled={diskId === null}
          >
            <InfoOutlinedIcon />
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <ButtonGroup
          aria-label="Direct Disk R/W"
          disabled={
            opMode !== TCTM.tctm_fs_operation_mode_e.TCTM_FS_OPMODE_SERVICE ||
            diskState !== TCTM.tctm_fs_disk_state_e.TCTM_FS_DISK_STATE_UNMOUNTED ||
            diskId === null
          }
        >
          <Button selected={openPanel === 'disk-dd-read'} onClick={() => selectPanel('disk-dd-read')}>
            Direct Read
          </Button>
          <Button selected={openPanel === 'disk-dd-write'} onClick={() => selectPanel('disk-dd-write')}>
            Direct Write
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <MountUnmountButton
          diskState={diskState}
          mountDisk={mountDisk}
          unmountDisk={() => selectPanel('unmount-disk')}
          disabled={diskId === null}
        />
      </Grid>
    </Grid>
  );
};

DiskManagementControls.propTypes = {
  opMode: PropTypes.oneOf(Object.values(TCTM.tctm_fs_operation_mode_e)),
  diskId: PropTypes.number.isRequired,
  availableDisks: PropTypes.arrayOf(PropTypes.number).isRequired,
  diskState: PropTypes.oneOf(Object.values(TCTM.tctm_fs_disk_state_e)),
  mountDisk: PropTypes.func.isRequired,
  selectDisk: PropTypes.func.isRequired,
  openPanel: PropTypes.oneOf(satelliteFileControlPanels),
  selectPanel: PropTypes.func.isRequired,
};

DiskManagementControls.defaultProps = {
  openPanel: null,
  opMode: undefined,
  diskState: undefined,
};

export default DiskManagementControls;
