import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const DiskSelect = ({ disk, availableDisks, selectDisk, ...otherMuiBtnProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const pickDisk = (event, index) => {
    selectDisk(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (availableDisks.length === 0) {
      selectDisk(null);
    } else if (disk === null || !availableDisks.includes(disk)) {
      selectDisk(availableDisks[0]);
    }
  }, [disk, availableDisks, selectDisk]);

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-controls="disk-menu"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        endIcon={availableDisks.length === 0 ? null : <ArrowDropDownIcon />}
        variant="outlined"
        {...otherMuiBtnProps}
        disabled={availableDisks.length === 0}
      >
        {diskIDOptions.find((option) => option.value === disk)?.label ??
          (availableDisks.length === 0 ? 'No disks' : 'Select Disk')}
      </Button>
      <Menu id="disk-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {availableDisks.map((value) => (
          <MenuItem key={value} selected={value === disk} onClick={(event) => pickDisk(event, value)}>
            {diskIDOptions.find((option) => option.value === value).label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

DiskSelect.propTypes = {
  disk: PropTypes.number.isRequired,
  availableDisks: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectDisk: PropTypes.func.isRequired,
};

export default DiskSelect;
