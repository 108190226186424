import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';

import GenericTestConnectorProtocolPage from './protocols';
import GenericTestConnectorSetupPage from './setup';

const GenericTestConnectorPage = ({ connectorKey }) => {
  return (
    <Router>
      <GenericTestConnectorProtocolPage path="protocols/:protocol" connectorKey={connectorKey} />
      <GenericTestConnectorSetupPage path="setup" connectorKey={connectorKey} />
    </Router>
  );
};

GenericTestConnectorPage.propTypes = {
  connectorKey: PropTypes.string.isRequired,
};

export default GenericTestConnectorPage;
