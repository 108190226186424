import PropTypes from 'prop-types';
import React from 'react';

import StatusPanel from '#connectors/bus-tester/status-panel/StatusPanel';
import RgContext from '#contexts/RgContext';
import HeartbeatPanel from '#satellites/RADCUBE/protocols/CAN/heartbeat-panel/HeartbeatPanel';

import useBustesterPageWrapper from './useBustesterPageWrapper';

const BustesterPageWrapper = ({ children, withStatus, connectorCmdProtocolStack }) => {
  const {
    classes,
    toggleShallStickStatus,
    toggleShallStickHeartbeat,
    ShallStickHeartbeat,
    ShallStickStatus,
    currentResGroup,
    commandingProtocol,
    tctmCreateFunction,
    fileTransferRpc,
  } = useBustesterPageWrapper();

  function getStatusPanel(visible) {
    return visible ? (
      <div className={classes.Wrapper}>
        <StatusPanel toggleShallStickStatus={toggleShallStickStatus} ShallStickStatus={ShallStickStatus} />
      </div>
    ) : null;
  }

  function getHeartbeatPanelContainer(visible) {
    return visible ? (
      <div className={classes.Wrapper}>
        <HeartbeatPanel
          toggleShallStickHeartbeat={toggleShallStickHeartbeat}
          ShallStickHeartbeat={ShallStickHeartbeat}
        />
      </div>
    ) : null;
  }

  return (
    <RgContext.Provider
      value={{
        currentResGroup,
        tcProtocolStack: [...connectorCmdProtocolStack, commandingProtocol],
        tctmCreateFunction,
        fileTransferRpc,
      }}
    >
      {withStatus && (
        <>
          {getStatusPanel(!ShallStickStatus)}
          <div className={classes.sticky}>
            <div className={classes.StickyWrapper}>
              {getStatusPanel(ShallStickStatus)}
              {getHeartbeatPanelContainer(ShallStickHeartbeat)}
            </div>
          </div>
          {getHeartbeatPanelContainer(!ShallStickHeartbeat)}
        </>
      )}
      <div className={classes.Wrapper}>{children}</div>
    </RgContext.Provider>
  );
};

BustesterPageWrapper.defaultProps = {
  withStatus: false,
};

BustesterPageWrapper.propTypes = {
  withStatus: PropTypes.bool,
  children: PropTypes.node.isRequired,
  connectorCmdProtocolStack: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BustesterPageWrapper;
