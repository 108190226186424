import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import bytes from 'bytes';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import NonIdealState from '#components/ui-helper/NonIdealState';
import TimeAgo from '#components/ui-helper/TimeAgo';

import { FIDExtensions, formatTimestamp, getBaseFileId, getFileIdExtension } from '../helpers';
import SatelliteFilesTableToolbar from './controls/SatelliteFilesTableToolbar';
import FileAttributesCell from './panels/FileAttributesCell';
import FileDetailCell from './panels/FileDetailCell';

const NoFiles = () => (
  <GridOverlay>
    <NonIdealState icon={<FontAwesomeIcon icon={['far', 'file']} />} message="No files." />
  </GridOverlay>
);

const fileDetailColumnDef = {
  renderCell: (params) => {
    const { formattedValue } = params;
    return <FileDetailCell value={formattedValue} />;
  },
};

const fileColumnsDef = [
  { field: 'id', headerName: 'File ID', type: 'number', flex: 1, ...fileDetailColumnDef },
  {
    field: 'base',
    headerName: 'Base ID',
    type: 'number',
    flex: 1,
    valueFormatter: (params) => params.value.toString(),
    ...fileDetailColumnDef,
  },
  {
    field: 'ext',
    headerName: 'Extension',
    flex: 1,
    type: 'singleSelect',
    valueOptions: Object.values(FIDExtensions),
    ...fileDetailColumnDef,
  },
  {
    field: 'fileSize',
    headerName: 'Size',
    type: 'number',
    flex: 1,
    valueFormatter: (params) => bytes.format(params.value),
    ...fileDetailColumnDef,
  },
  {
    field: 'fileAttributes',
    headerName: 'Attributes',
    flex: 1,
    valueFormatter: (params) => {
      const { value } = params;

      if (value === undefined) {
        return '';
      }
      const attr = [];
      if (value.isSystemFile) {
        attr.push('System File');
      }
      if (value.isProtectedFile) {
        attr.push('Protected File');
      }
      if (value.isDedicatedFile) {
        attr.push('Dedicated File');
      }
      return attr.join(', ');
    },
    renderCell: (params) => {
      const { value } = params;
      return <FileAttributesCell attrs={value} />;
    },
  },
  {
    field: 'fileCreated',
    headerName: 'Created',
    type: 'dateTime',
    flex: 1.5,
    valueFormatter: (params) => formatTimestamp(params.value),
    ...fileDetailColumnDef,
  },
  {
    field: 'fileModified',
    headerName: 'Modified',
    type: 'dateTime',
    flex: 1.5,
    valueFormatter: (params) => formatTimestamp(params.value),
    ...fileDetailColumnDef,
  },
  { field: 'sectorListSize', headerName: 'Sector List Size', type: 'number', flex: 1, ...fileDetailColumnDef },
  {
    field: 'sectorList',
    headerName: 'On Sectors',
    flex: 1,
    valueFormatter: (params) => params.value?.join(', '),
    ...fileDetailColumnDef,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    type: 'dateTime',
    flex: 1.5,
    valueFormatter: (params) => formatTimestamp(params.value),
    renderCell: (params) => {
      const { value } = params;
      return <TimeAgo date={value} />;
    },
  },
];

const useStyles = makeStyles(() => ({
  root: {
    height: 320,
    minHeight: '50vh',
  },
}));

const SatelliteFilesTable = ({ files, openFileOperationPanel }) => {
  const classes = useStyles();

  return (
    <DataGrid
      className={classes.root}
      rowHeight={32}
      rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      columns={fileColumnsDef}
      rows={Object.entries(files ?? {}).map(([fileId, fileDetails]) => ({
        id: fileId,
        ext: getFileIdExtension(fileId),
        base: getBaseFileId(fileId),
        ...fileDetails,
        fileCreated: fileDetails.fileCreated ? parseISO(fileDetails.fileCreated) : undefined,
        fileModified: fileDetails.fileModified ? parseISO(fileDetails.fileModified) : undefined,
        lastUpdated: fileDetails.lastUpdated ? parseISO(fileDetails.lastUpdated) : undefined,
      }))}
      components={{
        NoRowsOverlay: NoFiles,
        Toolbar: SatelliteFilesTableToolbar,
      }}
      componentsProps={{
        toolbar: {
          openFileOperationPanel,
        },
      }}
    />
  );
};

SatelliteFilesTable.propTypes = {
  files: PropTypes.objectOf(
    PropTypes.shape({
      fileSize: PropTypes.number,
      fileAttributes: PropTypes.shape({
        isSystemFile: PropTypes.bool,
        isProtectedFile: PropTypes.bool,
      }),
      fileCreated: PropTypes.string,
      fileModified: PropTypes.string,
      sectorListSize: PropTypes.number,
      sectorList: PropTypes.arrayOf(PropTypes.number),
      lastUpdated: PropTypes.string,
    }),
  ),
  openFileOperationPanel: PropTypes.func.isRequired,
};

SatelliteFilesTable.defaultProps = {
  files: {},
};

export default React.memo(SatelliteFilesTable);
