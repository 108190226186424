import { Grid } from '@material-ui/core';
import React from 'react';

import CanAbsTimeCard from './Cards/CanAbsTime';

const CanAbsTimeDistTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <CanAbsTimeCard />
    </Grid>
  </Grid>
);

export default CanAbsTimeDistTab;
