import { SyncCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useCanSyncServiceCard from './useCanSyncServiceCard';

const CanSyncServiceCard = () => {
  const {
    disableSync,
    enableSync,
    getSyncStatus,
    NumberOfCanSyncToSend,
    CanSyncAsObc,
    CanSyncInfinite,
    CanSyncCounterFixed,
    CanSyncCounterInitValue,
    isNumberOfSyncValid,
    isInitValueValid,
    numSyncMin,
    numSyncMax,
    numSyncInitMin,
    numSyncInitMax,
    setNumberOfCanSyncToSend,
    setCanSyncAsObc,
    setCanSyncInfinite,
    setCanSyncCounterFixed,
    setCanSyncCounterInitValue,
  } = useCanSyncServiceCard();

  return (
    <SyncCard
      comBus="can"
      disableSync={disableSync}
      enableSync={enableSync}
      getSyncStatus={getSyncStatus}
      NumberOfSyncToSend={NumberOfCanSyncToSend}
      SyncAsObc={CanSyncAsObc}
      SyncInfinite={CanSyncInfinite}
      SyncCounterFixed={CanSyncCounterFixed}
      SyncCounterInitValue={CanSyncCounterInitValue}
      isNumberOfSyncValid={isNumberOfSyncValid}
      isInitValueValid={isInitValueValid}
      numSyncMin={numSyncMin}
      numSyncMax={numSyncMax}
      numSyncInitMin={numSyncInitMin}
      numSyncInitMax={numSyncInitMax}
      setNumberOfSyncToSend={setNumberOfCanSyncToSend}
      setSyncAsObc={setCanSyncAsObc}
      setSyncInfinite={setCanSyncInfinite}
      setSyncCounterFixed={setCanSyncCounterFixed}
      setSyncCounterInitValue={setCanSyncCounterInitValue}
    />
  );
};

export default CanSyncServiceCard;
