// eslint-disable-next-line camelcase
import { _SSP_TypeDefs } from '@c3s/misc_radcube_ssp_packets_enums';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SSPFrameBuilder = ({ classes, Frame: { Src, Dst, Type }, dsRecord }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={1}>
        <Button size="small" style={{ maxWidth: 200 }} color="default" className={classes.ButtonLabel} disabled>
          SSP Frame
        </Button>
      </Grid>
      <Grid item xs={4}>
        <TextField
          style={{ width: '100%' }}
          id="src"
          label="Src:"
          name="src"
          value={Src}
          select
          onChange={(event) => dsRecord.set({ ...dsRecord.get(), Src: event.target.value })}
        >
          {Object.entries(_SSP_TypeDefs.ssp_subsystem_address_e).map(([key, val]) => {
            return (
              <MenuItem value={Number(val)} key={key}>
                {key} ({val})
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          style={{ width: '100%' }}
          id="dst"
          name="dst"
          label="Dst:"
          value={Dst}
          select
          onChange={(event) => dsRecord.set({ ...dsRecord.get(), Dst: event.target.value })}
        >
          {Object.entries(_SSP_TypeDefs.ssp_subsystem_address_e).map(([key, val]) => {
            return (
              <MenuItem value={val} key={key}>
                {key} ({val})
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField style={{ width: '100%' }} label="Type" value={Type} disabled />
      </Grid>
    </Grid>
  );
};

SSPFrameBuilder.propTypes = {
  classes: PropTypes.shape({
    CardContent: PropTypes.string,
    ButtonLabel: PropTypes.string,
  }).isRequired,
  Frame: PropTypes.shape({
    Src: PropTypes.number.isRequired,
    Dst: PropTypes.number.isRequired,
    Type: PropTypes.number.isRequired,
  }).isRequired,
  dsRecord: PropTypes.shape({
    set: PropTypes.func,
    get: PropTypes.func,
  }).isRequired,
};

export default SSPFrameBuilder;
