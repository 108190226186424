import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useSnackbar from '#hooks/useSnackbar';

import TCQueuePageWrapperStyles from './TCQueuePageWrapperStyles';

const useStyles = makeStyles(TCQueuePageWrapperStyles);

const TCQueuePageWrapper = ({ children }) => {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { enqueueSnackbar } = useSnackbar();

  const {
    missionKey,
    missionMeta: { commandingProtocol },
  } = useCurrentMission();

  const tctmCreateFunction = useCallback(
    async (paramsObject, snackbarMessage) => {
      const tcJson = paramsObject.SSPFrame.Data.TCFrame;

      const rpcUrl = `rg/${missionKey}/@c3s/fifo-provider/push`;
      const ret = await rpcWithErrorHandler(rpcUrl, { fifo: 'operator', item: tcJson });
      if (ret.status !== 'error') {
        enqueueSnackbar(
          <span>
            Enqueued TC.
            <br />
            {snackbarMessage}
          </span>,
          { variant: 'info' },
        );
      }
    },
    [missionKey, rpcWithErrorHandler, enqueueSnackbar],
  );

  const fileTransferRpc = { rpc: `rg/${missionKey}/@c3s/fifo-provider/pushToServices`, format: 'tc' };

  const classes = useStyles();

  return (
    <RgContext.Provider
      value={{
        currentResGroup: missionKey,
        tcProtocolStack: [commandingProtocol],
        tctmCreateFunction,
        fileTransferRpc,
      }}
    >
      <div className={classes.Wrapper}>{children}</div>
    </RgContext.Provider>
  );
};

TCQueuePageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TCQueuePageWrapper;
