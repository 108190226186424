import { useContext, useEffect } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';

const useDsRecordSnapshot = (recordPath, onRecordSnapshot) => {
  const { dsClient } = useContext(DeepstreamContext);

  useEffect(() => {
    async function getSnapshot() {
      const record = await dsClient.record.snapshot(recordPath);
      onRecordSnapshot(record);
    }
    getSnapshot();
  }, [dsClient, recordPath, onRecordSnapshot]);
};

export default useDsRecordSnapshot;
