import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdorment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function constrain(value, min, max) {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const DataRangeSelect = ({ range, totalSize, setRange }) => {
  const classes = useStyles();
  const [constrainValues, setConstrainValues] = useState(totalSize !== undefined);

  useEffect(() => {
    if (totalSize === undefined) {
      setConstrainValues(false);
    }
  }, [totalSize]);

  const applyConstraints = () => {
    if (constrainValues) {
      setRange((prevRange) => {
        const newAddress = constrain(prevRange.address, 0, totalSize - 1);
        const newAmount = constrain(prevRange.amount, 0, totalSize - newAddress < 0 ? 0 : totalSize - newAddress);
        return {
          address: newAddress,
          amount: newAmount,
        };
      });
    }
  };

  useEffect(applyConstraints, [setRange, totalSize, constrainValues]);

  const { address, amount } = range;

  return (
    <Grid container className={classes.root}>
      {constrainValues && (
        <Grid item xs={12}>
          <Slider
            min={0}
            max={totalSize}
            step={1024}
            value={[address, address + amount]}
            onChangeCommitted={(event, [newStart, newEnd]) => {
              setRange({ address: newStart, amount: newEnd - newStart });
            }}
          />
        </Grid>
      )}
      <Grid item container spacing={2} alignItems="baseline" justify="space-evenly">
        <Grid item xs container spacing={1} justify="center">
          <Grid item>
            <TextField
              label="Start Address"
              type="number"
              step={1024}
              value={address}
              onChange={(event) => {
                const newAddress = event.target.value === '' ? 0 : Number(event.target.value);
                setRange((prevRange) => ({ ...prevRange, address: newAddress }));
                applyConstraints();
              }}
              InputProps={{
                inputProps: { step: 4096 },
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Amount"
              type="number"
              value={amount}
              onChange={(event) => {
                const newAmount = event.target.value === '' ? 0 : Number(event.target.value);
                setRange((prevRange) => ({ ...prevRange, amount: newAmount }));
                applyConstraints();
              }}
              InputProps={{
                endAdornment: <InputAdorment position="end">bytes</InputAdorment>,
                inputProps: { step: 1024 },
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            disabled={totalSize === undefined}
            control={
              <Switch
                size="small"
                color="primary"
                checked={constrainValues}
                onChange={(event) => setConstrainValues(event.target.checked)}
              />
            }
            label={<Typography variant="body2">Constrain Values</Typography>}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DataRangeSelect.propTypes = {
  range: PropTypes.shape({
    address: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
  totalSize: PropTypes.number,
  setRange: PropTypes.func.isRequired,
};

DataRangeSelect.defaultProps = {
  totalSize: undefined,
};

export default DataRangeSelect;
