import { HexInput } from '@c3s/ui-components';
import { InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const BytesField = ({ ctype, fieldInternalState, dispatchFieldAction }) => {
  const match = ctype?.match(/(?<elementCtype>\w+)\s*\[(?<capacityStr>[0-9]+)\]/);
  const { capacityStr } = match?.groups ?? {};
  const capacity = parseInt(capacityStr, 10);

  const setFieldText = useCallback(
    (value) => {
      dispatchFieldAction({
        type: 'set-field-text',
        value,
      });
    },
    [dispatchFieldAction],
  );

  return (
    <HexInput
      multiline={capacity > 16}
      rows={Math.ceil(capacity / 32)}
      bytes={capacity}
      value={fieldInternalState?.fieldText}
      setValue={setFieldText}
      startAdornment={<InputAdornment position="start">0x</InputAdornment>}
    />
  );
};

BytesField.propTypes = {
  ctype: PropTypes.string.isRequired,
  fieldInternalState: PropTypes.shape({ fieldText: PropTypes.string, parseError: PropTypes.string }),
  dispatchFieldAction: PropTypes.func.isRequired,
};

BytesField.defaultProps = {
  fieldInternalState: { fieldText: '' },
};

export default BytesField;
