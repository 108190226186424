import React from 'react';
import { Helmet } from 'react-helmet';

import StationClusterTable from '#components/page-content/station-clusters-table';

import AddStation from './new-station-form';

const PageTitle = 'Station Clusters';

const StationClustersPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <div>
        <h1>{PageTitle}</h1>
        <StationClusterTable />
        <AddStation />
      </div>
    </>
  );
};

StationClustersPage.propTypes = {};

export default StationClustersPage;
