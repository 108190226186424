import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccordionSummary, Typography } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Duration } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import StatusValueDisplay from '../cards/indicators/StatusValue';
import StatusPanelStyles from './StatusPanel.styles';

const useStyles = makeStyles(StatusPanelStyles);

const StatusPanelTitle = ({
  isExpanded,
  ShallStickStatus,
  toggleShallStickStatus,
  connectionString,
  connectionIsAlive,
  AutoReportPeriodTimeInMs,
  isAutoReportSet,
}) => {
  const { accordionSummaryRoot, expanded, content, descriptionColumn, titleColumn, title } = useStyles();

  function displayDescription(visible) {
    if (visible) {
      return (
        <div className={descriptionColumn}>
          <StatusValueDisplay
            title="Connection"
            status={connectionIsAlive ? 'success' : 'danger'}
            value={connectionString}
          />
          <StatusValueDisplay
            title="Automatic reporting"
            status={isAutoReportSet ? 'success' : 'danger'}
            value={
              isAutoReportSet
                ? `ON @ ${Duration.fromMillis(AutoReportPeriodTimeInMs).toFormat(`mm 'm' ss 's' SSS 'ms' `)}`
                : `OFF`
            }
          />
        </div>
      );
    }
    return '';
  }

  return (
    <AccordionSummary
      classes={{
        root: accordionSummaryRoot,
        content,
        expanded,
      }}
      expandIcon={<ExpandMoreIcon />}
    >
      <div className={titleColumn}>
        <Typography className={title}>
          <FontAwesomeIcon
            icon="thumbtack"
            color={ShallStickStatus ? blue[500] : 'black'}
            size="sm"
            onClick={toggleShallStickStatus}
          />{' '}
          Bustester status
        </Typography>
      </div>
      {displayDescription(!isExpanded)}
    </AccordionSummary>
  );
};

StatusPanelTitle.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  ShallStickStatus: PropTypes.bool.isRequired,
  toggleShallStickStatus: PropTypes.func.isRequired,
  connectionString: PropTypes.string.isRequired,
  connectionIsAlive: PropTypes.bool.isRequired,
  AutoReportPeriodTimeInMs: PropTypes.number.isRequired,
  isAutoReportSet: PropTypes.bool.isRequired,
};

export default StatusPanelTitle;
