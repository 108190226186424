import {
  RADMAGDataAcquisitionServiceDropDown,
  RADMAGEventReportingServiceDropDown,
  RADMAGFunctionManagementServiceDropDown,
  RADMAGHousekeepingReportingServiceDropDown,
  RADMAGTestServiceDropDown,
} from '@c3s/ui-radcube-protocol-tctm';

export const components = [
  RADMAGHousekeepingReportingServiceDropDown,
  RADMAGTestServiceDropDown,
  RADMAGDataAcquisitionServiceDropDown,
  RADMAGEventReportingServiceDropDown,
  RADMAGFunctionManagementServiceDropDown,
];

export const initialOpenState = Array(components.length).fill(false);

export const reducer = (state, { index, value }) => {
  const newState = Array(components.length).fill(false);
  newState[index] = value;
  return newState;
};
