import { Button, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import styles from './EgseControlCard.styles';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import useEgseControlCard from './useEgseControlCard';

const useStyles = makeStyles(styles);

const EgseControlCard = () => {
  const { enableLog, disableLog, eraseChip, dump, progress, reply, buttonsDisabled } = useEgseControlCard();
  const classes = useStyles();

  return (
    <Card className={classes.Card}>
      <CardHeader title="EGSE Control" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
      <CardContent className={classes.CardContent}>
        <Typography variant="h6" gutterBottom>
          Progress: <LinearProgressWithLabel value={progress.percent} />
        </Typography>
      </CardContent>
      <CardContent className={classes.CardContent}>
        <Typography variant="h6" gutterBottom>
          Total sectors: {progress.total}
        </Typography>
      </CardContent>
      <CardContent className={classes.CardContent}>
        <Typography variant="h6" gutterBottom>
          Remaining sectors: {progress.remaining}
        </Typography>
      </CardContent>
      <CardContent className={classes.CardContent}>
        <Typography variant="h6" gutterBottom>
          Reply: {reply}
        </Typography>
      </CardContent>
      <CardContent className={classes.CardContent}>
        <Button disabled={buttonsDisabled} size="small" color="default" variant="outlined" onClick={disableLog}>
          Disable Log
        </Button>
        <Button disabled={buttonsDisabled} size="small" color="default" variant="outlined" onClick={enableLog}>
          Enable Log
        </Button>
        <Button disabled={buttonsDisabled} size="small" color="primary" variant="outlined" onClick={dump}>
          Dump Data
        </Button>
        <Button disabled={buttonsDisabled} size="small" color="secondary" variant="outlined" onClick={eraseChip}>
          Erase Chip
        </Button>
      </CardContent>
    </Card>
  );
};

export default EgseControlCard;
