import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import TestConnectionStatusCircle from '#components/page-content/test-connectors/status/TestConnectionStatusCircle';
import TestingContext from '#contexts/TestingContext';
import useCurrentMission from '#hooks/useCurrentMission';

const testConnectorFeatureSetOptions = [
  { value: null, label: 'None (generic)' },
  { value: 'bus-tester', label: 'Bus Tester' },
  { value: 'access-port', label: 'Access Port' },
  { value: 'owl-tester', label: 'OWL Tester' },
];

function validateIp(address) {
  if (typeof address !== 'string' || !address.match(/^\S+$/)) {
    return false;
  }
  const octets = address.split('.').map((o) => parseInt(o, 10));
  if (octets.length !== 4 || octets.some((o) => Number.isNaN(o) || o < 0 || o > 255)) {
    return false;
  }
  return true;
}

const TestConnectorTable = () => {
  const { testConnectors, listTestConnectors, addTestConnector, updateTestConnector, removeTestConnector } = useContext(
    TestingContext,
  );

  const [editedTestConnector, setEditedTestConnector] = useState({});
  const [editedTestConnectorValidationState, setEditedTestConnectorValidationState] = useState({});

  const [dialogState, setDialogState] = useState(false);

  const updateEditedTestConnectorDisplayName = useCallback((event) => {
    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      displayName: event.target.value,
    }));
  }, []);

  const updateEditedTestConnectorId = useCallback((event) => {
    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      id: event.target.value.replace(/[^\w-=]+/g, ''),
    }));
  }, []);

  const updateEditedTestConnectorFeatureSet = useCallback((event) => {
    const connectorFeatureSet = event.target.value;

    let overwriteCommandProtocolStack = null;
    if (connectorFeatureSet === 'bus-tester') {
      overwriteCommandProtocolStack = ['ETH', 'SSP'];
    }
    if (connectorFeatureSet === 'access-port') {
      overwriteCommandProtocolStack = ['SSP'];
    }
    if (connectorFeatureSet === 'owl-tester') {
      overwriteCommandProtocolStack = ['PC2GS'];
    }

    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      connectorFeatureSet,
      commandProtocolStack: overwriteCommandProtocolStack ?? prevEditedTestConnector.commandProtocolStack,
    }));
  }, []);

  const updateEditedTestConnectorCommandProtocolStack = useCallback((event) => {
    const commandProtocolStack = event.target.value === '' ? [] : event.target.value.replace(/\s+/g, '').split(',');

    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      commandProtocolStack,
    }));
  }, []);

  const updateEditedTestConnectorConnectionType = useCallback((event) => {
    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      connection: {
        type: event.target.value,
      },
    }));
  }, []);
  const updateEditedTestConnectorAddress = useCallback((event) => {
    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      connection: {
        ...prevEditedTestConnector.connection,
        ip: event.target.value,
      },
    }));
  }, []);
  const updateEditedTestConnectorPort = useCallback((event) => {
    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      connection: {
        ...prevEditedTestConnector.connection,
        port: parseInt(event.target.value, 10),
      },
    }));
  }, []);
  const updateEditedTestConnectorDevice = useCallback((event) => {
    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      connection: {
        ...prevEditedTestConnector.connection,
        dev: event.target.value,
      },
    }));
  }, []);
  const updateEditedTestConnectorBaud = useCallback((event) => {
    setEditedTestConnector((prevEditedTestConnector) => ({
      ...prevEditedTestConnector,
      connection: {
        ...prevEditedTestConnector.connection,
        baud: parseInt(event.target.value, 10),
      },
    }));
  }, []);

  const openDialogForCreate = useCallback(() => {
    setEditedTestConnector({});
    setDialogState('creating');
  }, []);

  const openDialogForEdit = useCallback(
    (id) => {
      setEditedTestConnector({ ...testConnectors[id], id });
      setDialogState('editing');
    },
    [testConnectors],
  );

  const handleDialogCancel = useCallback(() => {
    setDialogState(false);
  }, []);

  const handleDialogSubmit = useCallback(() => {
    const { id, ...editedTestConnectorValue } = editedTestConnector;
    if (dialogState === 'creating') {
      addTestConnector(id, editedTestConnectorValue);
    }
    if (dialogState === 'editing') {
      updateTestConnector(id, editedTestConnectorValue);
    }
    setDialogState(false);
  }, [addTestConnector, dialogState, editedTestConnector, updateTestConnector]);

  const columns = useMemo(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.075,
        valueGetter: (params) => params.getValue(params.id, 'id'),
        // eslint-disable-next-line react/destructuring-assignment
        renderCell: (params) => <TestConnectionStatusCircle connectorKey={params.value} />,
      },
      {
        field: 'displayName',
        headerName: 'Name',
        flex: 0.2,
      },
      {
        field: 'id',
        headerName: 'ID',
        flex: 0.2,
      },
      {
        field: 'connectorFeatureSet',
        headerName: 'Feature Set',
        flex: 0.2,
        type: 'singleSelect',
        valueOptions: testConnectorFeatureSetOptions.map(({ value }) => value),
      },
      {
        field: 'commandProtocolStack',
        headerName: 'Protocol Stack',
        flex: 0.2,
        valueFormatter: (params) => params.value?.join(', ') ?? '',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.15,
        valueGetter: (params) => params.getValue(params.id, 'id'),
        renderCell: (params) => (
          <>
            <IconButton onClick={() => openDialogForEdit(params.value)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => removeTestConnector(params.value)}>
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ],
    [removeTestConnector, openDialogForEdit],
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button
          onClick={() => {
            listTestConnectors();
          }}
          size="small"
          color="primary"
          startIcon={<CachedIcon />}
        >
          Refresh
        </Button>
        <Button onClick={openDialogForCreate} size="small" color="primary" startIcon={<AddIcon />}>
          Add New Test Connector
        </Button>
      </GridToolbarContainer>
    );
  }

  const { missionMeta } = useCurrentMission();

  useEffect(() => {
    const validation = {};
    validation.displayName = !!editedTestConnector.displayName?.replace(/\s+/g, '');
    validation.id =
      !!editedTestConnector.id?.replace(/\s+/g, '') &&
      (dialogState === 'editing' || !Object.keys(testConnectors).includes(editedTestConnector.id));
    validation.connectorFeatureSet = testConnectorFeatureSetOptions
      .map(({ value }) => value)
      .includes(editedTestConnector.connectorFeatureSet);
    validation.commandProtocolStack =
      editedTestConnector.commandProtocolStack === undefined ||
      editedTestConnector.commandProtocolStack.length === 0 ||
      (missionMeta?.protocols &&
        editedTestConnector.commandProtocolStack?.every((p) =>
          Object.keys(missionMeta?.structs) // TODO narrow this down to toplevel structs of protocols
            .map((k) => k.replace(/_+/g, '')) // TODO remove this map when we have struct metas for subservices
            .includes(p),
        ));

    validation.connection = {};
    validation.connection.ip = validateIp(editedTestConnector.connection?.ip);
    validation.connection.port =
      editedTestConnector.connection?.port && !Number.isNaN(editedTestConnector.connection?.port);
    validation.connection.dev = !!editedTestConnector.connection?.dev?.replace(/\s+/g, '');
    validation.connection.baud =
      editedTestConnector.connection?.baud && !Number.isNaN(editedTestConnector.connection?.baud);
    validation.connection.all =
      (editedTestConnector.connection?.type === 'tcp' && validation.connection.ip && validation.connection.port) ||
      (editedTestConnector.connection?.type === 'serial' && validation.connection.dev && validation.connection.baud);

    validation.all =
      validation.displayName &&
      validation.id &&
      validation.connectorFeatureSet &&
      validation.commandProtocolStack &&
      validation.connection.all;

    setEditedTestConnectorValidationState(validation);
  }, [dialogState, editedTestConnector, missionMeta, testConnectors]);

  return (
    <>
      <Dialog open={!!dialogState} onClose={handleDialogCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {dialogState === 'creating' ? 'Add New Test Connector' : 'Update Test Connector'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={editedTestConnector.displayName}
            onChange={updateEditedTestConnectorDisplayName}
            error={!editedTestConnectorValidationState.displayName}
          />
          <TextField
            margin="dense"
            label="ID"
            type="text"
            fullWidth
            value={editedTestConnector.id}
            onChange={updateEditedTestConnectorId}
            error={!editedTestConnectorValidationState.id}
            disabled={dialogState === 'editing'}
          />
          <TextField
            margin="dense"
            label="Feature Set"
            select
            fullWidth
            value={editedTestConnector.connectorFeatureSet ?? ''}
            onChange={updateEditedTestConnectorFeatureSet}
            error={!editedTestConnectorValidationState.connectorFeatureSet}
          >
            {testConnectorFeatureSetOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Protocol Stack"
            type="text"
            fullWidth
            value={editedTestConnector.commandProtocolStack?.join(', ')}
            onChange={updateEditedTestConnectorCommandProtocolStack}
            error={!editedTestConnectorValidationState.commandProtocolStack}
            helperText={
              !editedTestConnectorValidationState.commandProtocolStack
                ? 'Some of the specified protocols are unknown for this mission'
                : 'Specify the protocol needed to communicate with the test connector. Leave blank if the test connector forwards the protocol of the test item verbatim.'
            }
            InputLabelProps={{ shrink: !!editedTestConnector.commandProtocolStack }}
          />
          <FormGroup component="fieldset" style={{ marginTop: 16 }}>
            <FormLabel component="legend">Connection</FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={editedTestConnector.connection?.type ?? ''}
                onChange={updateEditedTestConnectorConnectionType}
              >
                <FormControlLabel value="tcp" control={<Radio />} label="TCP/IP" />
                <FormControlLabel value="serial" control={<Radio />} label="Serial" />
              </RadioGroup>
            </FormControl>
            {editedTestConnector.connection?.type === 'tcp' && (
              <>
                <TextField
                  margin="dense"
                  label="IP Address"
                  type="text"
                  fullWidth
                  value={editedTestConnector.connection?.ip}
                  onChange={updateEditedTestConnectorAddress}
                  error={!editedTestConnectorValidationState.connection?.ip}
                />
                <TextField
                  margin="dense"
                  label="Port"
                  type="number"
                  fullWidth
                  value={editedTestConnector.connection?.port}
                  onChange={updateEditedTestConnectorPort}
                  error={!editedTestConnectorValidationState.connection?.port}
                />
              </>
            )}
            {editedTestConnector.connection?.type === 'serial' && (
              <>
                <TextField
                  margin="dense"
                  label="Device"
                  type="text"
                  fullWidth
                  value={editedTestConnector.connection?.dev}
                  onChange={updateEditedTestConnectorDevice}
                  error={!editedTestConnectorValidationState.connection?.dev}
                />
                <TextField
                  margin="dense"
                  label="Baud Rate"
                  type="number"
                  fullWidth
                  value={editedTestConnector.connection?.baud}
                  onChange={updateEditedTestConnectorBaud}
                  error={!editedTestConnectorValidationState.connection?.baud}
                />
              </>
            )}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} color="primary" disabled={!editedTestConnectorValidationState.all}>
            {dialogState === 'creating' ? 'Add' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      <Box py={1}>
        <DataGrid
          rows={Object.entries(testConnectors).map(([id, testConnector]) => ({ ...testConnector, id }))}
          columns={columns}
          density="compact"
          pageSize={10}
          components={{
            Toolbar: CustomToolbar,
          }}
          autoHeight
        />
      </Box>
    </>
  );
};

export default TestConnectorTable;
