import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import { diskIDOptions, diskStateOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import GridDescriptionList from '#components/ui-helper/GridDescriptionList';
import TimeAgo from '#components/ui-helper/TimeAgo';

import usePanelStyles from '../../../usePanelStyles';

const DiskInfoPanel = ({ diskId, disk, updateDiskInfo }) => {
  const classes = usePanelStyles();
  const {
    diskState,
    diskSize,
    diskFreeSpaceBasedOnSectors,
    diskFreeSpaceBasedOnData,
    numberOfFiles,
    numberOfOpenedFiles,
    pageSizeInBytes,
    sectorSizeInPages,
    dataCacheSizeInPages,
    sectorDataSizeInPages,
    numberOfFileSystemSectors,
    lastUpdated,
  } = disk ?? {};

  const data = [
    { label: 'State', value: diskStateOptions.find((option) => option.value === diskState)?.label, gridWidth: 12 },
    { label: 'Size', value: diskSize, gridWidth: 4 },
    { label: 'Free Space Based on Sectors', value: diskFreeSpaceBasedOnSectors, gridWidth: 4 },
    { label: 'Free Space Based on Data', value: diskFreeSpaceBasedOnData, gridWidth: 4 },
    { label: 'Number of Files', value: numberOfFiles, gridWidth: 4 },
    { label: 'Number of Opened Files', value: numberOfOpenedFiles, gridWidth: 4 },
    { label: 'Page Size', value: pageSizeInBytes, suffix: ' bytes', gridWidth: 4 },
    { label: 'Sector Size', value: sectorSizeInPages, suffix: ' pages', gridWidth: 4 },
    { label: 'Data Cache Size', value: dataCacheSizeInPages, suffix: ' pages', gridWidth: 4 },
    { label: 'Sector Data Size', value: sectorDataSizeInPages, suffix: ' pages', gridWidth: 4 },
    { label: 'Number of Filesystem Sectors', value: numberOfFileSystemSectors, gridWidth: 4 },
  ];

  return (
    <>
      <Typography className={classes.panelTitle}>
        {diskIDOptions.find((option) => option.value === diskId).label} Disk Information
      </Typography>
      <GridDescriptionList data={data} />
      <Grid container className={classes.panelActionRow} spacing={2} alignItems="baseline" justify="flex-end">
        <Grid item>
          <Typography variant="body2">
            Last updated <TimeAgo date={parseISO(lastUpdated)} />
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={updateDiskInfo}>
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

DiskInfoPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  disk: PropTypes.shape({
    diskState: PropTypes.oneOf(Object.values(TCTM.tctm_fs_disk_state_e)),
    diskSize: PropTypes.number,
    diskFreeSpaceBasedOnSectors: PropTypes.number,
    diskFreeSpaceBasedOnData: PropTypes.number,
    numberOfFiles: PropTypes.number,
    numberOfOpenedFiles: PropTypes.number,
    pageSizeInBytes: PropTypes.number,
    sectorSizeInPages: PropTypes.number,
    dataCacheSizeInPages: PropTypes.number,
    sectorDataSizeInPages: PropTypes.number,
    numberOfFileSystemSectors: PropTypes.number,
    lastUpdated: PropTypes.string,
  }),
  updateDiskInfo: PropTypes.func.isRequired,
};

DiskInfoPanel.defaultProps = {
  disk: {},
};

export default DiskInfoPanel;
