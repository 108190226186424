import { Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import React from 'react';

import useConnectionCard from './useRawCommandCard';

const RawCommandCard = () => {
  const { command, handleChange, onSend } = useConnectionCard();
  return (
    <Card>
      <CardHeader title="Send Raw Command" titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <TextField
          label="Enter Raw Command"
          helperText="Newlines will be translated to \r\n."
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={command}
          onChange={handleChange}
        />
      </CardContent>
      <CardContent>
        <Button size="small" color="primary" variant="outlined" onClick={onSend}>
          Send
        </Button>
      </CardContent>
    </Card>
  );
};

export default RawCommandCard;
