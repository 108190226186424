import { useCallback, useContext, useMemo, useReducer } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const reducer = (s, a) => ({ ...s, ...a });

const initialState = {
  lastUpdated: 0,
  isPolling: false,
  isUpdating: false,
  isConnected: false,
  relativeHumidity: 0,
  barometricAltitude: 0,
  pressure: 0,
  temperatureOutdoor: 0,
  temperatureWindchill: 0,
  temperatureDewpoint: 0,
  temperatureSystem: 0,
  windSpeed: 0,
  windGust: 0,
  actualWindSpeed: 0,
  windDir: 0,
  connectionURL: '',
  updatePeriod: 2000,
};

export default function useWeatherDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const weatherStationPath = useMemo(() => `rg/${currentResGroup}/@c3s/weather-station-provider`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    if ('lastUpdated' in newRecord) {
      dispatch({ lastUpdated: newRecord.lastUpdated });
    }
    if ('isPolling' in newRecord) {
      dispatch({ isPolling: newRecord.isPolling });
    }
    if ('isUpdating' in newRecord) {
      dispatch({ isUpdating: newRecord.isUpdating });
    }
    if ('isConnected' in newRecord) {
      dispatch({ isConnected: newRecord.isConnected });
    }
    if ('relativeHumidity' in newRecord) {
      dispatch({ relativeHumidity: newRecord.relativeHumidity });
    }
    if ('barometricAltitude' in newRecord) {
      dispatch({ barometricAltitude: newRecord.barometricAltitude });
    }
    if ('pressure' in newRecord) {
      dispatch({ pressure: newRecord.pressure });
    }
    if ('temperatureOutdoor' in newRecord) {
      dispatch({ temperatureOutdoor: newRecord.temperatureOutdoor });
    }
    if ('temperatureWindchill' in newRecord) {
      dispatch({ temperatureWindchill: newRecord.temperatureWindchill });
    }
    if ('temperatureDewpoint' in newRecord) {
      dispatch({ temperatureDewpoint: newRecord.temperatureDewpoint });
    }
    if ('temperatureSystem' in newRecord) {
      dispatch({ temperatureSystem: newRecord.temperatureSystem });
    }
    if ('windSpeed' in newRecord) {
      dispatch({ windSpeed: newRecord.windSpeed });
    }
    if ('windGust' in newRecord) {
      dispatch({ windGust: newRecord.windGust });
    }
    if ('windDir' in newRecord) {
      dispatch({ windDir: newRecord.windDir });
    }
    if ('connectionURL' in newRecord) {
      dispatch({ connectionURL: newRecord.connectionURL });
    }
    if ('updatePeriod' in newRecord) {
      dispatch({ updatePeriod: newRecord.updatePeriod });
    }
  }, []);

  useDsRecordSubscription(`${weatherStationPath}/status`, handleChange);

  return {
    weatherState: state,
    weatherStationPath,
  };
}
