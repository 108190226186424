import { Grid } from '@material-ui/core';
import React from 'react';

import PowerCard from './Cards/LclControl/PowerCard';
import PowerSwitchCard from './Cards/LclControl/PowerSwitchCard';

const PowerControlTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <PowerCard />
    </Grid>
    <Grid item md={3}>
      <PowerSwitchCard />
    </Grid>
  </Grid>
);

export default PowerControlTab;
