import { EVENT } from '@deepstream/client/dist/src/constants';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';

const useDsRecordSubscription = (recordPath, onRecordUpdated, triggerNow, startAttached) => {
  const { dsClient } = useContext(DeepstreamContext);

  const dsRecord = useRef();
  const [isAttached, setIsAttached] = useState(startAttached ?? true);
  const [hasProvider, setHasProvider] = useState(undefined);
  const [isReady, setIsReady] = useState(undefined);
  const [isDestroyed, setIsDestroyed] = useState(undefined);
  const [lastError, setLastError] = useState(undefined);

  useEffect(() => {
    if (isAttached) {
      const record = dsClient.record.getRecord(recordPath);

      setHasProvider(record.hasProvider);
      setIsReady(record.isReady);
      setIsDestroyed(record.isDestroyed);
      setLastError(null);

      // record.on(EVENT.RECORD_HAS_PROVIDER_CHANGED, (v) => setHasProvider(v));
      record.record?.on?.(EVENT.RECORD_HAS_PROVIDER_CHANGED, (v) => setHasProvider(v)); // Workaround DS client bug
      record.on(EVENT.RECORD_DELETED, () => setIsDestroyed(true));
      record.on(EVENT.RECORD_DISCARDED, () => setIsDestroyed(true));
      record.on(EVENT.RECORD_ERROR, (e) => setLastError(e));

      record.whenReady(() => setIsReady(true));

      dsRecord.current = record;

      return () => {
        record.discard();
        setHasProvider(undefined);
        setIsReady(undefined);
        setIsDestroyed(undefined);
        setLastError(undefined);
      };
    }
    return undefined;
  }, [dsClient, isAttached, recordPath]);

  useEffect(() => {
    if (isAttached) {
      const record = dsRecord.current;
      record.subscribe(onRecordUpdated, triggerNow ?? true);

      return () => {
        if (!record.isDestroyed) {
          record.unsubscribe(onRecordUpdated);
        }
      };
    }
    return undefined;
  }, [dsClient, isAttached, recordPath, onRecordUpdated, triggerNow]);

  const setRecord = useCallback((value) => {
    if (dsRecord.current === undefined || dsRecord.current.isDestroyed) {
      throw new Error('Tried to set DS record in invalid state');
    }
    dsRecord.current.set(value);
  }, []);

  return {
    setRecord,
    isAttached,
    setIsAttached,
    isReady,
    isDestroyed,
    hasProvider,
    lastError,
  };
};

export default useDsRecordSubscription;
