import useProtocolMessage from '#hooks/useProtocolMessage';

import useMlvdsHeartBeatStatusEventListener from './useMlvdsHeartBeatStatusEventListener';

export default function useMlvdsHeartBeatStatusMessage() {
  return useProtocolMessage({
    listener: useMlvdsHeartBeatStatusEventListener,
    transform: (x) => ({
      SID: -1,
      value: {
        // TODO: remove hardcoded value after implementing thins function in bustester hardware
        IsEnabled: 0,
        SendAs: 'OBC',
        Message: '0x00',
        ...x.MlvdsHeartBeatStatus,
      },
    }),
    initialBody: {
      SID: -1,
      value: {
        IsEnabled: 0,
        SendAs: 'OBC',
        Message: '0x00',
      },
    },
  });
}
