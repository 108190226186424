import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import BusSelect from '#satellites/RADCUBE/BusSelect';

const ETHFrameBuilder = ({ classes, Frame: { Bus }, dsRecord }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <Button size="small" color="default" className={classes.ButtonLabel} disabled>
          ETH Frame
        </Button>
      </Grid>
      <Grid item xs={2}>
        <BusSelect
          style={{ width: '100%' }}
          id="BusSelect"
          name="BusSelect"
          value={Bus}
          error={false}
          label="Bus"
          onChange={(event) => {
            dsRecord.set({ ...dsRecord.get(), selectedBus: event.target.value });
          }}
        />
      </Grid>
    </Grid>
  );
};

ETHFrameBuilder.propTypes = {
  classes: PropTypes.shape({
    CardContent: PropTypes.string,
    ButtonLabel: PropTypes.string,
  }).isRequired,
  Frame: PropTypes.shape({
    Bus: PropTypes.number.isRequired,
  }).isRequired,
  dsRecord: PropTypes.shape({
    set: PropTypes.func,
    get: PropTypes.func,
  }).isRequired,
};

export default ETHFrameBuilder;
