import React from 'react';
import { Helmet } from 'react-helmet';

import BustesterPageWrapper from '#components/page-content/bus-tester/BustesterPageWrapper';
import TabPanel from '#components/page-fragments/TabPanel';
import tabs from '#connectors/configuration.constants';

const PageTitle = 'Bus Tester Configuration';

const BusTesterConfigurationPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <BustesterPageWrapper withStatus>
        <TabPanel tabs={tabs} />
      </BustesterPageWrapper>
    </>
  );
};

export default BusTesterConfigurationPage;
