import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import usePanelStyles from '../../../usePanelStyles';

const SetFileAttributesPanel = ({ diskId, fileId, fileAttributes, setAttributes }) => {
  const classes = usePanelStyles();
  const [isSystemFile, setIsSystemFile] = useState(fileAttributes?.isSystemFile ?? false);
  const [isProtectedFile, setIsProtectedFile] = useState(fileAttributes?.isProtectedFile ?? false);

  return (
    <>
      <Typography className={classes.panelTitle}>
        Set Attributes for file {fileId} on {diskIDOptions.find((option) => option.value === diskId).label}
      </Typography>
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSystemFile}
                  onChange={(event) => setIsSystemFile(event.target.checked)}
                  name="file-setattr-is-system"
                />
              }
              label="System File"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isProtectedFile}
                  onChange={(event) => setIsProtectedFile(event.target.checked)}
                  name="file-setattr-is-protected"
                />
              }
              label="Protected File"
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAttributes(isSystemFile ? 1 : 0, isProtectedFile ? 1 : 0)}
          >
            Set Attributes
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SetFileAttributesPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  fileId: PropTypes.number.isRequired,
  fileAttributes: PropTypes.shape({
    isSystemFile: PropTypes.bool,
    isProtectedFile: PropTypes.bool,
  }),
  setAttributes: PropTypes.func.isRequired,
};

SetFileAttributesPanel.defaultProps = {
  fileAttributes: {},
};

export default SetFileAttributesPanel;
