import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const RemoveFilePanel = ({ diskId, fileId, remove }) => {
  return (
    <>
      <Typography>
        Remove file {fileId} from {diskIDOptions.find((option) => option.value === diskId).label}
      </Typography>
      <Grid container spacing={2} alignItems="baseline">
        <Grid item>
          <FormControl margin="dense">
            <Button variant="contained" color="secondary" onClick={remove}>
              Remove
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

RemoveFilePanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  fileId: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
};

export default RemoveFilePanel;
