import { useContext } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordState from '#hooks/useDsRecordState';

const NAME = '@c3s/housekeeping-provider';

export function useCabinetHkStatusCard() {
  const { currentResGroup } = useContext(RgContext);

  const { record: status } = useDsRecordState(`rg/${currentResGroup}/${NAME}/status`, null);

  return { status };
}

export const labels = {
  inputLabels: [
    '',
    '',
    '',
    'Front Door Closed',
    'Rear Door Closed',
    '',
    'RF-FE Power Good',
    'Servo Power Good',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'PC On',
  ],
  outputLabels: [
    'Antenna Heater',
    'Mast Lamp',
    'Cabinet Heater',
    'Cabinet Cooler',
    'Cabinet Fan',
    'Reserved',
    '',
    '',
    'RF-FE Power',
    'Servo Power',
    'RF Attenuator',
    '',
    '',
    '',
    'SDR Power',
    'PC Power',
  ],
};
