import { ColoredCircleIcon } from '@c3s/ui-components';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

const CardHeaderWithStatus = ({ error, errorMsg, lastUpdated, titleText }) => {
  return (
    <CardHeader
      title={
        <>
          {error === 'err' ? (
            <Tooltip title={errorMsg}>
              <span>
                <ColoredCircleIcon status="danger" />
              </span>
            </Tooltip>
          ) : (
            <ColoredCircleIcon status="success" />
          )}
          {titleText}
        </>
      }
      subheader={`(Last updated at ${DateTime.fromMillis(lastUpdated).toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS,
      )})`}
      subheaderTypographyProps={{ variant: 'caption' }}
      titleTypographyProps={{ variant: 'h6' }}
      style={{ borderBottom: '1px solid #d2d2d2' }}
    />
  );
};

CardHeaderWithStatus.defaultProps = {
  errorMsg: '',
};

CardHeaderWithStatus.propTypes = {
  error: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  lastUpdated: PropTypes.number.isRequired,
  titleText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CardHeaderWithStatus;
