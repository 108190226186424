/* eslint-disable consistent-return */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TablePagination } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import MaterialTable, { MTableToolbar } from 'material-table';
import PropTypes from 'prop-types';
import React, { forwardRef, useRef } from 'react';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutlineIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <RemoveIcon {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumnIcon {...props} ref={ref} />),
};

const DynamicTable = ({
  actions,
  actionsColumnIndex,
  AdditionalComponents,
  columns,
  data,
  detailPanel,
  onSelectionChange,
  haveSearchBar,
  localization,
  options,
  pageSize,
  PaginationOptions,
  tableRef,
  title,
}) => {
  const defaultRef = useRef();

  return (
    <MaterialTable
      /*
        actions array should only be used if it contains nothing but global actions
          (row-specific actions are defined in the columns array)
      */
      tableRef={tableRef}
      actions={[
        ...actions,

        //  TODO: remove this action, make the parent handle it
        {
          isFreeAction: true,
          icon: forwardRef((props, ref) => (
            <FontAwesomeIcon {...props} ref={ref} icon={['fas', 'sync']} style={{ fontSize: 18 }} />
          )),
          tooltip: 'Refresh Data',
          onClick: () => {
            if (tableRef) {
              tableRef.current.onQueryChange();
            } else {
              defaultRef.current.onQueryChange();
            }
          },
        },
      ]}
      onSelectionChange={onSelectionChange}
      icons={tableIcons}
      title={title}
      detailPanel={detailPanel}
      columns={columns}
      data={data}
      localization={localization}
      /*
        searchBar had better not be set if the table updates automatically
        (textfield loses focus on every update)
      */

      options={{
        pageSize,
        actionsColumnIndex,
        search: haveSearchBar,
        emptyRowsWhenPaging: false,
        headerStyle: {
          zIndex: 'unset',
        },
        ...options,
      }}
      components={{
        Toolbar: (props) => (
          <>
            <MTableToolbar {...props} />
            <AdditionalComponents contentState={data} {...props} />
          </>
        ),
        Pagination: (props) => {
          if (PaginationOptions.length) {
            return <TablePagination {...props} rowsPerPageOptions={PaginationOptions} />;
          }
          return null;
        },
      }}
    />
  );
};

DynamicTable.defaultProps = {
  AdditionalComponents: () => <p />,
  actions: [],
  PaginationOptions: [],
  pageSize: 5,
  detailPanel: [],
  haveSearchBar: false,
  options: {},
  actionsColumnIndex: -1,
  onSelectionChange: () => {},
  localization: {},
};

DynamicTable.propTypes = {
  localization: PropTypes.shape({}),
  onSelectionChange: PropTypes.func,
  actionsColumnIndex: PropTypes.number,
  options: PropTypes.shape({}),
  detailPanel: PropTypes.arrayOf(PropTypes.shape({})),
  haveSearchBar: PropTypes.bool,
  pageSize: PropTypes.number,
  PaginationOptions: PropTypes.arrayOf(PropTypes.number),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  title: PropTypes.string.isRequired,
  AdditionalComponents: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tableRef: PropTypes.shape({
    current: PropTypes.shape({
      onQueryChange: PropTypes.func,
    }),
  }),
};

export default DynamicTable;
