import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

// eslint-disable-next-line import/no-cycle
import ArrayField from './ArrayField';
import BooleanField from './BooleanField';
import BytesField from './BytesField';
import ComplexField from './ComplexField';
import EnumField from './EnumField';
import NumberField from './NumberField';
import StringField from './StringField';
// eslint-disable-next-line import/no-cycle
import StructuredField from './StructuredField';

const useStyles = makeStyles(() => ({
  basicField: {
    flexGrow: 1,
    flexBasis: '17.5em',
    alignSelf: 'flex-end',
  },
  fullWidthField: {
    flexGrow: 1,
    flexBasis: '100%',
  },
}));

const Field = ({
  fieldId,
  fieldMeta,
  fieldValue,
  fieldInternalState,
  structId: overrideStructId,
  enumId: overrideEnumId,
  enumFilter,
  dispatchFieldAction,
  ...otherInputProps
}) => {
  const classes = useStyles();

  const setValue = useCallback(
    (value) => {
      if (fieldMeta !== undefined) {
        dispatchFieldAction({ type: 'set-value', value, fieldId, fieldMeta });
      }
    },
    [dispatchFieldAction, fieldId, fieldMeta],
  );

  const dispatchFieldActionWithMeta = useCallback(
    (action) => {
      if (fieldMeta !== undefined) {
        dispatchFieldAction({ ...action, fieldId, fieldMeta });
      }
    },
    [dispatchFieldAction, fieldId, fieldMeta],
  );

  const htmlId = `field-input-${fieldId}`;
  const htmlHelpId = `field-input-${fieldId}-help`;

  const { InputProps, ...fwdOtherInputProps } = otherInputProps;
  const fwdInputProps = { ...InputProps, id: htmlId, label: fieldMeta?.displayName, ariaDescribedby: htmlHelpId };

  let fieldInput;

  switch (fieldMeta?.type) {
    case 'number':
      fieldInput = (
        <NumberField
          ctype={fieldMeta.ctype}
          unit={fieldMeta.unit}
          polynomial={fieldMeta.polynomial}
          value={fieldInternalState}
          setValue={setValue}
          {...fwdInputProps}
        />
      );
      break;
    case 'boolean':
      fieldInput = (
        <BooleanField
          id={htmlId}
          value={fieldInternalState}
          setValue={setValue}
          label={fieldMeta.displayName}
          {...otherInputProps}
        />
      );
      break;
    case 'enum':
      fieldInput = (
        <EnumField
          enumId={overrideEnumId ?? fieldMeta.enum}
          enumFilter={enumFilter}
          value={fieldInternalState}
          setValue={setValue}
          {...fwdInputProps}
        />
      );
      break;
    case 'bytes':
      fieldInput = (
        <BytesField
          ctype={fieldMeta.ctype}
          fieldValue={fieldValue}
          fieldInternalState={fieldInternalState}
          dispatchFieldAction={dispatchFieldActionWithMeta}
          {...fwdInputProps}
        />
      );
      break;
    case 'string':
      fieldInput = (
        <StringField
          fieldMeta={fieldMeta}
          fieldInternalState={fieldInternalState}
          dispatchFieldAction={dispatchFieldActionWithMeta}
          {...fwdInputProps}
        />
      );
      break;
    case 'array':
      fieldInput = (
        <ArrayField
          fieldId={fieldId}
          fieldMeta={fieldMeta}
          fieldValue={fieldValue}
          fieldInternalState={fieldInternalState}
          dispatchFieldAction={dispatchFieldActionWithMeta}
          {...fwdInputProps}
        />
      );
      break;
    case 'struct':
      fieldInput = (
        <StructuredField
          structId={overrideStructId ?? fieldMeta.ctype}
          fieldValue={fieldValue}
          fieldInternalState={fieldInternalState}
          dispatchFieldAction={dispatchFieldActionWithMeta}
          {...fwdInputProps}
        />
      );
      break;
    case 'complex':
      fieldInput = (
        <ComplexField
          fieldValue={fieldValue}
          fieldInternalState={fieldInternalState}
          dispatchFieldAction={dispatchFieldActionWithMeta}
          {...fwdInputProps}
        />
      );
      break;
    case 'reserved':
    case undefined:
      return null;
    default:
      throw new Error(`Invalid field display type: ${fieldMeta.type}`);
  }

  let fieldControl;
  if (['boolean', 'array', 'struct'].includes(fieldMeta?.type)) {
    fieldControl = fieldInput;
  } else {
    fieldControl = (
      <FormControl
        margin="dense"
        size="small"
        variant={['string', 'complex'].includes(fieldMeta?.type) ? 'outlined' : 'standard'}
        {...fwdOtherInputProps}
        error={fieldInternalState?.validationError}
      >
        <InputLabel shrink htmlFor={htmlId}>
          {fieldMeta.displayName}
        </InputLabel>
        {fieldInput}
        {fieldInternalState?.validationError && (
          <FormHelperText id={htmlHelpId}>{fieldInternalState?.validationError}</FormHelperText>
        )}
      </FormControl>
    );
  }
  return (
    <div
      className={
        ['bytes', 'string', 'array', 'struct', 'complex'].includes(fieldMeta.type)
          ? classes.fullWidthField
          : classes.basicField
      }
    >
      {fieldControl}
    </div>
  );
};

Field.propTypes = {
  fieldId: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  fieldMeta: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object])).isRequired,
  structId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enumId: PropTypes.number,
  enumFilter: PropTypes.arrayOf(PropTypes.number),
  fieldValue: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]).isRequired,
  fieldInternalState: PropTypes.objectOf(PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object)
    .isRequired,
  dispatchFieldAction: PropTypes.func.isRequired,
};

Field.defaultProps = {
  structId: undefined,
  enumId: undefined,
  enumFilter: undefined,
};

export default Field;
