import { useContext, useEffect, useRef, useState } from 'react';
import { useToggle } from 'standard-hooks';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';

import useAutoReportPeriodTimeInMsMessage from '../../hooks/useAutoReportPeriodTimeInMsMessage';

export default function useStatusPanel() {
  const { dsClient } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const [connectionString, setConnectionString] = useState('disconnected');
  const [connectionIsAlive, setConnectionIsAlive] = useState(false);
  const [isExpanded, toggleExpanded] = useToggle(false);
  const AutoReportPeriodTimeInMsMessage = useAutoReportPeriodTimeInMsMessage();

  const dsConnectionStatusRecord = dsClient.record.getRecord(
    `rg/${currentResGroup}/@c3s/tester-provider/debug/bustester/status/connection`,
  );

  const dsConnectionSubscription = useRef((newRecord) => {
    setConnectionIsAlive(newRecord?.isAlive || false);
    if (newRecord?.isAlive && newRecord?.ipAddress && newRecord?.portNumber) {
      const { ipAddress, portNumber } = newRecord;
      setConnectionString(`${ipAddress} : ${portNumber}`);
    } else {
      setConnectionString(`disconnected`);
    }
  });

  useEffect(() => {
    dsConnectionStatusRecord.subscribe(dsConnectionSubscription.current, true);
    return function cleanup() {
      dsConnectionStatusRecord.discard();
    };
  }, [dsConnectionStatusRecord]);

  const AutoReportPeriodTimeInMs = AutoReportPeriodTimeInMsMessage?.body?.value || 0;
  const isAutoReportSet = AutoReportPeriodTimeInMs > 0;

  return {
    isExpanded,
    toggleExpanded,
    connectionString,
    connectionIsAlive,
    AutoReportPeriodTimeInMs,
    isAutoReportSet,
  };
}
