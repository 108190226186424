import { GpioCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useGPIOCard from './useGPIOCard';

const GPIOCard = () => {
  const { data, setData, setGpio, getGpio, dutRole } = useGPIOCard();
  return <GpioCard data={data} setData={setData} onGpioSet={setGpio} getGpio={getGpio} dutRole={dutRole} />;
};

export default GPIOCard;
