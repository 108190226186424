import { ControlCard, ErrorTableCard } from '@c3s/ui-tracking-station';
import { Button, Grid } from '@material-ui/core';
import React from 'react';

import AzimuthIndicator from './Indicators/AzimuthIndicator';
import ElevationIndicator from './Indicators/ElevationIndicator';
import useMainServoControl from './MainServoHooks/useMainServoControl';
import useServoAzConnection from './ServoAzConnectionHooks/useServoAzConnection';
import useServoAzControl from './ServoAzControlHooks/useServoAzControl';
import useServoAzStatus from './ServoAzStatusHooks/useServoAzStatus';
import useServoElConnection from './ServoElConnectionHooks/useServoElConnection';
import useServoElControl from './ServoElControlHooks/useServoElControl';
import useServoElStatus from './ServoElStatusHooks/useServoElStatus';
import useMotorCtrlAzDs from './useMotorCtrlAzDs';
import useMotorCtrlElDs from './useMotorCtrlElDs';

const MotorCtrlMonitorTab = () => {
  const { servoAzConnectionComponents } = useServoAzConnection();
  const { servoAzControlComponents, azClear } = useServoAzControl();
  const { servoAzStatusComponents, errors: azErrors } = useServoAzStatus();
  const { servoElControlComponents, elClear } = useServoElControl();
  const { servoElConnectionComponents } = useServoElConnection();
  const { servoElStatusComponents, errors: elErrors } = useServoElStatus();
  const { mainServoComponents } = useMainServoControl();
  const { motorCtrlEl, motionStopEl } = useMotorCtrlElDs();
  const { motorCtrlAz, motionStopAz } = useMotorCtrlAzDs();

  return (
    <>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={3}>
          <ControlCard title="AZ Connection" components={servoAzConnectionComponents} />
        </Grid>
        <Grid item xs={6} container spacing={1} direction="column" justify="space-between" alignItems="center">
          <Grid item>
            <ControlCard title="Main Connection" components={mainServoComponents} />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                motionStopEl();
                motionStopAz();
              }}
            >
              Motion Stop
            </Button>
          </Grid>
          <Grid item container spacing={2} justify="space-around" alignItems="center">
            <Grid item>
              <AzimuthIndicator azimuth={motorCtrlAz.status.ACT_POSITION} />
            </Grid>
            <Grid item>
              <ElevationIndicator elevation={motorCtrlEl.status.ACT_POSITION} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <ControlCard title="El Connection" components={servoElConnectionComponents} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <ControlCard title="Az Control" components={servoAzControlComponents} />
          <Grid container spacing={1}>
            <Grid item xs={6} md={6} lg={6}>
              <Button color="primary" variant="contained" onClick={azClear} fullWidth>
                Clear
              </Button>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Button color="primary" variant="contained" onClick={motionStopAz} fullWidth>
                Az Stop
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <ControlCard title="Az Status" components={servoAzStatusComponents} />
            </Grid>
            <Grid item xs={12} md={12}>
              <ErrorTableCard title="Az Errors" rows={azErrors} limit={10} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <ControlCard title="El Status" components={servoElStatusComponents} />
            </Grid>
            <Grid item xs={12} md={12}>
              <ErrorTableCard title="El Errors" rows={elErrors} limit={10} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <ControlCard title="El Control" components={servoElControlComponents} />
          <Grid container spacing={1}>
            <Grid item xs={6} md={6} lg={6}>
              <Button color="primary" variant="contained" onClick={elClear} fullWidth>
                Clear
              </Button>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Button color="primary" variant="contained" onClick={motionStopEl} fullWidth>
                El Stop
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MotorCtrlMonitorTab;
