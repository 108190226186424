import { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

const NAME = '@c3s/rf-fe-provider';

export default function useRffeControlCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { currentResGroup } = useContext(RgContext);
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const handleReset = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/reset`, {});
    enqueueSnackbar('RFFE Reset requested', { variant: 'info' });
  };

  const handleClearAllErrors = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearAllErrors`, {});
    enqueueSnackbar('RFFE Clear all errors requested', { variant: 'info' });
  };

  const handleClearPAOTError = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearPAOTError`, {});
    enqueueSnackbar('RFFE Clear PA O.T. error requested', { variant: 'info' });
  };

  const handleClearPAOCError = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearPAOCError`, {});
    enqueueSnackbar('RFFE Clear PA O.C. error requested', { variant: 'info' });
  };

  const handleClearRFSwitchPAOPError = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearRFSwitchPAOPError`, {});
    enqueueSnackbar('RFFE Clear RF Switch PA O.P. error requested', { variant: 'info' });
  };

  const handleClearBoardOTerror = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearBoardOTerror`, {});
    enqueueSnackbar('RFFE Clear Board O.T. error requested', { variant: 'info' });
  };

  const handleClearSWRValueMaxLimitReachedError = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearSWRValueMaxLimitReachedError`, {});
    enqueueSnackbar('RFFE Clear SWR Value Max Limit Reached error requested', { variant: 'info' });
  };

  const handleClearOutputPowerError = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearOutputPowerError`, {});
    enqueueSnackbar('RFFE Clear Output Power error requested', { variant: 'info' });
  };

  const handleClearPAStayedOnError = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearPAStayedOnError`, {});
    enqueueSnackbar('RFFE Clear PA stayed on error requested', { variant: 'info' });
  };

  const handleClearLNAStayedOnError = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearLNAStayedOnError`, {});
    enqueueSnackbar('RFFE Clear LNA stayed on error requested', { variant: 'info' });
  };

  const handleClearAllWarnings = async () => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/clearAllWarnings`, {});
    enqueueSnackbar('RFFE Clear All Warnings requested', { variant: 'info' });
  };

  const handleSetOutputPower = async (power) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setOutputPower`, { power });
    enqueueSnackbar('Set Output Power requested', { variant: 'info' });
  };

  const handleSetPaTempLimit = async (tempLimit) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setPATempLimit`, { tempLimit });
    enqueueSnackbar('Set PA Temperature Limit requested', { variant: 'info' });
  };

  const handleSetBoardTempLimit = async (tempLimit) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setBoardTempLimit`, { tempLimit });
    enqueueSnackbar('Set Board Temperature Limit requested', { variant: 'info' });
  };

  const handleSetCoolingTime = async (time) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setCoolingTime`, { time });
    enqueueSnackbar('Set Cooling Time requested', { variant: 'info' });
  };

  const handleSetRfMode = async (mode) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setRFMode`, { mode });
    enqueueSnackbar('Set RF Mode requested', { variant: 'info' });
  };

  const handleSetFanMode = async (mode) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setFanMode`, { mode });
    enqueueSnackbar('Set Fan Mode requested', { variant: 'info' });
  };

  const handleSetFanPower = async (state) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setFan`, { state });
    enqueueSnackbar('Set Fan State requested', { variant: 'info' });
  };

  const handleSetAntennaPolarity = async (polarity) => {
    await rpcWithErrorHandler(`rg/${currentResGroup}/${NAME}/setAntennaPolarity`, { polarity });
    enqueueSnackbar('Set Antenna Polarity requested', { variant: 'info' });
  };

  return {
    handleReset,
    handleClearAllErrors,
    handleClearPAOTError,
    handleClearPAOCError,
    handleClearRFSwitchPAOPError,
    handleClearBoardOTerror,
    handleClearSWRValueMaxLimitReachedError,
    handleClearOutputPowerError,
    handleClearPAStayedOnError,
    handleClearLNAStayedOnError,
    handleClearAllWarnings,
    handleSetOutputPower,
    handleSetPaTempLimit,
    handleSetBoardTempLimit,
    handleSetCoolingTime,
    handleSetRfMode,
    handleSetFanMode,
    handleSetFanPower,
    handleSetAntennaPolarity,
  };
}
