import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';

export default function useRFFEControls() {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const setCoolingTime = useCallback(
    (num) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.PACoolingTime',
          value: num,
        });
        enqueueSnackbar('Cooling Time change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const setTempLimit = useCallback(
    (temp) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.PATemperatureLimit',
          value: temp,
        });
        enqueueSnackbar('Temperature Limit change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const setBoardTempLimit = useCallback(
    (temp) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.boardTemperatureLimit ',
          value: temp,
        });
        enqueueSnackbar('Board Temperature Limit change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const rffeControlComponents = useMemo(
    () => [
      {
        type: 'input',
        options: {
          helperText: 'PA Cooling Time:',
          value: autonomyConfig.RFFEControl.PACoolingTime ?? 0,
          handleSet: setCoolingTime,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current PA Cooling Time:',
          value: sanitize(autonomyConfig?.RFFEControl?.PACoolingTime),
          unit: 'min',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'PA Temp. Limit:',
          numberInputProps: { inputProps: { min: -30, max: 100, scale: 1 } },
          value: autonomyConfig.RFFEControl.PATemperatureLimit ?? 0,
          handleSet: setTempLimit,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current PA Temp. Limit:',
          value: sanitize(autonomyConfig?.RFFEControl?.PATemperatureLimit, 1),
          unit: '°C',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Board Temp. Limit:',
          numberInputProps: { inputProps: { min: -30, max: 100, scale: 1 } },
          value: autonomyConfig.RFFEControl.boardTemperatureLimit ?? 0,
          handleSet: setBoardTempLimit,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Board Temp. Limit:',
          value: sanitize(autonomyConfig?.RFFEControl?.boardTemperatureLimit, 1),
          unit: '°C',
        },
      },
    ],
    [
      autonomyConfig.RFFEControl.PACoolingTime,
      autonomyConfig.RFFEControl.PATemperatureLimit,
      autonomyConfig.RFFEControl.boardTemperatureLimit,
      setBoardTempLimit,
      setCoolingTime,
      setTempLimit,
    ],
  );

  return { rffeControlComponents };
}
