import { AbsTimeDistCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import { periodMax, periodMin } from './CanAbsTimeCard.constants';
import useCanAbsTimeCard from './useCanAbsTimeCard';

const CanAbsTimeCard = () => {
  const {
    onDisable,
    onEnable,
    getStatus,
    TimeDistAsObc,
    TimeDistPeriodTimeInMs,
    isPeriodInvalid,
    setTimeDistAsObc,
    setTimeDistPeriodTimeInMs,
  } = useCanAbsTimeCard();

  return (
    <AbsTimeDistCard
      disableAbsTimeDist={onDisable}
      enableAbsTimeDist={onEnable}
      getAbsTimeStatus={getStatus}
      TimeDistAsObc={TimeDistAsObc}
      TimeDistPeriodTimeInMs={TimeDistPeriodTimeInMs}
      periodMin={periodMin}
      periodMax={periodMax}
      isPeriodInvalid={isPeriodInvalid}
      setTimeDistAsObc={setTimeDistAsObc}
      setTimeDistPeriodTimeInMs={setTimeDistPeriodTimeInMs}
    />
  );
};

export default CanAbsTimeCard;
