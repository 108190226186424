import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import printf from 'printf';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../../useAutonomyStatusDs';
import useRffeDs from '../useRffeDs';

const modeOptions = new Map();

export default function usePAPowerControl() {
  const theme = useTheme();
  const { rffeState, noResponse } = useRffeDs();
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  modeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO - 0', color: 'white' });
  modeOptions.set('manual', { bgColor: theme.palette.yellow.main, label: 'MANUAL - 1', color: 'black' });
  modeOptions.set('lost', { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });

  const getString = useCallback(
    (val) => {
      if (noResponse) {
        return 'lost';
      }
      return val;
    },
    [noResponse],
  );

  const setManualValue = useCallback(
    (value) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.PAPowerManualValue',
          value,
        });
        enqueueSnackbar('PA Power Manual Value change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const setMode = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.PAPowerMode',
          value: mode,
        });
        enqueueSnackbar('Mode change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [rpcWithErrorHandler, configPath, enqueueSnackbar],
  );

  const paPowerComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'Power Mode:',
          button1Text: 'MANUAL',
          handleButton1Click: () => setMode('manual'),
          button2Text: 'AUTO',
          handleButton2Click: () => setMode('auto'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Power Mode:',
          value: getString(autonomyConfig.RFFEControl.PAPowerMode),
          ValueComponent: GeneralStatusIndicator,
          options: modeOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'AZ Actual Point:',
          value: printf('%.01f', autonomyStatus.motorControl.outputValue.azAngle),
          unit: 'deg',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'EL Actual Point:',
          value: printf('%.01f', autonomyStatus.motorControl.outputValue.elAngle),
          unit: 'deg',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Auto PA Power:',
          value: printf('%.01f', autonomyStatus.RFFEControl.PAPowerAutoValue),
          unit: 'W',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Manual PA Power:',
          numberInputProps: { inputProps: { max: 100, scale: 3 } },
          value: autonomyConfig.RFFEControl.PAPowerManualValue,
          handleSet: setManualValue,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Manual PA Power:',
          value: printf('%.01f', autonomyConfig.RFFEControl.PAPowerManualValue),
          unit: 'W',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Setpoint PA Power:',
          value: printf('%.01f', autonomyStatus.RFFEControl.PAPowerOutputValue),
          unit: 'W',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual PA Power:',
          value: printf('%.01f', rffeState.PAOutputPower),
          unit: 'W',
        },
      },
    ],
    [
      autonomyConfig.RFFEControl.PAPowerManualValue,
      autonomyConfig.RFFEControl.PAPowerMode,
      autonomyStatus.RFFEControl.PAPowerAutoValue,
      autonomyStatus.RFFEControl.PAPowerOutputValue,
      autonomyStatus.motorControl.outputValue.azAngle,
      autonomyStatus.motorControl.outputValue.elAngle,
      getString,
      rffeState.PAOutputPower,
      setManualValue,
      setMode,
    ],
  );

  return { paPowerComponents };
}
