import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import usePanelStyles from '../../../usePanelStyles';

const SatelliteFileListingPanel = ({ diskId, listingLevels, toggleListingLevel, updateListing }) => {
  const classes = usePanelStyles();

  return (
    <>
      <Typography className={classes.panelTitle}>
        {diskIDOptions.find((option) => option.value === diskId).label} File Listing
      </Typography>
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={listingLevels[TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_0]}
                  onChange={() =>
                    toggleListingLevel(TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_0)
                  }
                  name="fs-ls-report-0"
                />
              }
              label="Report 0 (minimal)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={listingLevels[TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_1]}
                  onChange={() =>
                    toggleListingLevel(TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_1)
                  }
                  name="fs-ls-report-1"
                />
              }
              label="Report 1 (most)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={listingLevels[TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_2]}
                  onChange={() =>
                    toggleListingLevel(TCTM.tctm_file_mng_serv_disk_dir_report_type_e.TCTM_DISK_DIR_REPORT_2)
                  }
                  name="fs-ls-report-2"
                />
              }
              label="Report 2 (extra)"
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <Button
            disabled={!Object.values(listingLevels).reduce((acc, val) => acc || val, 0)}
            variant="contained"
            color="primary"
            onClick={updateListing}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SatelliteFileListingPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  listingLevels: PropTypes.shape({
    [PropTypes.oneOf(Object.values(TCTM.tctm_file_mng_serv_disk_dir_report_type_e))]: PropTypes.bool,
  }).isRequired,
  toggleListingLevel: PropTypes.func.isRequired,
  updateListing: PropTypes.func.isRequired,
};

export default SatelliteFileListingPanel;
