import Grid from '@material-ui/core/Grid';
import React from 'react';

import SetupMonitorTab from './SetupMonitorTab';

const SetupTab = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <SetupMonitorTab />
    </Grid>
  </Grid>
);

export default SetupTab;
