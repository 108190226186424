const apidMap = ['placeholder', 'OBC1', 'OBC2', 'COM1', 'COM2', 'EPS1', 'EPS2', 'AUX1', 'AUX2', 'ADCS', 'RADMAG'];

export default function lclCoeffs(dutApid, lcl, voltage, current) {
  if (dutApid == null || voltage == null || current == null) {
    return {
      voltage: '-',
      current: '-',
      auxLclCVoltage: '-',
      auxLclCCurrent: '-',
    };
  }

  const coeffs = {
    OBC1: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
    OBC2: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
    COM1: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
    COM2: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
    EPS1: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
    EPS2: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
    AUX1: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
    },
    AUX2: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
    },
    ADCS: {
      LCL_A: {
        current_si: (rawAD) => 0.032643105 * rawAD + 13.5,
        voltage_si: (rawAD) => 0.095974266 * rawAD - 96.2,
      },
      LCL_B: {
        current_si: (rawAD) => 0.032744566 * rawAD + 23.0,
        voltage_si: (rawAD) => 0.099920729 * rawAD + -323.3,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
    RADMAG: {
      LCL_A: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_B: {
        current_si: (rawAD) => 1 * rawAD + 0,
        voltage_si: (rawAD) => 1 * rawAD + 0,
      },
      LCL_C: {
        current_si: (/* rawAD */) => NaN,
        voltage_si: (/* rawAD */) => NaN,
      },
    },
  };

  return {
    current: Number.isNaN(coeffs[apidMap[dutApid]][lcl].current_si(current))
      ? '-'
      : parseFloat(coeffs[apidMap[dutApid]][lcl].current_si(current)).toFixed(),
    voltage: Number.isNaN(coeffs[apidMap[dutApid]][lcl].voltage_si(voltage))
      ? '-'
      : parseFloat(coeffs[apidMap[dutApid]][lcl].voltage_si(voltage)).toFixed(),
    auxLclCCurrent: Number.isNaN(coeffs.AUX1.LCL_C.current_si(current))
      ? '-'
      : parseFloat(coeffs.AUX1.LCL_C.current_si(current)).toFixed(),
    auxLclCVoltage: Number.isNaN(coeffs.AUX1.LCL_C.voltage_si(voltage))
      ? '-'
      : parseFloat(coeffs.AUX1.LCL_C.voltage_si(voltage)).toFixed(),
  };
}
