import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import EnumSelect from '#components/ui-helper/EnumSelect';

const useStyles = makeStyles(() => ({
  checkboxItem: {
    minWidth: '12em',
    width: 'max-content',
  },
}));

const TctmSettingsInputs = ({
  EnableAESCrypt,
  EnableRevCheck,
  EnablePECCheck,
  EnableFECCheck,
  EnableScidCheck,
  SCID,
  Rev,
  VC,
  dispatch,
}) => {
  const classes = useStyles();

  return (
    <>
      <FormGroup row component="fieldset">
        <FormControlLabel
          label="Enable AES"
          className={classes.checkboxItem}
          control={
            <Checkbox
              value="1"
              name="EnableAESCrypt"
              id="EnableAESCrypt"
              color="primary"
              checked={Boolean(EnableAESCrypt)}
              className={classes.Checkbox}
              onChange={(event, checked) => dispatch({ type: `setEnableAESCrypt`, value: Boolean(checked) })}
            />
          }
        />
        <FormControlLabel
          label="Verify Revision"
          className={classes.checkboxItem}
          control={
            <Checkbox
              value="1"
              name="EnableRevCheck"
              id="EnableRevCheck"
              color="primary"
              checked={Boolean(EnableRevCheck)}
              className={classes.Checkbox}
              onChange={(event, checked) => dispatch({ type: `setEnableRevCheck`, value: Boolean(checked) })}
            />
          }
        />
        <FormControlLabel
          label="Verify PEC"
          className={classes.checkboxItem}
          control={
            <Checkbox
              value="1"
              name="EnablePECCheck"
              id="EnablePECCheck"
              color="primary"
              checked={Boolean(EnablePECCheck)}
              className={classes.Checkbox}
              onChange={(event, checked) => dispatch({ type: `setEnablePECCheck`, value: Boolean(checked) })}
            />
          }
        />
        <FormControlLabel
          label="Verify FEC"
          className={classes.checkboxItem}
          control={
            <Checkbox
              value="1"
              name="EnableFECCheck"
              id="EnableFECCheck"
              color="primary"
              checked={Boolean(EnableFECCheck)}
              className={classes.Checkbox}
              onChange={(event, checked) => dispatch({ type: `setEnableFECCheck`, value: Boolean(checked) })}
            />
          }
        />
        <FormControlLabel
          label="Verify SCID & VC"
          className={classes.checkboxItem}
          control={
            <Checkbox
              value="1"
              name="EnableScidCheck"
              id="EnableScidCheck"
              color="primary"
              checked={Boolean(EnableScidCheck)}
              className={classes.Checkbox}
              onChange={(event, checked) => dispatch({ type: `setEnableScidCheck`, value: Boolean(checked) })}
            />
          }
        />
      </FormGroup>
      <Grid container spacing={2}>
        <Grid item sm={12} md>
          <EnumSelect
            enumId="tctm_scid_e"
            label="Select SCID"
            id="SCID"
            name="SCID"
            value={SCID}
            setValue={(value) => dispatch({ type: 'setSCID', value })}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} md>
          <TextField
            label="Revision number"
            id="Rev"
            name="Rev"
            value={Rev}
            onChange={(event) => dispatch({ type: 'setRev', value: Number(event.target.value) })}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} md>
          <EnumSelect
            enumId="tctm_virtual_channel_e"
            label="Select VC"
            id="VC"
            name="VC"
            value={VC}
            setValue={(value) => dispatch({ type: 'setVC', value })}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

TctmSettingsInputs.propTypes = {
  EnableAESCrypt: PropTypes.bool.isRequired,
  EnableRevCheck: PropTypes.bool.isRequired,
  EnablePECCheck: PropTypes.bool.isRequired,
  EnableFECCheck: PropTypes.bool.isRequired,
  EnableScidCheck: PropTypes.bool.isRequired,
  SCID: PropTypes.string.isRequired,
  Rev: PropTypes.string.isRequired,
  VC: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(TctmSettingsInputs);
