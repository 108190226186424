import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';

export default function useCabinetFan() {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const setTempFan = useCallback(
    (temp) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'cabinetFanControl.tempFanLimit',
          value: temp,
        });
        enqueueSnackbar('Temperature Fan Limit change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const cabinetFanComponents = useMemo(
    () => [
      {
        type: 'input',
        options: {
          helperText: 'Temperature Fan Limit:',
          numberInputProps: { inputProps: { min: -30, max: 100, scale: 1 } },
          value: autonomyConfig.cabinetFanControl.tempFanLimit ?? 0,
          handleSet: setTempFan,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Temp. Fan Limit:',
          value: sanitize(autonomyConfig?.cabinetFanControl?.tempFanLimit, 1),
          unit: '°C',
        },
      },
    ],
    [autonomyConfig.cabinetFanControl.tempFanLimit, setTempFan],
  );

  return { cabinetFanComponents };
}
