import React from 'react';

import {
  FileManagementTab,
  FileTransferTab,
  MessageFlowTab,
  TctmSettingsTab,
  TCVerificationTab,
} from '#satellites/RADCUBE/protocols/TCTM/control-tabpanel/tabs';

export default [
  {
    name: 'Message Flow',
    hash: 'message-flow',
    container: <MessageFlowTab />,
  },
  {
    name: 'TC Verification',
    hash: 'tc-verification',
    container: <TCVerificationTab />,
  },
  {
    name: 'File Management',
    hash: 'file-management',
    container: <FileManagementTab />,
  },
  {
    name: 'File Transfer',
    hash: 'file-transfer',
    container: <FileTransferTab />,
  },
  {
    name: 'TCTM Transcoder Settings',
    hash: 'tctm-settings',
    container: <TctmSettingsTab />,
  },
];
