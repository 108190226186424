import { HeartbeatCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import useCanHeartbeatServiceCard from './useCanHeartbeatServiceCard';

const CanHeartbeatServiceCardContainer = () => {
  const {
    enableHeartbeat,
    disableHeartbeat,
    CanHeartbeatMainRed,
    CanHeartbeatAsSubsystem,
    CanHeartbeatMessage,
    getHeartbeatStatus,
    isCanHeartbeatMessageInvalid,
    setCanHeartbeatMainRed,
    setCanHeartbeatAsSubsystem,
    setCanHeartbeatMessage,
  } = useCanHeartbeatServiceCard();

  return (
    <HeartbeatCard
      comBus="can"
      enableHeartbeat={enableHeartbeat}
      disableHeartbeat={disableHeartbeat}
      getHeartbeatStatus={getHeartbeatStatus}
      HeartbeatMainRed={CanHeartbeatMainRed}
      HeartbeatAsSubsystem={CanHeartbeatAsSubsystem}
      HeartbeatMessage={CanHeartbeatMessage}
      isHeartbeatMessageInvalid={isCanHeartbeatMessageInvalid}
      setHeartbeatMainRed={setCanHeartbeatMainRed}
      setHeartbeatAsSubsystem={setCanHeartbeatAsSubsystem}
      setHeartbeatMessage={setCanHeartbeatMessage}
    />
  );
};

export default CanHeartbeatServiceCardContainer;
