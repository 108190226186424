import React from 'react';
import { Helmet } from 'react-helmet';

import FileStorageTab from '#services/file-storage/FileStorageTab';

const PageTitle = 'File Server';

const FileServerPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <FileStorageTab />
    </>
  );
};

export default FileServerPage;
