import { Grid } from '@material-ui/core';
import React from 'react';

import CanHeartbeatServiceCard from './Cards/CanHeartbeat';

const CanHeartbeatTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <CanHeartbeatServiceCard />
    </Grid>
  </Grid>
);

export default CanHeartbeatTab;
