import {
  TCTM_EPS_09_0C,
  TCTM_EPS_09_0F,
  TCTM_EPS_09_03,
  TCTM_EPS_09_06,
  TCTM_EPS_09_09,
  TCTM_EPS_09_12,
  TCTM_EPS_09_15,
  TCTM_EPS_09_18,
} from '@c3s/ui-radcube-protocol-tctm';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useToggle } from 'standard-hooks';

import CardHeader from '#components/ui-helper/CardHeaderWithStatus';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import useThrottle from '#hooks/useThrottle';
import styles from '#utils/styles';

import { initialState, reducer, setObcTm } from './ParameterManagementService.helpers';

const useStyles = makeStyles(styles);

const ParameterManagementService = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, newSetValue] = useThrottle(500, [state, dispatch]);

  const setObcTelemetryWithRef = useRef((val) => setObcTm({ telemetry: val, dispatch: newSetValue, enqueueSnackbar }));
  const subscribeToTelemetry = setObcTelemetryWithRef.current;

  const [open, toggleOpen] = useToggle(false);

  const { currentResGroup } = useContext(RgContext);
  const { dsClient } = useContext(DeepstreamContext);

  useEffect(() => {
    dsClient.event.subscribe(`rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`, subscribeToTelemetry);

    return function cleanup() {
      dsClient.event.unsubscribe(
        `rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`,
        subscribeToTelemetry,
      );
    };
  }, [currentResGroup, subscribeToTelemetry, dsClient]);

  return (
    <Accordion square expanded={open} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleOpen}>
        <Typography variant="h6">Parameter Management Service</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_09_03.status}
                lastUpdated={value.err_09_03.lastValidTimestamp}
                errorMsg={value.err_09_03.Msg}
                titleText="TCTM_EPS_09_03"
              />
              <CardContent>
                <TCTM_EPS_09_03 status={value.TM_09_03} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_09_06.status}
                lastUpdated={value.err_09_06.lastValidTimestamp}
                errorMsg={value.err_09_06.Msg}
                titleText="TCTM_EPS_09_06"
              />
              <CardContent>
                <TCTM_EPS_09_06 status={value.TM_09_06} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_09_09.status}
                lastUpdated={value.err_09_09.lastValidTimestamp}
                errorMsg={value.err_09_09.Msg}
                titleText="TCTM_EPS_09_09"
              />
              <CardContent>
                <TCTM_EPS_09_09 status={value.TM_09_09} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_09_0C.status}
                lastUpdated={value.err_09_0C.lastValidTimestamp}
                errorMsg={value.err_09_0C.Msg}
                titleText="TCTM_EPS_09_0C"
              />
              <CardContent>
                <TCTM_EPS_09_0C status={value.TM_09_0C} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_09_0F.status}
                lastUpdated={value.err_09_0F.lastValidTimestamp}
                errorMsg={value.err_09_0F.Msg}
                titleText="TCTM_EPS_09_0F"
              />
              <CardContent>
                <TCTM_EPS_09_0F status={value.TM_09_0F} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <CardHeader
                error={value.err_09_12.status}
                lastUpdated={value.err_09_12.lastValidTimestamp}
                errorMsg={value.err_09_12.Msg}
                titleText="TCTM_EPS_09_12"
              />
              <CardContent>
                <TCTM_EPS_09_12 status={value.TM_09_12} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <CardHeader
                error={value.err_09_15.status}
                lastUpdated={value.err_09_15.lastValidTimestamp}
                errorMsg={value.err_09_15.Msg}
                titleText="TCTM_EPS_09_15"
              />
              <CardContent>
                <TCTM_EPS_09_15 status={value.TM_09_15} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <CardHeader
                error={value.err_09_18.status}
                lastUpdated={value.err_09_18.lastValidTimestamp}
                errorMsg={value.err_09_18.Msg}
                titleText="TCTM_EPS_09_18"
              />
              <CardContent>
                <TCTM_EPS_09_18 status={value.TM_09_18} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ParameterManagementService;
