import { Grid } from '@material-ui/core';
import React from 'react';

import MlvdsSyncServiceCard from './Cards/MlvdsSyncServiceCard';

const MlvdsSyncTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <MlvdsSyncServiceCard />
    </Grid>
  </Grid>
);

export default MlvdsSyncTab;
