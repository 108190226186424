import useProtocolMessage from '#hooks/useProtocolMessage';

import useMlvdsSyncStatusEventListener from './useMlvdsSyncStatusEventListener';

export default function useMlvdsSyncStatusMessage() {
  return useProtocolMessage({
    listener: useMlvdsSyncStatusEventListener,
    transform: (x) => ({
      SID: x.MlvdsSyncStatus.SID,
      value: {
        IsCounterFix: 0, // TODO: missing in config file, remove after implementing in bustester hardware
        CounterInitValue: 0, // TODO: missing in config file, remove after implementing in bustester hardware
        ...x.MlvdsSyncStatus.value,
      },
    }),
    initialBody: {
      SID: -1,
      value: {
        IsEnabled: 0,
        IsCounterFix: 0,
        CounterInitValue: 0,
        NumberOfSyncToSend: 0,
        RemainingSyncToSend: 0,
      },
    },
  });
}
