import useProtocolMessage from '#hooks/useProtocolMessage';

import useCanHeartBeatStatusEventListener from './useCanHeartBeatStatusEventListener';

export default function useCanHeartBeatStatusMessage() {
  return useProtocolMessage({
    listener: useCanHeartBeatStatusEventListener,
    transform: (x) => ({
      SID: -1,
      value: {
        // TODO: remove hardcoded value after implementing thins function in bustester hardware
        IsEnabled: 0,
        SendAs: 'OBC',
        Message: '0x00',
        ...x.CanHeartBeatStatus,
      },
    }),
    initialBody: {
      SID: -1,
      value: {
        IsEnabled: 0,
        SendAs: 'OBC',
        Message: '0x00',
      },
    },
  });
}
