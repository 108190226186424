import useTimeAgo from '@rooks/use-time-ago';
import { formatISO, isValid } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

const TimeAgo = ({ date }) => {
  const ago = useTimeAgo(date);

  if (date === null || date === undefined) {
    return 'N/A';
  }

  if (!(date instanceof Date) || !isValid(date)) {
    return '?';
  }

  return <span title={formatISO(date)}>{ago}</span>;
};

TimeAgo.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default TimeAgo;
