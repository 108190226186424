import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';

import Pc2GsProtocolPage from './protocols/pc2gs';

const OwlTesterPage = ({ connectorKey, connectorMeta }) => {
  return (
    <Router>
      <Pc2GsProtocolPage path="protocols/pc2gs" connectorKey={connectorKey} connectorMeta={connectorMeta} />
    </Router>
  );
};

OwlTesterPage.propTypes = {
  connectorKey: PropTypes.string.isRequired,
  connectorMeta: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OwlTesterPage;
