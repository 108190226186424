/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { red, teal } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const PageTitle = 'Automated Testing';

const TestItem = ({ name, error }) => {
  if (!error) {
    return <Typography color="primary">{name}</Typography>;
  }

  return (
    <Tooltip title={error.message}>
      <Typography color="error">{name}</Typography>
    </Tooltip>
  );
};

TestItem.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
};

TestItem.defaultProps = {
  error: null,
};

const AutomatedTestPage = () => {
  const classes = useStyles();
  const { dsClient } = useContext(DeepstreamContext);
  const [testStats, setTestStats] = useState();
  const [isTestRunning, setIsTestRunning] = useState(false);
  const [totalTests, setTotalTests] = useState(0);
  const [testList, setTestList] = useState([]);
  const [resultsList, setResultsList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const onRunBegin = useRef((total) => {
    setResultsList([]);
    setTestStats();
    setTotalTests(total);
    setIsTestRunning(true);
  });

  const onRunEnd = useRef((stats) => {
    setTestStats(stats);
    setIsTestRunning(false);
  });

  const onSuiteBegin = useRef((/* suite */) => {
    /* TODO */
  });

  const onSuiteEnd = useRef((/* suite */) => {
    /* TODO */
  });

  const onTestPassOrFail = useRef(({ name, duration, error }) => {
    setResultsList((prev) => [...prev, { name, duration, error }]);
  });

  useEffect(() => {
    dsClient.event.subscribe('automated-test/run-begin', onRunBegin.current);
    dsClient.event.subscribe('automated-test/run-end', onRunEnd.current);
    dsClient.event.subscribe('automated-test/suite-begin', onSuiteBegin.current);
    dsClient.event.subscribe('automated-test/suite-end', onSuiteEnd.current);
    dsClient.event.subscribe('automated-test/test-pass-fail', onTestPassOrFail.current);

    return function cleanup() {
      dsClient.event.unsubscribe('automated-test/run-begin', onRunBegin.current);
      dsClient.event.unsubscribe('automated-test/run-end', onRunEnd.current);
      dsClient.event.unsubscribe('automated-test/suite-begin', onSuiteBegin.current);
      dsClient.event.unsubscribe('automated-test/suite-end', onSuiteEnd.current);
      dsClient.event.unsubscribe('automated-test/test-pass-fail', onTestPassOrFail.current);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <Grid container direction="row" spacing={1} alignItems="stretch" alignContent="stretch">
        <Grid item xs={6} md={3} lg={2}>
          <Card className={classes.Card}>
            <CardHeader
              title="Automated Test Control"
              titleTypographyProps={{ variant: 'h6' }}
              className={classes.CardHeader}
            />
            <CardContent className={classes.CardContent}>
              <Button
                disabled={isTestRunning}
                size="small"
                color="primary"
                variant="outlined"
                onClick={async () => {
                  try {
                    const list = await dsClient.rpc.makeAsync('automated-test/list', null);
                    setTestList(list);
                    await dsClient.rpc.makeAsync('automated-test/run', list);
                  } catch (err) {
                    enqueueSnackbar(err.message, { variant: 'error' });
                  }
                }}
              >
                <FontAwesomeIcon icon={['fas', 'play']} size="lg" />
                {'\u2000'}Run tests
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.Card}>
            <CardHeader title="Tests" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
            <CardContent className={classes.CardContent}>
              <List dense>
                {testList.map((item) => (
                  // TODO: Consider using a real ID as key
                  <ListItem key={item}>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          <Card className={classes.Card}>
            <CardHeader title="Results" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
            <CardContent className={classes.CardContent}>
              <LinearProgress
                variant="determinate"
                value={resultsList.length ? (resultsList.length * 100) / totalTests : 0}
              />
            </CardContent>
            <CardContent className={classes.CardContent}>
              <Typography variant="subtitle2" gutterBottom>
                {testStats !== undefined
                  ? `${testStats.passes} passes, ${testStats.failures} fails out of ${testStats.tests} tests`
                  : ''}
              </Typography>
            </CardContent>
            <CardContent className={classes.CardContent}>
              <List dense>
                {resultsList.map(({ name, duration, error }) => (
                  // TODO: Consider using a real ID as key
                  <ListItem key={name}>
                    <ListItemIcon>
                      {error === undefined ? (
                        <FontAwesomeIcon icon={['fas', 'check']} style={{ color: teal[500] }} size="lg" />
                      ) : (
                        <FontAwesomeIcon icon={['fas', 'times']} style={{ color: red[500] }} size="lg" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={<TestItem name={name} error={error} />}
                      secondary={Duration.fromMillis(duration).toFormat(`mm 'm' ss 's' S 'ms'`)}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AutomatedTestPage;
