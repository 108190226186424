import { CabinetHkControl } from '@c3s/ui-tracking-station';
import React from 'react';

import useCabinetHkControlCard from './useCabinetHkControlCard';

const CabinetHkControlCard = () => {
  const { handleSetOutput, handleEnableOutput } = useCabinetHkControlCard();

  return <CabinetHkControl handleSetOutput={handleSetOutput} handleEnableOutput={handleEnableOutput} />;
};

export default CabinetHkControlCard;
