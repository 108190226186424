import { useCallback, useContext, useEffect, useReducer } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

const trackingStationCRUDPath = `rg/${process.env.GATSBY_RESOURCE_GROUP}/@c3s/tracking-station-crud`;

const initialState = {
  id: null,
  rg: 'lurdy-ts',
  stations: [],
  dialogOpen: false,
  openForCreate: true,
  stationName: 'New Station',
  latitude: 0,
  longitude: 0,
  altitude: 0,
  azPosMin: -180,
  azPosMax: 180,
  azMaxSpeed: 8,
  elPosMin: -90,
  elPosMax: 90,
  elMaxSpeed: 8,
  flipCapable: true,
};

const reducer = (state, { type, value }) => {
  switch (type) {
    case 'stations':
      return { ...state, stations: value };
    case 'dialogOpen':
      return { ...state, dialogOpen: value };
    case 'openForCreate':
      return { ...state, openForCreate: value };
    case 'id':
      return { ...state, id: value };
    case 'rg':
      return { ...state, rg: value };
    case 'stationName':
      return { ...state, stationName: value };
    case 'latitude':
      return { ...state, latitude: value };
    case 'longitude':
      return { ...state, longitude: value };
    case 'altitude':
      return { ...state, altitude: value };
    case 'azPosMin':
      return { ...state, azPosMin: value };
    case 'azPosMax':
      return { ...state, azPosMax: value };
    case 'azMaxSpeed':
      return { ...state, azMaxSpeed: value };
    case 'elPosMin':
      return { ...state, elPosMin: value };
    case 'elPosMax':
      return { ...state, elPosMax: value };
    case 'elMaxSpeed':
      return { ...state, elMaxSpeed: value };
    case 'flipCapable':
      return { ...state, flipCapable: value };
    default:
      throw new Error('Invalid action type in reducer.');
  }
};

export default function useStationTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const getStations = useCallback(async () => {
    const { status, result } = await rpcWithErrorHandler(`${trackingStationCRUDPath}/listTrackingStations`, {});
    if (status === 'success') {
      dispatch({ type: 'stations', value: Array.isArray(result) ? result : [] });
    }
  }, [rpcWithErrorHandler]);

  const addStation = useCallback(
    async (station) => {
      const { status } = await rpcWithErrorHandler(`${trackingStationCRUDPath}/addTrackingStation`, { station });
      if (status === 'success') {
        enqueueSnackbar('Successfully added new tracking station.', { variant: 'info' });
        await getStations();
      }
    },
    [rpcWithErrorHandler, enqueueSnackbar, getStations],
  );

  const removeStation = useCallback(
    async (id) => {
      const { status } = await rpcWithErrorHandler(`${trackingStationCRUDPath}/removeTrackingStation`, { id });
      if (status === 'success') {
        enqueueSnackbar('Successfully deleted tracking station.', { variant: 'info' });
        await getStations();
      }
    },
    [rpcWithErrorHandler, enqueueSnackbar, getStations],
  );

  const updateStation = useCallback(
    async (id, station) => {
      const { status } = await rpcWithErrorHandler(`${trackingStationCRUDPath}/updateTrackingStation`, {
        id,
        station,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully updatet tracking station.', { variant: 'info' });
        await getStations();
      }
    },
    [rpcWithErrorHandler, enqueueSnackbar, getStations],
  );

  const handleDialogOpen = useCallback((_openForCreate) => {
    if (_openForCreate) {
      dispatch({ type: 'openForCreate', value: true });
    } else {
      dispatch({ type: 'openForCreate', value: false });
    }
    dispatch({ type: 'dialogOpen', value: true });
  }, []);

  const handleDialogCancel = useCallback(() => {
    dispatch({ type: 'dialogOpen', value: false });
  }, []);

  const handleDialogCreate = useCallback(() => {
    const station = {
      name: state.stationName,
      rg: state.rg,
      position: {
        latitude: parseFloat(state.latitude),
        longitude: parseFloat(state.longitude),
        altitude: parseFloat(state.altitude),
      },
      azPosMin: parseFloat(state.azPosMin),
      azPosMax: parseFloat(state.azPosMax),
      azMaxSpeed: parseFloat(state.azMaxSpeed),
      elPosMin: parseFloat(state.elPosMin),
      elPosMax: parseFloat(state.elPosMax),
      elMaxSpeed: parseFloat(state.elMaxSpeed),
      flipCapable: !!state.flipCapable,
    };

    if (state.openForCreate) {
      addStation(station);
    } else {
      updateStation(state.id, station);
    }
    dispatch({ type: 'dialogOpen', value: false });
  }, [addStation, updateStation, state]);

  const handleDialogContentChange = useCallback((event) => {
    if (event.target.id === 'flipCapable') {
      dispatch({ type: event.target.id, value: event.target.checked });
    } else {
      dispatch({ type: event.target.id, value: event.target.value });
    }
  }, []);

  const handleDialogContentForUpdate = useCallback(
    ({ row }) => {
      dispatch({ type: 'id', value: row.id });
      dispatch({ type: 'rg', value: row.rg });
      dispatch({ type: 'stationName', value: row.name });
      dispatch({ type: 'latitude', value: row.position.latitude });
      dispatch({ type: 'longitude', value: row.position.longitude });
      dispatch({ type: 'altitude', value: row.position.altitude });
      dispatch({ type: 'azPosMin', value: row.azPosMin });
      dispatch({ type: 'azPosMax', value: row.azPosMax });
      dispatch({ type: 'azMaxSpeed', value: row.azMaxSpeed });
      dispatch({ type: 'elPosMin', value: row.elPosMin });
      dispatch({ type: 'elPosMax', value: row.elPosMax });
      dispatch({ type: 'elMaxSpeed', value: row.elMaxSpeed });
      dispatch({ type: 'flipCapable', value: !!row.flipCapable });
      handleDialogOpen(false);
    },
    [handleDialogOpen],
  );

  useEffect(() => {
    getStations();
  }, [getStations]);

  return {
    stations: state.stations,
    getStations,
    addStation,
    removeStation,
    dialogOpen: state.dialogOpen,
    openForCreate: state.openForCreate,
    handleDialogOpen,
    handleDialogCancel,
    handleDialogCreate,
    handleDialogContentChange,
    handleDialogContentForUpdate,
    newStationDetails: state,
  };
}
