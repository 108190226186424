import useProtocolMessage from '#hooks/useProtocolMessage';

import useCanSyncStatusEventListener from './useCanSyncStatusEventListener';

export default function useCanSyncStatusMessage() {
  return useProtocolMessage({
    listener: useCanSyncStatusEventListener,
    transform: (x) => x.CanSyncStatus,
    initialBody: {
      SID: -1,
      value: Array(2).fill({
        IsEnabled: 0,
        IsCounterFix: 0,
        CounterInitValue: 0,
        NumberOfSyncToSend: 0,
        RemainingSyncToSend: 0,
      }),
    },
  });
}
