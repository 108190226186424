import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import OperationModePanel from '../panels/OperationModePanel';
import OperationModeSelect from './OperationModeSelect';

const useStyles = makeStyles((theme) => ({
  opModePanel: {
    marginRight: theme.spacing(2),
  },
}));

const OperationModeControls = ({ opMode, diskStates, selectOpMode, getFsStatus }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item className={classes.opModePanel}>
        <OperationModePanel opMode={opMode} diskStates={diskStates} />
      </Grid>
      <Grid item>
        <Button variant="outlined" color="primary" onClick={getFsStatus}>
          Get FS Status
        </Button>
      </Grid>
      <Grid item>
        <OperationModeSelect opMode={opMode} selectOpMode={selectOpMode} />
      </Grid>
    </Grid>
  );
};

OperationModeControls.propTypes = {
  opMode: PropTypes.oneOf(Object.values(TCTM.tctm_fs_operation_mode_e)),
  diskStates: PropTypes.objectOf(PropTypes.oneOf(Object.values(TCTM.tctm_fs_disk_state_e))),
  selectOpMode: PropTypes.func.isRequired,
  getFsStatus: PropTypes.func.isRequired,
};

OperationModeControls.defaultProps = {
  opMode: undefined,
  diskStates: {},
};

export default OperationModeControls;
