import { useCallback, useContext, useReducer } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const initialState = {
  EnableAESCrypt: false,
  EnablePECCheck: false,
  EnableFECCheck: false,
  EnableRevCheck: false,
  EnableScidCheck: false,
  Rev: undefined,
  SCID: undefined,
  VC: undefined,
  secretKeys: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'setEnableAESCrypt':
      return { ...state, EnableAESCrypt: action.value ?? initialState.EnableAESCrypt };
    case 'setEnablePECCheck':
      return { ...state, EnablePECCheck: action.value ?? initialState.EnablePECCheck };
    case 'setEnableFECCheck':
      return { ...state, EnableFECCheck: action.value ?? initialState.EnableFECCheck };
    case 'setEnableRevCheck':
      return { ...state, EnableRevCheck: action.value ?? initialState.EnableRevCheck };
    case 'setEnableScidCheck':
      return { ...state, EnableScidCheck: action.value ?? initialState.EnableScidCheck };
    case 'setSCID':
      return { ...state, SCID: action.value ?? initialState.SCID };
    case 'setRev':
      return { ...state, Rev: action.value ?? initialState.Rev };
    case 'setVC':
      return { ...state, VC: action.value ?? initialState.VC };
    case 'setSecretKeys':
      return {
        ...state,
        secretKeys: {
          ...state.secretKeys,
          [action.scid]: {
            ...state.secretKeys[action.scid],
            [action.vc]: action.value != null ? action.value.replace(/\s+/g, '') : '',
          },
        },
      };
    case 'recordUpdate':
      return action.value;
    default:
      throw new Error(`Invalid action in TCTM transcoder settings: ${action.type}`);
  }
}

export default function useTctmTranscoderSettings() {
  const { currentResGroup } = useContext(RgContext);
  const recordPath = `rg/${currentResGroup}/@c3s/tctm-transcoder-provider/settings`;

  const [state, dispatch] = useReducer(reducer, initialState);

  const onRecordUpdated = useCallback((value) => {
    dispatch({ type: 'recordUpdate', value });
  }, []);

  const { setRecord } = useDsRecordSubscription(recordPath, onRecordUpdated);

  const persistState = useCallback(() => {
    setRecord(state);
  }, [setRecord, state]);

  return { state, dispatch, persistState };
}
