import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import { satelliteFileControlPanels } from '../../helpers';

const FilesystemManagementControls = ({ opMode, diskId, openPanel, selectPanel }) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          variant="outlined"
          selected={openPanel === 'file-listing'}
          onClick={() => selectPanel('file-listing')}
          disabled={diskId === null}
        >
          Listing
        </Button>
      </Grid>
      <Grid item>
        <ButtonGroup disabled={opMode !== TCTM.tctm_fs_operation_mode_e.TCTM_FS_OPMODE_SERVICE || diskId === null}>
          <Button selected={openPanel === 'reset-fs'} onClick={() => selectPanel('reset-fs')}>
            Reset FS
          </Button>
          <Button selected={openPanel === 'format-fs'} onClick={() => selectPanel('format-fs')}>
            Format FS
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

FilesystemManagementControls.propTypes = {
  opMode: PropTypes.oneOf(Object.values(TCTM.tctm_fs_operation_mode_e)),
  diskId: PropTypes.number.isRequired,
  openPanel: PropTypes.oneOf(satelliteFileControlPanels),
  selectPanel: PropTypes.func.isRequired,
};
FilesystemManagementControls.defaultProps = {
  opMode: undefined,
  openPanel: null,
};

export default FilesystemManagementControls;
