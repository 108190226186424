import { CanTable, TableFilters } from '@c3s/ui-radcube-message-logging';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React, { forwardRef, useContext, useEffect, useReducer, useRef, useState } from 'react';

import MultiSelectWithCheckbox from '#components/ui-helper/MultiSelectWithCheckbox';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

import { initialState, tableHeader } from './CANTabHelpers';

function reducer(state, action) {
  return { ...state, ...action };
}

const CANTab = () => {
  const tableRef = useRef();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();
  const [{ version, startTime, endTime, IDE, RTR, DLC, Identifier }, dispatch] = useReducer(reducer, initialState);
  const [filterVersion, setFilterVersion] = useState([]);
  const [filterIDE, setFilterIDE] = useState([]);
  const [filterRTR, setFilterRTR] = useState([]);
  const [filterDLC, setFilterDLC] = useState([]);
  const [filterIdentifier, setFilterIdentifier] = useState([]);
  const [filterDirectionMask, setFilterDirectionMask] = useState({
    encoded: 0,
    decoded: 0,
  });

  useEffect(() => {
    async function getFilters() {
      const { result, status } = await rpcWithErrorHandler(
        `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessageFilters`,
        {
          protocol: 'CAN',
        },
      );
      if (status === 'success' && Object.keys(result).includes('version')) {
        setFilterVersion(result.version?.map((label) => ({ label, value: label })));
        setFilterIDE(result['json.IDE']?.map((label) => ({ label, value: label })));
        setFilterRTR(result['json.RTR']?.map((label) => ({ label, value: label })));
        setFilterDLC(result['json.DLC']?.map((label) => ({ label, value: label })));
        setFilterIdentifier(result['json.Identifier']?.map((label) => ({ label, value: label })));
      }
    }
    getFilters();
  }, [currentResGroup, rpcWithErrorHandler]);

  function onStartChange(date) {
    dispatch({ startTime: date });
  }

  function onEndChange(date) {
    dispatch({ endTime: date });
  }

  function refresh() {
    tableRef.current.onQueryChange();
  }

  function CANFilters() {
    return (
      <Grid container spacing={1} style={{ margin: '1em 1em 1em 4px' }}>
        <Grid item>
          <MultiSelectWithCheckbox
            title="IDE"
            dropdownArray={filterIDE}
            state={{ IDE: IDE.length ? IDE.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="RTR"
            dropdownArray={filterRTR}
            state={{ RTR: RTR.length ? RTR.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="DLC"
            dropdownArray={filterDLC}
            state={{ DLC: DLC.length ? DLC.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="Identifier"
            dropdownArray={filterIdentifier}
            state={{ Identifier: Identifier.length ? Identifier.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
    );
  }

  const canFilter = {
    transformation: filterDirectionMask,
    version: version.length ? version : [],
    timestamp: [startTime, endTime],
    json: {
      IDE: IDE.length ? IDE : [],
      RTR: RTR.length ? RTR : [],
      DLC: DLC.length ? DLC : [],
      Identifier: Identifier.length ? Identifier : [],
    },
  };

  const getCanMessages = async ({ page, pageSize, orderBy, orderDirection }) => {
    const { result, status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessages`,
      {
        protocol: 'CAN',
        filter: canFilter,
        limit: pageSize,
        skip: pageSize * page,
        sort: {
          field: orderBy?.field,
          direction: orderDirection,
        },
      },
    );

    if (status === 'success') {
      return {
        data: result.data,
        page,
        totalCount: result.recordsTotal,
      };
    }

    return {
      data: [],
      page,
      totalCount: 0,
    };
  };

  const exportCanMessages = async () => {
    const fileName = `can-log-${Date.now()}.csv`;

    const { status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/exportLoggedMessages`,
      {
        protocol: 'CAN',
        filter: canFilter,
        sort: {
          direction: '',
        },
        bucketID: undefined,
        fileName,
      },
    );

    if (status === 'success') {
      enqueueSnackbar(`Filtered CAN message log saved to MOC files as ${fileName}`, { variant: 'info' });
    }
  };

  const canActions = [
    {
      isFreeAction: true,
      icon: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} ref={ref} icon={['fas', 'file-download']} style={{ fontSize: 18 }} />
      )),
      tooltip: 'Create Archive',
      onClick: () => {
        exportCanMessages();
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableFilters
          startTime={startTime}
          endTime={endTime}
          version={version.length ? version.map((label) => ({ label, value: label })) : []}
          filterDirectionMask={filterDirectionMask}
          setFilterDirectionMask={setFilterDirectionMask}
          filterVersion={filterVersion}
          dispatch={dispatch}
          tabSpecificFilters={CANFilters}
          refresh={refresh}
          onStartChange={onStartChange}
          onEndChange={onEndChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CanTable tableRef={tableRef} actions={canActions} TableHeader={tableHeader} getRemoteData={getCanMessages} />
      </Grid>
    </Grid>
  );
};

export default CANTab;
