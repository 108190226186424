import { NumberInput } from '@c3s/ui-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';

const NumberField = ({ ctype, unit, polynomial, value, setValue, ...otherInputProps }) => {
  let numberInputLimits = {};
  switch (ctype) {
    case 'uint8_t':
      numberInputLimits = { min: 0, max: 255 };
      break;
    case 'uint16_t':
      numberInputLimits = { min: 0, max: 65535 };
      break;
    case 'uint32_t':
      numberInputLimits = { min: 0, max: 4294967295 };
      break;
    case 'uint64_t':
      numberInputLimits = { min: 0 };
      break;
    case 'int8_t':
      numberInputLimits = { min: -128, max: 127 };
      break;
    case 'int16_t':
      numberInputLimits = { min: -32768, max: 32767 };
      break;
    case 'int32_t':
      numberInputLimits = { min: -2147483648, max: 2147483647 };
      break;
    default:
      break;
  }

  const { inputProps: innerInputProps, ...fwdInputProps } = otherInputProps;

  return (
    <NumberInput
      value={value}
      setValue={setValue}
      endAdornment={
        <InputAdornment position="end">
          {polynomial && polynomial !== 'x' ? `[${polynomial}] ${unit}` : `${unit}`}
        </InputAdornment>
      }
      {...fwdInputProps}
      inputProps={{
        ...numberInputLimits,
        scale: ctype.includes('int') ? 0 : 999,
        signed: !ctype.includes('uint'),
        placeholder: `Enter ${ctype}`,
        ...innerInputProps,
      }}
    />
  );
};

NumberField.propTypes = {
  ctype: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  polynomial: PropTypes.string.isRequired,
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
};

NumberField.defaultProps = {
  value: undefined,
};

export default NumberField;
