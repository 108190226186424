import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useMemo } from 'react';

import useStationTable from './useStationTable';

const StatusTable = () => {
  const {
    stations,
    getStations,
    removeStation,
    dialogOpen,
    openForCreate,
    handleDialogOpen,
    handleDialogCancel,
    handleDialogCreate,
    handleDialogContentChange,
    handleDialogContentForUpdate,
    newStationDetails,
  } = useStationTable();

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.2,
      },
      {
        field: 'rg',
        headerName: 'Res. Grp.',
        flex: 0.2,
      },
      {
        field: 'position',
        headerName: 'Location',
        flex: 0.4,
        rowHeight: '100px',
        renderCell: (params) => {
          const { value } = params;
          return (
            <p style={{ margin: 'unset' }}>
              Lat: {value?.latitude ?? 'N/A'}, Long: {value?.longitude ?? 'N/A'}, Alt: {value?.altitude ?? 'N/A'}
            </p>
          );
        },
      },
      {
        field: 'azPosMin',
        headerName: 'AZ MIN Pos',
        flex: 0.2,
      },
      {
        field: 'azPosMax',
        headerName: 'AZ MAX Pos',
        flex: 0.2,
      },
      {
        field: 'azMaxSpeed',
        headerName: 'AZ MAX Speed',
        flex: 0.2,
      },
      {
        field: 'elPosMin',
        headerName: 'EL MIN Pos',
        flex: 0.2,
      },
      {
        field: 'elPosMax',
        headerName: 'EL MAX Pos',
        flex: 0.2,
      },
      {
        field: 'elMaxSpeed',
        headerName: 'EL MAX Speed',
        flex: 0.2,
      },
      {
        field: 'flipCapable',
        headerName: 'Flip Capable',
        flex: 0.1,
        renderCell: (params) => {
          const { value } = params;
          return <Checkbox checked={value} color="primary" disabled />;
        },
      },
      {
        field: 'id',
        headerName: 'Actions',
        flex: 0.15,
        renderCell: (params) => (
          <>
            <IconButton onClick={() => removeStation(params.value)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => handleDialogContentForUpdate(params)}>
              <EditIcon />
            </IconButton>
          </>
        ),
      },
    ],
    [removeStation, handleDialogContentForUpdate],
  );

  const disableCreateButton =
    Number.isNaN(parseFloat(newStationDetails.latitude)) ||
    Number.isNaN(parseFloat(newStationDetails.longitude)) ||
    Number.isNaN(parseFloat(newStationDetails.altitude)) ||
    Number.isNaN(parseFloat(newStationDetails.azPosMin)) ||
    Number.isNaN(parseFloat(newStationDetails.azPosMax)) ||
    Number.isNaN(parseFloat(newStationDetails.azMaxSpeed)) ||
    Number.isNaN(parseFloat(newStationDetails.elPosMin)) ||
    Number.isNaN(parseFloat(newStationDetails.elPosMax)) ||
    Number.isNaN(parseFloat(newStationDetails.elMaxSpeed));

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button onClick={() => getStations()} size="small" color="primary" startIcon={<CachedIcon />}>
          Refresh
        </Button>
        <Button onClick={() => handleDialogOpen(true)} size="small" color="primary" startIcon={<AddIcon />}>
          Add New Station
        </Button>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <Dialog open={dialogOpen} onClose={handleDialogCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {openForCreate ? 'Create new tracking station' : 'Update tracking station'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="stationName"
            label="Name"
            type="text"
            fullWidth
            value={newStationDetails.stationName}
            onChange={handleDialogContentChange}
          />
          <TextField
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="rg"
            label="Resource Group"
            type="text"
            fullWidth
            value={newStationDetails.rg}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.latitude))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="latitude"
            label="Latitude"
            type="number"
            fullWidth
            value={newStationDetails.latitude}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.longitude))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="longitude"
            label="Longitude"
            type="number"
            fullWidth
            value={newStationDetails.longitude}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.altitude))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="altitude"
            label="Altitude"
            type="number"
            fullWidth
            value={newStationDetails.altitude}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.azPosMin))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="azPosMin"
            label="Azimuth MIN Position"
            type="number"
            fullWidth
            value={newStationDetails.azPosMin}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.azPosMax))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="azPosMax"
            label="Azimuth MAX Position"
            type="number"
            fullWidth
            value={newStationDetails.azPosMax}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.azMaxSpeed))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="azMaxSpeed"
            label="Azimuth MAX Speed"
            type="number"
            fullWidth
            value={newStationDetails.azMaxSpeed}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.elPosMin))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="elPosMin"
            label="Elevation MIN Position"
            type="number"
            fullWidth
            value={newStationDetails.elPosMin}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.elPosMax))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="elPosMax"
            label="Elevation MAX Position"
            type="number"
            fullWidth
            value={newStationDetails.elPosMax}
            onChange={handleDialogContentChange}
          />
          <TextField
            error={Number.isNaN(parseFloat(newStationDetails.elMaxSpeed))}
            onFocus={(event) => event.target.select()}
            margin="dense"
            id="elMaxSpeed"
            label="Elevation MAX Speed"
            type="number"
            fullWidth
            value={newStationDetails.elMaxSpeed}
            onChange={handleDialogContentChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="flipCapable"
                checked={newStationDetails.flipCapable}
                onChange={handleDialogContentChange}
                color="primary"
              />
            }
            label="Station is FLIP capable"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} color="default" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDialogCreate} color="primary" variant="contained" disabled={disableCreateButton}>
            {openForCreate ? 'Create' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      <Box py={1}>
        <DataGrid
          rows={stations.map((station, i) => ({ id: i, ...station }))}
          columns={columns}
          density="compact"
          pageSize={10}
          components={{
            Toolbar: CustomToolbar,
          }}
          autoHeight
        />
      </Box>
    </>
  );
};

export default StatusTable;
