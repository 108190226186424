import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import EnumField from '#components/page-content/packet-builder/field-inputs/EnumField';

const EnumSelect = ({ enumId, id, label, helperText, value, setValue, ...otherInputProps }) => {
  const htmlHelpId = `${id}-help`;
  const { SelectProps: fwdSelectProps, ...fwdInputProps } = otherInputProps;

  return (
    <FormControl {...fwdInputProps}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <EnumField
        id={id}
        enumId={enumId}
        value={value}
        setValue={setValue}
        ariaDescribedby={id ? htmlHelpId : undefined}
        {...fwdSelectProps}
      />
      {helperText && <FormHelperText id={id ? htmlHelpId : undefined}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

EnumSelect.propTypes = {
  enumId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
};

EnumSelect.defaultProps = {
  value: undefined,
  id: undefined,
  label: undefined,
  helperText: undefined,
};

export default EnumSelect;
