import { useContext } from 'react';

import RgContext from '#contexts/RgContext';

import useDsEventListener from './useDsEventListener';

export default function useProtocolEventListener({ protocol, filter = () => true, callback }) {
  const { currentResGroup } = useContext(RgContext);

  return useDsEventListener(
    `rg/${currentResGroup}/@c3s/${protocol.toLowerCase()}-transcoder-provider/decoded${protocol}`,
    callback
      ? (data) => {
          if (filter(data.json)) {
            callback(data);
          }
        }
      : undefined,
  );
}
