import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../../useAutonomyStatusDs';
import useRffeDs from '../useRffeDs';

const modeOptions = new Map();
const autoRxTxValueOptions = new Map();
const manualRxTxValueOptions = new Map();

export default function useTxRxControl() {
  const theme = useTheme();
  const { rffeState, noResponse } = useRffeDs();
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  modeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO - 0', color: 'white' });
  modeOptions.set('manual', { bgColor: theme.palette.yellow.main, label: 'MANUAL - 1', color: 'black' });
  modeOptions.set('lost', { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });

  autoRxTxValueOptions.set('off', { bgColor: theme.palette.grey.main, label: 'OFF', color: 'white' });
  autoRxTxValueOptions.set('OFF', { bgColor: theme.palette.grey.main, label: 'OFF', color: 'white' });
  autoRxTxValueOptions.set('idle', { bgColor: theme.palette.yellow.main, label: 'IDLE', color: 'black' });
  autoRxTxValueOptions.set('IDLE', { bgColor: theme.palette.yellow.main, label: 'IDLE', color: 'black' });
  autoRxTxValueOptions.set('rx', { bgColor: theme.palette.warning.main, label: 'RX', color: 'white' });
  autoRxTxValueOptions.set('RX', { bgColor: theme.palette.warning.main, label: 'RX', color: 'white' });
  autoRxTxValueOptions.set('tx', { bgColor: theme.palette.error.main, label: 'TX', color: 'white' });
  autoRxTxValueOptions.set('TX', { bgColor: theme.palette.error.main, label: 'TX', color: 'white' });
  autoRxTxValueOptions.set('lost', { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });
  autoRxTxValueOptions.set('LOST', { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });

  manualRxTxValueOptions.set('off', { bgColor: theme.palette.grey.main, label: 'OFF', color: 'white' });
  manualRxTxValueOptions.set('OFF', { bgColor: theme.palette.grey.main, label: 'OFF', color: 'white' });
  manualRxTxValueOptions.set('idle', { bgColor: theme.palette.yellow.main, label: 'IDLE', color: 'black' });
  manualRxTxValueOptions.set('IDLE', { bgColor: theme.palette.yellow.main, label: 'IDLE', color: 'black' });
  manualRxTxValueOptions.set('rx', { bgColor: theme.palette.warning.main, label: 'RX', color: 'white' });
  manualRxTxValueOptions.set('RX', { bgColor: theme.palette.warning.main, label: 'RX', color: 'white' });
  manualRxTxValueOptions.set('tx', { bgColor: theme.palette.error.main, label: 'TX', color: 'white' });
  manualRxTxValueOptions.set('TX', { bgColor: theme.palette.error.main, label: 'TX', color: 'white' });
  manualRxTxValueOptions.set('lost', { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });
  manualRxTxValueOptions.set('LOST', { bgColor: theme.palette.grey.main, label: 'LOST', color: 'white' });
  manualRxTxValueOptions.set('diag', { bgColor: theme.palette.primary.light, label: 'DIAG', color: 'white' });
  manualRxTxValueOptions.set('DIAG', { bgColor: theme.palette.primary.light, label: 'DIAG', color: 'white' });
  manualRxTxValueOptions.set('error', { bgColor: theme.palette.grey.light, label: 'ERROR', color: 'white' });
  manualRxTxValueOptions.set('ERROR', { bgColor: theme.palette.grey.light, label: 'ERROR', color: 'white' });

  const getString = useCallback(
    (val) => {
      if (noResponse) {
        return 'lost';
      }
      return val;
    },
    [noResponse],
  );

  const setMode = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.rxTxMode',
          value: mode,
        });
        enqueueSnackbar('Mode change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [rpcWithErrorHandler, configPath, enqueueSnackbar],
  );

  const setTxRxManualValue = useCallback(
    (manualValue) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'RFFEControl.rxTxManualValue',
          value: manualValue,
        });
        enqueueSnackbar('Manual value change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [rpcWithErrorHandler, configPath, enqueueSnackbar],
  );

  const txRxControlComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'TX-RX Mode:',
          button1Text: 'MANUAL',
          handleButton1Click: () => setMode('manual'),
          button2Text: 'AUTO',
          handleButton2Click: () => setMode('auto'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current RX-TX Mode:',
          value: getString(autonomyConfig.RFFEControl.rxTxMode),
          ValueComponent: GeneralStatusIndicator,
          options: modeOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Auto RX-TX Value:',
          value: getString(autonomyConfig.RFFEControl.rxTxAutoValue),
          ValueComponent: GeneralStatusIndicator,
          options: autoRxTxValueOptions,
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'Manual TX-RX Mode:',
          options: [
            {
              label: 'RX',
              value: 'rx',
            },
            {
              label: 'TX',
              value: 'tx',
            },
            {
              label: 'OFF',
              value: 'off',
            },
            {
              label: 'IDLE',
              value: 'idle',
            },
            {
              label: 'DIAG',
              value: 'diag',
            },
          ],
          selectedValue: autonomyConfig.RFFEControl.rxTxManualValue,
          handleSet: setTxRxManualValue,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Manual RX-TX Value:',
          value: getString(autonomyConfig.RFFEControl.rxTxManualValue),
          ValueComponent: GeneralStatusIndicator,
          options: manualRxTxValueOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Required RX-TX Value:',
          value: autonomyStatus.RFFEControl.rxTxOutputValue,
          ValueComponent: GeneralStatusIndicator,
          options: manualRxTxValueOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Actual RX-TX Value:',
          value: getString(rffeState.RFMode.toLowerCase()),
          ValueComponent: GeneralStatusIndicator,
          options: manualRxTxValueOptions,
        },
      },
    ],
    [
      autonomyConfig.RFFEControl.rxTxAutoValue,
      autonomyConfig.RFFEControl.rxTxManualValue,
      autonomyConfig.RFFEControl.rxTxMode,
      autonomyStatus.RFFEControl.rxTxOutputValue,
      getString,
      rffeState.RFMode,
      setMode,
      setTxRxManualValue,
    ],
  );

  return { txRxControlComponents };
}
