import resolveTCTM from '@c3s/tctm-service-dictionary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsEventListener from '#hooks/useDsEventListener';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';
import useSnackbar from '#hooks/useSnackbar';
import { saveStringToFile } from '#utils/downloadData';

import EnhancedTable from './EnhancedTable';

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    marginBottom: theme.spacing(1),
  },
  fileInput: {
    display: 'none',
  },
}));

const columns = [
  {
    Header: 'Inserted On',
    accessor: (originalRow) => DateTime.fromISO(originalRow.insertedOn).toFormat(`yyyy.LL.dd. HH:mm:ss SSS`),
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
  {
    Header: 'Position',
    accessor: 'pos',
  },
  {
    Header: 'Item',
    columns: [
      {
        Header: 'APID',
        id: 'apid',
        accessor: 'item.Packet.APID',
        Cell: ({ value, row }) => resolveTCTM(row.original.item.SCID, value).APID,
      },
      {
        Header: 'Service',
        id: 'service',
        accessor: 'item.Packet.Service',
        Cell: ({ value, row }) => resolveTCTM(row.original.item.SCID, row.values.apid, value).service,
      },
      {
        Header: 'SubService',
        accessor: 'item.Packet.SubService',
        id: 'subservice',
        Cell: ({ value, row }) =>
          resolveTCTM(row.original.item.SCID, row.values.apid, row.values.service, value).subservice,
      },
    ],
  },
];

const TelecommandQueueContainer = memo(({ fifo }) => {
  const classes = useStyles();

  const [pageCount, setPageCount] = useState(-1);
  const { enqueueSnackbar } = useSnackbar();
  const { currentResGroup } = useContext(RgContext);
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const rpcUrl = useMemo(() => `rg/${currentResGroup}/@c3s/fifo-provider`, [currentResGroup]);
  const changedEventName = useMemo(() => `rg/${currentResGroup}/@c3s/fifo-provider/changed`, [currentResGroup]);

  const [valve, setValve] = useState(false);
  const [data, setData] = useState([]);

  const loadFileForm = useRef();

  const processRpcResponse = useCallback(
    async ({ endpoint, paramsObject, snackbarMessage }) => {
      const ret = await rpcWithErrorHandler(endpoint, paramsObject);
      if (ret.status !== 'error') {
        enqueueSnackbar(snackbarMessage, { variant: 'info' });
      }
    },
    [rpcWithErrorHandler, enqueueSnackbar],
  );

  const fetchData = useCallback(
    async ({ pageIndex, pageSize }) => {
      const { status, result } = await rpcWithErrorHandler(`${rpcUrl}/list`, {
        fifo,
        query: {},
        sort: fifo !== 'output' ? { pos: 1 } : { priority: 1, _id: 1 },
        limit: pageSize,
        skip: pageIndex * pageSize,
      });

      if (status === 'success') {
        setData(result.data);
        setPageCount(result.pageCount);
      } else {
        enqueueSnackbar(`Cannot list ${fifo} queue contents`, { variant: 'error' });
      }
    },
    [rpcUrl, rpcWithErrorHandler, fifo, enqueueSnackbar],
  );

  const importFifoContents = useCallback(
    async (items) => {
      const { status } = await rpcWithErrorHandler(`${rpcUrl}/importFromJSON`, {
        fifo,
        arrayOfItems: items,
      });

      if (status !== 'success') {
        enqueueSnackbar(`Cannot import file contents into ${fifo} queue`, { variant: 'error' });
      }
    },
    [rpcUrl, rpcWithErrorHandler, fifo, enqueueSnackbar],
  );

  const exportFifoContents = useCallback(async () => {
    const { status, result } = await rpcWithErrorHandler(`${rpcUrl}/exportToJSON`, {
      fifo,
    });

    if (status === 'success') {
      saveStringToFile('application/json', `${fifo}-queue-${Date.now()}.json`, result.data);
    } else {
      enqueueSnackbar(`Cannot export ${fifo} queue contents`, { variant: 'error' });
    }
  }, [rpcUrl, rpcWithErrorHandler, fifo, enqueueSnackbar]);

  const clearFifoItem = useCallback(
    async ({ _id }) => {
      const { status } = await rpcWithErrorHandler(`${rpcUrl}/remove`, {
        fifo,
        id: _id,
      });

      if (status !== 'success') {
        enqueueSnackbar(`Cannot remove item form ${fifo} queue`, { variant: 'error' });
      }
    },
    [rpcUrl, rpcWithErrorHandler, fifo, enqueueSnackbar],
  );

  const handleFileOpen = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (file !== undefined) {
        const text = await file.text();
        try {
          const fifoContents = JSON.parse(text);
          importFifoContents(fifoContents);
        } catch (e) {
          enqueueSnackbar(`Cannot import from malformed input file`, { variant: 'error' });
        } finally {
          loadFileForm.current.reset();
        }
      }
    },
    [importFifoContents, enqueueSnackbar],
  );

  const valveStateCallback = useCallback(
    async (record) => {
      setValve(record[fifo] || false);
      await fetchData({ pageIndex: 0, pageSize: 10 });
    },
    [fetchData, fifo],
  );

  const changedCallback = useCallback(async () => {
    await fetchData({ pageIndex: 0, pageSize: 10 });
  }, [fetchData]);

  useDsRecordSubscription(`rg/${currentResGroup}/@c3s/fifo-provider/valveStates`, valveStateCallback);
  useDsEventListener(changedEventName, changedCallback);

  return (
    <>
      <Grid container className={classes.buttonRow} justify="space-between">
        <Grid item xs>
          {valve ? (
            <FontAwesomeIcon icon={['fas', 'faucet']} size="lg" />
          ) : (
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon="faucet" size="lg" />
              <FontAwesomeIcon icon="slash" transform="left-3" size="lg" />
            </span>
          )}
          <ButtonGroup
            size="small"
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            style={{ marginLeft: '10px' }}
          >
            <Button
              onClick={() => {
                processRpcResponse({
                  endpoint: `${rpcUrl}/setValve`,
                  paramsObject: {
                    fifo,
                    open: true,
                  },
                  snackbarMessage: `Open ${fifo} valve`,
                });
              }}
            >
              Open
            </Button>
            <Button
              onClick={() => {
                processRpcResponse({
                  endpoint: `${rpcUrl}/setValve`,
                  paramsObject: {
                    fifo,
                    open: false,
                  },
                  snackbarMessage: `Close ${fifo} valve`,
                });
              }}
            >
              Close
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs container justify="flex-end" spacing={1}>
          {fifo === 'operator' && (
            <>
              <Grid item>
                <form ref={loadFileForm}>
                  <label htmlFor={`${fifo}-fifo-import-button-file`}>
                    <input
                      className={classes.fileInput}
                      id={`${fifo}-fifo-import-button-file`}
                      type="file"
                      onChange={handleFileOpen}
                    />
                    <Button size="small" variant="contained" color="primary" component="span">
                      Load
                    </Button>
                  </label>
                </form>
              </Grid>
              <Grid item>
                <Button size="small" variant="contained" color="primary" onClick={exportFifoContents}>
                  Save
                </Button>
              </Grid>
            </>
          )}
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                processRpcResponse({
                  endpoint: `${rpcUrl}/empty`,
                  paramsObject: {
                    fifo,
                  },
                  snackbarMessage: `Empty queue.`,
                });
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <EnhancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        clearFifoItem={clearFifoItem}
        pageCount={pageCount}
      />
    </>
  );
});

TelecommandQueueContainer.propTypes = {
  fifo: PropTypes.string.isRequired,
};

export default TelecommandQueueContainer;
