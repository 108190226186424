import { ControlCard } from '@c3s/ui-tracking-station';
import { Grid } from '@material-ui/core';
import React from 'react';

import useFFTControl from './FFTControl/useFFTControl';
import WaterfallWrapper from './Waterfall/WaterfallWrapper';

const FFTMonitorTab = () => {
  const { fftControlComponents } = useFFTControl();

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <ControlCard title="FFT Control" components={fftControlComponents} style={{ height: '100%' }} />
      </Grid>
      <Grid item xs={12}>
        <WaterfallWrapper />
      </Grid>
    </Grid>
  );
};

export default FFTMonitorTab;
