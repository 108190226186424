import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import printf from 'printf';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import useHkAutonomyConfigDs from '../../useAutonomyConfigDs';
import useHkAutonomyStatusDs from '../../useAutonomyStatusDs';
import useHousekeepingDs from '../useHousekeepingDs';

const onOffOptions = new Map();
const relayOptions = new Map();
const doorOptions = new Map();
const circuitOptions = new Map();
const autoValueOptions = new Map();
const modeOptions = new Map();

export default function useCabinetFanControl() {
  const theme = useTheme();
  const { hkState } = useHousekeepingDs();
  const { autonomyConfig, configPath } = useHkAutonomyConfigDs();
  const { autonomyStatus } = useHkAutonomyStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  modeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO', color: 'white' });
  modeOptions.set('manual', { bgColor: theme.palette.warning.main, label: 'MANUAL', color: 'white' });
  onOffOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  onOffOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });
  relayOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  relayOptions.set(1, { bgColor: theme.palette.error.main, label: 'ERR - 1', color: 'white' });
  circuitOptions.set(0, { bgColor: theme.palette.success.main, label: 'OK - 0', color: 'white' });
  circuitOptions.set(1, { bgColor: theme.palette.error.main, label: 'ERR - 1', color: 'white' });
  doorOptions.set(0, { bgColor: theme.palette.error.main, label: 'OPEN - 0', color: 'white' });
  doorOptions.set(1, { bgColor: theme.palette.success.main, label: 'CLOSED - 1', color: 'white' });
  autoValueOptions.set(0, { bgColor: theme.palette.error.main, label: 'OFF - 0', color: 'white' });
  autoValueOptions.set(1, { bgColor: theme.palette.success.main, label: 'ON - 1', color: 'white' });

  const setMode = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'cabinetFanControl.mode',
          value: mode,
        });
        enqueueSnackbar('Mode change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const setManualValue = useCallback(
    (value) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'cabinetFanControl.manualValue',
          value,
        });
        enqueueSnackbar('Manual Value change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const cabinetHeatingOutput = hkState.outputs[2];
  const cabinetCoolingOutput = hkState.outputs[3];
  const cabinetFanOutput = hkState.outputs[4];

  const frontDoorInput = hkState.inputs[3];
  const rearDoorInput = hkState.inputs[4];

  const cabinetFanComponents = useMemo(
    () => [
      {
        type: 'buttons',
        options: {
          helperText: 'Mode:',
          button1Text: 'AUTO',
          handleButton1Click: () => setMode('auto'),
          button2Text: 'MANUAL',
          handleButton2Click: () => setMode('manual'),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Mode:',
          value: autonomyConfig.cabinetFanControl.mode,
          ValueComponent: GeneralStatusIndicator,
          options: modeOptions,
        },
      },
      {
        type: 'buttons',
        options: {
          helperText: 'MANUAL Value:',
          button1Text: 'ON',
          handleButton1Click: () => setManualValue(true),
          button2Text: 'OFF',
          handleButton2Click: () => setManualValue(false),
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current MANUAL Value:',
          value: Number(autonomyConfig.cabinetFanControl.manualValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoValueOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Internal Temp:',
          value: hkState.temperature.indoor != null ? printf('%+.1f', hkState.temperature.indoor) : '-',
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'External Temp:',
          value: hkState.temperature.outdoor != null ? printf('%+.1f', hkState.temperature.outdoor) : '-',
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Cabinet Heating State:',
          value: Number(cabinetHeatingOutput ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: onOffOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Cabinet Cooling State:',
          value: Number(cabinetCoolingOutput ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: onOffOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Relay Drv. A fault',
          value: Number(hkState.errorFlags.relayDriverAFault ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: relayOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'F-DOOR State',
          value: Number(frontDoorInput ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: doorOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'R-DOOR State',
          value: Number(rearDoorInput ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: doorOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Short Circuit Flag',
          value: Number(hkState.errorFlags.outdoorTempSensorShortCircuitError ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: circuitOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Open Circuit Flag',
          value: Number(hkState.errorFlags.outdoorTempSensorOpenCircuitError ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: circuitOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: '*Temp Fan Limit',
          value:
            autonomyConfig.cabinetFanControl.tempFanLimit != null
              ? printf('%+.1f', autonomyConfig.cabinetFanControl.tempFanLimit)
              : '-',
          unit: '°C',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'AUTO Value:',
          value: Number(autonomyStatus.cabinetFanControl.autoValue ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoValueOptions,
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual Value:',
          value: Number(cabinetFanOutput ?? 0),
          ValueComponent: GeneralStatusIndicator,
          options: autoValueOptions,
        },
      },
    ],
    [
      autonomyConfig.cabinetFanControl.mode,
      autonomyConfig.cabinetFanControl.manualValue,
      autonomyConfig.cabinetFanControl.tempFanLimit,
      hkState.temperature.indoor,
      hkState.temperature.outdoor,
      hkState.errorFlags.relayDriverAFault,
      hkState.errorFlags.outdoorTempSensorShortCircuitError,
      hkState.errorFlags.outdoorTempSensorOpenCircuitError,
      cabinetHeatingOutput,
      cabinetCoolingOutput,
      frontDoorInput,
      rearDoorInput,
      autonomyStatus.cabinetFanControl.autoValue,
      cabinetFanOutput,
      setMode,
      setManualValue,
    ],
  );

  return { cabinetFanComponents };
}
