import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

export default function usePowerSwitchCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcBaseUrl = `rg/${currentResGroup}/@c3s/bustester-provider/lclControl`;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const rpcUrl = `${rpcBaseUrl}/setLcl`;

  const setLcl = ({ LCL_ID, newLclState }) => {
    processRpcResponse({
      endpoint: rpcUrl,
      paramsObject: { LCL_ID, Cmd: newLclState },
      snackbarMessage: 'Set LCL requested',
    });
  };

  const getStatus = () => {
    processRpcResponse({
      endpoint: `${rpcBaseUrl}/getStatus`,
      paramsObject: null,
      snackbarMessage: 'Get LCL status requested',
    });
  };

  const dsRecord = useDsRecord('bustester', 'control/role');
  const [dutRole, setDutRole] = useState(2);

  const dsSubscription = (newRecord) => {
    setDutRole(newRecord?.dutRole ?? 2);
  };

  useEffect(() => {
    dsRecord.subscribe(dsSubscription, true);
    return function cleanup() {
      dsRecord.discard();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { setLcl, getStatus, dutRole };
}
