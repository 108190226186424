import { MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import styles from '#utils/styles';

import BusList from './BusList';

const useStyles = makeStyles(styles);

const BusSelect = ({ id, name, value, error, label, onChange, style }) => {
  const classes = useStyles();

  return (
    <TextField
      select
      id={id}
      name={name}
      value={value}
      error={error}
      label={label}
      style={style}
      onChange={onChange}
      className={classes.TextField}
      SelectProps={{ id, name }}
      InputLabelProps={{ shrink: true }}
    >
      {BusList.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

BusSelect.defaultProps = {
  style: {},
};

BusSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

export default memo(BusSelect);
