import { useContext, useEffect, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';

/**
 * Retrieve a DS record
 *
 * @param {('bustester' | 'accessport' | 'sdr')} connectionType
 * @param {('obc' | 'com' | 'eps' | 'aux' | 'adcs' | 'radmag')} apidOrService
 *
 * @returns The requested record | null
 *
 * @example
 * const dsRecord = useDsRecord('bustester', 'control');
 * @example
 * const dsRecord = useDsRecord('bustester', 'aux/tc');
 */
export default function useDsRecord(connectionType, apidOrService) {
  const {
    dsClient,
    clientData: { userName },
  } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);

  const dsRecord = useMemo(
    () =>
      dsClient.record.getRecord(
        `username/${userName}/rg/${currentResGroup}/@c3s/moc-ui/debug/${connectionType}/${apidOrService}`,
      ),
    [dsClient.record, userName, currentResGroup, connectionType, apidOrService],
  );

  useEffect(() => {
    return function cleanup() {
      dsRecord.discard();
    };
  }, [dsRecord]);

  return dsRecord;
}
