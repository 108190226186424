import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

export default function useMlvdsHeartbeatServiceCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcBaseUrl = `rg/${currentResGroup}/@c3s/bustester-provider/mlvdsHeartbeat`;

  const mlvdsHbDsRecord = useDsRecord('bustester', 'control/mlvdsheartbeat');
  const [MlvdsHeartbeatMainRed, setMlvdsHeartbeatMainRed] = useState(0);
  const [MlvdsHeartbeatAsSubsystem, setMlvdsHeartbeatAsSubsystem] = useState(2);
  const [MlvdsHeartbeatMessage, setMlvdsHeartbeatMessage] = useState('');

  const isMlvdsHeartbeatMessageInvalid = MlvdsHeartbeatMessage === '';

  const persistState = () => {
    mlvdsHbDsRecord.set({
      ...mlvdsHbDsRecord.get(),
      MlvdsHeartbeatMainRed,
      MlvdsHeartbeatAsSubsystem,
      MlvdsHeartbeatMessage,
    });
  };

  useEffect(() => {
    mlvdsHbDsRecord.whenReady((record) => {
      setMlvdsHeartbeatMainRed(record.get()?.CanHeartbeatMainRed || 0);
      setMlvdsHeartbeatAsSubsystem(record.get()?.CanHeartbeatAsSubsystem ?? 2);
      setMlvdsHeartbeatMessage(record.get()?.CanHeartbeatMessage || '');
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const disableHeartbeat = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcBaseUrl}/disableHeartbeat`,
      paramsObject: {
        APID: MlvdsHeartbeatAsSubsystem,
        Red: MlvdsHeartbeatMainRed,
      },
      snackbarMessage: 'Disable M-LVDS heartbeat requested',
    });
  };

  const enableHeartbeat = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcBaseUrl}/enableHeartbeat`,
      paramsObject: {
        APID: MlvdsHeartbeatAsSubsystem,
        Red: MlvdsHeartbeatMainRed,
        heartbeat: isMlvdsHeartbeatMessageInvalid
          ? []
          : MlvdsHeartbeatMessage.replace(/\s+/g, '')
              .match(/.{1,2}/g)
              .map((value) => parseInt(value, 16)),
      },
      snackbarMessage: 'Enable M-LVDS heartbeat requested',
    });
  };

  const getHeartbeatStatus = () => console.log('TODO: Implement get hb');

  return {
    enableHeartbeat,
    disableHeartbeat,
    getHeartbeatStatus,
    MlvdsHeartbeatMainRed,
    MlvdsHeartbeatAsSubsystem,
    MlvdsHeartbeatMessage,
    isMlvdsHeartbeatMessageInvalid,
    setMlvdsHeartbeatMainRed,
    setMlvdsHeartbeatAsSubsystem,
    setMlvdsHeartbeatMessage,
  };
}
