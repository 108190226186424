import { FrequencyWaterfall } from '@c3s/ui-tracking-station';
import React, { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';

export default function WaterfallWrapper() {
  const { currentResGroup } = useContext(RgContext);
  const { dsClient } = useContext(DeepstreamContext);
  const [nfft, setNfft] = useState(2048);
  const [fc, setFc] = useState(2250e6);
  const [fs, setFs] = useState(409600);
  const [psd, setPsd] = useState(Array.from({ length: nfft }).fill(0));

  useEffect(() => {
    dsClient.event.subscribe(`rg/${currentResGroup}/lime-sdr-driver/spectrogram`, (data) => {
      const { fc: newFc, fs: newFs, nfft: newNfft, psd: newPsd } = JSON.parse(data);
      setNfft(newNfft);
      setPsd(newPsd);
      setFc(newFc);
      setFs(newFs);
    });

    return () => {
      dsClient.event.unsubscribe(`rg/${currentResGroup}/lime-sdr-driver/spectrogram`);
    };
  }, [dsClient.event, currentResGroup]);

  return <FrequencyWaterfall nfft={nfft} fc={fc} fs={fs} psd={psd} />;
}
