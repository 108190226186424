import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

import TCQueuePageWrapper from '#components/page-content/telecommand-queue/TCQueuePageWrapper';
import TelecommandQueueContainer from '#components/page-content/telecommand-queue/TelecommandQueueContainer';

const PageTitle = 'Satellite Control';

const SatelliteControlPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <TCQueuePageWrapper>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Card>
              <CardHeader title="Timesync Queue" titleTypographyProps={{ variant: 'h6' }} />
              <CardContent>
                <TelecommandQueueContainer fifo="timesync" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card>
              <CardHeader title="Operator Queue" titleTypographyProps={{ variant: 'h6' }} />
              <CardContent>
                <TelecommandQueueContainer fifo="operator" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card>
              <CardHeader title="Services High" titleTypographyProps={{ variant: 'h6' }} />
              <CardContent>
                <TelecommandQueueContainer fifo="services_high" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card>
              <CardHeader title="Services Normal" titleTypographyProps={{ variant: 'h6' }} />
              <CardContent>
                <TelecommandQueueContainer fifo="services_normal" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12}>
            <Card>
              <CardHeader title="Output Queue" titleTypographyProps={{ variant: 'h6' }} />
              <CardContent>
                <TelecommandQueueContainer fifo="output" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TCQueuePageWrapper>
    </>
  );
};

export default SatelliteControlPage;
