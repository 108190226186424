import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import { useToggle } from 'standard-hooks';

import HeartBeatReport from '#satellites/RADCUBE/protocols/CAN/status/HeartBeatReport';
import { redundancyToIdentifier } from '#utils/subsystemDictionary';

import HeartbeatPanelStyles from './HeartbeatPanel.styles';

const useStyles = makeStyles(HeartbeatPanelStyles);

const HeartbeatPanel = ({ toggleShallStickHeartbeat, ShallStickHeartbeat }) => {
  const {
    accordion,
    accordionSummary,
    content,
    expanded,
    titleColumn,
    title,
    descriptionColumn,
    details,
    card,
    cardContent,
    table,
    tableHead,
    tableRow,
    cell,
    valueCell,
    tableBody,
  } = useStyles();
  const [isExpanded, toggleExpanded] = useToggle(false);

  return (
    <Accordion square expanded={isExpanded} onChange={toggleExpanded} className={accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: accordionSummary,
          content,
          expanded,
        }}
      >
        <div className={titleColumn}>
          <Typography className={title}>
            <FontAwesomeIcon
              icon="thumbtack"
              color={ShallStickHeartbeat ? blue[500] : 'black'}
              size="sm"
              onClick={toggleShallStickHeartbeat}
            />{' '}
            CAN Heartbeat status
          </Typography>
        </div>
        <div className={descriptionColumn} />
      </AccordionSummary>
      <AccordionDetails className={details}>
        <Grid container>
          <Grid item xs={12} md={12} lg>
            <Card className={card}>
              <CardContent className={cardContent}>
                <Table size="small" className={table}>
                  <TableHead className={tableHead}>
                    <TableRow className={tableRow}>
                      <TableCell className={cell}>SubSystem</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Code Exec. State</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Relative Time</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Event FIFO Counter</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>DAQ FIFO Counter</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={tableBody}>
                    {['OBC1', 'AUX1', 'COM1', 'EPS1', 'ADCS'].map((subsystem) => {
                      return (
                        <HeartBeatReport
                          key={subsystem}
                          title={subsystem}
                          identifier={redundancyToIdentifier[subsystem]}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                <Table size="small" className={table}>
                  <TableHead className={tableHead}>
                    <TableRow className={tableRow}>
                      <TableCell className={cell}>SubSystem</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Code Exec. State</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Relative Time</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Event FIFO Counter</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>DAQ FIFO Counter</TableCell>
                      <TableCell className={`${cell} ${valueCell}`}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={tableBody}>
                    {['OBC2', 'AUX2', 'COM2', 'EPS2', 'RADMAG'].map((subsystem) => {
                      return (
                        <HeartBeatReport
                          key={subsystem}
                          title={subsystem}
                          identifier={redundancyToIdentifier[subsystem]}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

HeartbeatPanel.propTypes = {
  toggleShallStickHeartbeat: PropTypes.func.isRequired,
  ShallStickHeartbeat: PropTypes.bool.isRequired,
};

export default HeartbeatPanel;
