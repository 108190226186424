import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  dataValue: {
    fontWeight: 'bold',
  },
}));

const GridDescriptionList = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid container component="dl" spacing={1} className={classes.root}>
      {data.map(({ label, prefix, value, suffix, gridWidth }) => (
        <Grid key={label} item container spacing={1} md={12} lg={gridWidth}>
          <Grid item component="dt">
            {label}:
          </Grid>
          <Grid item component="dd" className={classes.dataValue}>
            {prefix ?? ''}
            {value ?? 'N/A'}
            {suffix ?? ''}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

GridDescriptionList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      prefix: PropTypes.string,
      value: PropTypes.node,
      suffix: PropTypes.string,
      gridWidth: PropTypes.number,
    }),
  ),
};

GridDescriptionList.defaultProps = {
  data: [],
};

export default GridDescriptionList;
