import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useSnackbar from '#hooks/useSnackbar';

import AccessPortPageWrapperStyles from './AccessPortPageWrapperStyles';

const useStyles = makeStyles(AccessPortPageWrapperStyles);

const AccessPortPageWrapper = ({ children, connectorCmdProtocolStack }) => {
  const {
    missionKey,
    missionMeta: { commandingProtocol },
  } = useCurrentMission();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { enqueueSnackbar } = useSnackbar();

  const tctmCreateFunction = useCallback(
    async (paramsObject, snackbarMessage) => {
      const rpcUrl = `rg/${missionKey}/@c3s/bustester-provider/messageForwarding/ssp`;
      const ret = await rpcWithErrorHandler(rpcUrl, paramsObject);
      if (ret.status !== 'error') {
        enqueueSnackbar(snackbarMessage, { variant: 'info' });
      }
    },
    [missionKey, rpcWithErrorHandler, enqueueSnackbar],
  );

  const fileTransferRpc = { rpc: `rg/${missionKey}/@c3s/tester-provider/sendToBusTester`, format: 'eth' };

  const classes = useStyles();

  return (
    <RgContext.Provider
      value={{
        currentResGroup: missionKey,
        tcProtocolStack: [...connectorCmdProtocolStack, commandingProtocol],
        tctmCreateFunction,
        fileTransferRpc,
      }}
    >
      <div className={classes.Wrapper}>{children}</div>
    </RgContext.Provider>
  );
};

AccessPortPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  connectorCmdProtocolStack: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AccessPortPageWrapper;
