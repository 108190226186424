export default () => ({
  container: {
    marginBottom: '2em',
  },
  hkConnCard: {
    height: '100%',
  },
  hkAntCard: {
    height: '100%',
  },
  hkCabinetFanCard: {
    height: '100%',
  },
  hkCabinetHeaterCard: {
    height: '100%',
  },
  hkCabinetCoolingCard: {
    height: '100%',
  },
  hkRffeCard: {
    height: '100%',
  },
  hkServoPwCard: {
    height: '100%',
  },
  hkMastLampCard: {
    height: '100%',
  },
  hkPdmcCard: {
    height: '80%',
  },
  hkPdmcResetButton: {
    width: '100%',
    marginTop: '1em',
  },
});
