import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

const initialFilterValues = {
  CAN_STREAM_SERV_FCU_EN: false,
  CAN_DATA_STREAM_SERV_FILTER_EN: false,
  CAN_TCTM_STREAM_SERV_FILTER_EN: false,
  CAN_SYNC_MSG_SERV_FILTER_EN: false,
  CAN_DEV_CMD_SERV_FILTER_EN: false,
  CAN_ABS_TIME_DIST_SERV_FILTER_EN: false,
  CAN_NODE_MNG_SERV_FILTER_EN: false,
  CAN_TIME_SYNC_SERV_FILTER_EN: false,
  CAN_BUS_MNG_SERV_FILTER_EN: false,
  OBC_COM_FCU_EN: false,
  OBC_COM_DIR: false,
  OBC_PL_FCU_EN: false,
};

export default function useFilterAndFcuCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/busConfig`;
  const filtersDsRecord = useDsRecord('bustester', 'control/filter');
  const [FilterFcuConfig, setFilterFcuConfig] = useState(initialFilterValues);

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  useEffect(() => {
    filtersDsRecord.whenReady((record) => {
      setFilterFcuConfig(record.get()?.FilterFcuConfig || initialFilterValues);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numCheckedItems = Object.values(FilterFcuConfig).reduce((acc, value) => acc + value, 0);

  const persistState = () => {
    filtersDsRecord.set({ ...filtersDsRecord.get(), FilterFcuConfig });
  };

  const onFilterSet = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/setConfig`,
      paramsObject: FilterFcuConfig,
      snackbarMessage: 'Filter set requested',
    });
  };

  const getFilterStatus = () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/getStatus`,
      paramsObject: null,
      snackbarMessage: 'Filter get requested',
    });
  };

  return {
    onFilterSet,
    getFilterStatus,
    FilterFcuConfig,
    numCheckedItems,
    setFilterFcuConfig,
  };
}
