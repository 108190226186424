import { CabinetHkStatus } from '@c3s/ui-tracking-station';
import React from 'react';

import { labels, useCabinetHkStatusCard } from './useCabinetHkStatusCard';

const CabinetHkStatusCard = () => {
  const { status } = useCabinetHkStatusCard();

  return <CabinetHkStatus status={status} labels={labels} />;
};

export default CabinetHkStatusCard;
