import Grid from '@material-ui/core/Grid';
import React from 'react';

import TCPacketBuilder from './CommandBuilder/TCPacketBuilder';
import TCTable from './TCTable';
import TMTable from './TMTable';

const MessageFlowTab = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <TCPacketBuilder />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TCTable />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TMTable />
      </Grid>
    </Grid>
  );
};

export default MessageFlowTab;
