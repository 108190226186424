import { useContext } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

export default function useCommunicationBusCard() {
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/busConfig/setActiveBuses`;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const setCanBus = (Redundant) => {
    processRpcResponse({
      endpoint: rpcUrl,
      paramsObject: { activeCanBus: Redundant, activeCanBusValid: true },
      snackbarMessage: 'Set CAN BUS Redundant requested',
    });
  };

  const setComMlvdsBus = (Redundant) => {
    processRpcResponse({
      endpoint: rpcUrl,
      paramsObject: { activeComMlvdsBus: Redundant, activeComMlvdsBusValid: true },
      snackbarMessage: 'Set COM M-LVDS Redundant requested',
    });
  };

  const setPayloadMlvdsBus = (Redundant) => {
    processRpcResponse({
      endpoint: rpcUrl,
      paramsObject: {
        activePayloadMlvdsBus: Redundant,
        activePayloadMlvdsBusValid: true,
      },
      snackbarMessage: 'Set Payload M-LVDS Redundant requested',
    });
  };

  const setCanBusToA = () => setCanBus(0);
  const setCanBusToB = () => setCanBus(1);
  const setComMlvdsBusToA = () => setComMlvdsBus(0);
  const setComMlvdsBusToB = () => setComMlvdsBus(1);
  const setPayloadMlvdsBusToA = () => setPayloadMlvdsBus(0);
  const setPayloadMlvdsBusToB = () => setPayloadMlvdsBus(1);

  return {
    setCanBusToA,
    setCanBusToB,
    setComMlvdsBusToA,
    setComMlvdsBusToB,
    setPayloadMlvdsBusToA,
    setPayloadMlvdsBusToB,
  };
}
