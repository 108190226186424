import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const CustomMaskedInput = ({ inputRef, mask, pipe, ...other }) => {
  return (
    <MaskedInput
      guide={false}
      pipe={pipe}
      keepCharPositions={false}
      mask={mask}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    />
  );
};

CustomMaskedInput.defaultProps = {
  pipe: (conformedValue) => conformedValue,
};

CustomMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  pipe: PropTypes.func,
};

export default CustomMaskedInput;
