import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../../useAutonomyStatusDs';
import useMotorCtrlElDs from '../useMotorCtrlElDs';

const modeOptions = new Map();

export default function useServoElControl() {
  const theme = useTheme();
  const { motorCtrlEl, motorCtrlElPath } = useMotorCtrlElDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  modeOptions.set('park', { bgColor: theme.palette.warning.main, label: 'PARK', color: 'white' });
  modeOptions.set('manual', { bgColor: theme.palette.yellow.main, label: 'MANUAL', color: 'black' });
  modeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO', color: 'white' });
  modeOptions.set('stop', { bgColor: theme.palette.error.main, label: 'STOP', color: 'black' });

  const elClear = useCallback(async () => {
    const { status } = await rpcWithErrorHandler(`${motorCtrlElPath}/clear`, {});
    if (status === 'success') {
      enqueueSnackbar('Clear', { variant: 'info' });
    }
  }, [enqueueSnackbar, motorCtrlElPath, rpcWithErrorHandler]);

  const setMode = useCallback(
    (mode) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'motorControl.elMode',
          value: mode,
        });
        enqueueSnackbar('Mode change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const setManualPosition = useCallback(
    (value) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'motorControl.elManualPosition',
          value: Number(value),
        });
        enqueueSnackbar('Manual Position change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [configPath, enqueueSnackbar, rpcWithErrorHandler],
  );

  const servoElControlComponents = useMemo(
    () => [
      {
        type: 'select',
        options: {
          helperText: 'Mode:',
          options: [
            {
              label: 'PARK',
              value: 'park',
            },
            {
              label: 'MANUAL',
              value: 'manual',
            },
            {
              label: 'AUTO',
              value: 'auto',
            },
          ],
          selectedValue: autonomyConfig.motorControl.elMode,
          handleSet: setMode,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Mode:',
          value: autonomyConfig.motorControl.elMode,
          ValueComponent: GeneralStatusIndicator,
          options: modeOptions,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'TLE Pos:',
          value: sanitize(autonomyStatus.motorControl.trackingValue.elAngle, 2),
          unit: 'deg',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Park Pos:',
          value: sanitize(autonomyConfig.motorControl.elParkPosition, 2),
          unit: 'deg',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Manual Pos:',
          numberInputProps: { inputProps: { min: -180, max: 180, scale: 1 } },
          value: autonomyConfig.motorControl.elManualPosition,
          handleSet: setManualPosition,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current MANUAL Pos:',
          value: sanitize(autonomyConfig.motorControl.elManualPosition, 2),
          unit: 'deg',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Set Pos:',
          value: sanitize(autonomyStatus.motorControl.outputValue.elAngle, 2),
          unit: 'deg',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Pointing Error:',
          value: sanitize(autonomyStatus.motorControl.outputValue.elAngle - motorCtrlEl.status.ACT_POSITION, 2),
          unit: 'deg',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual Pos:',
          value: sanitize(motorCtrlEl.status.ACT_POSITION, 2),
          unit: 'deg',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual Speed:',
          value: sanitize(motorCtrlEl.status.ACT_SPEED, 2),
          unit: 'deg/s',
        },
      },
    ],
    [
      autonomyConfig.motorControl.elMode,
      autonomyConfig.motorControl.elManualPosition,
      autonomyConfig.motorControl.elParkPosition,
      autonomyStatus.motorControl.trackingValue.elAngle,
      autonomyStatus.motorControl.outputValue.elAngle,
      motorCtrlEl.status.ACT_POSITION,
      motorCtrlEl.status.ACT_SPEED,
      setMode,
      setManualPosition,
    ],
  );

  return { servoElControlComponents, elClear };
}
