import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import isEqual from 'react-fast-compare';

import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const CardWithStartStopButtons = ({ title, children, onContinue, onPause, onClear, isRunning }) => {
  const classes = useStyles();
  return (
    <Card className={classes.Card} style={{ minHeight: '270px', width: '100%' }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        className={classes.CardHeader}
        action={
          <>
            <IconButton
              color="secondary"
              onClick={onClear}
              className={classes.errorColor}
              style={{ marginRight: '16px' }}
            >
              <Tooltip title="Clear table">
                <div className={classes.fontAwesomeIconHolder}>
                  <FontAwesomeIcon icon={['fas', 'trash']} size="xs" />
                </div>
              </Tooltip>
            </IconButton>
            <IconButton color="primary" onClick={onContinue} disabled={isRunning}>
              <Tooltip title="Continue">
                <div className={classes.fontAwesomeIconHolder}>
                  <FontAwesomeIcon icon={['fas', 'play-circle']} size="xs" />
                </div>
              </Tooltip>
            </IconButton>
            <IconButton color="primary" onClick={onPause} disabled={!isRunning}>
              <Tooltip title="Pause">
                <div className={classes.fontAwesomeIconHolder}>
                  <FontAwesomeIcon icon={['fas', 'pause-circle']} size="xs" />
                </div>
              </Tooltip>
            </IconButton>
          </>
        }
      />
      <CardContent className={classes.CardContent}>{children}</CardContent>
    </Card>
  );
};

CardWithStartStopButtons.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onContinue: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isRunning: PropTypes.bool.isRequired,
};

export default memo(CardWithStartStopButtons, isEqual);
