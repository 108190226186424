import { useState } from 'react';

import useThrottle from '#hooks/useThrottle';

const initialHeader = {
  version: '',
  rawFrame: '',
  timestamp: 0,
};

export default function useProtocolMessage({ listener, transform = (x) => x, initialBody = {} }) {
  const [value, setValue] = useThrottle(500, useState({ header: initialHeader, body: initialBody }));

  listener(({ json, ...header }) => {
    setValue({ header, body: transform(json) });
  });

  return value;
}
