import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import hyperid from 'hyperid';
import { DateTime } from 'luxon';
import React, { memo } from 'react';
import isEqual from 'react-fast-compare';

import CardWithStartStopButtons from '#components/page-fragments/CardWithStartStopButtons';

import MessageTableStyles from '../MessageTableStyles';
import useTCTable from './useTCTable';

const useStyles = makeStyles(MessageTableStyles);

const TCTable = () => {
  const { table, thNumeric, tdShallEllipse, tableRowStyle, thSrcDest, tdPayload } = useStyles();
  const { rows, isRunning, setIsRunning, setRows } = useTCTable();
  const instance = hyperid();
  return (
    <CardWithStartStopButtons
      title="Transmitted messages"
      isRunning={isRunning}
      onContinue={() => {
        setIsRunning(true);
      }}
      onPause={() => {
        setIsRunning(false);
      }}
      onClear={() => {
        setRows([]);
      }}
    >
      <TableContainer style={{ maxHeight: 400 }}>
        <Table size="small" className={table}>
          <TableHead>
            <TableRow>
              <TableCell className={thNumeric}>Apid</TableCell>
              <TableCell className={thNumeric}>Red</TableCell>
              <TableCell className={thNumeric}>Service</TableCell>
              <TableCell className={thNumeric}>SubService</TableCell>
              <TableCell className={thNumeric}>TimeStamp</TableCell>
              <TableCell className={thSrcDest}>ACK</TableCell>
              <TableCell className={tdShallEllipse}>Payload</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              if (Object.keys(row).length) {
                const id = instance();
                const { APID, Red, Service, SubService, Ack, Data } = row.json.Packet;
                return (
                  <TableRow className={tableRowStyle} key={id}>
                    <TableCell>{APID}</TableCell>
                    <TableCell>{Red}</TableCell>
                    <TableCell>{Service}</TableCell>
                    <TableCell>{SubService}</TableCell>
                    <TableCell>{DateTime.fromMillis(row?.timestamp).toFormat(`yyyy.LL.dd. HH:mm:ss SSS`)}</TableCell>
                    <TableCell>{Ack}</TableCell>
                    <TableCell className={tdPayload}>{JSON.stringify(Data)?.toString() ?? 'N/A'}</TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </CardWithStartStopButtons>
  );
};

export default memo(TCTable, isEqual);
