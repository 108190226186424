import { bigFontSize, smallFontSize } from '#utils/styles';

export default (theme) => ({
  Card: {
    fontSize: smallFontSize,
    marginRight: theme.spacing(2),
    padding: 0,
    '&:last-child': {
      marginRight: 0,
    },
    boxShadow: 'none',
  },
  CardHeader: {
    fontSize: smallFontSize,
    fontWeight: 'bold',
    padding: theme.spacing(1),
  },
  CardContent: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'stretch',
    justifyItems: 'space-between',
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
    '&>*': {
      '&:first-child': {
        marginLeft: theme.spacing(1),
      },
      '&:last-child': {
        marginRight: theme.spacing(1),
      },
    },
  },
  Accordion: {
    border: 'unset',
    backgroundColor: '#fff',
    boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
    '&:before': {
      display: 'none',
    },
  },
  AccordionSummary: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    margin: 0,
    fontWeight: 500,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  LabelCol: {
    padding: 0,
  },
  ValueCol: {
    textAlign: 'right',
    padding: 0,
  },
  FontAwesomeIcon: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
  titleColumn: {
    paddingRight: theme.spacing(2),
  },
  title: {
    fontSize: bigFontSize,
  },
  descriptionColumn: {
    display: 'flex',
    verticalAlign: 'middle',
  },
  details: {
    padding: 0,
  },
  Cell: {
    padding: 0,
  },
  ValueCell: {
    textAlign: 'right',
    color: 'black',
  },
  KeyCell: {
    fontWeight: 'bold',
    borderRight: '1px solid rgba(0, 0, 0, 0.125)',
    color: 'inherit',
  },
  Table: {
    '&:first-child': {
      borderRight: '1px solid rgba(0, 0, 0, 0.125)',
    },
    marginLeft: 5,
    marginTop: 0,
  },
  TableHead: {
    borderBottom: 'unset',
    '&>*>*': {
      fontWeight: 'bold',
      color: 'inherit',
    },
  },
  TableBody: {
    borderBottom: 'unset',
    color: 'inherit',
  },
});
