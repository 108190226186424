/* eslint-disable no-console */
import Grid from '@material-ui/core/Grid';
import React from 'react';

import MotorCtrlMonitor from './MotorCtrlMonitorTab';

const MotorCtrlTab = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} xl={12}>
        <MotorCtrlMonitor />
      </Grid>
    </Grid>
  );
};

export default MotorCtrlTab;
