import Grid from '@material-ui/core/Grid';
import React from 'react';

import CabinetHkControlCard from './CabinetHkControlCard';
import CabinetHkStatusCard from './CabinetHkStatusCard';
import HKMonitorTab from './HKMonitorTab';

const CabinetHkTab = () => (
  <Grid container spacing={1}>
    <Grid item>
      <HKMonitorTab />
    </Grid>
    <Grid item>
      <CabinetHkStatusCard />
    </Grid>
    <Grid item xs={12}>
      <CabinetHkControlCard />
    </Grid>
  </Grid>
);

export default CabinetHkTab;
