const encoder = new TextEncoder();
const nullTerminator = Array.from(encoder.encode('\0'));

// eslint-disable-next-line import/prefer-default-export
export function reduceStringFieldState(value, internalState, action) {
  switch (action.type) {
    case 'set-field-text': {
      const newValue = [...Array.from(encoder.encode(action.value)), ...nullTerminator];
      if (newValue.length > action.capacity) {
        return [value, internalState];
      }
      return [newValue, action.value];
    }
    default:
      throw new Error(`Invalid complex field action type: ${action.type}`);
  }
}
