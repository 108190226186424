import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useContext } from 'react';
import { useToggle } from 'standard-hooks';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useCurrentMission from '#hooks/useCurrentMission';
import useSnackbar from '#hooks/useSnackbar';

import BustesterPageWrapperStyles from './BustesterPageWrapper.styles';

const useStyles = makeStyles(BustesterPageWrapperStyles);

export default function useBustesterPageWrapper() {
  const {
    missionKey,
    missionMeta: { commandingProtocol },
  } = useCurrentMission();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { enqueueSnackbar } = useSnackbar();

  const tctmCreateFunction = useCallback(
    async (paramsObject, snackbarMessage) => {
      const rpcUrl = `rg/${missionKey}/@c3s/bustester-provider/messageForwarding/ssp`;
      const ret = await rpcWithErrorHandler(rpcUrl, paramsObject);
      if (ret.status !== 'error') {
        enqueueSnackbar(snackbarMessage, { variant: 'info' });
      }
    },
    [missionKey, rpcWithErrorHandler, enqueueSnackbar],
  );
  const fileTransferRpc = { rpc: `rg/${missionKey}/@c3s/tester-provider/sendToBusTester`, format: 'eth' };

  const classes = useStyles();
  const [ShallStickStatus, toggleShallStickStatus] = useToggle(false);
  const [ShallStickHeartbeat, toggleShallStickHeartbeat] = useToggle(false);

  return {
    classes,
    ShallStickStatus,
    toggleShallStickStatus,
    ShallStickHeartbeat,
    toggleShallStickHeartbeat,
    tctmCreateFunction,
    currentResGroup: missionKey,
    commandingProtocol,
    fileTransferRpc,
  };
}
