import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
import useAutonomyStatusDs from '../../useAutonomyStatusDs';
import useCCCStatus from '../useCCCStatusDs';
import useLatestBeacon from '../useLatestBeacon';
import { opModeFromEnum } from './helpers';

const DurationModeOptions = new Map();

export default function useDuration(direction) {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { autonomyStatus } = useAutonomyStatusDs();
  const { state: cccStatus } = useCCCStatus();
  const { state: latestBeacon } = useLatestBeacon();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const theme = useTheme();

  DurationModeOptions.set('manual', { bgColor: theme.palette.yellow.main, label: 'MANUAL', color: 'black' });
  DurationModeOptions.set('auto', { bgColor: theme.palette.success.main, label: 'AUTO', color: 'white' });
  DurationModeOptions.set('request', {
    bgColor: theme.palette.info.main,
    label: 'FILE TRANSFER',
    color: 'white',
  });

  const setDurationManual = useCallback(
    async (duration) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRDirDurControl.${direction}.manualValue`,
        value: 1e3 * parseFloat(duration),
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Duration', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler, direction],
  );

  const setDurationMode = useCallback(
    async (mode) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `SDRDirDurControl.${direction}.mode`,
        value: mode,
      });
      if (status === 'success') {
        enqueueSnackbar('Successfully set Duration Mode', { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler, direction],
  );

  const durationComponents = useMemo(
    () => [
      {
        type: 'status',
        options: {
          helperText: 'Duty Factor',
          value: sanitize(100 * cccStatus?.[`${direction}DutyFactor`], 3),
          unit: '%',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Satellite OpMode',
          value: opModeFromEnum(latestBeacon?.json?.BEACON_PAYLOAD?.SAT_OP_MODE),
        },
      },
      {
        type: 'select',
        options: {
          helperText: 'Mode',
          options: [
            {
              label: 'AUTO',
              value: 'auto',
            },
            {
              label: 'MANUAL',
              value: 'manual',
            },
            {
              label: 'FILE TRANSFER',
              value: 'request',
            },
          ],
          selectedValue: autonomyConfig?.SDRDirDurControl?.[direction]?.mode ?? '',
          handleSet: setDurationMode,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Mode:',
          value: autonomyConfig?.SDRDirDurControl?.[direction]?.mode ?? '-',
          ValueComponent: GeneralStatusIndicator,
          options: DurationModeOptions,
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Duration Manual',
          value: sanitize(1e-3 * autonomyConfig?.SDRDirDurControl?.[direction]?.manualValue),
          numberInputProps: { inputProps: { max: 120, scale: 3 } },
          handleSet: setDurationManual,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Duration Manual',
          value: sanitize(1e-3 * autonomyConfig?.SDRDirDurControl?.[direction]?.manualValue, 3),
          unit: 's',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Duration Auto',
          value: sanitize(1e-3 * autonomyStatus?.SDRDirDurControl?.[direction]?.autoValue, 3),
          unit: 's',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Duration FT',
          value: sanitize(1e-3 * autonomyConfig?.SDRDirDurControl?.[direction]?.requestValue, 3),
          unit: 's',
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Duration Demand',
          value: sanitize(1e-3 * autonomyStatus?.SDRDirDurControl?.[direction]?.outputValue, 3),
          unit: 's',
        },
      },
      {
        type: 'status-important',
        options: {
          helperText: 'Actual Duration',
          value: sanitize(1e-3 * cccStatus?.[`${direction}Duration`], 3),
          unit: 's',
        },
      },
    ],
    [
      direction,
      autonomyConfig.SDRDirDurControl,
      autonomyStatus.SDRDirDurControl,
      cccStatus,
      latestBeacon,
      setDurationManual,
      setDurationMode,
    ],
  );

  return { durationComponents };
}
