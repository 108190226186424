import { UploadDownloadDropDown } from '@c3s/ui-radcube-protocol-tctm';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

import useFileTransferControlState from './useFileTransferControlState';

const ControlDropdown = () => {
  const {
    open,
    setOpen,
    useStyles,
    uploadState,
    downloadState,
    getFileListHandler,
    uploadDispatch,
    downloadDispatch,
    onCreate,
    setMode,
  } = useFileTransferControlState();
  const classes = useStyles();

  return (
    <Accordion square expanded={open} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setOpen((prev) => !prev)}>
        <Typography variant="h6">Upload/Download File</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <UploadDownloadDropDown
          uploadDefaults={uploadState}
          downloadDefaults={downloadState}
          loadFileList={() => getFileListHandler({ skip: 0, limit: 100 })}
          updateUploadValues={uploadDispatch}
          updateDownloadValues={downloadDispatch}
          onCreate={onCreate}
          setMode={setMode}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ControlDropdown;
