import Grid from '@material-ui/core/Grid';
import React from 'react';

import MocFilesPanel from './moc-files/MocFilesPanel';
import SatelliteFilesPanel from './satellite-files/SatelliteFilesPanel';

const FileManagementTab = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SatelliteFilesPanel />
      </Grid>
      <Grid item xs={12}>
        <MocFilesPanel />
      </Grid>
    </Grid>
  );
};

export default FileManagementTab;
