import { ControlCard } from '@c3s/ui-tracking-station';
import { Button, Grid } from '@material-ui/core';
import React from 'react';

import usePAConstants from './PAConstantHooks/usePAConstants';
import usePaPowerControl from './PAPowerControlHooks/usePAPowerControl';
import usePaStatus from './PAStatusHooks/usePAStatus';
import useRffeConnection from './RffeConnectionHooks/useRffeConnection';
import useRffeFanControl from './RffeFanControlHooks/useRffeFanControl';
import useTxRxControl from './TxRxControlHooks/useTxRxControl';

const RffeMonitorTab = () => {
  const { rffeConnectionComponents, reset } = useRffeConnection();
  const { txRxControlComponents } = useTxRxControl();
  const { paPowerComponents } = usePaPowerControl();
  const { paStatusComponents } = usePaStatus();
  const { paConstantComponents } = usePAConstants();
  const { rffeFanComponents } = useRffeFanControl();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <ControlCard title="RF-FE Connection" components={rffeConnectionComponents} style={{ width: 430 }} />
            <Button onClick={reset} variant="contained" color="primary" style={{ width: '100%', margin: '1em 0' }}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ControlCard title="TX-RX Control" components={txRxControlComponents} style={{ width: 430, height: '100%' }} />
      </Grid>
      <Grid item>
        <ControlCard title="PA Power Control" components={paPowerComponents} style={{ width: 430, height: '100%' }} />
      </Grid>
      <Grid item>
        <ControlCard title="PA Status" components={paStatusComponents} style={{ width: 430, height: '100%' }} />
      </Grid>
      <Grid item>
        <ControlCard title="RF-FE FAN Control" components={rffeFanComponents} style={{ width: 430, height: '100%' }} />
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <ControlCard title="PA Constants" components={paConstantComponents} style={{ width: 430 }} />
            <Button variant="contained" color="primary" style={{ width: '100%', margin: '1em 0' }} disabled>
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RffeMonitorTab;
