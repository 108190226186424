import { SspTable, TableFilters } from '@c3s/ui-radcube-message-logging';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React, { forwardRef, useContext, useEffect, useReducer, useRef, useState } from 'react';

import MultiSelectWithCheckbox from '#components/ui-helper/MultiSelectWithCheckbox';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

import { initialState, tableHeader } from './SSPTabHelpers';

function reducer(state, action) {
  return { ...state, ...action };
}

const SSPTab = () => {
  const tableRef = useRef();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();
  const [{ version, startTime, endTime, SN, Ack, Nack, Src, Dst, Type }, dispatch] = useReducer(reducer, initialState);
  const [filterVersion, setFilterVersion] = useState([]);
  const [filterSN, setFilterSN] = useState([]);
  const [filterACK, setFilterACK] = useState([]);
  const [filterNACK, setFilterNACK] = useState([]);
  const [filterSrc, setFilterSrc] = useState([]);
  const [filterDst, setFilterDst] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [filterDirectionMask, setFilterDirectionMask] = useState({
    encoded: 0,
    decoded: 0,
  });

  useEffect(() => {
    async function getFilters() {
      const { result, status } = await rpcWithErrorHandler(
        `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessageFilters`,
        {
          protocol: 'SSP',
        },
      );
      if (status === 'success' && Object.keys(result).includes('version')) {
        setFilterVersion(result.version?.map((label) => ({ label, value: label })));
        setFilterSN(result['json.SN']?.map((label) => ({ label, value: label })));
        setFilterDst(result['json.Dst']?.map((label) => ({ label, value: label })));
        setFilterACK(result['json.Ack']?.map((label) => ({ label, value: label })));
        setFilterNACK(result['json.Nack']?.map((label) => ({ label, value: label })));
        setFilterSrc(result['json.Src']?.map((label) => ({ label, value: label })));
        setFilterType(result['json.Type']?.map((label) => ({ label, value: label })));
      }
    }
    getFilters();
  }, [currentResGroup, rpcWithErrorHandler]);

  function onStartChange(date) {
    dispatch({ startTime: date });
  }

  function onEndChange(date) {
    dispatch({ endTime: date });
  }

  function refresh() {
    tableRef.current.onQueryChange();
  }

  function SSPFilters() {
    return (
      <Grid container spacing={1} style={{ margin: '1em 1em 1em 4px' }}>
        <Grid item>
          <MultiSelectWithCheckbox
            title="SN"
            dropdownArray={filterSN}
            state={{ SN: SN.length ? SN.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="ACK"
            dropdownArray={filterACK}
            state={{ Ack: Ack.length ? Ack.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="NACK"
            dropdownArray={filterNACK}
            state={{ Nack: Nack.length ? Nack.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="Source"
            dropdownArray={filterSrc}
            state={{ Src: Src.length ? Src.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="Destination"
            dropdownArray={filterDst}
            state={{ Dst: Dst.length ? Dst.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item>
          <MultiSelectWithCheckbox
            title="Type"
            dropdownArray={filterType}
            state={{ Type: Type.length ? Type.map((label) => ({ label, value: label })) : [] }}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
    );
  }

  const sspFilter = {
    transformation: filterDirectionMask,
    version: version.length ? version : [],
    timestamp: [startTime, endTime],
    json: {
      SN: SN.length ? SN : [],
      Ack: Ack.length ? Ack : [],
      Nack: Nack.length ? Nack : [],
      Src: Src.length ? Src : [],
      Dst: Dst.length ? Dst : [],
      Type: Type.length ? Type : [],
    },
  };

  const getSspMessages = async ({ page, pageSize, orderBy, orderDirection }) => {
    const { result, status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/getLoggedMessages`,
      {
        protocol: 'SSP',
        filter: sspFilter,
        limit: pageSize,
        skip: pageSize * page,
        sort: {
          field: orderBy?.field,
          direction: orderDirection,
        },
      },
    );

    if (status === 'success') {
      return {
        data: result.data,
        page,
        totalCount: result.recordsTotal,
      };
    }
    return {
      data: [],
      page,
      totalCount: 0,
    };
  };

  const exportSspMessages = async () => {
    const fileName = `ssp-log-${Date.now()}.csv`;

    const { status } = await rpcWithErrorHandler(
      `rg/${currentResGroup}/@c3s/log-reader-provider/exportLoggedMessages`,
      {
        protocol: 'SSP',
        filter: sspFilter,
        sort: {
          direction: '',
        },
        bucketID: undefined,
        fileName,
      },
    );

    if (status === 'success') {
      enqueueSnackbar(`Filtered SSP message log saved to MOC files as ${fileName}`, { variant: 'info' });
    }
  };

  const sspActions = [
    {
      isFreeAction: true,
      icon: forwardRef((props, ref) => (
        <FontAwesomeIcon {...props} ref={ref} icon={['fas', 'file-download']} style={{ fontSize: 18 }} />
      )),
      tooltip: 'Create Archive',
      onClick: () => {
        exportSspMessages();
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableFilters
          startTime={startTime}
          endTime={endTime}
          version={version.length ? version.map((label) => ({ label, value: label })) : []}
          filterDirectionMask={filterDirectionMask}
          setFilterDirectionMask={setFilterDirectionMask}
          filterVersion={filterVersion}
          dispatch={dispatch}
          tabSpecificFilters={SSPFilters}
          refresh={refresh}
          onStartChange={onStartChange}
          onEndChange={onEndChange}
        />
      </Grid>
      <Grid item xs={12}>
        <SspTable tableRef={tableRef} actions={sspActions} TableHeader={tableHeader} getRemoteData={getSspMessages} />
      </Grid>
    </Grid>
  );
};

export default SSPTab;
