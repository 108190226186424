import { _TCTM_TypeDefs as TCTM } from '@c3s/misc_radcube_tctm_packets_enums'; // eslint-disable-line camelcase
/* eslint-disable camelcase */
import {
  validate_TCTM_OBC_0B_0A,
  validate_TCTM_OBC_0B_0B,
  validate_TCTM_OBC_0B_0C,
  validate_TCTM_OBC_0B_0F,
  validate_TCTM_OBC_0B_08,
  validate_TCTM_OBC_0B_11,
  validate_TCTM_OBC_0B_14,
  validate_TCTM_OBC_0B_17,
  validate_TCTM_OBC_0B_19,
} from '@c3s/ui-radcube-protocol-tctm';
import printf from 'printf';

const FileMgmtSubServiceNumberSet = new Set([
  TCTM.file_management_subservice_e.FM_SUBSERVICE_DISK_INFO_REPORT,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_DISK_DIR_REPORT_0,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_DISK_DIR_REPORT_1,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_DISK_DIR_REPORT_2,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_DIRECT_DISK_READ_REPORT,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_CHECKSUM_REPORT,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_FILE_REPORT,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_FILE_READ_REPORT,
  TCTM.file_management_subservice_e.FM_SUBSERVICE_FS_STATUS_REPORT,
]);

const getValues = (tmData) => {
  const retObj = {};
  Object.entries(tmData).forEach(([key, val]) => {
    retObj[key] = val.value;
  });
  return retObj;
};

export const setObcTm = ({ telemetry, dispatch, enqueueSnackbar }) => {
  // eslint-disable-next-line no-unused-expressions
  if (
    telemetry.json.Packet.APID === TCTM.apid_e.APID_OBC &&
    telemetry.json.Packet.Service === TCTM.service_e.SERVICE_FILE_MANAGEMENT
  ) {
    const {
      json: {
        Packet: { SubService, Data },
      },
    } = telemetry;

    // eslint-disable-next-line no-unused-expressions
    FileMgmtSubServiceNumberSet.has(SubService)
      ? dispatch({
          type: `set${printf('%02X', telemetry.json.Packet.Service)}_${printf('%02X', SubService)}`,
          value: Data,
        })
      : enqueueSnackbar(`Unknown Subservice number at FileManagementService.jsx: ${telemetry.json.Packet}`, {
          variant: 'error',
        });
  }
};

export const initialState = {
  TM_0B_08: {},
  err_0B_08: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_0A: {},
  err_0B_0A: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_0B: {},
  err_0B_0B: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_0C: {},
  err_0B_0C: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_0F: {},
  err_0B_0F: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_11: {},
  err_0B_11: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_14: {},
  err_0B_14: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_17: {},
  err_0B_17: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
  TM_0B_19: {},
  err_0B_19: { status: 'err', Msg: 'Disconnected', lastValidTimestamp: 0 },
};

export const reducer = (state, action) => {
  const relevantValues = getValues(action.value);
  switch (action.type) {
    case 'set0B_08':
      try {
        const validatedValues = validate_TCTM_OBC_0B_08(relevantValues);
        return {
          ...state,
          TM_0B_08: validatedValues.value,
          err_0B_08: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_08: { ...state.err_0B_08, status: 'err', Msg: err } };
      }
    case 'set0B_0A':
      try {
        const validatedValues = validate_TCTM_OBC_0B_0A(relevantValues);
        return {
          ...state,
          TM_0B_0A: validatedValues.value,
          err_0B_0A: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_0A: { ...state.err_0B_0A, status: 'err', Msg: err } };
      }
    case 'set0B_0B':
      try {
        const validatedValues = validate_TCTM_OBC_0B_0B(relevantValues);
        return {
          ...state,
          TM_0B_0B: validatedValues.value,
          err_0B_0B: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_0B: { ...state.err_0B_0B, status: 'err', Msg: err } };
      }
    case 'set0B_0C':
      try {
        const validatedValues = validate_TCTM_OBC_0B_0C(relevantValues);
        return {
          ...state,
          TM_0B_0C: validatedValues.value,
          err_0B_0C: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_0C: { ...state.err_0B_0C, status: 'err', Msg: err } };
      }
    case 'set0B_0F':
      try {
        const validatedValues = validate_TCTM_OBC_0B_0F(relevantValues);
        return {
          ...state,
          TM_0B_0F: validatedValues.value,
          err_0B_0F: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_0F: { ...state.err_0B_0F, status: 'err', Msg: err } };
      }
    case 'set0B_11':
      try {
        const validatedValues = validate_TCTM_OBC_0B_11(relevantValues);
        return {
          ...state,
          TM_0B_11: validatedValues.value,
          err_0B_11: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_11: { ...state.err_0B_11, status: 'err', Msg: err } };
      }
    case 'set0B_14':
      try {
        const validatedValues = validate_TCTM_OBC_0B_14(relevantValues);
        return {
          ...state,
          TM_0B_14: validatedValues.value,
          err_0B_14: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_14: { ...state.err_0B_14, status: 'err', Msg: err } };
      }
    case 'set0B_17':
      try {
        const validatedValues = validate_TCTM_OBC_0B_17(relevantValues);
        return {
          ...state,
          TM_0B_17: validatedValues.value,
          err_0B_17: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_17: { ...state.err_0B_17, status: 'err', Msg: err } };
      }
    case 'set0B_19':
      try {
        const validatedValues = validate_TCTM_OBC_0B_19(relevantValues);
        return {
          ...state,
          TM_0B_19: validatedValues.value,
          err_0B_19: { status: 'ok', Msg: null, lastValidTimestamp: Date.now() },
        };
      } catch (err) {
        return { ...state, err_0B_19: { ...state.err_0B_19, status: 'err', Msg: err } };
      }
    default:
      throw new Error();
  }
};
