import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useDsRecord from '#hooks/useDsRecord';
import useSnackbar from '#hooks/useSnackbar';

const periodMin = 50;
const periodMax = 5000;

export default function useCanAbsTimeCard() {
  const { enqueueSnackbar } = useSnackbar();

  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const rpcUrl = `rg/${currentResGroup}/@c3s/bustester-provider/canAbsTimeDist`;

  const timeDistDsRecord = useDsRecord('bustester', 'control/timedist');
  const [TimeDistAsObc, setTimeDistAsObc] = useState(0);
  const [TimeDistPeriodTimeInMs, setTimeDistPeriodTimeInMs] = useState(0);
  const isPeriodInvalid = TimeDistPeriodTimeInMs < periodMin || TimeDistPeriodTimeInMs > periodMax;

  const processRpcResponse = async ({ endpoint, paramsObject, snackbarMessage }) => {
    const ret = await rpcWithErrorHandler(endpoint, paramsObject);
    if (ret.status !== 'error') {
      enqueueSnackbar(snackbarMessage, { variant: 'info' });
    }
  };

  const persistState = () => {
    timeDistDsRecord.set({ ...timeDistDsRecord.get(), TimeDistPeriodTimeInMs, TimeDistAsObc });
  };

  useEffect(() => {
    timeDistDsRecord.whenReady((record) => {
      setTimeDistAsObc(record.get()?.TimeDistAsObc || 0);
      setTimeDistPeriodTimeInMs(record.get()?.TimeDistPeriodTimeInMs ?? periodMin);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDisable = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/disableAbsTimeDistribution`,
      paramsObject: {
        Red: TimeDistAsObc,
      },
      snackbarMessage: 'Disable Time Distribution Service requested',
    });
  };

  const onEnable = () => {
    persistState();
    processRpcResponse({
      endpoint: `${rpcUrl}/enableAbsTimeDistribution`,
      paramsObject: {
        Red: TimeDistAsObc,
        PeriodTimeInMs: TimeDistPeriodTimeInMs,
      },
      snackbarMessage: 'Enable Time Distribution Service requested',
    });
  };

  const getStatus = () => {
    processRpcResponse({
      endpoint: `${rpcUrl}/getStatus`,
      paramsObject: null,
      snackbarMessage: 'Get Time Distribution Service Status requested',
    });
  };

  return {
    onDisable,
    onEnable,
    getStatus,
    TimeDistAsObc,
    TimeDistPeriodTimeInMs,
    periodMin,
    periodMax,
    isPeriodInvalid,
    setTimeDistAsObc,
    setTimeDistPeriodTimeInMs,
  };
}
