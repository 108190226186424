import { VoltageConverterCard } from '@c3s/ui-radcube-connector-bustester';
import React from 'react';

import usePowerCard from './usePowerCard';

const PowerCard = () => {
  const { changeVoltageState } = usePowerCard();

  return <VoltageConverterCard handleAction={changeVoltageState} />;
};

export default PowerCard;
