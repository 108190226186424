import { blue, blueGrey, brown, green, lightGreen, red, yellow } from '@material-ui/core/colors';

import styles from '#utils/styles';

export default (theme) => ({
  ...styles(theme),
  thDateTime: {
    minWidth: 200,
  },
  thBool: {
    textAlign: 'center',
    minWidth: 50,
  },
  thIdentifier: {
    minWidth: 100,
  },
  tdPayload: {
    maxWidth: 300,
    cursor: 'pointer',
    '&:active': {
      overflow: 'unset',
      whiteSpace: 'unset',
      textOverflow: 'unset',
      wordBreak: 'break-all',
    },
  },
  tableRowStyle: {
    height: 24,
  },
  FontAwesomeIcon: {
    textAlign: 'center',
    color: '#bdbdbd',
  },
  RedBg: {
    background: red[50],
  },
  BlueBg: {
    background: blue[50],
  },
  YellowBg: {
    background: yellow[50],
  },
  GreenBg: {
    background: green[50],
  },
  LightGreenBg: {
    background: lightGreen[50],
  },
  BrownBg: {
    background: brown[50],
  },
  BlueGreyBg: {
    background: blueGrey[50],
  },
});
