import {
  TCTM_EPS_05_0B,
  TCTM_EPS_05_02,
  TCTM_EPS_05_04,
  TCTM_EPS_05_07,
  TCTM_EPS_05_16,
  TCTM_EPS_05_19,
} from '@c3s/ui-radcube-protocol-tctm';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useToggle } from 'standard-hooks';

import CardHeader from '#components/ui-helper/CardHeaderWithStatus';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import useThrottle from '#hooks/useThrottle';
import styles from '#utils/styles';

import { initialState, reducer, setObcTm } from './TestService.helpers';

const useStyles = makeStyles(styles);

const TestService = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, newSetValue] = useThrottle(500, [state, dispatch]);

  const setObcTelemetryWithRef = useRef((val) => setObcTm({ telemetry: val, dispatch: newSetValue, enqueueSnackbar }));
  const subscribeToTelemetry = setObcTelemetryWithRef.current;

  const [open, toggleOpen] = useToggle(false);

  const { currentResGroup } = useContext(RgContext);
  const { dsClient } = useContext(DeepstreamContext);

  useEffect(() => {
    dsClient.event.subscribe(`rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`, subscribeToTelemetry);

    return function cleanup() {
      dsClient.event.unsubscribe(
        `rg/${currentResGroup}/@c3s/tctm-transcoder-provider/decodedTCTM`,
        subscribeToTelemetry,
      );
    };
  }, [currentResGroup, subscribeToTelemetry, dsClient]);

  return (
    <Accordion square expanded={open} className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleOpen}>
        <Typography variant="h6">Test Service</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={4} md={4} lg={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    error={value.err_05_0B.status}
                    lastUpdated={value.err_05_0B.lastValidTimestamp}
                    errorMsg={value.err_05_0B.Msg}
                    titleText="TCTM_EPS_05_0B"
                  />
                  <CardContent>
                    <TCTM_EPS_05_0B status={value.TM_05_0B} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    error={value.err_05_16.status}
                    lastUpdated={value.err_05_16.lastValidTimestamp}
                    errorMsg={value.err_05_16.Msg}
                    titleText="TCTM_EPS_05_16"
                  />
                  <CardContent>
                    <TCTM_EPS_05_16 status={value.TM_05_16} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    error={value.err_05_02.status}
                    lastUpdated={value.err_05_02.lastValidTimestamp}
                    errorMsg={value.err_05_02.Msg}
                    titleText="TCTM_EPS_05_02"
                  />
                  <CardContent>
                    <TCTM_EPS_05_02 status={value.TM_05_02} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    error={value.err_05_04.status}
                    lastUpdated={value.err_05_04.lastValidTimestamp}
                    errorMsg={value.err_05_04.Msg}
                    titleText="TCTM_EPS_05_04"
                  />
                  <CardContent>
                    <TCTM_EPS_05_04 status={value.TM_05_04} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    error={value.err_05_19.status}
                    lastUpdated={value.err_05_19.lastValidTimestamp}
                    errorMsg={value.err_05_19.Msg}
                    titleText="TCTM_EPS_05_19"
                  />
                  <CardContent>
                    <TCTM_EPS_05_19 status={value.TM_05_19} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Card>
              <CardHeader
                error={value.err_05_07.status}
                lastUpdated={value.err_05_07.lastValidTimestamp}
                errorMsg={value.err_05_07.Msg}
                titleText="TCTM_EPS_05_07"
              />
              <CardContent>
                <TCTM_EPS_05_07 status={value.TM_05_07} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TestService;
