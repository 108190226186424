import { Grid } from '@material-ui/core';
import React from 'react';

import ConnectionCard from './Cards/General/ConnectionCard';
import RoleCard from './Cards/General/RoleCard';

const GeneralTab = () => (
  <Grid container spacing={1}>
    <Grid item md={3}>
      <ConnectionCard />
    </Grid>
    <Grid item md={3}>
      <RoleCard />
    </Grid>
  </Grid>
);

export default GeneralTab;
