import {
  OBCDataAcquisitionServiceDropDown,
  OBCEventReportingServiceDropDown,
  OBCFileManagementServiceDropDown,
  OBCFileTransferServiceDropDown,
  OBCFirmwareServiceDropDown,
  OBCFunctionManagementServiceDropDown,
  OBCHousekeepingReportingServiceDropDown,
  OBCSecurityServiceDropDown,
  OBCTestServiceDropDown,
  OBCTimeManagementServiceDropDown,
} from '@c3s/ui-radcube-protocol-tctm';

export const components = [
  OBCSecurityServiceDropDown,
  OBCHousekeepingReportingServiceDropDown,
  OBCTestServiceDropDown,
  OBCDataAcquisitionServiceDropDown,
  OBCEventReportingServiceDropDown,
  OBCFunctionManagementServiceDropDown,
  OBCTimeManagementServiceDropDown,
  OBCFileManagementServiceDropDown,
  OBCFileTransferServiceDropDown,
  OBCFirmwareServiceDropDown,
];

export const initialOpenState = Array(components.length).fill(false);

export const reducer = (state, { index, value }) => {
  const newState = Array(components.length).fill(false);
  newState[index] = value;
  return newState;
};
