import { diskIDOptions } from '@c3s/ui-radcube-protocol-tctm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  checksumValue: {
    fontFamily: 'monospace',
  },
}));

const FileChecksumPanel = ({ diskId, fileId, checksum, getChecksum }) => {
  const classes = useStyles();

  const copyChecksumToClipBoard = () => {
    navigator.clipboard.writeText(checksum);
  };

  return (
    <>
      <Typography>
        Checksum for file {fileId} on {diskIDOptions.find((option) => option.value === diskId).label}
      </Typography>
      <Grid container spacing={2} alignItems="baseline" justify="space-between">
        <Grid item>
          <Typography component="span">
            {checksum !== undefined ? (
              <>
                <span className={classes.checksumValue}>{`0x${checksum.toString(16).toUpperCase()}`}</span>{' '}
                <Tooltip title="Copy to Clipboard">
                  <IconButton size="small" color="primary" onClick={copyChecksumToClipBoard}>
                    <FontAwesomeIcon icon={['far', 'copy']} />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>&ndash;</>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={getChecksum}>
            Get Checksum
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

FileChecksumPanel.propTypes = {
  diskId: PropTypes.oneOf(diskIDOptions.map((option) => option.value)).isRequired,
  fileId: PropTypes.number.isRequired,
  checksum: PropTypes.number,
  getChecksum: PropTypes.func.isRequired,
};

FileChecksumPanel.defaultProps = {
  checksum: undefined,
};

export default FileChecksumPanel;
