import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import PacketBuilder from '#components/page-content/packet-builder/PacketBuilder';
import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

const Pc2GsProtocolPage = ({ connectorKey }) => {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const { enqueueSnackbar } = useSnackbar();

  const handlePacketSubmit = useCallback(
    async (packet) => {
      const rpcUrl = `rg/owl-gs/${connectorKey}/@c3s/gnd-sw/sendTo`;
      const ret = await rpcWithErrorHandler(rpcUrl, { packet });
      if (ret.status !== 'error') {
        enqueueSnackbar('Telecommand sent', { variant: 'info' });
      }
    },
    [connectorKey, rpcWithErrorHandler, enqueueSnackbar],
  );

  return (
    <Accordion square defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="packet-builder-header">
        <Typography variant="h6">OWL Tester Packet Builder</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* TODO change this when packets have struct metas */}
        <PacketBuilder packetId="__PC2GS" submitPacket={handlePacketSubmit} submitLabel="Send Single Command" />
      </AccordionDetails>
    </Accordion>
  );
};

Pc2GsProtocolPage.propTypes = {
  connectorKey: PropTypes.string.isRequired,
};

export default Pc2GsProtocolPage;
