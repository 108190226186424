import { ControlCard } from '@c3s/ui-tracking-station';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import useAntennaHC from './AntennaHooks/useAntennaHC';
import useCabinetCoolingControl from './CabinetCoolingHooks/useCabinetCoolingControl';
import useCabinetFanControl from './CabinetFanHooks/useCabinetFanControl';
import useCabinetHeaterControl from './CabinetHeaterHooks/useCabinetHeaterControl';
import useHkConnection from './HKConnectionHooks/useHkConnection';
import styles from './HKMonitorTab.styles';
import useMastLampControl from './MastLampHooks/useMastLampControl';
import usePdmcControl from './PdmcHooks/usePdmcControl';
import useRffeControl from './RffeHooks/useRffeControl';
import useServoPowerControl from './ServoPowerHooks/useServoPowerControl';

const useStyles = makeStyles(styles);

const HKMonitorTab = () => {
  const {
    container,
    hkConnCard,
    hkAntCard,
    hkCabinetFanCard,
    hkCabinetHeaterCard,
    hkCabinetCoolingCard,
    hkRffeCard,
    hkServoPwCard,
    hkMastLampCard,
    hkPdmcCard,
    hkPdmcResetButton,
  } = useStyles();
  const { antennaComponents } = useAntennaHC();
  const { hkConnectionComponents } = useHkConnection();
  const { cabinetFanComponents } = useCabinetFanControl();
  const { cabinetHeaterComponents } = useCabinetHeaterControl();
  const { cabinetCoolingComponents } = useCabinetCoolingControl();
  const { rffeComponents } = useRffeControl();
  const { servoComponents } = useServoPowerControl();
  const { mastLampComponents } = useMastLampControl();
  const { pdmcComponents, resetPdmc } = usePdmcControl();

  return (
    <Grid container spacing={1} className={container}>
      <Grid item>
        <ControlCard title="HK Connection" components={hkConnectionComponents} className={hkConnCard} />
      </Grid>
      <Grid item>
        <ControlCard title="Antenna Heater Control" components={antennaComponents} className={hkAntCard} />
      </Grid>
      <Grid item>
        <ControlCard title="Cabinet Fan Control" components={cabinetFanComponents} className={hkCabinetFanCard} />
      </Grid>
      <Grid item>
        <ControlCard
          title="Cabinet Heater Control"
          components={cabinetHeaterComponents}
          className={hkCabinetHeaterCard}
        />
      </Grid>
      <Grid item>
        <ControlCard
          title="Cabinet Cooling Control"
          components={cabinetCoolingComponents}
          className={hkCabinetCoolingCard}
        />
      </Grid>
      <Grid item>
        <ControlCard title="RF-FE Control" components={rffeComponents} className={hkRffeCard} />
      </Grid>
      <Grid item>
        <ControlCard title="Servo Power Control" components={servoComponents} className={hkServoPwCard} />
      </Grid>
      <Grid item>
        <ControlCard title="Mast Lamp Control" components={mastLampComponents} className={hkMastLampCard} />
      </Grid>
      <Grid item>
        <ControlCard title="PDMC Control" components={pdmcComponents} className={hkPdmcCard} />
        <Button variant="contained" color="primary" className={hkPdmcResetButton} onClick={resetPdmc}>
          PDMC Reset
        </Button>
      </Grid>
    </Grid>
  );
};

export default HKMonitorTab;
