import resolveTCTM from '@c3s/tctm-service-dictionary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const tableHeader = [
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'transformation',
    title: 'Tr.',
    // eslint-disable-next-line react/prop-types
    render: ({ transformation }) =>
      transformation === 'encoded' ? (
        <FontAwesomeIcon icon={['far', 'arrow-alt-circle-up']} />
      ) : (
        <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-down']} />
      ),
  },
  {
    cellStyle: {
      minWidth: 300,
    },
    field: 'timestamp',
    title: 'Timestamp',
    render: ({ timestamp }) =>
      DateTime.fromISO(timestamp || Date.now()).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.SCID',
    title: 'SCID',
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.Rev',
    title: 'REV',
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.VC',
    title: 'VC',
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.Packet.APID',
    title: 'APID',
    render: (rowData) => resolveTCTM(rowData.json.SCID, rowData.json.Packet.APID).APID,
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.Packet.Red',
    title: 'Red',
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.Packet.Ack',
    title: 'ACK',
  },
  {
    cellStyle: {
      minWidth: 60,
    },
    field: 'json.Packet.Type',
    title: 'Type',
  },
  {
    cellStyle: {
      minWidth: 200,
    },
    field: 'json.Packet.Service',
    title: 'Service',
    render: (rowData) => resolveTCTM(rowData.json.SCID, rowData.json.Packet.APID, rowData.json.Packet.Service).service,
  },
  {
    cellStyle: {
      minWidth: 250,
    },
    field: 'json.Packet.SubService',
    title: 'Subservice',
    render: (rowData) =>
      resolveTCTM(
        rowData.json.SCID,
        rowData.json.Packet.APID,
        rowData.json.Packet.Service,
        rowData.json.Packet.SubService,
      ).subservice,
  },
  {
    cellStyle: {
      minWidth: 300,
    },
    field: 'version',
    title: 'Version',
  },
];

export const initialState = {
  version: [],
  startTime: DateTime.local().minus({ days: 1 }).toMillis(),
  endTime: DateTime.local().plus({ days: 1 }).toMillis(),
  Rev: [],
  SCID: [],
  VC: [],
  APID: [],
  Red: [],
  Type: [],
  Ack: [],
  Service: [],
  SubService: [],
};
