import { useCallback, useContext, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsEventListener from '#hooks/useDsEventListener';

export default function useEgseControlCard() {
  const { currentResGroup, egseAction } = useContext(RgContext);
  const [progress, setProgress] = useState({ total: 0, remaining: 0, percent: 0 });
  const [reply, setReply] = useState('-');
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const replyCallback = useCallback((r) => {
    setReply(r);
  }, []);

  const progressCallback = useCallback((p) => {
    setProgress(p);
  }, []);

  const eraseChipFinishedCallback = useCallback(() => {
    setButtonsDisabled(false);
  }, []);

  const dumpFinished = useCallback(() => {
    setButtonsDisabled(false);
  }, []);

  useDsEventListener(`rg/${currentResGroup}/@c3s/egse-dump-provider/reply`, replyCallback);
  useDsEventListener(`rg/${currentResGroup}/@c3s/egse-dump-provider/progress`, progressCallback);
  useDsEventListener(`rg/${currentResGroup}/@c3s/egse-dump-provider/eraseChipFinished`, eraseChipFinishedCallback);
  useDsEventListener(`rg/${currentResGroup}/@c3s/egse-dump-provider/dumpFinished`, dumpFinished);

  const enableLog = useCallback(async () => {
    await egseAction('enableLog', true, 'Reqesting enable logging.');
  }, [egseAction]);

  const disableLog = useCallback(async () => {
    await egseAction('enableLog', false, 'Reqesting disable logging.');
  }, [egseAction]);

  const eraseChip = useCallback(async () => {
    setProgress({ total: 0, remaining: 0, percent: 0 });
    setButtonsDisabled(true);
    await egseAction('eraseChip', null, 'Reqesting chip erase.');
  }, [egseAction]);

  const dump = useCallback(async () => {
    setProgress({ total: 0, remaining: 0, percent: 0 });
    setButtonsDisabled(true);
    await egseAction('dump', null, 'Reqesting dump.');
  }, [egseAction]);

  return {
    enableLog,
    disableLog,
    eraseChip,
    dump,
    progress,
    reply,
    buttonsDisabled,
  };
}
