import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import useCurrentMissionEnumMeta from '#hooks/useCurrentMissionEnumMeta';

const useStyles = makeStyles((theme) => ({
  enumLabel: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    columnGap: theme.spacing(1),
  },
  enumLabelNumericValue: {
    flexBasis: 'auto',
    flexGrow: 0,
    fontSize: '0.9em',
    fontFamily: 'Consolas, monospace',
    color: theme.palette.text.secondary,
    lineHeight: '0.8em',
  },
}));

function enumMetaToOption({ displayName, description, numericValue }) {
  return { label: displayName, description, value: parseInt(numericValue, 10) };
}

const EnumField = ({ enumId, enumFilter, value, setValue, ...otherInputProps }) => {
  const classes = useStyles();
  const enumMeta = useCurrentMissionEnumMeta(enumId);

  const options = useMemo(() => {
    if (enumFilter !== undefined) {
      return (
        enumMeta?.items?.filter((e) => enumFilter.includes(parseInt(e.numericValue, 10))).map(enumMetaToOption) ?? []
      );
    }
    return enumMeta?.items?.map(enumMetaToOption) ?? [];
  }, [enumFilter, enumMeta?.items]);

  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <Select
      value={value ?? ''}
      onChange={handleChange}
      MenuProps={{
        elevation: 2,
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      {...otherInputProps}
    >
      {options.map((option) => (
        <MenuItem value={option.value}>
          <Tooltip key={option.value} placement="right" enterDelay={1500} title={option.description}>
            <span className={classes.enumLabel}>
              <Typography variant="inherit" noWrap>
                {option.label}
              </Typography>
              <span className={classes.enumLabelNumericValue}>{`0x${option.value?.toString(16).toUpperCase()}`}</span>
            </span>
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  );
};

EnumField.propTypes = {
  enumId: PropTypes.number.isRequired,
  enumFilter: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
};

EnumField.defaultProps = {
  enumFilter: undefined,
  value: undefined,
};

export default EnumField;
