import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';

export default function useWindAlarm() {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const setWindVelLimit = useCallback(
    (vel) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'windAlarm.windLimit',
          value: vel,
        });
        enqueueSnackbar('Temperature High Limit change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const setHysteresisTime = useCallback(
    (time) => {
      try {
        rpcWithErrorHandler(configPath, {
          path: 'windAlarm.hysteresisTime',
          value: time,
        });
        enqueueSnackbar('Temperature Low Limit change requested', { variant: 'info' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, rpcWithErrorHandler, configPath],
  );

  const windAlarmComponents = useMemo(
    () => [
      {
        type: 'input',
        options: {
          helperText: 'Wind Vel. Limit',
          numberInputProps: { inputProps: { min: 0, max: 200, scale: 1 } },
          value: autonomyConfig.windAlarm.windLimit ?? 0,
          handleSet: setWindVelLimit,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Wind Vel. Limit',
          value: sanitize(autonomyConfig?.windAlarm?.windLimit, 1),
          unit: 'km/h',
        },
      },
      {
        type: 'input',
        options: {
          helperText: 'Hysteresis time',
          numberInputProps: { inputProps: { min: 0, max: 36000, scale: 1 } },
          value: autonomyConfig.windAlarm.hysteresisTime ?? 0,
          handleSet: setHysteresisTime,
        },
      },
      {
        type: 'status',
        options: {
          helperText: 'Current Hysteresis time',
          value: sanitize(autonomyConfig?.windAlarm?.hysteresisTime, 1),
          unit: 's',
        },
      },
    ],
    [autonomyConfig.windAlarm.windLimit, autonomyConfig.windAlarm.hysteresisTime, setWindVelLimit, setHysteresisTime],
  );

  return { windAlarmComponents };
}
