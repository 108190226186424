import { bigFontSize } from '#utils/styles';

export default (theme) => ({
  GridShrink: {
    flexGrow: '0 !important',
    whiteSpace: 'nowrap',
  },
  accordionRoot: {
    '&:before': {
      display: 'none',
    },
    boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
  },
  accordionSummaryRoot: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    margin: 0,
    fontWeight: 500,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  titleColumn: {
    paddingRight: theme.spacing(2),
  },
  title: {
    fontSize: bigFontSize,
  },
  descriptionColumn: {
    display: 'flex',
    verticalAlign: 'middle',
  },
  details: {
    padding: 0,
  },
});
