import merge from 'deepmerge';
import { useCallback, useContext, useMemo, useState } from 'react';

import RgContext from '#contexts/RgContext';
import useDsRecordSubscription from '#hooks/useDsRecordSubscription';

const cccDefaults = {
  uplinkDutyFactor: 0,
  downlinkDutyFactor: 0,
};

export default function useCCCStatusDs() {
  const { currentResGroup } = useContext(RgContext);
  const [state, setState] = useState(cccDefaults);

  const statusPathRoot = useMemo(() => `rg/${currentResGroup}/@c3s/com-cycle-controller`, [currentResGroup]);

  const handleChange = useCallback((newRecord) => {
    setState(merge(cccDefaults, newRecord));
  }, []);

  useDsRecordSubscription(`${statusPathRoot}/status`, handleChange);

  return { state };
}
