import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import hyperid from 'hyperid';
import { DateTime } from 'luxon';
import React, { memo } from 'react';
import isEqual from 'react-fast-compare';

import CardWithStartStopButtons from '#components/page-fragments/CardWithStartStopButtons';

import MessageTableStyles from '../MessageTableStyles';
import useTMTable from './useTMTable';

const useStyles = makeStyles(MessageTableStyles);

const TMTable = () => {
  const classes = useStyles();
  const instance = hyperid();
  const { rows, isRunning, setIsRunning, subscribeToTctm, unsubscribeFromTctm, setRows } = useTMTable();
  return (
    <CardWithStartStopButtons
      title="Received messages"
      isRunning={isRunning}
      onContinue={() => {
        setIsRunning(true);
        subscribeToTctm();
      }}
      onPause={() => {
        setIsRunning(false);
        unsubscribeFromTctm();
      }}
      onClear={() => {
        setRows([]);
      }}
    >
      <TableContainer style={{ maxHeight: 400 }}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.thNumeric}>Apid</TableCell>
              <TableCell className={classes.thNumeric}>Red</TableCell>
              <TableCell className={classes.thNumeric}>Service</TableCell>
              <TableCell className={classes.thNumeric}>SubService</TableCell>
              <TableCell className={classes.thNumeric}>TimeStamp</TableCell>
              <TableCell className={classes.tdShallEllipse}>Payload</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const id = instance();
              const { APID, Red, Service, SubService, Data } = row.json.Packet;
              return (
                <TableRow className={classes.TableRow} key={id}>
                  <TableCell>{APID}</TableCell>
                  <TableCell>{Red}</TableCell>
                  <TableCell>{Service}</TableCell>
                  <TableCell>{SubService}</TableCell>
                  <TableCell>{DateTime.fromMillis(row?.timestamp).toFormat(`yyyy.LL.dd. HH:mm:ss SSS`)}</TableCell>
                  <TableCell className={classes.tdPayload}>{JSON.stringify(Data).toString()}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </CardWithStartStopButtons>
  );
};

export default memo(TMTable, isEqual);
