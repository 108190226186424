import { GeneralStatusIndicator } from '@c3s/ui-tracking-station';
import { useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useMemo } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';
import { sanitize } from '#utils/helper';

import useAutonomyConfigDs from '../../useAutonomyConfigDs';
// import useAutonomyStatusDs from '../../useAutonomyStatusDs';

const RuleStatusOptions = new Map();

export default function useDuration() {
  const { autonomyConfig, configPath } = useAutonomyConfigDs();
  // const { autonomyStatus } = useAutonomyStatusDs();
  const { enqueueSnackbar } = useSnackbar();
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const theme = useTheme();

  RuleStatusOptions.set('on', { bgColor: theme.palette.success.main, label: 'ON', color: 'white' });
  RuleStatusOptions.set('off', { bgColor: theme.palette.warning.main, label: 'OFF', color: 'black' });

  const setParam = useCallback(
    async (index, paramIndex, newValue) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `ITURules[${index}].params[${paramIndex}].value`,
        value: parseFloat(newValue),
      });
      if (status === 'success') {
        enqueueSnackbar(`Successfully set parameter #{paramIndex} value for rule #${index}`, { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const setRule = useCallback(
    async (index, newState) => {
      const { status } = await rpcWithErrorHandler(configPath, {
        path: `ITURules[${index}].status`,
        value: newState,
      });
      if (status === 'success') {
        enqueueSnackbar(`Successfully set Rule #${index} State`, { variant: 'info' });
      }
    },
    [enqueueSnackbar, configPath, rpcWithErrorHandler],
  );

  const ITURulesComponents = useMemo(() => {
    return autonomyConfig?.ITURules?.reduce((ruleArray, currentRule, ruleIndex) => {
      ruleArray.push(
        {
          type: 'buttons',
          options: {
            helperText: `${currentRule?.name} Rule:`,
            button1Text: 'ON',
            handleButton1Click: () => setRule(ruleIndex, true),
            button2Text: 'OFF',
            handleButton2Click: () => setRule(ruleIndex, false),
          },
        },
        {
          type: 'status',
          options: {
            helperText: `${currentRule?.name} Status:`,
            value: currentRule?.status ? 'on' : 'off',
            ValueComponent: GeneralStatusIndicator,
            options: RuleStatusOptions,
          },
        },
      );
      ruleArray.push(
        ...currentRule?.params?.reduce((paramArray, currentParam, paramIndex) => {
          if (currentParam?.type === 'num')
            paramArray.push(
              {
                type: 'input',
                options: {
                  helperText: `Set ${currentParam?.name}`,
                  numberInputProps: { inputProps: { signed: true, scale: 6 } },
                  value: currentParam?.value,
                  handleSet: (value) => setParam(ruleIndex, paramIndex, value),
                },
              },
              {
                type: 'status-important',
                options: {
                  helperText: `Current ${currentParam?.name}`,
                  value: sanitize(currentParam?.value, 3, 0),
                  unit: `${currentParam?.unit}`,
                },
              },
            );
          return paramArray;
        }, []),
      );
      return ruleArray;
    }, []);
  }, [autonomyConfig.ITURules, setRule, setParam]);

  return { ITURulesComponents };
}
